import React from 'react';
import PropTypes from 'prop-types';
import styles from './SelectEmployeeModal.css';

import { connect } from 'react-redux';
import { Button, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { EmployeeSelector } from '_components/EmployeeSelector/EmployeeSelector';

class SelectEmployeeModal extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            modal: false, 
            employees: [],
            employeeRoles: []
        };

        this.toggle = this.toggle.bind(this);
        this.confirm = this.confirm.bind(this);
        this.selectEmployeesCallback = this.selectEmployeesCallback.bind(this);
    }

    selectEmployeesCallback(employeeData, roleData) {
        const { customRoles } = this.props;

        if(customRoles) {
            var keys = Object.keys(employeeData);

            for(var i = 0; i < keys.length; i++) {
                employeeData[keys[i]] = roleData[keys[i]];
            }

            this.setState({
                employeeRoles: employeeData
            });
        } else {
            this.setState({
                employees: employeeData
            });
        }
    }

    toggle() {
        this.setState({
            modal: !this.state.modal,
            employees: []
        });
    }

    confirm() {
        const { confirmCallback, customRoles } = this.props;
        const { employees, employeeRoles } = this.state;

        if(!customRoles && employees && Object.keys(employees).length > 0) {
            //callback of setting state with latest values
            confirmCallback({ succeed: true, employees });
            this.toggle();
        } else if(customRoles && employeeRoles && Object.keys(employeeRoles).length > 0) {
            confirmCallback({ succeed: true, employees: employeeRoles });
            this.toggle();
        }
    }

    render() {
        const { clickable, buttonColor, disabled, customRoles } = this.props;

        return (
        <div>
            {clickable &&
                <div onClick={this.toggle}>
                    {clickable}
                </div>
            }
            {!clickable &&
                <Button className={styles.button} color={buttonColor} disabled={disabled} onClick={this.toggle}>Add Employee</Button>
            }
            <Modal isOpen={this.state.modal} toggle={this.toggle} className={styles.modal}>
                <ModalHeader toggle={this.toggle}>Add New Employee</ModalHeader>
                <ModalBody className={styles.modalbody}>
                    <FormGroup>
                        <EmployeeSelector forTask={false} maxSelections={1} selectedCallback={this.selectEmployeesCallback} customRoles={customRoles}/>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.confirm}>Confirm</Button>
                    <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
        );
    }
}

SelectEmployeeModal.propTypes = {
    buttonColor: PropTypes.string,
    clickable: PropTypes.object,
    confirmCallback: PropTypes.func,
    disabled: PropTypes.bool,
    customRoles: PropTypes.bool
};
  
SelectEmployeeModal.defaultProps = {
    buttonColor: 'success',
    clickable: null,
    confirmCallback: null,
    disabled: false,
    customRoles: false
};

function mapStateToProps(state) {
    const { company } = state;

    return {
        company
    };
}

const connectedSelectEmployeeModal = connect(mapStateToProps)(SelectEmployeeModal);
export { connectedSelectEmployeeModal as SelectEmployeeModal };