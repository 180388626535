import React from 'react';
import checked from '_images/thumbsup.png';
import styles from './AdminPage.module.css';

import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { Button } from 'reactstrap';
import { formatShortDateTime, status, customTableTheme } from '_helpers';
import { adminActions } from '_actions';
import { ConfirmationModal } from '_components/ConfirmationModal';
import { FaTrash } from 'react-icons/fa';
import { CompanySelector } from '_components/CompanySelector';

class AdminQuestionPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            companyId: ''
        }

        this.handleDelete = this.handleDelete.bind(this);
    }

    columns = [
        {
            name: 'Id',
            selector: 'id',
            sortable: true,
            wrap: true
        },
        {
            name: 'Question',
            selector: 'question',
            sortable: true,
            wrap: true
        },
        {
            name: 'Type',
            selector: 'type',
            sortable: true,    
            wrap: true
        },
        {
            name: 'Create Date',
            selector: 'create_date',
            sortable: true,
            wrap: true,
            hide: 'sm'
        },
        {
            name: 'Last Updated',
            selector: 'last_updated',
            sortable: true,
            wrap: true,
            hide: 'sm'
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: true,
            wrap: true,
            hide: 'sm'
        },
        {
            name: '',
            grow: 0,
            right: true,
            hide: 'sm',
            cell: row => <ConfirmationModal bodyText={`By confirming you will be deleting ${row.question} from our site for this company.`} 
                                            clickable={<FaTrash className={styles.edit}/>} 
                                            confirmCallback={this.handleDelete} 
                                            data={row.ref}
                                            />
        }
    ];

    handleDelete({data}) {
        const { dispatch, company } = this.props;
        
        var question = data;
        question.status = 0;

        dispatch(adminActions.deactivateQuestion(company.company_id, question));
    }

    componentDidUpdate() {
        const { dispatch, company } = this.props;
        const { companyId } = this.state;

        if(company && company.company_id && company.company_id !== companyId) {
            this.setState({
                companyId: company.company_id
            }, dispatch(adminActions.getQuestions(company.company_id, 'all')));
        }
    }

    render() {
        const { company, questions } = this.props;

        var data = questions.map(q => {
            return {
                id: q.question_id,
                question: q.text,
                type: q.type,
                create_date: formatShortDateTime(q.create_date),
                last_updated: formatShortDateTime(q.last_update_date),
                status: status(q.status),
                ref: q
            };
        });

        return (
            <div className={styles.container}>
                <div className={styles.headingcontainer}>
                    <CompanySelector/>
                    <h4 className={styles.header}>{company.company_name} Questions</h4>
                    <div>
                        <LinkContainer to='/admin/companies/questions/create' className={styles.headingbutton}>
                            <Button color="success" disabled={company && company.company_id ? false : true}>Create Question</Button>
                        </LinkContainer>
                    </div>
                </div>
                <DataTable
                    columns={this.columns}
                    data={data}
                    striped
                    fixedHeader
                    fixedHeaderScrollHeight='500px'
                    customTheme={customTableTheme}
                    noHeader
                    noDataComponent={
                        <div className={styles.nodatacontainer}>
                            <span className={styles.heading}>No Questions!</span>
                            <span className={styles.para}>You have nothing to view at this time.</span>
                            <img className={styles.checkedimg} src={checked} alt=":("/>
                        </div>
                    }/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { admin } = state;
    const { company, questions } = admin;

    return { 
        company,
        questions
    };
}

const connectedAdminQuestionPage = connect(mapStateToProps)(AdminQuestionPage);
export { connectedAdminQuestionPage as AdminQuestionPage };