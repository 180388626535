import React from 'react';
import PropTypes from 'prop-types';
import checked from '_images/thumbsup.png';
import styles from './TaskEmployeeTable.module.css';

import DataTable from 'react-data-table-component';
import { FaFileDownload } from 'react-icons/fa';
import { connect } from 'react-redux';
import { status, formatShortDateTime, customTableTheme } from '_helpers';
import { activeActions, companyActions, documentActions } from '_actions';
import { Badge, Button, Input } from 'reactstrap';

class TaskEmployeeTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentSearch: '',
            filters: [],
            selectedEmployee: 'select'
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleFilterApply = this.handleFilterApply.bind(this);
        this.handleEmployeeChange = this.handleEmployeeChange.bind(this);
        this.handleBadgeClick = this.handleBadgeClick.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    columns = [
        {
            name: 'Task Name',
            selector: row => row['task_name'],
            sortable: true,
            grow: 2,
        },
        {
            name: 'Last Updated',
            selector: row => row['task_last_update_date'],
            format: row => formatShortDateTime(row.ref.task_last_update_date),
            sortable: true,
            wrap: true
        },
        {
            name: 'Status',
            selector: row => row['task_status'],
            format: row => status(row.ref.task_status),
            sortable: true,
            hide: 'sm'
        },
        {
            name: 'Document',
            selector: row => row['document_name'],
            format: row => row.document_name || 'N/A',
            sortable: true,
            hide: 'sm',
            grow: 2,
        },
        {
            name: '',
            right: true,
            cell: row => row.document_id ? <FaFileDownload className={styles.view} did={row.document_id} onClick={this.handleClick}/> : null
        }
    ];

    handleClick(event) {
        const { dispatch } = this.props;

        var docId = event.currentTarget.getAttribute('did');
        var viewableType = '0';
        var renderType = '';

        dispatch(documentActions.downloadAdminFile(docId, viewableType, renderType));
    }

    handleTextChange(event) {
        this.setState({
            currentSearch: event.target.value
        })
    }

    handleFilterApply() {
        const { currentSearch, filters } = this.state;
        
        if(currentSearch) {
            if(!filters.includes(currentSearch)) {
                filters.push(currentSearch);
            }

            this.setState({
                currentSearch: '',
                filters: filters
            })
        }
    }

    handleEmployeeChange(event) {
        const { dispatch } = this.props;

        var index = event.target.selectedIndex;
        var selectedOption = event.target.childNodes[index];
        var employeeId = selectedOption.getAttribute('eid');

        dispatch(activeActions.getTaskEmployeeSummaries(employeeId));
    }

    handleBadgeClick(e) {
        var id = e.target.getAttribute("fid");

        const { filters } = this.state;

        for(var i = 0; i < filters.length; i++) {
            if(filters[i] === id) {
                filters.splice(i, 1);
            }
        }

        this.setState({
            filters: filters
        });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.handleFilterApply();
        }
    }
    
    componentDidMount() {
        const { dispatch, filter } = this.props;

        dispatch(companyActions.getEmployeesByFilter(filter));
    }

    render() {
        const { currentSearch, filters } = this.state;
        const { employees, employeeTaskSummaries } = this.props;

        var data = employeeTaskSummaries.filter(t => t.task_status !== 4).reduce((result, t) => {
            if(filters.length === 0) {
                result.push({
                    ...t,
                    ref: t
                });
            } else {
                for(var i = 0; i < filters.length; i++) {
                    if( t.task_name.toLowerCase().includes(filters[i].toLowerCase()) || 
                        (t.document_name || '').toLowerCase().includes(filters[i].toLowerCase())) {

                        result.push({
                            ...t,
                            ref: t
                        });
                        break;
                    }
                }
            }
            return result;
        }, []);

        return (
            <div className={styles.container}>
                <div className={styles.taskbarcontainer}>
                    <Input type='select' className={styles.selectinput} defaultValue={'select'} onChange={this.handleEmployeeChange}>
                        <option key={'select'} value='select' disabled={true}>Select An Employee...</option>
                        {(employees || []).filter(e => e.status !== 1).map(e => {
                            return <option key={e.user.user_id} eid={e.user.user_id}>{e.user.first_name} {e.user.last_name}</option>
                        })
                        }
                    </Input>
                    <div className={styles.filtercontainer}>
                        {filters.map(f => {

                            return  <Badge pill className={styles.badge} key={f} fid={f} onClick={this.handleBadgeClick}>
                                        <span className={styles.btext} fid={f} onClick={this.handleBadgeClick}>{f}</span>
                                        <span fid={f} className={styles.ex} onClick={this.handleBadgeClick}>X</span>
                                    </Badge>
                            
                        })
                        }
                        <Input className={styles.filterinput} placeholder='Search...' onChange={this.handleTextChange} onKeyDown={this.handleKeyPress} value={currentSearch}/>
                        <Button className={styles.filterbutton} onClick={this.handleFilterApply}>Apply</Button>
                    </div>
                </div>
                <DataTable
                    columns={this.columns}
                    data={data}
                    striped
                    fixedHeader
                    fixedHeaderScrollHeight='500px'
                    customTheme={customTableTheme}
                    noHeader
                    noDataComponent={
                        <div className={styles.nodatacontainer}>
                            <span className={styles.heading}>No Tasks!</span>
                            <span className={styles.para}>You have nothing to view at this time.</span>
                            <img className={styles.checkedimg} src={checked} alt=":("/>
                        </div>
                    }
                />
            </div>        
        );
    }
}

TaskEmployeeTable.propTypes = {
    filter: PropTypes.string
};
  
TaskEmployeeTable.defaultProps = {
    filter: 'all'
};

function mapStateToProps(state) {
    const { active, company } = state;
    const { employeeTaskSummaries } = active;
    const { employees } = company;

    return {
        employees,
        employeeTaskSummaries
    };
}

const connectedTaskEmployeeTable = connect(mapStateToProps)(TaskEmployeeTable);
export { connectedTaskEmployeeTable as TaskEmployeeTable };