import { authHeader, config, currentCompany, handleResponse, handleError, currentUser  } from '_helpers';

export const assetService = {
    getAllCompanyAssets,
    getAsset,
    getAssetTemplates,
    getAssetTemplate,
    create,
    update,
    createAssetTemplate,
    updateAssetTemplate,
    getAssetAnnualSummary,
    getAssetAnnualOrganizationSummary,
    getAssetAnnualDetailedReport,
    createUpdateAssetDetails,
    getAssetDetailLimits,
    getOrganizationAssetDetailLimits,
    updateAssetDetailLimits,
    updateOrganizationAssetDetailLimits
};

function getAllCompanyAssets(filter) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/assets?filter=${filter}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function getAsset(assetId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/assets/${assetId}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function getAssetTemplates(filter) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/assets/templates?filter=${filter}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });
}

function getAssetAnnualSummary(year) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/assets/details/${year}/${currentUser().user_id}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });
}

function getAssetAnnualOrganizationSummary(year) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/assets/details/${year}/organizations`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });
}

function getAssetAnnualDetailedReport(asset_id, year) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/assets/${asset_id}/detailedreport/${year}/${currentUser().user_id}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });
}

function getAssetTemplate(assetTemplateType) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/assets/templates/${assetTemplateType}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });
}

function create(asset) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(asset)
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/assets`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function update(asset) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(asset)
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/assets/${asset.asset_id}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function createAssetTemplate(assetTemplate){
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(assetTemplate)
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/assets/templates`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function updateAssetTemplate(assetTemplate){
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(assetTemplate)
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/assets/templates/${encodeURIComponent(assetTemplate.type)}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function createUpdateAssetDetails(assetId, year, data){
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/assets/${assetId}/details/${year}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
    });
}

function getAssetDetailLimits(assetId, year){
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/assets/${assetId}/limits/${year}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
    });
}

function getOrganizationAssetDetailLimits(year){
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/assets/organization/limits/${year}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
    });
}

function updateAssetDetailLimits(assetId, year, data){
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/assets/${assetId}/limits/${year}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
    });
}

function updateOrganizationAssetDetailLimits(year, data){
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/assets/organization/limits/${year}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
    });
}