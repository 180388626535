import React from 'react';
import styles from './HomePage.module.css';

import { connect } from 'react-redux';
import { Label } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { MdFolderOpen } from 'react-icons/md';
import { CompanySelector } from '_components/CompanySelector';

class AdminHomePage extends React.Component {
    render() {
        const { user } = this.props;
        return (
            <div className={styles.container}>
                <h2 className={styles.heading}>Welcome {user.first_name} {user.last_name},</h2>
                <p className={styles.para}>Admin Panel</p>
                <CompanySelector/>
                <div className={styles.activitycontainer}>
                    <LinkContainer key='companies' to='/admin/companies'>
                        <div className={styles.activity}>
                            <Label className={styles.activitylabel}>Companies</Label>
                            <MdFolderOpen size={50}/>
                        </div>
                    </LinkContainer>
                    <LinkContainer key='questions' to='/admin/companies/questions'>
                        <div className={styles.activity}>
                            <Label className={styles.activitylabel}>Questions</Label>
                            <MdFolderOpen size={50}/>
                        </div>
                    </LinkContainer>
                    <LinkContainer key='doctemplates' to='/admin/companies/documents/templates'>
                        <div className={styles.activity}>
                            <Label className={styles.activitylabel}>Document Templates</Label>
                            <MdFolderOpen size={50}/>
                        </div>
                    </LinkContainer>
                    <LinkContainer key='actiontemplates' to='/admin/companies/actions/templates'>
                        <div className={styles.activity}>
                            <Label className={styles.activitylabel}>Action Templates</Label>
                            <MdFolderOpen size={50}/>
                        </div>
                    </LinkContainer>
                    <LinkContainer key='tasktemplates' to='/admin/companies/tasks/templates'>
                        <div className={styles.activity}>
                            <Label className={styles.activitylabel}>Task Templates</Label>
                            <MdFolderOpen size={50}/>
                        </div>
                    </LinkContainer>
                    <LinkContainer key='assets' to='/company/assets'>
                        <div className={styles.activity}>
                            <Label className={styles.activitylabel}>Assets</Label>
                            <MdFolderOpen size={50}/>
                        </div>
                    </LinkContainer>
                    <LinkContainer key='assettemplates' to='/company/assets/templates'>
                        <div className={styles.activity}>
                            <Label className={styles.activitylabel}>Asset Templates</Label>
                            <MdFolderOpen size={50}/>
                        </div>
                    </LinkContainer>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    const { user } = authentication;
    return {
        user
    };
}

const connectedAdminHomePage = connect(mapStateToProps)(AdminHomePage);
export { connectedAdminHomePage as AdminHomePage };