import React from 'react';
import styles from './CompanyOwnerInvitePage.module.css';

import { connect } from 'react-redux';
import { CompanyForm } from '_components/CompanyForm';

class CompanyOwnerInvitePage extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    
    render() {
        return (
            <div className={styles.container}>
                <div className={styles.headingcontainer}>
                    <h4 className={styles.header}>Complete Company Information</h4>
                </div>
                <CompanyForm acceptCompany={true}></CompanyForm>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

const connectedCompanyOwnerInvitePage = connect(mapStateToProps)(CompanyOwnerInvitePage);
export { connectedCompanyOwnerInvitePage as CompanyOwnerInvitePage };