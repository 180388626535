import React from 'react';
import styles from './NavigationBar.module.css';
import user_fallback from '_images/user_fallback.svg';
import logo from '_images/LongLogoDark256a.png';

import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Helmet } from 'react-helmet';

class UserNavBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        };
        
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    
    render() {
        const{ user } = this.props;

        return (
            <div className={styles.navcontainer}>
                <Helmet> 
                    <title>Welcome To TackleDocs!</title>
                    <meta name="description" content="Manage your business from your smartphone. Generate Invoices, Documents and Customer agreements on the fly! Keep organized with our Company Calendar." />
                </Helmet>
                <Navbar className={styles.navbar} dark expand="md">
                    <LinkContainer to='/home'>
                        <NavbarBrand><img src={logo} className={styles.brand} alt=":("/></NavbarBrand>
                    </LinkContainer>   
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="ml-auto" navbar>
                            <LinkContainer to='/documents'>
                                <NavItem>
                                    <NavLink>
                                        <span className={styles.linkcontainer}>
                                            My Documents
                                        </span>
                                    </NavLink>
                                </NavItem>
                            </LinkContainer>
                            <LinkContainer to='/companies'>
                                <NavItem>
                                    <NavLink>
                                        <span className={styles.linkcontainer}>
                                            My Companies
                                        </span>
                                    </NavLink>
                                </NavItem>
                            </LinkContainer>
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret>
                                    <span className={styles.linkcontainer}>
                                        { user.first_name } 
                                        {user.user_logo_path ? 
                                            <img src={user.user_logo_path} className={styles.logo} alt=":("></img> : 
                                            <img src={user_fallback} className={styles.logo} alt=":("></img>
                                        }
                                    </span>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <LinkContainer to='/profile' activeClassName="">
                                        <DropdownItem>
                                            Profile
                                        </DropdownItem>
                                    </LinkContainer>
                                    <LinkContainer to='/profile/password' activeClassName="">
                                        <DropdownItem>
                                            Update Password
                                        </DropdownItem>
                                    </LinkContainer>
                                    <LinkContainer to='/preferences' activeClassName="">
                                        <DropdownItem>
                                            Preferences
                                        </DropdownItem>
                                    </LinkContainer>
                                    <DropdownItem divider />
                                    <LinkContainer to='/login' activeClassName="">
                                        <DropdownItem>
                                            Logout
                                        </DropdownItem>
                                    </LinkContainer>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    const { user } = authentication;
    return {
        user
    };
}

const connectedUserNavBar = connect(mapStateToProps)(UserNavBar);
export { connectedUserNavBar as UserNavBar };