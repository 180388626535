import { userConstants } from '_constants';
import { userService } from '_services';
import { alertActions } from './';
import { history } from '_helpers';

export const userActions = {
    login,
    logout,
    register,
    resetRegistering,
    completeRegister,
    update,
    refreshToken,
    resetPassword,
    completeResetPassword,
    getPreferences,
    getPreferencesAnon,
    updatePreferences,
    updatePreferencesAnon,
    togglePreference,
    updateLanguagePref
};

function register(user) {
    return dispatch => {
        dispatch(request(user));
        
        userService.register(user)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(response));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(response) { return { type: userConstants.REGISTER_SUCCESS, response } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

function resetRegistering() {
    return dispatch => {
        dispatch(reset());
        dispatch(alertActions.clear());
    };

    function reset() { return { type: userConstants.REGISTER_CLEAR } }
}

function completeRegister(userId, registrationId) {
    return dispatch => {
        dispatch(request({userId, registrationId}));
        
        userService.completeRegister(userId, registrationId)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.success(response));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error)); 
                }
            );
    };

    function request(request) { return { type: userConstants.REGISTER_REQUEST, request } }
    function success(response) { return { type: userConstants.REGISTER_SUCCESS, response } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

function login(username, password, redirectPath) {
    return dispatch => {
        dispatch(request({ username }));

        userService.login(username, password)
            .then(
                response => { 
                    dispatch(success(response));
                    dispatch(alertActions.clear());
                    redirectPath ? history.push(redirectPath) : history.push('/home');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(response) { return { type: userConstants.LOGIN_SUCCESS, response } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout(dontPush, autoLogout) {
    userService.logout();

    if(!dontPush) {
        history.push('/login');
    }
    
    return dispatch => dispatch(reduxLogout(autoLogout));
    
    function reduxLogout(autoLogout) { return { type: userConstants.LOGOUT, autoLogout } }
}

function refreshToken(user) {
    return dispatch => {
        dispatch(request(user));
       
        userService.refreshToken(user)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.clear());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.REFRESH_REQUEST, user } }
    function success(response) { return { type: userConstants.REFRESH_SUCCESS, response } }
    function failure(error) { return { type: userConstants.REFRESH_FAILURE, error } }
}

function update(user){
    return dispatch => {
        dispatch(request(user));

        userService.update(user)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.clear());
                    history.push('/home');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.UPDATE_REQUEST, user } }
    function success(user) { return { type: userConstants.UPDATE_SUCCESS, user } }
    function failure(error) { return { type: userConstants.UPDATE_FAILURE, error } }
}

function resetPassword(email) {
    return dispatch => {
        dispatch(request(email));

        userService.resetPassword(email)
            .then(
                message => {
                    dispatch(success(message));
                    dispatch(alertActions.success(message));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(email) { return { type: userConstants.UPDATE_REQUEST, email } }
    function success(message) { return { type: userConstants.UPDATE_SUCCESS, message } }
    function failure(error) { return { type: userConstants.UPDATE_FAILURE, error } }
}

function completeResetPassword(req) {
    return dispatch => {
        dispatch(request(req));

        userService.completeResetPassword(req)
            .then(
                message => {
                    dispatch(success(message));
                    dispatch(alertActions.success(message));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(req) { return { type: userConstants.UPDATE_REQUEST, req } }
    function success(message) { return { type: userConstants.UPDATE_SUCCESS, message } }
    function failure(error) { return { type: userConstants.UPDATE_FAILURE, error } }
}

function getPreferences(userId) {
    return dispatch => {
        dispatch(request(userId));

        userService.getPreferences(userId)
            .then(
                preferences => {
                    dispatch(success(preferences));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error('There was an error with retrieving your preferences'));
                }
            );
    };

    function request(userId) { return { type: userConstants.GET_PREFERENCES_REQUEST, userId } }
    function success(preferences) { return { type: userConstants.GET_PREFERENCES_SUCCESS, preferences } }
    function failure(error) { return { type: userConstants.GET_PREFERENCES_FAILURE, error } }
}

function getPreferencesAnon(userId, hashedUserId) {
    return dispatch => {
        dispatch(request(userId));

        userService.getPreferencesAnon(userId, hashedUserId)
            .then(
                preferences => {
                    dispatch(success(preferences));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error('There was an error with retrieving your preferences'));
                }
            );
    };

    function request(userId) { return { type: userConstants.GET_PREFERENCES_REQUEST, userId } }
    function success(preferences) { return { type: userConstants.GET_PREFERENCES_SUCCESS, preferences } }
    function failure(error) { return { type: userConstants.GET_PREFERENCES_FAILURE, error } }
}

function updatePreferences(preferences) {
    return dispatch => {
        dispatch(request(preferences));

        userService.updatePreferences(preferences)
            .then(
                preferences => {
                    dispatch(success(preferences));
                    history.push('home');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(preferences) { return { type: userConstants.UPDATE_PREFERENCES_REQUEST, preferences } }
    function success(preferences) { return { type: userConstants.UPDATE_PREFERENCES_SUCCESS, preferences } }
    function failure(error) { return { type: userConstants.UPDATE_PREFERENCES_FAILURE, error } }
}

function updatePreferencesAnon(uid, huid, preferences) {
    return dispatch => {
        dispatch(request(preferences));

        userService.updatePreferencesAnon(uid, huid, preferences)
            .then(
                preferences => {
                    dispatch(success(preferences));
                    dispatch(alertActions.success(`Successfully updated preferences for ${preferences.email}`));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(preferences) { return { type: userConstants.UPDATE_PREFERENCES_REQUEST, preferences } }
    function success(preferences) { return { type: userConstants.UPDATE_PREFERENCES_SUCCESS, preferences } }
    function failure(error) { return { type: userConstants.UPDATE_PREFERENCES_FAILURE, error } }
}

function togglePreference(name) {
    return dispatch => {
        dispatch(update(name));
    };

    function update(name) { return { type: userConstants.TOGGLE_PREFERENCE, name } }
}

function updateLanguagePref(code){   
    return dispatch => {
        dispatch(update(code));
    };

    function update(code) { return { type: userConstants.UPDATE_LANGUAGE_PREFERENCE, code } }
}