var long = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short' };
var short = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', timeZoneName: 'short' };
var justDate = { year: 'numeric', month: 'long', day: 'numeric' };
var justShortDate = { year: 'numeric', month: 'numeric', day: 'numeric' };

export function formatDateTime(utcString) {
    var date = new Date(utcString + 'Z');

    if(date.getFullYear() <= 1) {
        return "N/A";
    }

    return date.toLocaleString("en-US", long);
}

export function formatShortDateTime(utcString) {
    var date = new Date(utcString + 'Z');

    if(date.getFullYear() <= 1) {
        return "N/A";
    }

    return date.toLocaleString("en-US", short);
}

export function formatDate(utcString) {
    var date = new Date(utcString + 'Z');

    if(date.getFullYear() <= 1) {
        return "N/A";
    }

    return date.toLocaleString("en-US", justDate);
}

export function formatUtcDate(utcString) {
    var date = new Date(utcString);
    
    if(date.getFullYear() <= 1) {
        return "N/A";
    }

    return date.toLocaleString("en-US", justShortDate);
}

export function convertDate(utcString) {
    return new Date(utcString + 'Z');
}

export function isValidDate(utcString) {
    var date = new Date(utcString + 'Z');

    if(date.getFullYear() <= 1) {
        return false;
    }

    return true;
}

export function getListOfYears(startYear, endYear) {
    var years = []

    while(startYear <= endYear) {
        years.push(startYear++);
    }

    return years;
}

export function getCurrentDate() {
    var date = new Date();

    return date.toLocaleString("en-US", justShortDate);
}

export function getDateRangeString(startDate, endDate) {
    var sDate = formatDate(startDate);
    var eDate = formatDate(endDate);

    return sDate + ' - ' + eDate;
}