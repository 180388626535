import React from 'react';
import PropTypes from 'prop-types';
import styles from './TaskListGroup.module.css';
import check from '_images/thumbsup.png';

import { connect } from 'react-redux';
import { ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Badge } from 'reactstrap';
import { activeActions } from '_actions';
import { formatShortDateTime } from '_helpers';
import { MdFolderOpen } from 'react-icons/md';

class TaskListGroup extends React.Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }
    
    componentDidMount() {
        const { dispatch, filter } = this.props;
        dispatch(activeActions.getManagerTasks(filter));
    }

    handleClick(event) {
        const { dispatch } = this.props;
        dispatch(activeActions.getTaskSummary(event.currentTarget.getAttribute('tid')));
    }

    render() {
        const { tasks } = this.props;

        if(tasks.length === 0) {
            return (
                <div className={styles.container}>
                    <div className={styles.headingcontainer}>
                        <h2 className={styles.heading}>My Tasks</h2>
                    </div>
                    <div className={styles.listcontainer}>
                        <div className={styles.centercontainer}>
                            <span className={styles.title}>
                                Good Job!
                            </span>
                            <span className={styles.para}>
                                You have no tasks at the moment.
                            </span>
                            <img className={styles.check} src={check} alt=":("></img>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className={styles.container}>
                <div className={styles.headingcontainer}>
                    <h2 className={styles.heading}>My Tasks</h2>
                </div>
                <div className={styles.listcontainer}>
                    <ListGroup className={styles.list}>
                        {
                            tasks.map(t => {
                                return  <ListGroupItem className={styles.listitem} key={t.task_id} tid={t.task_id} onClick={this.handleClick}>
                                            <ListGroupItemHeading><MdFolderOpen/> {t.name}</ListGroupItemHeading>
                                            <ListGroupItemText className={styles.itemtext}>
                                                    <span>
                                                        {t.task_template.description}
                                                    </span>
                                                    <Badge className={styles.badge}>
                                                        <span className={styles.badgetext}>
                                                            {formatShortDateTime(t.create_date)}
                                                        </span>
                                                    </Badge>
                                            </ListGroupItemText>
                                        </ListGroupItem>
                            })
                        }
                    </ListGroup>
                </div>
            </div>
        );
    }
}

TaskListGroup.propTypes = {
    filter: PropTypes.string
};
  
TaskListGroup.defaultProps = {
    filter: 'active'
};

function mapStateToProps(state) {
    const { active } = state;
    const { tasks } = active;
    return {
        tasks
    };
}

const connectedTaskListGroup = connect(mapStateToProps)(TaskListGroup);
export { connectedTaskListGroup as TaskListGroup };