import React from 'react';
import styles from './CompanyListGroup.module.css';
import check from '_images/thumbsup.png';

import { connect } from 'react-redux';
import { ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Badge } from 'reactstrap';
import { companyRole, status } from '_helpers';
import { companyActions } from '_actions';
import { MdAccountBalance, MdCheck, MdCancel } from 'react-icons/md';

class CompanyListGroup extends React.Component {
    constructor(props) {
        super(props);
        
        this.handleClick = this.handleClick.bind(this);
        this.handlePendingClick = this.handlePendingClick.bind(this);
        this.handlePendingOwnerClick = this.handlePendingOwnerClick.bind(this);
    }

    handleClick(event) {
        const { dispatch } = this.props;

        dispatch(companyActions.getCompany(event.currentTarget.getAttribute('cid'), true));
    }

    handlePendingClick(event) {
        const { dispatch, user } = this.props;
        var role = event.currentTarget.getAttribute('rid');
        var status = event.currentTarget.getAttribute('sid');
        var company = event.currentTarget.getAttribute('cid');

        dispatch(companyActions.updateRelationship({
            email: user.email,
            user_id: user.user_id, 
            company_id: company, 
            role: role,
            status: status
        }));
    }

    handlePendingOwnerClick(event) {
        const { dispatch } = this.props;

        dispatch(companyActions.getCompany(event.currentTarget.getAttribute('cid'), false, true));
    }

    render() {
        const { relationships } = this.props;

        if(relationships.filter(r => r.status === 1 || r.status === 2).length === 0) {
            return (
                <div className={styles.container}>
                    <div className={styles.headingcontainer}>
                        <h2 className={styles.heading}>My Companies</h2>
                    </div>
                    <div className={styles.listcontainer}>
                        <div className={styles.centercontainer}>
                            <span className={styles.title}>
                                Heads up!
                            </span>
                            <span className={styles.para}>
                                You have no company relationships at the moment.
                            </span>
                            <img className={styles.check} src={check} alt=":("></img>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className={styles.container}>
                <div className={styles.headingcontainer}>
                    <h2 className={styles.heading}>My Companies</h2>
                </div>
                <div className={styles.listcontainer}>
                    <ListGroup className={styles.list}>
                        {
                            relationships.sort(function(a, b) { return a.status - b.status || a.company_name - b.company_name }).map(r => {
                                if(r.status === 2) {
                                    return  <ListGroupItem className={styles.listitem} key={r.company_id} cid={r.company_id} onClick={this.handleClick}>
                                                <ListGroupItemHeading><MdAccountBalance className={styles.icon}/> {r.company_name}</ListGroupItemHeading>
                                                <ListGroupItemText className={styles.itemtext}>
                                                    <span>
                                                        {r.company_description}
                                                    </span>
                                                    <Badge className={styles.badge}>
                                                        <span className={styles.badgetext}>
                                                            {companyRole(r.role, r.sub_role)}
                                                        </span>
                                                    </Badge>
                                                </ListGroupItemText>
                                            </ListGroupItem>
                                } else if(r.status === 1 && r.role === 1) {
                                    return  <ListGroupItem key={r.company_id} className={styles.listitem} cid={r.company_id}>
                                                <ListGroupItemHeading><MdAccountBalance className={styles.icon}/> {r.company_name}</ListGroupItemHeading>
                                                <div>
                                                    {r.company_description} <Badge color="warning">{status(r.status)} Invitation</Badge><br/>
                                                    <MdCancel className={styles.pendingchoice} size={30} cid={r.company_id} rid={r.role} sid={4} color="red" onClick={this.handlePendingClick}/>
                                                    <MdCheck className={styles.pendingchoice} size={30} cid={r.company_id} rid={r.role} sid={2} color="green" onClick={this.handlePendingClick}/>
                                                </div>
                                            </ListGroupItem>
                                } else if(r.status === 1 && r.role === 4) {
                                    return  <ListGroupItem key={r.company_id} className={styles.listitem} cid={r.company_id} onClick={this.handlePendingOwnerClick}>
                                                <ListGroupItemHeading><MdAccountBalance className={styles.icon}/> {r.company_name}</ListGroupItemHeading>
                                                <div>
                                                    {r.company_description} <Badge color="warning">{status(r.status)} Owner Invitation</Badge><br/>
                                                    <MdCheck className={styles.pendingchoice} size={30} cid={r.company_id} rid={r.role} sid={2} color="green" onClick={this.handlePendingOwnerClick}/>
                                                </div>
                                            </ListGroupItem>
                                } else {
                                    return null;
                                }
                            })
                        }
                    </ListGroup>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { relationships, authentication } = state;
    const { user } = authentication;

    return {
        relationships, user
    };
}

const connectedCompanyListGroup = connect(mapStateToProps)(CompanyListGroup);
export { connectedCompanyListGroup as CompanyListGroup };