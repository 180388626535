import React from 'react';
import styles from './CompleteFormAction.module.css';
import { activeActions } from '_actions';
import { Label } from 'reactstrap';

import { connect } from 'react-redux';

class CompleteFormAction extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };
        
        this.handleMessage = this.handleMessage.bind(this);
    }

    componentDidMount() {
        const { dispatch, currentActionRequirements, currentActionRequirementSelections} = this.props;
       
        var action_id = currentActionRequirements.action.action_id;
        var content_id = currentActionRequirements.action.content_id;
        dispatch(activeActions.previewAction({ action_id, content_id, selections: currentActionRequirementSelections }));

        window.addEventListener("message", this.handleMessage);
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.handleMessage);
    }

    render() {
        const {currentActionPreviewResult}= this.props;

        if(currentActionPreviewResult == null) return null;

        return (
            <div className={styles.container}>
                <h2 className={styles.heading}>Complete Your Form</h2>
                {currentActionPreviewResult && 
                    <iframe className={styles.form} src={currentActionPreviewResult.result} id={"td_form"} title={"form1"}/>   
                }
                {!currentActionPreviewResult &&
                    <Label>Loading</Label>
                }
            </div>
        );
    }

    handleMessage(event) {
        const { dispatch, currentActionRequirements, currentActionRequirementSelections } = this.props;

        //handle a form completion
        if(event.data && event.data.source === "td_form" && event.data.form_completed === true) {
            var html = event.data.form_html;
            var action_id = currentActionRequirements.action.action_id;
            var content_id = currentActionRequirements.action.content_id;

            dispatch(activeActions.completeAction({ action_id, content_id, selections: currentActionRequirementSelections, encoded_result: html }));
        }
    }
}

function mapStateToProps(state) {
    const { active, authentication } = state;
    const { currentActionRequirements, currentActionRequirementSelections, currentActionPreviewResult, currentActionCompletionResult } = active;
    const { autoLoggedOut, user } = authentication;

    return {
        autoLoggedOut,
        currentActionCompletionResult,
        currentActionPreviewResult,
        currentActionRequirements,
        currentActionRequirementSelections,
        user
    };
}

const connectedCompleteFormAction = connect(mapStateToProps)(CompleteFormAction);
export { connectedCompleteFormAction as CompleteFormAction };