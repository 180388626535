import React from 'react';
import PropTypes from 'prop-types';
import styles from './LoginPage.module.css';

import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { userActions } from '_actions';
import { LoginForm } from '_components/LoginForm';
import { Helmet } from 'react-helmet';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        //gets router state for redirects if there is one
        const { location } = props;
        const { state } = location;
        this.state = state || {};

        // reset login status
        this.props.dispatch(userActions.logout(true));
    }

    componentDidMount() {
        const { dispatch, match: { params } } = this.props;

        if(params.uid && params.rid) {
            dispatch(userActions.completeRegister(params.uid, params.rid));
        }
    }

    render() {
        const { from } = this.state;

        return (
            <div>
                <Helmet> 
                    <title>Sign Into TackleDocs!</title>
                    <meta name="description" content="One account for all your document and company needs." />
                </Helmet>
                <Container className={styles.container}>
                    <h2 className={styles.heading}>Sign In</h2>
                    <p className={styles.para}>One account for all your document and company needs.</p>
                    <LoginForm redirectPath={from ? from.pathname : null}></LoginForm>
                </Container>
            </div>
        );
    }
}


LoginPage.propTypes = {
    uid: PropTypes.string,
    rid: PropTypes.string
};

LoginPage.defaultProps = {
    uid: '',
    rid: ''
};

function mapStateToProps(state) {
    return {
    };
}

const connectedLoginPage = connect(mapStateToProps)(LoginPage);
export { connectedLoginPage as LoginPage }; 