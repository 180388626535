import React from 'react';
import styles from './CompanyEmployeeDocumentsPage.module.css';

import { connect } from 'react-redux';
import { DocumentTable } from '_components/DocumentTable';

class CompanyEmployeeDocumentsPage extends React.Component {
    render() {
        const { company } = this.props;

        return (
            <div className={styles.container}>
                <div className={styles.headingcontainer}>
                    <h4 className={styles.header}>{company.company_name}</h4>
                </div>
                <DocumentTable/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { company } = state;

    return {
        company
    };
}

const connectedCompanyEmployeeDocumentsPage = connect(mapStateToProps)(CompanyEmployeeDocumentsPage);
export { connectedCompanyEmployeeDocumentsPage as CompanyEmployeeDocumentsPage };