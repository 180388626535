import React from 'react';
import styles from './MyCompanies.module.css'

import { connect } from 'react-redux';
import { RelationshipTable } from '_components/RelationshipTable';

class MyCompaniesPage extends React.Component 
{
    render() {
        return (
            <div className={styles.container}>
                <div className={styles.headingcontainer}>
                    <h4 className={styles.heading}>My Company Relationships</h4>
                </div>
                <RelationshipTable/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { authentication, relationships } = state;
    const { user } = authentication;
    return {
        user, relationships
    };
}

const connectedMyCompaniesPage = connect(mapStateToProps)(MyCompaniesPage);
export { connectedMyCompaniesPage as MyCompaniesPage };