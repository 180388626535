import { adminService } from '_services';
import { alertActions } from './';
import { history } from '_helpers';
import { adminConstants } from '_constants';

export const adminActions = {
    createCompany,
    deactivateCompany,
    updateCompanySelection,
    getCompanies,
    createQuestion,
    createQuestions,
    deactivateQuestion,
    getQuestions,
    createDocumentTemplate,
    createAdvancedDocumentTemplate,
    deactivateDocumentTemplate,
    getDocumentTemplates,
    createActionTemplate,
    deactivateActionTemplate,
    getActionTemplates,
    createTaskTemplate,
    deactivateTaskTemplate,
    getTaskTemplates
};

function createCompany(company){
    return dispatch => {
        dispatch(request(company));

        adminService.createCompany(company)
            .then(
                response => {
                    dispatch(success(response));
                    history.push('/admin/companies');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(company) { return { type: adminConstants.CREATE_COMPANY_REQUEST, company } }
    function success(response) { return { type: adminConstants.CREATE_COMPANY_SUCCESS, response } }
    function failure(error) { return { type: adminConstants.CREATE_COMPANY_FAILURE, error } }
}

function deactivateCompany(companyId){
    return dispatch => {
        dispatch(request(companyId));

        adminService.deactivateCompany(companyId)
            .then(
                response => {
                    dispatch(success(response));
                    history.push('/admin/companies');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(company) { return { type: adminConstants.DEACTIVATE_COMPANY_REQUEST, company } }
    function success(response) { return { type: adminConstants.DEACTIVATE_COMPANY_SUCCESS, response } }
    function failure(error) { return { type: adminConstants.DEACTIVATE_COMPANY_FAILURE, error } }
}

function updateCompanySelection(companyId){
    return dispatch => {
        dispatch(update(companyId));
    };

    function update(companyId) { return { type: adminConstants.UPDATE_COMPANY_SELECTION, companyId } }
}

function getCompanies(filter){
    return dispatch => {
        dispatch(request(filter));

        adminService.getCompanies(filter)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(filter) { return { type: adminConstants.GET_COMPANIES_REQUEST, filter } }
    function success(companies) { return { type: adminConstants.GET_COMPANIES_SUCCESS, companies } }
    function failure(error) { return { type: adminConstants.GET_COMPANIES_FAILURE, error } }
}

function createQuestion(companyId, question){
    return dispatch => {
        dispatch(request(question));

        adminService.createQuestion(companyId, question)
            .then(
                response => {
                    dispatch(success(response));
                    history.push('/admin/companies/questions');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(question) { return { type: adminConstants.CREATE_QUESTION_REQUEST, question } }
    function success(response) { return { type: adminConstants.CREATE_QUESTION_REQUEST, response } }
    function failure(error) { return { type: adminConstants.CREATE_QUESTION_FAILURE, error } }
}

function createQuestions(companyId, questions){
    return dispatch => {
        dispatch(request(questions));

        adminService.createQuestions(companyId, questions)
            .then(
                response => {
                    dispatch(success(response));
                    history.push('/admin/companies/questions');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(questions) { return { type: adminConstants.CREATE_QUESTION_REQUEST, questions } }
    function success(response) { return { type: adminConstants.CREATE_QUESTION_REQUEST, response } }
    function failure(error) { return { type: adminConstants.CREATE_QUESTION_FAILURE, error } }
}

function deactivateQuestion(companyId, question){
    return dispatch => {
        dispatch(request(question));

        adminService.deactivateQuestion(companyId, question)
            .then(
                response => {
                    dispatch(success(response));
                    history.push('/admin/companies/questions');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(question) { return { type: adminConstants.DEACTIVATE_QUESTION_REQUEST, question } }
    function success(response) { return { type: adminConstants.DEACTIVATE_QUESTION_SUCCESS, response } }
    function failure(error) { return { type: adminConstants.DEACTIVATE_QUESTION_FAILURE, error } }
}

function getQuestions(companyId, filter){
    return dispatch => {
        dispatch(request(companyId));

        adminService.getQuestions(companyId, filter)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(companyId) { return { type: adminConstants.GET_QUESTIONS_REQUEST, companyId } }
    function success(questions) { return { type: adminConstants.GET_QUESTIONS_SUCCESS, questions } }
    function failure(error) { return { type: adminConstants.GET_QUESTIONS_FAILURE, error } }
}

function createDocumentTemplate(companyId, documentTemplate){
    return dispatch => {
        dispatch(request(documentTemplate));

        adminService.createDocumentTemplate(companyId, documentTemplate)
            .then(
                response => {
                    dispatch(success(response));
                    history.push('/admin/companies/documents/templates');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(question) { return { type: adminConstants.CREATE_DOCUMENT_TEMPLATE_REQUEST, question } }
    function success(response) { return { type: adminConstants.CREATE_DOCUMENT_TEMPLATE_SUCCESS, response } }
    function failure(error) { return { type: adminConstants.CREATE_DOCUMENT_TEMPLATE_FAILURE, error } }
}

function createAdvancedDocumentTemplate(companyId, documentTemplate){
    return dispatch => {
        dispatch(request(documentTemplate));

        adminService.createDocumentTemplate(companyId, JSON.parse(documentTemplate))
            .then(
                response => {
                    dispatch(success(response));
                    history.push('/admin/companies/documents/templates');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(question) { return { type: adminConstants.CREATE_DOCUMENT_TEMPLATE_REQUEST, question } }
    function success(response) { return { type: adminConstants.CREATE_DOCUMENT_TEMPLATE_SUCCESS, response } }
    function failure(error) { return { type: adminConstants.CREATE_DOCUMENT_TEMPLATE_FAILURE, error } }
}

function deactivateDocumentTemplate(companyId, documentTemplate){
    return dispatch => {
        dispatch(request(documentTemplate));

        adminService.deactivateDocumentTemplate(companyId, documentTemplate)
            .then(
                response => {
                    dispatch(success(response));
                    history.push('/admin/companies/documents/templates');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(documentTemplate) { return { type: adminConstants.DEACTIVATE_DOCUMENT_TEMPLATE_REQUEST, documentTemplate } }
    function success(response) { return { type: adminConstants.DEACTIVATE_DOCUMENT_TEMPLATE_SUCCESS, response } }
    function failure(error) { return { type: adminConstants.DEACTIVATE_DOCUMENT_TEMPLATE_FAILURE, error } }
}

function getDocumentTemplates(companyId, filter){
    return dispatch => {
        dispatch(request(companyId));

        adminService.getDocumentTemplates(companyId, filter)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(companyId) { return { type: adminConstants.GET_DOCUMENT_TEMPLATE_REQUEST, companyId } }
    function success(documentTemplates) { return { type: adminConstants.GET_DOCUMENT_TEMPLATE_SUCCESS, documentTemplates } }
    function failure(error) { return { type: adminConstants.GET_DOCUMENT_TEMPLATE_FAILURE, error } }
}

function createActionTemplate(companyId, actionTemplate){
    return dispatch => {
        dispatch(request(actionTemplate));

        adminService.createActionTemplate(companyId, actionTemplate)
            .then(
                response => {
                    dispatch(success(response));
                    history.push('/admin/companies/actions/templates');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(actionTemplate) { return { type: adminConstants.CREATE_ACTION_TEMPLATE_REQUEST, actionTemplate } }
    function success(response) { return { type: adminConstants.CREATE_ACTION_TEMPLATE_SUCCESS, response } }
    function failure(error) { return { type: adminConstants.CREATE_ACTION_TEMPLATE_FAILURE, error } }
}

function deactivateActionTemplate(companyId, actionTemplate){
    return dispatch => {
        dispatch(request(actionTemplate));

        adminService.deactivateActionTemplate(companyId, actionTemplate)
            .then(
                response => {
                    dispatch(success(response));
                    history.push('/admin/companies/actions/templates');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(actionTemplate) { return { type: adminConstants.DEACTIVATE_ACTION_TEMPLATE_REQUEST, actionTemplate } }
    function success(response) { return { type: adminConstants.DEACTIVATE_ACTION_TEMPLATE_SUCCESS, response } }
    function failure(error) { return { type: adminConstants.DEACTIVATE_ACTION_TEMPLATE_FAILURE, error } }
}

function getActionTemplates(companyId, filter){
    return dispatch => {
        dispatch(request(companyId));

        adminService.getActionTemplates(companyId, filter)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(companyId) { return { type: adminConstants.GET_ACTION_TEMPLATE_REQUEST, companyId } }
    function success(actionTemplates) { return { type: adminConstants.GET_ACTION_TEMPLATE_SUCCESS, actionTemplates } }
    function failure(error) { return { type: adminConstants.GET_ACTION_TEMPLATE_FAILURE, error } }
}

function createTaskTemplate(companyId, taskTemplate){
    return dispatch => {
        dispatch(request(taskTemplate));

        adminService.createTaskTemplate(companyId, taskTemplate)
            .then(
                response => {
                    dispatch(success(response));
                    history.push('/admin/companies/tasks/templates');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(taskTemplate) { return { type: adminConstants.CREATE_TASK_TEMPLATE_REQUEST, taskTemplate } }
    function success(response) { return { type: adminConstants.CREATE_TASK_TEMPLATE_SUCCESS, response } }
    function failure(error) { return { type: adminConstants.CREATE_TASK_TEMPLATE_FAILURE, error } }
}

function deactivateTaskTemplate(companyId, taskTemplate){
    return dispatch => {
        dispatch(request(taskTemplate));

        adminService.deactivateTaskTemplate(companyId, taskTemplate)
            .then(
                response => {
                    dispatch(success(response));
                    history.push('/admin/companies/tasks/templates');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(taskTemplate) { return { type: adminConstants.DEACTIVATE_TASK_TEMPLATE_REQUEST, taskTemplate } }
    function success(response) { return { type: adminConstants.DEACTIVATE_TASK_TEMPLATE_SUCCESS, response } }
    function failure(error) { return { type: adminConstants.DEACTIVATE_TASK_TEMPLATE_FAILURE, error } }
}

function getTaskTemplates(companyId, filter){
    return dispatch => {
        dispatch(request(companyId));

        adminService.getTaskTemplates(companyId, filter)
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(companyId) { return { type: adminConstants.GET_TASK_TEMPLATE_REQUEST, companyId } }
    function success(taskTemplates) { return { type: adminConstants.GET_TASK_TEMPLATE_SUCCESS, taskTemplates } }
    function failure(error) { return { type: adminConstants.GET_TASK_TEMPLATE_FAILURE, error } }
}