import React from 'react';
import styles from './AdminPage.module.css';

import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { Button, Container, FormGroup, Input, Label  } from 'reactstrap';
import { adminActions } from '_actions';

class AdminCreateDocumentTemplatePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            documentTemplate: {
                name: '',
                description: '',
                is_html_template: '',
                is_task_document: '',
                task_document_zone_name: '',
                template: ''
            }
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        const { documentTemplate } = this.state;

        this.setState({ 
            documentTemplate: {
                ...documentTemplate,
                [name]: value 
            }
        });
    }

    handleSubmit() {
        const { dispatch, company } = this.props;
        const { documentTemplate } = this.state;

        if(company && company.company_id && documentTemplate) {
            dispatch(adminActions.createDocumentTemplate(company.company_id, documentTemplate));
        }
    }

    render() {
        const { company } = this.props;

        return (
            <div className={styles.container}>
                <div className={styles.headingcontainer}>
                    <h4 className={styles.header}>Create Document Template For {company.company_name}</h4>
                    <div>
                        <LinkContainer to='/admin/companies/documents/templates/advanced' className={styles.headingbutton}>
                            <Button color="success" disabled={company && company.company_id ? false : true}>Create Advanced Template</Button>
                        </LinkContainer>
                    </div>
                </div>
                <div className={styles.main}>
                    <Container>
                        <FormGroup>
                            <Label>Document Template Name</Label>
                            <Input name="name" onChange={this.handleChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Document Template Description</Label>
                            <Input name="description" onChange={this.handleChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Is Html Template (boolean)</Label>
                            <Input name="is_html_template" onChange={this.handleChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Is Task Document (boolean)</Label>
                            <Input name="is_task_document" onChange={this.handleChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Task Document Zone Name</Label>
                            <Input name="task_document_zone_name" onChange={this.handleChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Document Template</Label>
                            <Input type="textarea" className={styles.largeTextInput} name="template" onChange={this.handleChange}/>
                        </FormGroup>
                        <Button color="primary" onClick={this.handleSubmit}>Create Template</Button>
                    </Container>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { admin } = state;
    const { company } = admin;

    return { 
        company
    };
}

const connectedAdminCreateDocumentTemplatePage = connect(mapStateToProps)(AdminCreateDocumentTemplatePage);
export { connectedAdminCreateDocumentTemplatePage as AdminCreateDocumentTemplatePage };