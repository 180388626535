import React from 'react';
import checked from '_images/thumbsup.png';
import styles from './AssetTable.module.css';

import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { formatShortDateTime, status, customTableTheme } from '_helpers';
import { assetActions } from '_actions';
import { ConfirmationModal } from '_components/ConfirmationModal';
import { FaEdit, FaTrash } from 'react-icons/fa';

class AssetTable extends React.Component {
    constructor(props) {
        super(props);

        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    columns = [
        {
            name: 'Name',
            selector: row => row['name'],
            sortable: true,
            wrap: true
        },
        {
            name: 'Type',
            selector: row => row['type'],
            sortable: true,    
            wrap: true
        },
        {
            name: 'Last Updated',
            selector: row => row['last_updated'],
            format: row => formatShortDateTime(row.ref.last_update_date),
            sortable: true,
            wrap: true,
            hide: 'sm'
        },
        {
            name: 'Status',
            selector: row => row['status'],
            sortable: true,
            wrap: true,
            hide: 'sm'
        },
        {
            name: '',
            grow: 0,
            right: true,
            cell: row => <FaEdit className={styles.edit} aid={row.id} onClick={this.handleEdit}/>
        },
        {
            name: '',
            grow: 0,
            right: true,
            hide: 'sm',
            cell: row => <ConfirmationModal bodyText={`By confirming you will be deleting ${row.name} from your company.`} 
                                            clickable={<FaTrash className={styles.edit}/>} 
                                            confirmCallback={this.handleDelete} 
                                            data={row.ref}
                                            />
        }
    ];

    handleEdit(event) {
        const { dispatch } = this.props;
        dispatch(assetActions.getAsset(event.currentTarget.getAttribute('aid')));
    }

    handleDelete({data}) {
        const { dispatch } = this.props;

        data.status = 0;
        
        dispatch(assetActions.updateAsset(data));
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(assetActions.getAssets('active'));
    }

    render() {
        const { assets } = this.props;
        var data = assets.map(a => {
            return {
                id: a.asset_id,
                name: a.name,
                type: a.type,
                last_updated: a.last_update_date,
                status: status(a.status),
                ref: a
            };
        });

        return (
            <div className={styles.container}>
                <DataTable
                    columns={this.columns}
                    data={data}
                    striped
                    fixedHeader
                    fixedHeaderScrollHeight='500px'
                    customTheme={customTableTheme}
                    noHeader
                    noDataComponent={
                        <div className={styles.nodatacontainer}>
                            <span className={styles.heading}>No Assets!</span>
                            <span className={styles.para}>You have nothing to view at this time.</span>
                            <img className={styles.checkedimg} src={checked} alt=":("/>
                        </div>
                    }
                />
            </div>        
        );
    }
}

function mapStateToProps(state) {
    const { asset } = state;
    const { assets, fetchingAssets } = asset;
    return {
        assets,
        fetchingAssets
    };
}

const connectedAssetTable = connect(mapStateToProps)(AssetTable);
export { connectedAssetTable as AssetTable };