import React from 'react';
import styles from './RegisterPage.module.css';

import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { RegisterForm } from '_components/RegisterForm';
import { Helmet } from 'react-helmet';
import { userActions } from '_actions';

class RegisterPage extends React.Component {
    constructor(props) {
        super(props);

        // reset login status
        this.props.dispatch(userActions.logout(true));
    }

    render() {
        return (
            <div>
                <Helmet> 
                    <title>Register For TackleDocs!</title>
                    <meta name="description" content="Create an account for all your document and company needs on TackleDocs in seconds!" />
                </Helmet>
                <Container className={styles.container}>
                    <h2 className={styles.heading}>Sign Up</h2>
                    <p className={styles.para}>Create an account for all your document and company needs.</p>
                    <RegisterForm></RegisterForm>
                </Container>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

const connectedRegisterPage = connect(mapStateToProps)(RegisterPage);
export { connectedRegisterPage as RegisterPage };