import React from 'react';
import styles from './CompleteForgotPasswordPage.module.css'

import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { ResetPasswordForm } from '_components/PasswordForm';

class CompleteForgotPasswordPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            uid: '',
            rid: ''
        }
    }

    componentDidMount() {
        const { match: { params } } = this.props;

        this.setState({
            uid: params.uid,   
            rid: params.rid
        })
    }

    render() {
        const { uid, rid } = this.state;

        return (
            <Container className={styles.container}>
                <h2 className={styles.heading}>Reset Password</h2>
                <ResetPasswordForm uid={uid} rid={rid}></ResetPasswordForm>
            </Container>
        );
    }
}
    
function mapStateToProps(state) {      
    return {
    };
}

const connectedCompleteForgotPasswordPage = connect(mapStateToProps)(CompleteForgotPasswordPage);
export { connectedCompleteForgotPasswordPage as CompleteForgotPasswordPage };