import React from 'react';
import IdleTimer from 'react-idle-timer';
import Moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import styles from './App.module.css';

import { Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from '_helpers';
import { alertActions, userActions } from '_actions';
import { PrivateRoute } from '_components';
import { NavigationBar } from '_components/NavigationBar';

import { AdminCreateActionTemplatePage } from 'Admin';
import { AdminActionTemplatePage } from 'Admin';
import { AdminCreateCompanyPage } from 'Admin';
import { AdminCompanyPage } from 'Admin';
import { AdminCreateDocumentTemplatePage } from 'Admin';
import { AdminCreateDocumentTemplateAdvancedPage } from 'Admin';
import { AdminDocumentTemplatePage } from 'Admin';
import { AdminCreateQuestionPage } from 'Admin';
import { AdminBatchCreateQuestionPage } from 'Admin';
import { AdminQuestionPage } from 'Admin';
import { AdminCreateTaskTemplatePage } from 'Admin';
import { AdminTaskTemplatePage } from 'Admin';
import { ChangePasswordPage } from 'ChangePasswordPage';
import { CompanyPage } from 'CompanyPage';
import { CompanyEmployeeDocumentsPage } from 'CompanyEmployeeDocumentsPage';
import { CompanyAssetsPage } from 'CompanyAssetsPage';
import { CompanyAssetTypesPage } from 'CompanyAssetTypesPage';
import { CompanyCalendarPage } from 'CompanyCalendarPage';
import { CompanyEmployeesPage } from 'CompanyEmployeesPage';
import { CompanyEmployeeInfoPage } from 'CompanyEmployeeInfoPage';
import { CompanyInfoPage } from 'CompanyInfoPage';
import { CompanyOwnerInvitePage } from 'CompanyOwnerInvitePage';
import { CompanyTasksPage } from 'CompanyTasksPage';
import { CompanyTasksByEmployeePage } from 'CompanyTasksByEmployeePage';
import { CompanyTaskSummaryPage } from 'CompanyTaskSummaryPage';
import { CompleteActionPage } from 'CompleteActionPage';
import { CompleteForgotPasswordPage } from 'CompleteForgotPasswordPage';
import { CreateAssetPage } from 'CreateAssetPage';
import { CreateAssetTemplatePage } from 'CreateAssetTemplatePage';
import { CreateTaskPage } from 'CreateTaskPage';
import { EditAssetPage } from 'EditAssetPage';
import { EditAssetTemplatePage } from 'EditAssetTemplatePage';
import { ForgotPasswordPage } from 'ForgotPasswordPage';
import { HomePage } from 'HomePage';
import { LoginPage } from 'LoginPage';
import { MyCompaniesPage } from 'MyCompaniesPage';
import { MyDocumentsPage } from 'MyDocumentsPage';
import { ProfilePage } from 'ProfilePage';
import { RegisterPage } from 'RegisterPage';
import { SupportPage } from 'SupportPage';
import { HealthCheckPage } from 'HealthCheckPage';
import { UserPreferencesPage } from 'UserPreferencesPage';
import { WelcomePage } from 'WelcomePage';
import { CreateReminderPage } from 'CreateReminderPage';
import { AlertDisplay } from '_components/AlertDisplay';
import { BillingInfoPage } from 'BillingInfoPage';
import { UpdateBillingInfoPage } from 'UpdateBillingInfoPage';
import { NotFoundPage } from 'NotFoundPage';
import { CompanyRemindersLogPage } from 'CompanyRemindersLogPage';
import { AssetAnnualDetailsPage } from 'AssetAnnualDetailsPage';
import { AssetDetailsDisplayDetailedPoundsPage } from 'AssetDetailsDisplayDetailedPoundsPage';
import { AssetAnnualOrganizationPage } from 'AssetAnnualOrganizationPage';
import { CreateLogEntryPage } from 'CreateLogEntryPage';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.onIdle = this._onIdle.bind(this);
        const { dispatch } = this.props;

        history.listen(event => {
            if(event.pathname !== '/login') {
                // clear alert on location change
                dispatch(alertActions.clear());
            }
        });

        //localizer for some premade widgets that use dates and times
        Moment.locale('en');
        momentLocalizer();
    }

    render() {
        return (     
            <Router history={history}>
                <div className={styles.content}>
                    <IdleTimer element={document} onIdle={this.onIdle} debounce={250} timeout={1000 * 60 * 10} />
                    <NavigationBar/>
                    <AlertDisplay/>
                    <Switch>
                        <PrivateRoute exact path="/home" component={HomePage}/>
                        <PrivateRoute exact path="/profile" component={ProfilePage}/>
                        <PrivateRoute exact path="/profile/password" component={ChangePasswordPage}/>
                        <PrivateRoute exact path="/preferences" component={UserPreferencesPage}/>
                        <PrivateRoute exact path="/admin/companies" component={AdminCompanyPage}/>
                        <PrivateRoute exact path="/admin/companies/create" component={AdminCreateCompanyPage}/>
                        <PrivateRoute exact path="/admin/companies/actions/templates" component={AdminActionTemplatePage}/>
                        <PrivateRoute exact path="/admin/companies/actions/templates/create" component={AdminCreateActionTemplatePage}/>
                        <PrivateRoute exact path="/admin/companies/documents/templates" component={AdminDocumentTemplatePage}/>
                        <PrivateRoute exact path="/admin/companies/documents/templates/create" component={AdminCreateDocumentTemplatePage}/>
                        <PrivateRoute exact path="/admin/companies/documents/templates/advanced" component={AdminCreateDocumentTemplateAdvancedPage}/>
                        <PrivateRoute exact path="/admin/companies/tasks/templates" component={AdminTaskTemplatePage}/>
                        <PrivateRoute exact path="/admin/companies/tasks/templates/create" component={AdminCreateTaskTemplatePage}/>
                        <PrivateRoute exact path="/admin/companies/questions" component={AdminQuestionPage}/>
                        <PrivateRoute exact path="/admin/companies/questions/create" component={AdminCreateQuestionPage}/>
                        <PrivateRoute exact path="/admin/companies/questions/create/batch" component={AdminBatchCreateQuestionPage}/>
                        <PrivateRoute exact path="/company" component={CompanyPage}/>
                        <PrivateRoute exact path="/company/billing" component={BillingInfoPage }/>
                        <PrivateRoute exact path="/company/updatebilling" component={UpdateBillingInfoPage }/>
                        <PrivateRoute exact path="/company/accept" component={CompanyOwnerInvitePage}/>
                        <PrivateRoute exact path="/company/assets" component={CompanyAssetsPage}/>
                        <PrivateRoute exact path="/company/assets/create" component={CreateAssetPage}/>
                        <PrivateRoute exact path="/company/assets/edit" component={EditAssetPage}/>
                        <PrivateRoute exact path="/company/assets/templates" component={CompanyAssetTypesPage}/>
                        <PrivateRoute exact path="/company/assets/templates/create" component={CreateAssetTemplatePage}/>
                        <PrivateRoute exact path="/company/assets/templates/edit" component={EditAssetTemplatePage}/>
                        <PrivateRoute exact path="/company/calendar" component={CompanyCalendarPage}/>
                        <PrivateRoute exact path="/company/employees" component={CompanyEmployeesPage}/>
                        <PrivateRoute exact path="/company/employees/info" component={CompanyEmployeeInfoPage}/>
                        <PrivateRoute exact path="/company/employee/documents" component={CompanyEmployeeDocumentsPage}/>
                        <PrivateRoute exact path="/company/info" component={CompanyInfoPage}/>
                        <PrivateRoute exact path="/company/tasks" component={CompanyTasksPage}/>
                        <PrivateRoute exact path="/company/tasks/create" component={CreateTaskPage}/>
                        <PrivateRoute exact path="/company/tasks/employees" component={CompanyTasksByEmployeePage}/>
                        <PrivateRoute exact path="/company/tasks/summary" component={CompanyTaskSummaryPage}/>
                        <PrivateRoute exact path="/companies" component={MyCompaniesPage}/>
                        <PrivateRoute exact path="/documents" component={MyDocumentsPage}/>
                        <PrivateRoute exact path="/action" component={CompleteActionPage}/>
                        <PrivateRoute exact path="/:cid/action/:aid" component={CompleteActionPage}/>
                        <PrivateRoute exact path="/company/reminder/create" component={CreateReminderPage}/>
                        <PrivateRoute exact path="/company/reminders/log" component={CompanyRemindersLogPage}/>
                        <PrivateRoute exact path="/company/reminders/log/create" component={CreateLogEntryPage}/>
                        <PrivateRoute exact path="/company/pounds" component={AssetAnnualDetailsPage}/>
                        <PrivateRoute exact path="/company/pounds/details" component={AssetDetailsDisplayDetailedPoundsPage}/>
                        <PrivateRoute exact path="/company/pounds/research" component={AssetAnnualOrganizationPage}/>
                        <Route path="/user/:uid/registration/:rid" component={LoginPage}/>
                        <Route path="/user/:uid/preferences/:huid" component={UserPreferencesPage}/>
                        <Route path="/login" component={LoginPage}/>
                        <Route path="/register" component={RegisterPage}/>
                        <Route path="/support" component={SupportPage}/>
                        <Route path="/healthcheck" component={HealthCheckPage}/>
                        <Route path="/password/reset" component={ForgotPasswordPage}/>
                        <Route path="/password/:uid/reset/:rid" component={CompleteForgotPasswordPage}/>
                        <Route path="/(welcome|)/" component={WelcomePage}/>
                        <Route component={NotFoundPage}/>
                    </Switch>
                </div>
            </Router>
        );
    }
     
    _onIdle() {
        const { dispatch, user } = this.props;

        if(user) {
            dispatch(userActions.logout(false, true));
            dispatch(alertActions.error("You have been logged out due to inactivity."));
        }
    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    const { user } = authentication;
    return {
        user
    };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };