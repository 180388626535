import React from 'react';
import PropTypes from 'prop-types';
import styles from './AddEmployeeModal.module.css';

import { connect } from 'react-redux';
import { Button, FormFeedback, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { validateEmail } from '_helpers';

class AddEmployeeModal extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            modal: false, 
            email: '',
            validEmail: false,
        };

        this.toggle = this.toggle.bind(this);
        this.confirm = this.confirm.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        var value = event.target.value

        this.setState({
            email: value,
            validEmail: validateEmail(value)
        });
    }

    toggle() {
        this.setState({
            modal: !this.state.modal,
            email: '',
            validEmail: false
        });
    }

    confirm() {
        const { confirmCallback } = this.props;
        const { email, validEmail } = this.state;
        
        if(validEmail) {
            //callback of setting state with latest values
            confirmCallback({ succeed: true, email });
            this.toggle();
        }
    }

    render() {
        const { email, validEmail } = this.state;
        const { buttonColor, disabled } = this.props;

        return (
        <div>
            <Button className={styles.button} color={buttonColor} disabled={disabled} onClick={this.toggle}>Add Employee</Button>
            <Modal isOpen={this.state.modal} toggle={this.toggle} className={styles.modal}>
                <ModalHeader toggle={this.toggle}>Add New Employee</ModalHeader>
                <ModalBody className={styles.modalbody}>
                    <FormGroup>
                        <Label>Enter Email Address</Label>
                        <Input valid={validEmail} invalid={email.length > 0 && !validEmail} autoComplete="off" autoCorrect="off" type="email" name="email" value={email} onChange={this.handleChange}></Input>
                        <FormFeedback invalid="true">
                            Please enter a valid email
                        </FormFeedback>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.confirm}>Confirm</Button>
                    <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
        );
    }
}

AddEmployeeModal.propTypes = {
    buttonColor: PropTypes.string,
    confirmCallback: PropTypes.func,
    disabled: PropTypes.bool
};
  
AddEmployeeModal.defaultProps = {
    buttonColor: 'success',
    confirmCallback: null,
    disabled: false
};

function mapStateToProps(state) {
    const { company } = state;

    return {
        company
    };
}

const connectedAddEmployeeModal = connect(mapStateToProps)(AddEmployeeModal);
export { connectedAddEmployeeModal as AddEmployeeModal };