import React from 'react';
import styles from './NavigationBar.module.css'
import logo from '_images/LongLogoDark256a.png'

import { Button, Navbar, NavbarBrand, Nav, NavItem } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';

export class GuestNavBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        isOpen: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    const { sticky } = this.props;

    return (
      <div className={sticky ? styles.navcontainergueststicky : styles.navcontainerguest}>
        <Navbar className={styles.navbar} dark expand="md">
          <LinkContainer to='/'>
            <NavbarBrand><img src={logo} className={styles.brand} alt=":("/></NavbarBrand>
          </LinkContainer>
            <Nav className='ml-auto' navbar>
              <div className={styles.sidebyside}>
                <LinkContainer to='/login'>          
                  <NavItem>
                    <Button className={styles.signinbutton}>Sign In</Button>
                  </NavItem>
                </LinkContainer>
                <LinkContainer to='/register'>          
                  <NavItem>
                    <Button className={styles.registerbutton} color="info">Sign Up</Button>
                  </NavItem>
                </LinkContainer>
              </div>
            </Nav>
        </Navbar>
      </div>
    );
  }
}