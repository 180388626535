import { authHeader, config, currentCompany, currentUser, handleResponse, handleError } from '_helpers';

export const actionService = {
    get,
    getActionRequirements,
    getActionRequirementsForReminder,
    getAllByFilterForCompanyAndUser,
    getAllByFilterForUser,
    completeAction,
    previewAction
};

function get(actionId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/actions/${actionId}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });
        
}

function getActionRequirements(actionId, companyId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${companyId || currentCompany().company_id}/actions/${actionId}/requirements`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });
}

function getActionRequirementsForReminder(reminderId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/actions/users/${currentUser().user_id}/reminders/${reminderId}/requirements`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });
}

function getAllByFilterForCompanyAndUser(filter) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/actions/users/${currentUser().user_id}/actions?filter=${encodeURIComponent(filter)}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function getAllByFilterForUser(filter) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.apiUrl + `/users/${currentUser().user_id}/actions?filter=${encodeURIComponent(filter)}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function completeAction(actionRequest) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(actionRequest)
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/actions`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function previewAction(actionRequest) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(actionRequest)
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/actions/previews`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}