import { documentConstants } from '_constants';
import { documentService } from '_services';
import { alertActions } from './';

export const documentActions = {
    getAll,
    getAllForCompany,
    getAllForTask,
    downloadFile,
    downloadAdminFile,
    updateSelectedCompany
};

function getAll(filter) {
    return dispatch => {
        dispatch(request(filter));

        documentService.getAll(filter)
            .then(
                documents => {
                    dispatch(success(documents));
                    dispatch(alertActions.clear());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(document) { return { type: documentConstants.DOC_GET_ALL_COMPANIES_REQUEST, document } }
    function success(documents) { return { type: documentConstants.DOC_GET_ALL_COMPANIES_SUCCESS, documents } }
    function failure(error) { return { type: documentConstants.DOC_GET_ALL_COMPANIES_FAILURE, error } }
}

function getAllForCompany(filter){
    return dispatch => {
        dispatch(request(filter));

        documentService.getAllForCompany(filter)
            .then(
                documents => {
                    dispatch(success(documents));
                    dispatch(alertActions.clear());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(document) { return { type: documentConstants.DOC_GET_ALL_REQUEST, document } }
    function success(documents) { return { type: documentConstants.DOC_GET_ALL_SUCCESS, documents } }
    function failure(error) { return { type: documentConstants.DOC_GET_ALL_FAILURE, error } }
}

function getAllForTask(taskId){
    return dispatch => {
        dispatch(request(taskId));

        documentService.getAllForTask(taskId)
            .then(
                documents => {
                    dispatch(success(documents));
                    dispatch(alertActions.clear());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(taskId) { return { type: documentConstants.DOC_GET_ALL_TASK_REQUEST, taskId } }
    function success(documents) { return { type: documentConstants.DOC_GET_ALL_TASK_SUCCESS, documents } }
    function failure(error) { return { type: documentConstants.DOC_GET_ALL_TASK_FAILURE, error } }
}

function downloadFile(documentId){
    return dispatch => {
        dispatch(request(documentId));

        documentService.getDownloadLink(documentId)
            .then(
                documentLink => {
                    dispatch(success(documentLink));
                    dispatch(alertActions.clear());
                    window.open(documentLink.document_path);
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(documentId) { return { type: documentConstants.DOC_GET_REQUEST, documentId } }
    function success(documentLink) { return { type: documentConstants.DOC_GET_SUCCESS, documentLink } }
    function failure(error) { return { type: documentConstants.DOC_GET_FAILURE, error } }
}

function downloadAdminFile(documentId, viewableType, renderType){
    return dispatch => {
        dispatch(request(documentId));

        documentService.getAdminDownloadLink(documentId, viewableType, renderType)
            .then(
                documentLink => {
                    dispatch(success(documentLink));
                    dispatch(alertActions.clear());
                    window.open(documentLink.document_path);
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(documentId) { return { type: documentConstants.DOC_GET_REQUEST, documentId } }
    function success(documentLink) { return { type: documentConstants.DOC_GET_SUCCESS, documentLink } }
    function failure(error) { return { type: documentConstants.DOC_GET_FAILURE, error } }
}

function updateSelectedCompany(companyId){
    return dispatch => {
        dispatch(update(companyId));
    };

    function update(companyId) { return { type: documentConstants.UPDATE_DOC_COMPANY, companyId } }
}