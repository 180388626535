import React from 'react';

import { connect } from 'react-redux';
import { AdminHomePage, UserHomePage } from './';

class HomePage extends React.Component {
    render() {
        const { user } = this.props;
        return (
            user.site_role > 1 ? <AdminHomePage/> : <UserHomePage/>
        );
    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    const { user } = authentication;
    return {
        user
    };
}

const connectedHomePage = connect(mapStateToProps)(HomePage);
export { connectedHomePage as HomePage };