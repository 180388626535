import React from 'react';
import styles from './BillingInfoPage.module.css';

import { connect } from 'react-redux';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { CheckoutForm } from '_components/CheckoutForm/CheckoutForm';
import { config } from '_helpers';

class BillingInfoPage extends React.Component {
    render() {   
        return (
            <StripeProvider apiKey={config.stripe_pk}>
                <div className={styles.container}>
                    <div className={styles.headingcontainer}>
                        <h4 className={styles.header}>Company Billing Information</h4>
                    </div>
                    <Elements>
                        <CheckoutForm update={false} />
                    </Elements>
                </div>
            </StripeProvider>
        );
    }  
}

function mapStateToProps(state) {
    return {
    };
}

const connectedBillingInfoPage = connect(mapStateToProps)(BillingInfoPage);
export { connectedBillingInfoPage as BillingInfoPage };