import React from 'react';
import PropTypes from 'prop-types';
import styles from './CompleteActionPage.module.css';

import { connect } from 'react-redux';
import { CompleteManualAction } from '_components/CompleteManualAction';
import { CompleteDocumentAction } from '_components/CompleteDocumentAction/';
import { CompleteFormAction } from '_components/CompleteFormAction';
import { CompleteAssetDetailsAction } from '_components/CompleteAssetDetailsAction';
import { CompleteTaskRequirementsAction } from '_components/CompleteTaskRequirementsAction';
import { companyActions, activeActions } from '_actions';

class CompleteActionPage extends React.Component {

    componentDidMount() {
        const { match: { params } } = this.props;
        const { dispatch } = this.props;
        
        if(params.aid && params.cid) {
            dispatch(companyActions.getCompany(params.cid, false));
            dispatch(activeActions.getActionRequirements(params.aid, params.cid));
        }
    }
    
    render() {
        const { currentActionRequirements } = this.props;

        if(!currentActionRequirements) {
            return null;
        }

        var actionType = currentActionRequirements.action_template.action_type;

        return (
            <div className={styles.container}>
                {(actionType === 1 || actionType === 4) && 
                    <CompleteManualAction></CompleteManualAction>
                }
                {(actionType === 2 || actionType === 3 || actionType === 5 || actionType === 6) &&
                    <CompleteDocumentAction></CompleteDocumentAction>
                }
                {(actionType === 7 || actionType === 8) && 
                    <CompleteFormAction></CompleteFormAction>
                }
                {actionType === 10 &&
                    <CompleteAssetDetailsAction></CompleteAssetDetailsAction>
                }
                {actionType === 11 &&
                    <CompleteTaskRequirementsAction></CompleteTaskRequirementsAction>
                }
            </div>
        );
    }
}

CompleteActionPage.propTypes = {
    aid: PropTypes.string,
    cid: PropTypes.string
};

CompleteActionPage.defaultProps = {
    aid: '',
    cid: ''
};

function mapStateToProps(state) {   
    const { active } = state;
    const { currentActionRequirements } = active;
    return {
        currentActionRequirements
    };
}

const connectedCompleteActionPage = connect(mapStateToProps)(CompleteActionPage);
export { connectedCompleteActionPage as CompleteActionPage };