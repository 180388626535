import React from 'react';
import styles from './CompanyTaskSummaryPage.module.css';

import { connect } from 'react-redux';
import { activeActions } from '_actions';
import { TaskSummaryTable } from '_components/TaskSummaryTable';
import { ConfirmationModal } from '_components/ConfirmationModal';

class CompanyTaskSummaryPage extends React.Component {
    constructor(props) {
        super(props);

        this.handleCancel = this.handleCancel.bind(this);
    }

    handleCancel() {
        const { currentTask, dispatch } = this.props;
        dispatch(activeActions.cancelTask(currentTask.task_id));
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { currentTask } = this.props;

        if(!currentTask) {
            return null;
        }

        return (
            <div className={styles.container}>
                <div className={styles.headingcontainer}>
                    <h4 className={styles.header}>{currentTask.name} Summary</h4>
                    <div>
                        {currentTask.status === 2 &&
                        <ConfirmationModal  bodyText="By cancelling this task your will be nullfiying all documents and To Do's that have been completed and cancelling ones that have yet to be compeleted." 
                                            buttonColor="danger" 
                                            buttonText="Cancel Task" 
                                            confirmCallback={this.handleCancel}/>
                        }
                    </div>
                </div>
                <TaskSummaryTable></TaskSummaryTable>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { active } = state;
    const { currentTask } = active;

    return {
        currentTask 
    };
}

const connectedCompanyTaskSummaryPage = connect(mapStateToProps)(CompanyTaskSummaryPage);
export { connectedCompanyTaskSummaryPage as CompanyTaskSummaryPage };