import { authHeader, config, handleResponse, handleError  } from '_helpers';

export const adminService = {
    createCompany,
    deactivateCompany,
    getCompanies,
    createQuestion,
    createQuestions,
    deactivateQuestion,
    getQuestions,
    createDocumentTemplate,
    deactivateDocumentTemplate,
    getDocumentTemplates,
    createActionTemplate,
    deactivateActionTemplate,
    getActionTemplates,
    createTaskTemplate,
    deactivateTaskTemplate,
    getTaskTemplates
};

function createCompany(company) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(company)
    };

    return fetch(config.apiUrl + `/admin/companies`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function deactivateCompany(companyId) {
    const requestOptions = {
        method: 'DELETE',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.apiUrl + `/admin/companies/${companyId}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function getCompanies(filter) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.apiUrl + `/admin/companies?filter=${filter}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function createQuestion(companyId, question) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(question)
    };

    return fetch(config.capiUrl + `/${companyId}/questions`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function createQuestions(companyId, questions) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: questions
    };

    return fetch(config.capiUrl + `/${companyId}/questions/batch`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function deactivateQuestion(companyId, question) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(question)
    };

    return fetch(config.capiUrl + `/${companyId}/questions/${question.question_id}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function getQuestions(companyId, filter) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${companyId}/questions?filter=${filter}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function createDocumentTemplate(companyId, documentTemplate) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(documentTemplate)
    };

    return fetch(config.capiUrl + `/${companyId}/documents/templates`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function deactivateDocumentTemplate(companyId, documentTemplate) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(documentTemplate)
    };

    return fetch(config.capiUrl + `/${companyId}/documents/templates/${documentTemplate.document_template_id}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function getDocumentTemplates(companyId, filter) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${companyId}/documents/templates?filter=${filter}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function createActionTemplate(companyId, actionTemplate) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(actionTemplate)
    };

    return fetch(config.capiUrl + `/${companyId}/actions/templates`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function deactivateActionTemplate(companyId, actionTemplate) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(actionTemplate)
    };

    return fetch(config.capiUrl + `/${companyId}/actions/templates/${actionTemplate.action_template_id}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function getActionTemplates(companyId, filter) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${companyId}/actions/templates?filter=${filter}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function createTaskTemplate(companyId, taskTemplate) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(taskTemplate)
    };

    return fetch(config.capiUrl + `/${companyId}/tasks/templates`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function deactivateTaskTemplate(companyId, taskTemplate) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(taskTemplate)
    };

    return fetch(config.capiUrl + `/${companyId}/tasks/templates/${taskTemplate.task_template_id}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function getTaskTemplates(companyId, filter) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${companyId}/tasks/templates?filter=${filter}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}