import React from 'react';
import styles from './CompanyAssetsPage.module.css';

import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { Button } from 'reactstrap';
import { AssetTable } from '_components/AssetTable';
import { currentCompanyRole } from '_helpers';

class CompanyAssetsPage extends React.Component 
{
    render() {
        const { company } = this.props;
        
        return (
            <div className={styles.container}>
                <div className={styles.headingcontainer}>
                    <h4 className={styles.header}>{company.company_name} Assets</h4>
                    <div>
                        <LinkContainer to='/company/assets/create' className={styles.headingbutton}>
                            <Button color="success">Add Asset</Button>
                        </LinkContainer>
                        { currentCompanyRole() === 4 &&
                        <LinkContainer to='/company/assets/templates' className={styles.headingbutton}>
                            <Button color="primary">Manage Types</Button>
                        </LinkContainer>
                        }
                    </div>
                </div>
                <AssetTable></AssetTable>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { company } = state;
    return {
        company
    };
}

const connectedCompanyAssetsPage = connect(mapStateToProps)(CompanyAssetsPage);
export { connectedCompanyAssetsPage as CompanyAssetsPage };