import React from 'react';
import styles from './AssetAnnualDetailsPage.module.css';

import { Input, Button, Label } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { AssetDetailsDisplayPounds } from '_components/AssetDetailsDisplayPounds';
import { connect } from 'react-redux';
import { assetActions } from '_actions';
import { getListOfYears } from '_helpers';
import { exportVesselData } from '_helpers';
import { FaFileExcel } from 'react-icons/fa';
 
class AssetAnnualDetailsPage extends React.Component {
    constructor(props) {
        super(props);

        var possibleYears = getListOfYears(2020, new Date().getFullYear());

        this.state = {
            possibleYears: possibleYears,
            selectedYear: possibleYears[possibleYears.length - 1]
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleExportToExcel = this.handleExportToExcel.bind(this);
    }

    handleChange(event) {
        const { dispatch } = this.props;

        var index = event.target.selectedIndex;
        var selectedYear = event.target.childNodes[index].getAttribute('year');

        this.setState({
            selectedYear: selectedYear
        });
    
        dispatch(assetActions.getAssetAnnualSummary(selectedYear));
    }

    handleExportToExcel() {
        const { assetSummary, assets } = this.props;
        exportVesselData(assetSummary, assets);
    }

    componentDidMount() {
        const { possibleYears } = this.state;
        const { dispatch } = this.props;
    
        dispatch(assetActions.getAssets('active'));
        dispatch(assetActions.getAssetAnnualSummary(possibleYears[possibleYears.length - 1]));
    }

    render() {
        const { possibleYears, selectedYear } = this.state;
        const { assetSummary, company } = this.props;
        
        if(!assetSummary) {
            return null;
        }

        var entries = Object.entries(assetSummary);

        return (
            <div className={styles.container}>
                <div className={styles.headingcontainer}>
                    <h4 className={styles.header}>{company.company_name} Pounds Information</h4>
                    <Button className={styles.button} color="success" onClick={this.handleExportToExcel}><FaFileExcel size={20} className={styles.icon}/></Button>
                </div>
                <div className={styles.toolcontainer}>
                    <Input className={styles.year} type='select' defaultValue={selectedYear} onChange={this.handleChange}>
                    {
                        possibleYears.map(year => {
                            return <option key={year} value={year} year={year}>{year} - {year + 1}</option>
                        })
                    }
                    </Input>
                    <LinkContainer to='/company/pounds/research' className={styles.headingbutton}>
                        <Button color="secondary">Research Pounds</Button>
                    </LinkContainer>
                </div>
                <div className={styles.wrapcontainer}>
                {entries.length > 0 ?
                    entries.map(kvp => {
                        const [assetId, data] = kvp;
                        var year = parseInt(selectedYear);
                        return <AssetDetailsDisplayPounds key={assetId} assetId={assetId} year={year} data={data}/>
                    })
                    :
                    <div className={styles.container}>
                        <Label className={styles.nodata}>No Data To Display!</Label>
                    </div>
                }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { asset, company } = state;
    const { assetSummary, assets } = asset;
    
    return {
        assets,
        assetSummary,
        company
    };
}

const connectedAssetAnnualDetailsPage = connect(mapStateToProps)(AssetAnnualDetailsPage);
export { connectedAssetAnnualDetailsPage as AssetAnnualDetailsPage };