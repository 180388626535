import { activeConstants } from '_constants';
import { isPartOfTable } from '_helpers';

var initialState = {
    actions: [],
    completingCurrentAction: false,
    currentActionCompleted: false,
    currentAction: null,
    currentActionCompletionResult: null,
    currentActionPreviewResult: null,
    currentActionRequirements: null,
    currentActionRequirementSelections: null,
    currentTask: null,
    employeeActionSummaries: null,
    employeeTaskSummaries: [],
    managerActionSummaries: null,
    possibleTaskYears: [],
    tasks: [],
    taskTemplates: [],
    taskAsset: {},
    updatedSelection: null
};

export function active(state = initialState, reduxAction) {
    switch (reduxAction.type) {
        case activeConstants.ACTIONS_GET_SUCCESS:
            return {
                ...state,
                actions: reduxAction.actions,
                currentAction: null,
                currentActionRequirements: null,
                currentActionRequirementSelections: null,
                currentActionResult: null
            };
        case activeConstants.ACTIONS_GET_REQUEST:
        case activeConstants.ACTIONS_GET_FAILURE:
            return {
                ...state,
                actions: [],
                currentAction: null,
                currentActionRequirements: null,
                currentActionRequirementSelections: null,
                currentActionResult: null
            };
        case activeConstants.ACTION_GET_SUCCESS:
            return {
                ...state,
                currentAction: reduxAction.currentAction,
                currentActionRequirements: null,
                currentActionRequirementSelections: null,
                currentActionResult: null
            };
        case activeConstants.ACTION_GET_REQUEST:
        case activeConstants.ACTION_GET_FAILURE:
            return {
                ...state,
                currentAction: null,
                currentActionRequirements: null,
                currentActionRequirementSelections: null,
                currentActionResult: null
            };

        case activeConstants.ACTION_REQUIREMENTS_GET_SUCCESS:
            return {
                ...state,
                currentActionRequirements: reduxAction.currentActionRequirements,
                currentActionRequirementSelections: buildActionRequirementSelections(reduxAction.currentActionRequirements),
                completingCurrentAction: false,
                currentActionCompleted: false
            };
        case activeConstants.ACTION_REQUIREMENTS_GET_REQUEST:
        case activeConstants.ACTION_REQUIREMENTS_GET_FAILURE:
            return {
                ...state,
                currentActionRequirements: null,
                currentActionRequirementSelections: null,
                currentActionCompletionResult: null,
                currentActionPreviewResult: null,
                completingCurrentAction: false,
                currentActionCompleted: false
            };
        case activeConstants.REMINDER_ACTION_REQUIREMENTS_GET_SUCCESS:
            return {
                ...state,
                currentActionRequirements: reduxAction.currentActionRequirements,
                currentActionRequirementSelections: buildActionRequirementSelections(reduxAction.currentActionRequirements),
            };
        case activeConstants.REMINDER_ACTION_REQUIREMENTS_GET_REQUEST:
        case activeConstants.REMINDER_ACTION_REQUIREMENTS_GET_FAILURE:
            return {
                ...state,
                currentActionRequirements: null,
                currentActionRequirementSelections: null,
                currentActionCompletionResult: null,
                currentActionPreviewResult: null
            };
        case activeConstants.ACTION_REQUIREMENTS_UPDATE:
            return {
                ...state,
                currentActionRequirementSelections: updateActionRequirementSelections(state.currentActionRequirementSelections, reduxAction.updatedSelection)
            };
        case activeConstants.ACTION_REQUIREMENTS_SELECTABLES_UPDATE:
            return {
                ...state,
                currentActionRequirementSelections: updateActionRequirementSelectables(state.currentActionRequirementSelections, reduxAction.updatedSelectables)
            };
        case activeConstants.ACTION_PREVIEW_SUCCESS:
            return {
                ...state,
                currentActionCompletionResult: null,
                currentActionPreviewResult: reduxAction.currentActionResult
            };
        case activeConstants.ACTION_PREVIEW_REQUEST:    
        case activeConstants.ACTION_PREVIEW_FAILURE:
            return {
                ...state,
                currentActionCompletionResult: null,
                currentActionPreviewResult: null
            };
        case activeConstants.ACTION_COMPLETE_SUCCESS:
            return {
                ...state,
                currentActionCompletionResult: reduxAction.currentActionResult,
                currentActionPreviewResult: null,
                completingCurrentAction: false,
                currentActionCompleted: true
            };
        case activeConstants.ACTION_COMPLETE_REQUEST:    
            return {
                ...state,
                currentActionCompletionResult: null,
                currentActionPreviewResult: null,
                completingCurrentAction: true,
                currentActionCompleted: false
            };
        case activeConstants.ACTION_COMPLETE_FAILURE:
            return {
                ...state,
                currentActionCompletionResult: null,
                currentActionPreviewResult: null,
                completingCurrentAction: false,
                currentActionCompleted: false
            };
        case activeConstants.TASKS_GET_SUCCESS:
            return {
                ...state,
                tasks: reduxAction.tasks,
                taskTemplates: buildTaskTemplateList(reduxAction.tasks),
                possibleTaskYears: buildPossibleTaskYearsList(reduxAction.tasks)
            };
        case activeConstants.TASKS_GET_FAILURE:
        case activeConstants.TASKS_GET_REQUEST:
            return {
                ...state,
                tasks: [],
                taskTemplates: [],
                possibleTaskYears: [],
                employeeTaskSummaries: []
            };
        case activeConstants.TASK_GET_SUCCESS:
            return {
                ...state,
                currentTask: reduxAction.currentTask,
                employeeActionSummaries: buildEmployeeActionSummaries(reduxAction.currentTask.action_summaries),
                managerActionSummaries: buildManagerActionSummaries(reduxAction.currentTask.action_summaries, reduxAction.currentTask.manager)
            };
        case activeConstants.TASK_GET_REQUEST:
        case activeConstants.TASK_GET_FAILURE:
            return {
                ...state,
                currentTask: {},
                employeeActionSummaries: [],
                managerActionSummaries: []
            };
        case activeConstants.TASK_CREATE_SUCCESS:
            return {
                ...state,
                currentTask: reduxAction.currentTask
            };
        case activeConstants.TASK_UPDATE_SUCCESS:
            return {
                ...state,
                currentTask: reduxAction.currentTask
            };
        case activeConstants.TASK_CANCEL_SUCCESS:
            return {
                ...state,
                currentTask: null
            };
        case activeConstants.TASK_CANCEL_REQUEST:
        case activeConstants.TASK_CANCEL_FAILURE:
            return {
                ...state
            };
        case activeConstants.TASK_MARK_EMPLOYEE_INCOMPLETE_SUCCESS:
        case activeConstants.TASK_MARK_EMPLOYEE_INCOMPLETE_REQUEST:
        case activeConstants.TASK_MARK_EMPLOYEE_INCOMPLETE_FAILURE:
            return {
                ...state
            };
        case activeConstants.TASK_ADD_EMPLOYEE_SUCCESS:
        case activeConstants.TASK_ADD_EMPLOYEE_REQUEST:
        case activeConstants.TASK_ADD_EMPLOYEE_FAILURE:
            return {
                ...state
            };
        case activeConstants.TASK_GET_ROLE_REQUIREMENT_SUCCESS:
            return {
                ...state,
                taskRequiresCustomRole: reduxAction.requiresCustomRole
            }
        case activeConstants.TASK_GET_ROLE_REQUIREMENT_REQUEST:
        case activeConstants.TASK_GET_ROLE_REQUIREMENT_FAILURE:
            return {
                ...state
            }
        case activeConstants.TASK_GET_TASK_ASSET_REQUEST:
        case activeConstants.TASK_GET_TASK_ASSET_FAILURE:
            return {
                ...state,
                taskAsset: {}
            };
        case activeConstants.TASK_GET_TASK_ASSET_SUCCESS:
            return {
                ...state,
                taskAsset: reduxAction.asset
            };
        case activeConstants.TASK_GET_TASK_REQUIRES_EMPLOYEES_REQUEST:
        case activeConstants.TASK_GET_TASK_REQUIRES_EMPLOYEES_FAILURE:
            return {
                ...state,
                taskRequiresEmployees: false
            };
        case activeConstants.TASK_GET_TASK_REQUIRES_EMPLOYEES_SUCCESS:
            return {
                ...state,
                taskRequiresEmployees: reduxAction.requiresEmployees
            };
        case activeConstants.TASK_GET_TASK_EMPLOYEE_SUMMARIES_REQUEST:
        case activeConstants.TASK_GET_TASK_EMPLOYEE_SUMMARIES_FAILURE:
            return {
                ...state,
                employeeTaskSummaries: []
            };
        case activeConstants.TASK_GET_TASK_EMPLOYEE_SUMMARIES_SUCCESS:
            return {
                ...state,
                employeeTaskSummaries: reduxAction.summaries
            };
        default:
            return state
    }
}

function buildEmployeeActionSummaries(actionSummaries) {
    var summaries = [];

    actionSummaries.forEach(sum => {
        if(sum.template.role === 1) {
            summaries.push(sum);
        }
    });

    return summaries;
}

function buildManagerActionSummaries(actionSummaries, manager) {
    var summaries = [];

    actionSummaries.forEach(sum => {
        if(sum.template.role === 2) {
            summaries.push(sum);
        } else if(sum.template.role === 1) {
            sum.actions.forEach(action => {
                if(action.user_id === manager.user.user_id) {
                    summaries.push(sum);
                }
            })
        }
    });

    return summaries;
}

function buildActionRequirementSelections(actionRequirements) {
    var selections  = [];

    if(!actionRequirements.requirements) {
        return null;
    }

    actionRequirements.requirements.forEach(r => {
        if(!r.read_only) {
            selections.push({
                key: r.key,
                selection: r.secondary_display || (isPartOfTable(r.type, r.sub_type) ? 0 : null),
                selectables: (r.selectables_answers || []).map(sa => sa.selectable_id),
                min_selections: r.min_selections,
                max_selections: r.max_selections,
                type: r.type,
                sub_type: r.sub_type
            })
        }
    });
    
    return selections;
}

function updateActionRequirementSelections(selections, updatedSelection) {
    var found = false;

    for(var i = 0; i < selections.length; i++) {
        var s = selections[i];

        if(s.key === updatedSelection.key) {
            s.selection = updatedSelection.selection;
            found = true;
            break;
        }
    }

    if(!found) {
        selections.push({
            key: updatedSelection.key,
            selection: updatedSelection.selection,
            type: updatedSelection.type,
            sub_type: updatedSelection.subtype
        })
    }

    return selections;
}

function updateActionRequirementSelectables(selections, updatedSelectables) {
    selections.forEach(s => {
        if(s.key === updatedSelectables.key) {
            s.selectables = updatedSelectables.selectables
        }
    });

    return selections;
}

function buildPossibleTaskYearsList(tasks) {
    var years = {};

    for(var i = 0; i < tasks.length; i++) {
        var date = new Date(tasks[i].last_update_date);

        years[date.getFullYear()] = '';
    }

    return Object.keys(years).sort((a, b) => a - b);
}

function buildTaskTemplateList(tasks) {
    var taskTemplates = {};

    for(var i = 0; i < tasks.length; i++) {
        var template = tasks[i].task_template;

        if(template) {
            taskTemplates[template.task_template_id] = template;
        }
    }
    
    return Object.values(taskTemplates).sort((a, b) => (a.name > b.name) ? 1 : -1);
}