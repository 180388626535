import { authHeader, config, currentCompany, currentUser, handleResponse, handleError } from '_helpers';

export const taskService = { 
    create,
    update,
    cancel,
    get,
    getSummary,
    getAllForManager,
    getAllForCompany,
    getRecentEmployees,
    markEmployeeIncomplete,
    addEmployeeToTask,
    getTaskRequireCustomRole,
    getTaskAsset,
    getTaskRequiresEmployees,
    getTaskEmployeeSummaries
};

function create(task) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(task)
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/tasks/v2`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function update(task) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(task)
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/tasks`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function cancel(taskId) {
    const requestOptions = {
        method: 'DELETE',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/tasks/${taskId}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function get(taskId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/tasks/${taskId}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });
}

function getSummary(taskId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/tasks/${taskId}/summary`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });
}

function getAllForManager(filter) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/tasks/users/${currentUser().user_id}/tasks?filter=${encodeURIComponent(filter)}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function getAllForCompany(filter) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/tasks?filter=${encodeURIComponent(filter)}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function getRecentEmployees(taskTemplateId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/tasks/templates/${taskTemplateId}/recents/${currentUser().user_id}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function markEmployeeIncomplete(request) {
    const requestOptions = {
        method: 'DELETE',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/tasks/${request.taskId}/employees/${request.employeeId}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function addEmployeeToTask(request) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(request.employeeRole)
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/tasks/${request.taskId}/employees/${request.employeeId}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function getTaskRequireCustomRole(taskId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/tasks/${taskId}/requirements/role`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function getTaskAsset(taskId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/tasks/${taskId}/asset`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function getTaskRequiresEmployees(taskId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/tasks/${taskId}/requirements/employees`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function getTaskEmployeeSummaries(userId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/tasks/users/${userId}/summaries`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}