import React from 'react';
import styles from './CreateLogEntryPage.module.css';

import { connect } from 'react-redux';
import { assetActions, alertActions } from '_actions';
import { DateTimePicker } from 'react-widgets';
import { Button, Container, FormGroup, Input, Label } from 'reactstrap';
import { reminderActions } from '_actions'; 

class CreateLogEntryPage extends React.Component {
    constructor(props) {
        super(props);

        var currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 1);

        this.state = {
            reminder: {
                title: '',
                recurring: 0,
                recurring_type: 0,
                end_date: this.props.date || currentDate.toISOString(),
                asset_id: null,
                user_ids: [],
                notes: null
            }
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleAssetSelection = this.handleAssetSelection.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { reminder } = this.state;
        var value = e.target.value
        var name = e.target.name;

        this.setState({
            reminder: {
                ...reminder,
                [name]: value
            }
        });
    }
    
    handleDateChange(updatedDate) {
        const { reminder } = this.state;

        this.setState({
            reminder: {
                ...reminder,
                end_date: updatedDate.toISOString()
            }
        });
    }

    handleAssetSelection(e) {
        const { reminder } = this.state;
        var index = e.target.selectedIndex;
        var selectedOption = e.target.childNodes[index];
       
        this.setState({
            reminder: {
                ...reminder,
                asset_id : selectedOption.id
            }
        });
    }

    handleSubmit() {
        const { dispatch } = this.props;
        const { reminder } = this.state;

        if(reminder.title && reminder.asset_id && reminder.end_date) {
            dispatch(reminderActions.createLogEntry(reminder));
        } else {
            dispatch(alertActions.error("Please enter all required information"));
        }
    }
    
    componentDidMount(){
        const { dispatch } = this.props;
        dispatch(assetActions.getAssets("active"));
    }

    render() {
        const { reminder } = this.state;
        const { assets } = this.props;
        return (
            <div className={styles.container}>
                <Container className={styles.main}>
                    <div className={styles.headingcontainer}>
                        <h4 className={styles.heading}>Create Log Entry</h4>
                    </div>
                    <div className={styles.subcontainer}>
                        <FormGroup className={styles.formgroup}>
                            <Label>Title</Label>
                            <Input required name="title" defaultValue={reminder.title} onChange={this.handleChange}/>
                        </FormGroup>
                        <FormGroup className={styles.formgroup}>
                            <Label>Date</Label>
                            <DateTimePicker time={false} name="end_date" defaultValue={reminder.end_date ? new Date(reminder.end_date) : new Date()} onChange={this.handleDateChange}/>
                        </FormGroup>
                        <FormGroup className={styles.formgroup}>
                            <Label>Select Asset</Label>
                            <Input type="select" onChange={this.handleAssetSelection}>
                            <option disabled selected>Select a Asset...</option>
                            {
                                assets.map(o => {
                                return <option key={o.asset_id} id={o.asset_id}>{o.name}</option>
                                })
                            }
                            </Input>
                        </FormGroup>
                        <FormGroup className={styles.formgroup}>
                            <Label>Notes</Label>
                            <Input name="notes" type="textarea" className={styles.notesarea} defaultValue={reminder.notes} onChange={this.handleChange}/>
                        </FormGroup>
                        <div className={styles.subcontainer}>
                            <Button className={styles.button} color="success" onClick={this.handleSubmit}>Add Log Entry</Button>
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { reminder, asset } = state;
    const { date } = reminder;
    const { assets } = asset;
    return {
        date, assets
    };
}

const connectedCreateLogEntryPage = connect(mapStateToProps)(CreateLogEntryPage);
export { connectedCreateLogEntryPage as CreateLogEntryPage };