export const reminderConstants = {
    GET_COMPANY_REMINDERS_REQUEST: 'GET_COMPANY_REMINDERS_REQUEST',
    GET_COMPANY_REMINDERS_SUCCESS: 'GET_COMPANY_REMINDERS_SUCCESS',
    GET_COMPANY_REMINDERS_FAILURE: 'GET_COMPANY_REMINDERS_FAILURE',
    
    GET_USER_REMINDERS_REQUEST: 'GET_USER_REMINDERS_REQUEST',
    GET_USER_REMINDERS_SUCCESS: 'GET_USER_REMINDERS_SUCCESS',
    GET_USER_REMINDERS_FAILURE: 'GET_USER_REMINDERS_FAILURE',
    
    GET_REMINDER_DETAILS_REQUEST: 'GET_REMINDER_DETAILS_REQUEST',
    GET_REMINDER_DETAILS_SUCCESS: 'GET_REMINDER_DETAILS_SUCCESS',
    GET_REMINDER_DETAILS_FAILURE: 'GET_REMINDER_DETAILS_FAILURE',
    
    NAVIGATE_TO_CREATE_REMINDER_REQUEST: 'NAVIGATE_TO_CREATE_REMINDER_REQUEST',

    CREATE_COMPANY_REMINDER_REQUEST: 'CREATE_COMPANY_REMINDER_REQUEST',
    CREATE_COMPANY_REMINDER_SUCCESS: 'CREATE_COMPANY_REMINDER_SUCCESS',
    CREATE_COMPANY_REMINDER_FAILURE: 'CREATE_COMPANY_REMINDER_FAILURE',

    CREATE_COMPANY_LOG_ENTRY_REQUEST: 'CREATE_COMPANY_LOG_ENTRY_REQUEST',
    CREATE_COMPANY_LOG_ENTRY_SUCCESS: 'CREATE_COMPANY_LOG_ENTRY_SUCCESS',
    CREATE_COMPANY_LOG_ENTRY_FAILURE: 'CREATE_COMPANY_LOG_ENTRY_FAILURE',

    DEACTIVATE_COMPANY_REMINDER_REQUEST: 'DEACTIVATE_COMPANY_REMINDER_REQUEST',
    DEACTIVATE_COMPANY_REMINDER_SUCCESS: 'DEACTIVATE_COMPANY_REMINDER_SUCCESS',
    DEACTIVATE_COMPANY_REMINDER_FAILURE: 'DEACTIVATE_COMPANY_REMINDER_FAILURE',
}