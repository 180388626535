import { authHeader, config, currentCompany, currentUser, handleResponse, handleError } from '_helpers';

export const reminderService = {
    getCompanyReminders,
    getUserReminders,
    getReminderDetails,
    createReminder,
    createLogEntry,
    deactivateReminder
};

function getCompanyReminders(filter) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/reminders?filter=${encodeURIComponent(filter)}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function getUserReminders(filter) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/reminders/users/${currentUser().user_id}?filter=${encodeURIComponent(filter)}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function getReminderDetails(reminderId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/reminders/${reminderId}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function createReminder(reminder) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(reminder)
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/reminders`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function createLogEntry(reminder) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(reminder)
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/reminders/log`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function deactivateReminder(reminderId) {
    const requestOptions = {
        method: 'DELETE',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/reminders/${reminderId}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}