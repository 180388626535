import React from 'react';
import styles from './AssetDetailsDisplayPounds.module.css';
import PropTypes from 'prop-types';

import { FaInfoCircle, FaShip } from 'react-icons/fa';
import { connect } from 'react-redux';
import { Button, Label } from 'reactstrap';
import { ProgressBar } from 'react-step-progress-bar';
import { isPoundsLimit } from '_helpers';
import { assetActions } from '_actions';

class AssetDetailsDisplayPounds extends React.Component {
    constructor(props) {
        super(props);

        this.handleInfoClick = this.handleInfoClick.bind(this);
    }

    handleInfoClick() {
        const { dispatch, assetId, year } = this.props;
        
        dispatch(assetActions.viewAssetAnnualDetails(assetId, year));
    }

    render() {
        const { assetId, assets, data } = this.props;
       
        if (!data) {
            return null;
        }

        var assetLookup = assets.reduce((result, a) => {
            result[a.asset_id] = a.name;
            return result;
        }, {});

        return (
            <div className={styles.container}>
                <div className={styles.headingcontainer}>
                    <h5><FaShip className={styles.boat}/> {assetLookup[assetId]} Tracking</h5>
                    <Button className={styles.button} onClick={this.handleInfoClick} ><FaInfoCircle className={styles.icon}/></Button>
                </div>
                    {
                        data.map(displayData => {
                            return  <div key={displayData.area} className={styles.horcontainer}>
                                        <div className={styles.areacontainer}>
                                            <Label className={styles.arealabel}>{displayData.area}</Label>
                                        </div>
                                        <div className={styles.detailcontainer}>
                                            <div className={styles.wrapcontainer}>
                                                <div className={styles.detailcontainer}>
                                                    <Label>Allocation:<br/>{displayData.limit}</Label>
                                                </div>
                                                {isPoundsLimit(displayData.type) ?
                                                    <div className={styles.wrapcontainer}>
                                                        <div className={styles.detailcontainer}>
                                                            <Label>Caught:<br/>{displayData.caught}</Label>
                                                        </div>
                                                        <div className={styles.detailcontainer}>
                                                            <Label>Remaining:<br/>{(displayData.limit - displayData.caught).toPrecision(7)}</Label>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className={styles.wrapcontainer}>
                                                        <div className={styles.detailcontainer}>
                                                            <Label>Days:<br/>{displayData.totalDays}</Label>
                                                        </div>
                                                        <div className={styles.detailcontainer}>
                                                            <Label>Remaining:<br/>{(displayData.limit - displayData.totalDays).toPrecision(7)}</Label>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            {isPoundsLimit(displayData.type) ?
                                                <ProgressBar percent={displayData.caught / displayData.limit * 100}></ProgressBar>
                                                :
                                                <ProgressBar percent={displayData.totalDays / displayData.limit * 100}></ProgressBar>
                                            }
                                        </div>
                                    </div>
                        })
                    }
            </div>
        );
    }
}



AssetDetailsDisplayPounds.propTypes = {
    data: PropTypes.array,
    assetId: PropTypes.string,
    year: PropTypes.number
};
  
AssetDetailsDisplayPounds.defaultProps = {
    data: [],
    assetId: null,
    year: null
};

function mapStateToProps(state) {
    const { asset } = state;
    const { assets } = asset;

    return {
        assets
    };
}

const connectedAssetDetailsDisplayPounds = connect(mapStateToProps)(AssetDetailsDisplayPounds);
export { connectedAssetDetailsDisplayPounds as AssetDetailsDisplayPounds };