export const documentConstants = {
    DOC_GET_REQUEST: 'DOC_GET_REQUEST',
    DOC_GET_SUCCESS: 'DOC_GET_SUCCESS',
    DOC_GET_FAILURE: 'DOC_GET_FAILURE',

    DOC_GET_ALL_REQUEST: 'DOC_GET_ALL_REQUEST',
    DOC_GET_ALL_SUCCESS: 'DOC_GET_ALL_SUCCESS',
    DOC_GET_ALL_FAILURE: 'DOC_GET_ALL_FAILURE',

    DOC_GET_ALL_TASK_REQUEST: 'DOC_GET_ALL_TASK_REQUEST',
    DOC_GET_ALL_TASK_SUCCESS: 'DOC_GET_ALL_TASK_SUCCESS',
    DOC_GET_ALL_TASK_FAILURE: 'DOC_GET_ALL_TASK_FAILURE',

    DOC_GET_ALL_COMPANIES_REQUEST: 'DOC_GET_ALL_COMPANIES_REQUEST',
    DOC_GET_ALL_COMPANIES_SUCCESS: 'DOC_GET_ALL_COMPANIES_SUCCESS',
    DOC_GET_ALL_COMPANIES_FAILURE: 'DOC_GET_ALL_COMPANIES_FAILURE',

    UPDATE_DOC_COMPANY: 'UPDATE_DOC_COMPANY',
}