export function handleResponse(response) {
    return new Promise((resolve, reject) => {
        if (response && response.ok) {
            // return json if it was returned in the response
            var contentType = response.headers.get("content-type");
            if (contentType && contentType.includes("application/json")) {
                response.json().then(json => resolve(json));
            } else {
                response.text().then(text => resolve(text));
            }
        } else {
            // return error message from response body
            response.text().then(text => reject(text));
        }
    });
}

export function handleError(error) {
    return Promise.reject(error && error.message);
}