import React from 'react';
import styles from './CreateAssetTemplatePage.module.css';

import { connect } from 'react-redux';
import { alertActions, assetActions } from '_actions';
import { Button, Container, Form, FormGroup, Input, ListGroup, ListGroupItem } from 'reactstrap';

class CreateAssetTemplatePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            type: null,
            dataKeys: [],
            currentDataKey: null
        };

        this.handleAdd = this.handleAdd.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleDelete(event) {
        const { dataKeys } = this.state;
        var list = [];
        for(var i = 0; i< dataKeys.length; i++) {
            if(dataKeys[i] !== event.target.id) {
                list.push(dataKeys[i]);
            }
        }

        this.setState({
            dataKeys: list
        });
    }

    handleAdd(event) {
        event.preventDefault();
        const { dataKeys, currentDataKey } = this.state;

        var valid = true;
        dataKeys.forEach(dk => {
            if(dk === currentDataKey) {
                valid = false;
            }
        });

        if(currentDataKey && valid) {
            dataKeys.push(currentDataKey);
        }

        this.setState({
            currentDataKey: null
        });
    }

    onSubmit(event) {
        event.preventDefault();
        const { dispatch } = this.props;
        const { type, dataKeys } = this.state;
        
        if (type) {
            dispatch(assetActions.createAssetTemplate({type, data_keys: dataKeys}));
        } else {
            //handle fail
            dispatch(alertActions.error("A type is required to be entered"));
        }       
    }
    
    render() {
        const { currentDataKey, dataKeys, type } = this.state;
        
        return (
            <div className={styles.container}>
                <Container className={styles.main}>
                    <div className={styles.headingcontainer}>
                        <h3 className={styles.heading}>Create Type</h3>
                    </div>
                    <div className={styles.typecontainer}>
                        <h4 className={styles.subheading}>Asset Type</h4>
                        <Input id="type" value={type || ''} onChange={this.handleChange}/>
                    </div>
                    <Form>
                        <FormGroup>
                            <h4 className={styles.subheading}>Add Attribute</h4>
                            <div className={styles.formsubgroup}>
                                <Input className={styles.input} id="currentDataKey" value={currentDataKey || ''} onChange={this.handleChange}/>
                                <Button className={styles.button} id="addAttribute" onClick={this.handleAdd}>Add</Button>
                            </div>
                        </FormGroup>
                        <h4 className={styles.subheading}>Asset Attributes</h4>
                        <ListGroup className={styles.listgroup}>
                        {dataKeys && dataKeys.length > 0 ?
                            dataKeys.map(dk => {
                                return <ListGroupItem className={styles.listgroupitem} key={dk}>{dk} <Button color="danger" id={dk} onClick={this.handleDelete}>X</Button></ListGroupItem>
                            })
                            :
                            <div className={styles.nodatacontainer}>
                                <span className={styles.title}>Heads up!</span>
                                <span className={styles.para}>No attributes added</span>
                            </div>
                        }
                        </ListGroup>
                        <Button color="success" name="submit" onClick={this.onSubmit}>Submit</Button>
                    </Form>
                </Container>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

const connectedAssetTemplatePage = connect(mapStateToProps)(CreateAssetTemplatePage);
export { connectedAssetTemplatePage as CreateAssetTemplatePage };