import React from 'react';
import PropTypes from 'prop-types';
import styles from './AssetDetailsUpdateLimitsModal.module.css';

import { connect } from 'react-redux';
import { Button, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { assetActions } from '_actions';
import { getLimitType } from '_helpers';

class AssetDetailsUpdateLimitsModal extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            modal: false
        };

        this.toggle = this.toggle.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.confirm = this.confirm.bind(this);
    }

    toggle() {
        const { dispatch, detailsAssetId, detailsYear, forOrganization, organizationYear } = this.props;

        this.setState({
            modal: !this.state.modal
        }, () => {
            //if opening modal refresh data
            if(this.state.modal) {
                if(forOrganization) {
                    dispatch(assetActions.getOrganizationAssetDetailLimits(organizationYear));
                } else {
                    dispatch(assetActions.getAssetDetailLimits(detailsAssetId, detailsYear));
                }
            }
        });
    }

    handleUpdate(e) {
        const { dispatch } = this.props;

        var limit_id = e.currentTarget.getAttribute('lid');
        var asset_id = e.currentTarget.getAttribute('aid');
        var area = e.currentTarget.getAttribute('area');
        var year = e.currentTarget.getAttribute('year');
        var value = e.currentTarget.value;

        dispatch(assetActions.updateAssetDetailsLimitSelections({limit_id, asset_id, year, area, value}));
    }

    confirm() {
        const { dispatch, assetDetailLimitSelections, detailsAssetId, detailsYear, forOrganization, organizationYear } = this.props;

        var isValid = true;

        if(forOrganization) {
            assetDetailLimitSelections.forEach(s => {
                if(parseFloat(s.limit) < 0 || !s.area || s.year !== parseInt(organizationYear)) {
                    isValid = false;
                }
            });
            
            if(isValid) {
                dispatch(assetActions.updateOrganizationAssetDetailLimits(organizationYear, {limits: assetDetailLimitSelections}));
                this.toggle();
            }
        } else {
            assetDetailLimitSelections.forEach(s => {
                if(parseFloat(s.limit) < 0 || !s.area || s.asset_id !== detailsAssetId || s.year !== parseInt(detailsYear)) {
                    isValid = false;
                }
            });
            
            if(isValid) {
                dispatch(assetActions.updateAssetDetailLimits(detailsAssetId, detailsYear, {limits: assetDetailLimitSelections}));
                this.toggle();
            }
        }
    }

    render() {
        const { buttonClassOverride, containerClassOverride, buttonColor, buttonText, assets, assetDetailLimits, detailsAssetId, detailsYear, forOrganization, organizationYear } = this.props;

        if(!assetDetailLimits) {
            return null;
        }

        var assetLookup = assets.reduce((d, v) => {d[v.asset_id] = v; return d}, {});
        var selectedAsset = assetLookup[detailsAssetId];
        selectedAsset = selectedAsset || { name: "Unknown" };

        return (
        <div className={containerClassOverride}>
            <Button className={buttonClassOverride || styles.button} color={buttonColor} onClick={this.toggle}>{buttonText}</Button>
            <Modal isOpen={this.state.modal} toggle={this.toggle} className={styles.modal}>
                <ModalHeader toggle={this.toggle}>{forOrganization ? 'Research Organization' : selectedAsset.name} {forOrganization ? organizationYear : detailsYear} Allocations</ModalHeader>
                <ModalBody className={styles.modalbody}>
                    {assetDetailLimits.map(l => {
                        return  <FormGroup key={l.limit_id}>
                                    <Label>{l.area} ({getLimitType(l.type)})</Label>
                                    <Input lid={l.limit_id} aid={l.asset_id} area={l.area} year={l.year} type={l.type.toString()} defaultValue={l.limit} onChange={this.handleUpdate}/>
                                </FormGroup>
                    })
                    }
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={this.confirm}>Confirm</Button>
                    <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
        );
    }
}

AssetDetailsUpdateLimitsModal.propTypes = {
    buttonColor: PropTypes.string,
    buttonText: PropTypes.string,
    containerClassOverride: PropTypes.string,
    buttonClassOverride: PropTypes.string,
    forOrganization: PropTypes.bool,
    organizationYear: PropTypes.number
};
  
AssetDetailsUpdateLimitsModal.defaultProps = {
    buttonColor: 'success',
    buttonText: '',
    containerClassOverride: null,
    buttonClassOverride: null,
    forOrganization: false,
    organizationYear: null
};

function mapStateToProps(state) {
    const { asset } = state;
    const { assets, assetDetailLimits, assetDetailLimitSelections, detailsAssetId, detailsYear } = asset;

    return {
        assets,
        assetDetailLimits,
        assetDetailLimitSelections,
        detailsAssetId,
        detailsYear
    };
}

const connectedAssetDetailsUpdateLimitsModal = connect(mapStateToProps)(AssetDetailsUpdateLimitsModal);
export { connectedAssetDetailsUpdateLimitsModal as AssetDetailsUpdateLimitsModal };