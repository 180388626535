import { documentConstants } from '_constants';

var initialState = {
    companyDocuments: [],
    documents: [],
    documentLink: null,
    fetchingDocument: false
};

export function document(state = initialState, reduxAction) {
    switch (reduxAction.type) {
        case documentConstants.DOC_GET_REQUEST:
        case documentConstants.DOC_GET_FAILURE:    
            return {
                ...state,
                documentLink: null,
                fetchingDocument: true
            };
        case documentConstants.DOC_GET_SUCCESS:
            return {
                ...state,
                documentLink: reduxAction.documentLink.document_path,
                fetchingDocument: false
            };
        case documentConstants.DOC_GET_ALL_REQUEST:
        case documentConstants.DOC_GET_ALL_FAILURE:    
            return {
                documents: [],
                fetchingDocument: true
            };
        case documentConstants.DOC_GET_ALL_SUCCESS:
            return {
                documents: reduxAction.documents,
                fetchingDocument: false
            };
        case documentConstants.DOC_GET_ALL_TASK_REQUEST:
        case documentConstants.DOC_GET_ALL_TASK_FAILURE:    
            return {
                documents: [],
                fetchingDocument: true
            };
        case documentConstants.DOC_GET_ALL_TASK_SUCCESS:
            return {
                documents: reduxAction.documents,
                fetchingDocument: false
            };
        case documentConstants.DOC_GET_ALL_COMPANIES_REQUEST:
        case documentConstants.DOC_GET_ALL_COMPANIES_FAILURE:    
            return {
                companyDocuments: {},
                documents: [],
                fetchingDocument: true
            };
        case documentConstants.DOC_GET_ALL_COMPANIES_SUCCESS:
            return {
                companyDocuments: buildCompanyDocuments(reduxAction.documents),
                documents: [],
                fetchingDocument: false
            };
        case documentConstants.UPDATE_DOC_COMPANY: {
            return {
                ...state,
                selectedCompanyId: reduxAction.companyId,
                documents: state.companyDocuments[reduxAction.companyId] || []
            }
        }
        default:
            return state;
    }
}

function buildCompanyDocuments(documents) {
    var companyDocuments = {};

    documents.forEach(doc => {
        if(!companyDocuments[doc.company.company_id]) {
            companyDocuments[doc.company.company_id] = [];
        }
        companyDocuments[doc.company.company_id].push(doc);
    });

    return companyDocuments;
}