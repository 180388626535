import React from 'react';
import PropTypes from 'prop-types';
import styles from './CompanyRoleModal.module.css';

import { connect } from 'react-redux';
import { companyRole, possibleRoles } from '_helpers';
import { Button, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class CompanyRoleModal extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            modal: false,
            roles: possibleRoles(),
            selectedRole: props.currentEmployee.company_role,
            selectedSubRole: props.currentEmployee.sub_role
        };

        this.toggle = this.toggle.bind(this);
        this.confirm = this.confirm.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        var index = event.target.selectedIndex;
        var selectedRole = event.target.childNodes[index].getAttribute('rid');
        var selectedSubRole = event.target.childNodes[index].getAttribute('rsid');

        this.setState({
            selectedRole,
            selectedSubRole
        });
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    confirm() {
        const { confirmCallback } = this.props;
        const { selectedRole, selectedSubRole } = this.state;
        
        //callback of setting state with latest values
        confirmCallback({ succeed: true, selectedRole, selectedSubRole });
        this.toggle();
    }

    render() {
        const { roles } = this.state;
        const { buttonColor, currentEmployee, disabled } = this.props;

        return (
        <div>
            <Button className={styles.button} color={buttonColor} disabled={disabled} onClick={this.toggle}>Update Employee Role</Button>
            <Modal isOpen={this.state.modal} toggle={this.toggle} className={styles.modal}>
                <ModalHeader toggle={this.toggle}>Update {currentEmployee.user.first_name} {currentEmployee.user.last_name}'s Company Role</ModalHeader>
                <ModalBody className={styles.modalbody}>
                    <Label>Current Role</Label>
                    <Input key= {'oldRole'} readOnly defaultValue={companyRole(currentEmployee.company_role, currentEmployee.sub_role)}></Input>
                    <br/>
                    <Label>New Role</Label>
                    <Input key= {'newRole'} type='select' defaultValue={companyRole(currentEmployee.company_role, currentEmployee.sub_role)} onChange={this.handleChange}>
                        {roles.map(r => {
                            return  <option key={r.role + '-' + r.subRole} rid={r.role} rsid={r.subRole}>
                                        {companyRole(r.role, r.subRole)}
                                    </option>
                            })
                        }
                    </Input>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.confirm}>Confirm</Button>
                    <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
        );
    }
}

CompanyRoleModal.propTypes = {
    buttonColor: PropTypes.string,
    confirmCallback: PropTypes.func,
    disabled: PropTypes.bool
};
  
CompanyRoleModal.defaultProps = {
    buttonColor: 'success',
    confirmCallback: null,
    disabled: false
};

function mapStateToProps(state) {
    const { company } = state;
    const { currentEmployee } = company;
    return {
        currentEmployee
    };
}

const connectedCompanyRoleModal = connect(mapStateToProps)(CompanyRoleModal);
export { connectedCompanyRoleModal as CompanyRoleModal };