export const assetConstants = {
    ASSETS_GET_REQUEST: 'ASSETS_GET_REQUEST',
    ASSETS_GET_SUCCESS: 'ASSETS_GET_SUCCESS',
    ASSETS_GET_FAILURE: 'ASSETS_GET_FAILURE',

    ASSET_GET_REQUEST: 'ASSET_GET_REQUEST',
    ASSET_GET_SUCCESS: 'ASSET_GET_SUCCESS',
    ASSET_GET_FAILURE: 'ASSET_GET_FAILURE',

    ASSET_CREATE_REQUEST: 'ASSET_CREATE_REQUEST',
    ASSET_CREATE_SUCCESS: 'ASSET_CREATE_SUCCESS',
    ASSET_CREATE_FAILURE: 'ASSET_CREATE_FAILURE',
    
    ASSET_UPDATE_REQUEST: 'ASSET_UPDATE_REQUEST',
    ASSET_UPDATE_SUCCESS: 'ASSET_UPDATE_SUCCESS',
    ASSET_UPDATE_FAILURE: 'ASSET_UPDATE_FAILURE',

    UPDATE_ASSET_REQUIREMENTS_SELECTION: 'UPDATE_ASSET_REQUIREMENTS_SELECTION',

    ASSET_TEMPLATES_GET_REQUEST: 'ASSET_TEMPLATES_GET_REQUEST',
    ASSET_TEMPLATES_GET_SUCCESS: 'ASSET_TEMPLATES_GET_SUCCESS',
    ASSET_TEMPLATES_GET_FAILURE: 'ASSET_TEMPLATES_GET_FAILURE',

    ASSET_TEMPLATE_GET_REQUEST: 'ASSET_TEMPLATE_GET_REQUEST',
    ASSET_TEMPLATE_GET_SUCCESS: 'ASSET_TEMPLATE_GET_SUCCESS',
    ASSET_TEMPLATE_GET_FAILURE: 'ASSET_TEMPLATE_GET_FAILURE',

    ASSET_TEMPLATE_CREATE_REQUEST: 'ASSET_TEMPLATE_CREATE_REQUEST',
    ASSET_TEMPLATE_CREATE_SUCCESS: 'ASSET_TEMPLATE_CREATE_SUCCESS',
    ASSET_TEMPLATE_CREATE_FAILURE: 'ASSET_TEMPLATE_CREATE_FAILURE',
    
    ASSET_TEMPLATE_UPDATE_REQUEST: 'ASSET_TEMPLATE_UPDATE_REQUEST',
    ASSET_TEMPLATE_UPDATE_SUCCESS: 'ASSET_TEMPLATE_UPDATE_SUCCESS',
    ASSET_TEMPLATE_UPDATE_FAILURE: 'ASSET_TEMPLATE_UPDATE_FAILURE',

    ASSET_GET_ANNUAL_SUMMARY_REQUEST: 'ASSET_GET_ANNUAL_SUMMARY_REQUEST',
    ASSET_GET_ANNUAL_SUMMARY_SUCCESS: 'ASSET_GET_ANNUAL_SUMMARY_SUCCESS',
    ASSET_GET_ANNUAL_SUMMARY_FAILURE: 'ASSET_GET_ANNUAL_SUMMARY_FAILURE',

    ASSET_GET_ANNUAL_ORG_SUMMARY_REQUEST: 'ASSET_GET_ANNUAL_ORG_SUMMARY_REQUEST',
    ASSET_GET_ANNUAL_ORG_SUMMARY_SUCCESS: 'ASSET_GET_ANNUAL_ORG_SUMMARY_SUCCESS',
    ASSET_GET_ANNUAL_ORG_SUMMARY_FAILURE: 'ASSET_GET_ANNUAL_ORG_SUMMARY_FAILURE',

    ASSET_VIEW_ASSET_ANNUAL_DETAILS: 'ASSET_VIEW_ASSET_ANNUAL_DETAILS',

    ASSET_GET_ANNUAL_DETAILS_REQUEST: 'ASSET_GET_ANNUAL_DETAILS_REQUEST',
    ASSET_GET_ANNUAL_DETAILS_SUCCESS: 'ASSET_GET_ANNUAL_DETAILS_SUCCESS',
    ASSET_GET_ANNUAL_DETAILS_FAILURE: 'ASSET_GET_ANNUAL_DETAILS_FAILURE',

    ASSET_GET_ANNUAL_DETAIED_REPORT_REQUEST: 'ASSET_GET_ANNUAL_DETAIED_REPORT_REQUEST',
    ASSET_GET_ANNUAL_DETAIED_REPORT_SUCCESS: 'ASSET_GET_ANNUAL_DETAIED_REPORT_SUCCESS',
    ASSET_GET_ANNUAL_DETAIED_REPORT_FAILURE: 'ASSET_GET_ANNUAL_DETAIED_REPORT_FAILURE',

    ASSET_CUPDATE_ANNUAL_DETAILS_REQUEST: 'ASSET_CUPDATE_ANNUAL_DETAILS_REQUEST',
    ASSET_CUPDATE_ANNUAL_DETAILS_SUCCESS: 'ASSET_CUPDATE_ANNUAL_DETAILS_SUCCESS',
    ASSET_CUPDATE_ANNUAL_DETAILS_FAILURE: 'ASSET_CUPDATE_ANNUAL_DETAILS_FAILURE',

    ASSET_GET_DETAILS_LIMITS_REQUEST: 'ASSET_GET_DETAILS_LIMITS_REQUEST',
    ASSET_GET_DETAILS_LIMITS_SUCCESS: 'ASSET_GET_DETAILS_LIMITS_SUCCESS',
    ASSET_GET_DETAILS_LIMITS_FAILURE: 'ASSET_GET_DETAILS_LIMITS_FAILURE',

    ASSET_UPDATE_DETAILS_LIMITS_REQUEST: 'ASSET_UPDATE_DETAILS_LIMITS_REQUEST',
    ASSET_UPDATE_DETAILS_LIMITS_SUCCESS: 'ASSET_UPDATE_DETAILS_LIMITS_SUCCESS',
    ASSET_UPDATE_DETAILS_LIMITS_FAILURE: 'ASSET_UPDATE_DETAILS_LIMITS_FAILURE',

    ASSET_DETAILS_LIMITS_UPDATE: 'ASSET_DETAILS_LIMITS_UPDATE',
};