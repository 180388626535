import React from 'react';
import styles from './Footer.module.css'

import { connect } from 'react-redux';
import { Container } from 'reactstrap'

class Footer extends React.Component {
    render() {
        return (
            <Container fluid className={styles.footer}>
                <br/>
                <a href="/support" className="active">Click For Support</a>
                <br/>
                <span>
                    © {new Date().getFullYear()} AB Squared, LLC. All rights reserved. TackleDocs is a registered trademarks of AB Squared, LLC.
                </span>
                <br/>
                <span>
                    Terms and conditions, features, support, pricing, and service options subject to change without notice.
                </span>
            </Container>
        )
    }
}

function mapStateToProps(state) {    
    return {
    };
}

const connectedFooter = connect(mapStateToProps)(Footer);
export { connectedFooter as Footer };