import React from 'react';
import styles from './ForgotPasswordPage.module.css';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Form, FormFeedback, FormGroup, Button, Input, Label } from 'reactstrap';
import { userActions } from '_actions';
import { validateEmail } from '_helpers';

class ForgotPasswordPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            submitted: false,
            validEmail: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        this.setState({
            email: e.currentTarget.value,
            validEmail: validateEmail(e.currentTarget.value)
        })
    }

    handleSubmit(e) {
        e.preventDefault(); //THIS IS SO FUCKING IMPORTANT, IT WILL BREAK REACT BECAUSE OF THINGS

        const { email, validEmail } = this.state;
        const { dispatch } = this.props;

        if(validEmail) {
            this.setState({
                submitted: true
            });

            dispatch(userActions.resetPassword(email));
        }
    }

    render() {
        const { email, submitted, validEmail } = this.state;

        return (
            <Container className={styles.container}>
                <h2 className={styles.heading}>Sign In Help</h2>
                <p className={styles.para}>Your TackleDocs account will give you access to all our features</p>
                <Form onSubmit={this.handleSubmit} className={styles.form}>
                    <FormGroup>
                        <Label>Email</Label>
                        <Input required valid={validEmail} invalid={email.length > 0 && !validEmail} readOnly={submitted} type="email" value={email} onChange={this.handleChange}></Input>
                        <FormFeedback invalid="true">
                            Please enter a valid email
                        </FormFeedback>
                    </FormGroup>
                    <Button color="primary" disabled={submitted} className={styles.submit}>Continue</Button>
                    <FormGroup>
                        <Link to="/login" className="btn btn-link">Cancel</Link>
                    </FormGroup>
                </Form>
            </Container>
        );
    }
}
    
function mapStateToProps(state) {      
    return {
    };
}

const connectedForgotPasswordPage = connect(mapStateToProps)(ForgotPasswordPage);
export { connectedForgotPasswordPage as ForgotPasswordPage };