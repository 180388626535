import React from 'react';
import PropTypes from 'prop-types';
import styles from './ReminderDetailsModal.module.css';

import { connect } from 'react-redux';
import { Button, Input, Label, ListGroup, ListGroupItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { currentCompanyRole, formatDate, status } from '_helpers';
import { activeActions, reminderActions } from '_actions';

class ReminderDetailsModal extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            modal: props.forceModal
        };

        this.handleCancel = this.handleCancel.bind(this);
        this.handleComplete = this.handleComplete.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    handleCancel() {
        const { dispatch, currentReminder } = this.props;

        dispatch(reminderActions.deactivateReminder(currentReminder.reminder_id));

        this.toggle();
    }

    handleComplete() {
        const { dispatch, currentReminder } = this.props;

        dispatch(activeActions.getActionRequirementsForReminder(currentReminder.reminder_id));

        this.toggle();
    }

    toggle() {
        if(this.state.modal || this.props.forceModal) {
            this.props.closeModal();
        } else {
            
            this.setState({
                modal: !this.state.modal
            });
        }
    }

    render() {
        const { currentReminder, forceModal, user } = this.props;

        if(!currentReminder) {
            return null;
        }

        var isUsersReminder = false;
        for(var i = 0; i < currentReminder.employees.length; i++) {
            if(currentReminder.employees[i].user.user_id === user.user_id) {
                isUsersReminder = true;
            }
        }

        return (
            <div>
                <Modal isOpen={forceModal || this.state.modal} toggle={this.toggle} className={styles.modal}>
                    <ModalHeader toggle={this.toggle}>Reminder Information</ModalHeader>
                    <ModalBody className={styles.modalbody}>
                        <h5>{currentReminder ? currentReminder.heading : ''}</h5>
                        <Label className={styles.label}>Asset</Label>
                        <Input readOnly defaultValue={currentReminder.asset.name}></Input>
                        <div className={styles.infocontainer}>
                            <div className={styles.info}>
                                <Label className={styles.label}>Due Date</Label>
                                <Input readOnly defaultValue={formatDate(currentReminder.end_date)}></Input>
                            </div>
                            <div className={styles.infosecond}>
                                <Label className={styles.label}>Status</Label>
                                <Input readOnly defaultValue={status(currentReminder.status)}></Input>
                            </div>
                        </div>
                        {currentReminder.notes && 
                            <div>
                                <Label className={styles.label}>Notes</Label>
                                <Input readOnly type="textarea" className={styles.notesarea} defaultValue={currentReminder.notes}></Input>
                            </div>
                        }
                        {currentReminder.employees && currentReminder.employees.length > 0 &&
                            <div>
                                <Label className={styles.label}>Employee's Responsible</Label>
                                <ListGroup className={styles.listgroup}>
                                {
                                    currentReminder.employees.map(e => {
                                        return  <ListGroupItem key={e.user.user_id}>
                                                    {e.user.first_name} {e.user.last_name}
                                                </ListGroupItem>
                                    })
                                }
                                </ListGroup>
                            </div>
                        }
                    </ModalBody>
                    <ModalFooter>
                        {isUsersReminder && currentReminder.status === 2 &&
                            <Button color="success" onClick={this.handleComplete}>Complete</Button>
                        }
                        {currentCompanyRole() >= 3 && currentReminder.status === 2?
                            <Button color="danger" onClick={this.handleCancel}>Deactivate</Button> :
                            <Button color="secondary" onClick={this.toggle}>Close</Button>
                        }
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

ReminderDetailsModal.propTypes = {
    forceModal: PropTypes.bool,
};
  
ReminderDetailsModal.defaultProps = {
    forceModal: false,
};

function mapStateToProps(state) {
    const { authentication, reminder } = state;
    const { currentReminder } = reminder;
    const { user } = authentication;

    return {
        currentReminder,
        user
    };
}

const connectedReminderDetailsModal = connect(mapStateToProps)(ReminderDetailsModal);
export { connectedReminderDetailsModal as ReminderDetailsModal };