import React from 'react';
import styles from './ProfilePage.module.css';
import fallback from '_images/user_fallback_dark.svg';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import * as loadImage from 'blueimp-load-image';

import { connect } from 'react-redux';
import { Button, Form, FormGroup, FormFeedback, Input, Label } from 'reactstrap';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { Link } from 'react-router-dom';
import { userActions } from '_actions';
import { formatPhoneNumber, convertDate, isValidDate, validatePhoneNumber, validPhoneNumberMessage } from '_helpers';

class ProfilePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: props.user, //this takes the user out of redux store and copies it to local component state so that changes dont take effect until confirmed
            validNumbers: validatePhoneNumber(props.user.phone_number) && validatePhoneNumber(props.user.emergency_contact_number) ? true : false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleBirthDateChange = this.handleBirthDateChange.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
    }
    
    handleChange(event) {
        const { name, value } = event.target;
        const { user } = this.state;

        this.setState({
            user: {
                ...user,
                [name]: value
            },
            validNumbers:   name === 'phone_number' ? validatePhoneNumber(value) : validatePhoneNumber(user.phone_number) && 
                            name === 'emergency_contact_number' ? validatePhoneNumber(value) : validatePhoneNumber(user.emergency_contact_number)
        });
    }

    handleBirthDateChange(updatedDate) {
        const { user } = this.state;

        this.setState({
            user: {
                ...user,
                birth_date: updatedDate.toISOString()
            }
        });
    }

    handleFileChange(event) {
        var file = event.target.files[0];
        
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
            loadImage(reader.result, (image) => {
                const { user } = this.state;
                user.user_local_logo = image.src;

                this.setState({
                    user
                });

            }, {orientation: true, minWidth: 320, maxWidth: 320, minHeight: 240, maxHeight: 240});
        }.bind(this);
    }

    handleCountryChange(value) {
        const { user } = this.state;

        this.setState({
            user: {
                ...user,
                country: value
            }
        });
    }

    handleStateChange(value) {
        const { user } = this.state;

        this.setState({
            user: {
                ...user,
                state: value
            }
        });
    }

    handleSubmit(e) {
        e.preventDefault(); //THIS IS SO FUCKING IMPORTANT, IT WILL BREAK REACT BECAUSE OF THINGS

        this.setState({ submitted: true });
        const { user } = this.state;
        const { dispatch } = this.props;

        if (user.email) {
            if(user.user_local_logo) {
                user.user_logo = user.user_local_logo;
            }

            dispatch(userActions.update(user));
        }
    }

    render() {
        const { user, validNumbers } = this.state;

        return (
            <div className={styles.container}>
                <div className={styles.topcontainer}>
                    <div className={styles.avatarcontainer}>
                        <img className={styles.imglogo} src={user.user_local_logo || user.user_logo_path || fallback} alt=":("/>
                        <div className={styles.uploadbtnwrapper}>
                            <Button className={styles.btn}>Upload</Button>
                            <Input className={styles.fileinput} type="file" name="user_local_logo" accept=".jpg, .jpeg, .png, .bmp, .gif" onChange={this.handleFileChange}></Input>
                        </div>
                    </div>
                    <div className={styles.headingcontainer}>
                        <h2>{user.first_name} {user.last_name}</h2>
                        <h5>{user.email}</h5>
                    </div>
                </div>
                <div className={styles.botcontainer}>
                    <Form>
                        <h5 className={styles.formheading}>General Information</h5>
                        <div className={styles.formarea}>
                            <FormGroup className={styles.formgroup}>
                                <Label>First Name</Label>
                                <Input required readOnly name="first_name" autoCapitalize={'words'} defaultValue={user.first_name} onChange={this.handleChange}/>
                            </FormGroup>
                            <FormGroup className={styles.formgroup}>
                                <Label>Last Name</Label>
                                <Input required readOnly name="last_name" autoCapitalize={'words'} defaultValue={user.last_name} onChange={this.handleChange}/>
                            </FormGroup>
                            <FormGroup className={styles.formgroup}>
                                <Label>Date of Birth</Label>
                                <DateTimePicker time={false}
                                                name="birth_date"
                                                max={new Date()}
                                                defaultValue={isValidDate(user.birth_date) ? convertDate(user.birth_date) : undefined}
                                                onChange={this.handleBirthDateChange}
                                                placeholder={'Enter Your DOB (mm/dd/yyyy)'}/>
                            </FormGroup>
                            <FormGroup className={styles.formgroup}>
                                <Label>Phone Number</Label>
                                <Input  type="tel"
                                        name="phone_number" 
                                        invalid={user.phone_number ? !validatePhoneNumber(user.phone_number) : false}
                                        defaultValue={user.phone_number || ''}
                                        onChange={(e) => {e.target.value = formatPhoneNumber(user.phone_number, e.target.value); this.handleChange(e);}}/>
                                <FormFeedback invalid="true">
                                    {validPhoneNumberMessage()}
                                </FormFeedback>
                            </FormGroup>
                        </div>
                        <h5 className={styles.formheading}>Address Information</h5>
                        <div className={styles.formarea}>
                            <FormGroup className={styles.formgroup}>
                                <Label>Address</Label>
                                <Input name="address" autoCapitalize={'words'} defaultValue={user.address || ''} onChange={this.handleChange}/>
                            </FormGroup>
                            <FormGroup className={styles.formgroup}>
                                <Label>City</Label>
                                <Input name="city" autoCapitalize={'words'} defaultValue={user.city || ''} onChange={this.handleChange}/>
                            </FormGroup>
                            <FormGroup className={styles.formgroup}>
                                <Label>State</Label>
                                <RegionDropdown
                                    className={styles.crselect}
                                    blankOptionLabel="Select a country"
                                    defaultOptionLabel="Select a state"
                                    country={user.country || 'United States'}
                                    value={user.state || ''}
                                    onChange={this.handleStateChange} />
                            </FormGroup>
                            <FormGroup className={styles.formgroup}>
                                <Label>Country</Label>
                                <CountryDropdown
                                    className={styles.crselect}
                                    defaultOptionLabel="Select a country"
                                    value={user.country || ''}
                                    onChange={this.handleCountryChange}
                                    priorityOptions={["US", "GB", "CA"]} />
                            </FormGroup>
                            <FormGroup className={styles.formgroup}>
                                <Label>Zip Code</Label>
                                <Input name="zip_code" defaultValue={user.zip_code || ''} onChange={this.handleChange}/>
                            </FormGroup>
                        </div>
                        <h5 className={styles.formheading}>Emergency Contact Information</h5>
                        <div className={styles.formarea}>
                            <FormGroup className={styles.formgroup}>
                                <Label>Contact Name</Label>
                                <Input name="emergency_contact_name" autoCapitalize={'words'} defaultValue={user.emergency_contact_name || ''} onChange={this.handleChange}/>
                            </FormGroup>
                            <FormGroup className={styles.formgroup}>
                                <Label>Contact Number</Label>
                                <Input  type="tel" name="emergency_contact_number"
                                        invalid={user.emergency_contact_number ? !validatePhoneNumber(user.emergency_contact_number) : false}
                                        defaultValue={user.emergency_contact_number || ''}
                                        onChange={(e) => {e.target.value = formatPhoneNumber(user.emergency_contact_number, e.target.value); this.handleChange(e);}}/>
                                <FormFeedback invalid="true">
                                    {validPhoneNumberMessage()}
                                </FormFeedback>
                            </FormGroup>
                        </div>
                        <FormGroup key='submit'>
                            <Button color="primary" disabled={!validNumbers} onClick={this.handleSubmit}>Update</Button>
                            <Link to="/home" className="btn btn-link">Cancel</Link>
                        </FormGroup>
                    </Form>
                </div>
            </div>
        );
    }
}
    
function mapStateToProps(state) {
    const { authentication } = state;
    const { user } = authentication;

    return {
        user
    };
}

const connectedProfilePage = connect(mapStateToProps)(ProfilePage);
export { connectedProfilePage as ProfilePage };