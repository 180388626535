export const activeConstants = {
    ACTIONS_GET_REQUEST: 'ACTIONS_GET_REQUEST',
    ACTIONS_GET_SUCCESS: 'ACTIONS_GET_SUCCESS',
    ACTIONS_GET_FAILURE: 'ACTIONS_GET_FAILURE',

    ACTION_GET_REQUEST: 'ACTION_GET_REQUEST',
    ACTION_GET_SUCCESS: 'ACTION_GET_SUCCESS',
    ACTION_GET_FAILURE: 'ACTION_GET_FAILURE',

    ACTION_REQUIREMENTS_GET_REQUEST: 'ACTION_REQUIREMENTS_GET_REQUEST',
    ACTION_REQUIREMENTS_GET_SUCCESS: 'ACTION_REQUIREMENTS_GET_SUCCESS',
    ACTION_REQUIREMENTS_GET_FAILURE: 'ACTION_REQUIREMENTS_GET_FAILURE',

    REMINDER_ACTION_REQUIREMENTS_GET_REQUEST: 'REMINDER_ACTION_REQUIREMENTS_GET_REQUEST',
    REMINDER_ACTION_REQUIREMENTS_GET_SUCCESS: 'REMINDER_ACTION_REQUIREMENTS_GET_SUCCESS',
    REMINDER_ACTION_REQUIREMENTS_GET_FAILURE: 'REMINDER_ACTION_REQUIREMENTS_GET_FAILURE',

    ACTION_REQUIREMENTS_UPDATE: 'ACTION_REQUIREMENTS_UPDATE',
    ACTION_REQUIREMENTS_SELECTABLES_UPDATE: 'ACTION_REQUIREMENTS_SELECTABLES_UPDATE',

    ACTION_COMPLETE_REQUEST: 'ACTION_COMPLETE_REQUEST',
    ACTION_COMPLETE_SUCCESS: 'ACTION_COMPLETE_SUCCESS',
    ACTION_COMPLETE_FAILURE: 'ACTION_COMPLETE_FAILURE',

    ACTION_PREVIEW_REQUEST: 'ACTION_PREVIEW_REQUEST',
    ACTION_PREVIEW_SUCCESS: 'ACTION_PREVIEW_SUCCESS',
    ACTION_PREVIEW_FAILURE: 'ACTION_PREVIEW_FAILURE',

    TASKS_GET_REQUEST: 'TASKS_GET_REQUEST',
    TASKS_GET_SUCCESS: 'TASKS_GET_SUCCESS',
    TASKS_GET_FAILURE: 'TASKS_GET_FAILURE',

    TASK_GET_REQUEST: 'TASK_GET_REQUEST',
    TASK_GET_SUCCESS: 'TASK_GET_SUCCESS',
    TASK_GET_FAILURE: 'TASK_GET_FAILURE',

    TASK_UPDATE_REQUEST: 'TASK_UPDATE_REQUEST',
    TASK_UPDATE_SUCCESS: 'TASK_UPDATE_SUCCESS',
    TASK_UPDATE_FAILURE: 'TASK_UPDATE_FAILURE',

    TASK_CREATE_REQUEST: 'TASK_CREATE_REQUEST',
    TASK_CREATE_SUCCESS: 'TASK_CREATE_SUCCESS',
    TASK_CREATE_FAILURE: 'TASK_CREATE_FAILURE',
    
    TASK_CANCEL_REQUEST: 'TASK_CANCEL_REQUEST',
    TASK_CANCEL_SUCCESS: 'TASK_CANCEL_SUCCESS',
    TASK_CANCEL_FAILURE: 'TASK_CANCEL_FAILURE',

    TASK_MARK_EMPLOYEE_INCOMPLETE_REQUEST: 'TASK_MARK_EMPLOYEE_INCOMPLETE_REQUEST',
    TASK_MARK_EMPLOYEE_INCOMPLETE_SUCCESS: 'TASK_MARK_EMPLOYEE_INCOMPLETE_SUCCESS',
    TASK_MARK_EMPLOYEE_INCOMPLETE_FAILURE: 'TASK_MARK_EMPLOYEE_INCOMPLETE_FAILURE',

    TASK_ADD_EMPLOYEE_REQUEST: 'TASK_ADD_EMPLOYEE_REQUEST',
    TASK_ADD_EMPLOYEE_SUCCESS: 'TASK_ADD_EMPLOYEE_SUCCESS',
    TASK_ADD_EMPLOYEE_FAILURE: 'TASK_ADD_EMPLOYEE_FAILURE',

    TASK_GET_ROLE_REQUIREMENT_REQUEST: 'TASK_GET_ROLE_REQUIREMENT_REQUEST',
    TASK_GET_ROLE_REQUIREMENT_SUCCESS: 'TASK_GET_ROLE_REQUIREMENT_SUCCESS',
    TASK_GET_ROLE_REQUIREMENT_FAILURE: 'TASK_GET_ROLE_REQUIREMENT_FAILURE',

    TASK_GET_TASK_ASSET_REQUEST: 'TASK_GET_TASK_ASSET_REQUEST',
    TASK_GET_TASK_ASSET_SUCCESS: 'TASK_GET_TASK_ASSET_SUCCESS',
    TASK_GET_TASK_ASSET_FAILURE: 'TASK_GET_TASK_ASSET_FAILURE',

    TASK_GET_TASK_REQUIRES_EMPLOYEES_REQUEST: 'TASK_GET_TASK_REQUIRES_EMPLOYEES_REQUEST',
    TASK_GET_TASK_REQUIRES_EMPLOYEES_SUCCESS: 'TASK_GET_TASK_REQUIRES_EMPLOYEES_SUCCESS',
    TASK_GET_TASK_REQUIRES_EMPLOYEES_FAILURE: 'TASK_GET_TASK_REQUIRES_EMPLOYEES_FAILURE',

    TASK_GET_TASK_EMPLOYEE_SUMMARIES_REQUEST: 'TASK_GET_TASK_EMPLOYEE_SUMMARIES_REQUEST',
    TASK_GET_TASK_EMPLOYEE_SUMMARIES_SUCCESS: 'TASK_GET_TASK_EMPLOYEE_SUMMARIES_SUCCESS',
    TASK_GET_TASK_EMPLOYEE_SUMMARIES_FAILURE: 'TASK_GET_TASK_EMPLOYEE_SUMMARIES_FAILURE'
};