import React from 'react';
import PropTypes from 'prop-types';
import styles from './ActionListGroup.module.css';
import check from '_images/thumbsup.png';

import { connect } from 'react-redux';
import { ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Badge } from 'reactstrap';
import { activeActions, companyActions } from '_actions';
import { formatShortDateTime } from '_helpers';
import { MdAssignment } from 'react-icons/md';

class ActionListGroup extends React.Component {
    constructor(props) {
        super(props);
        
        this.handleClick = this.handleClick.bind(this);
    }
    
    componentDidMount() {
        const { dispatch, filter, allCompanies } = this.props;

        if(allCompanies) {
            dispatch(activeActions.getAllActions(filter));
        } else {     
            dispatch(activeActions.getAllActionsForCurrentCompany(filter));
        }
    }

    handleClick(event) {
        const { dispatch, allCompanies } = this.props;

        if(allCompanies) {
            dispatch(companyActions.getCompany(event.currentTarget.getAttribute('cid'), false));
        }

        dispatch(activeActions.getActionRequirements(event.currentTarget.getAttribute('aid'), event.currentTarget.getAttribute('cid')));
    }

    render() {
        const { actions, allCompanies } = this.props;

        if(actions.length === 0) {
            return (
                <div className={styles.container}>
                    <div className={styles.headingcontainer}>
                        <h2 className={styles.heading}>My To Do's</h2>
                    </div>
                    <div className={styles.listcontainer}>
                        <div className={styles.centercontainer}>
                            <span className={styles.title}>
                                Good Job!
                            </span>
                            <span className={styles.para}>
                                You have nothing to do at the moment.
                            </span>
                            <img className={styles.check} src={check} alt=":("></img>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className={styles.container}>
                <div className={styles.headingcontainer}>
                    <h2 className={styles.heading}>My To Do's</h2>
                </div>
                <div className={styles.listcontainer}>
                    <ListGroup className={styles.list}>
                        {
                            actions.map(a => {
                                return <ListGroupItem className={styles.listitem} key={a.action_id} aid={a.action_id} cid={a.company ? a.company.company_id : null} onClick={this.handleClick}>
                                            <ListGroupItemHeading><MdAssignment/> {a.action_template.name}</ListGroupItemHeading>
                                            {allCompanies === true && a.company &&
                                                <ListGroupItemText className={styles.itemtext}>
                                                    <span>
                                                        {a.company.company_name}
                                                    </span>
                                                    <Badge className={styles.badge}>
                                                        <span className={styles.badgetext}>
                                                            {formatShortDateTime(a.create_date)}
                                                        </span>
                                                    </Badge>
                                                </ListGroupItemText>
                                            }
                                            {allCompanies === false && a.task &&
                                                <ListGroupItemText className={styles.itemtext}>
                                                    <span>
                                                    {a.action_template.description}
                                                    </span>
                                                    <Badge className={styles.badge}>
                                                        <span className={styles.badgetext}>
                                                            {formatShortDateTime(a.create_date)}
                                                        </span>
                                                    </Badge>
                                                </ListGroupItemText>
                                            }
                                        </ListGroupItem>
                            })
                        }
                    </ListGroup>
                </div>
            </div>
        );
    }
}

ActionListGroup.propTypes = {
    filter: PropTypes.string,
    allCompanies: PropTypes.bool
};
  
ActionListGroup.defaultProps = {
    filter: 'active',
    allCompanies: true
};

function mapStateToProps(state) {
    const { active } = state;
    const { actions } = active;
    return {
        actions
    };
}

const connectedActionListGroup = connect(mapStateToProps)(ActionListGroup);
export { connectedActionListGroup as ActionListGroup };