import { alertConstants } from '_constants';

export const alertActions = {
    success,
    error,
    clear
};

function success(message) {
    window.scrollTo(0, 0);
    return { type: alertConstants.SUCCESS, message };
}

function error(message) {
    setTimeout(function() { window.scrollTo(0, 0) }, 500);
    return { type: alertConstants.ERROR, message };
}

function clear() {
    return { type: alertConstants.CLEAR };
}