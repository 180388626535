import React from 'react';
import styles from './CreateAssetPage.module.css';

import { connect } from 'react-redux';
import { AssetTemplateRequirements } from '_components/AssetTemplateRequirements';
import { assetActions } from '_actions';
import { Container, Input, Label } from 'reactstrap';

class CreateAssetPage extends React.Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(assetActions.getAssetTemplates('active'));
    }

    handleChange(e) {
        const { dispatch } = this.props;
   
        var index = e.target.selectedIndex;
        var selectedOption = e.target.childNodes[index];

        dispatch(assetActions.getAssetTemplate(selectedOption.id));
    }

    render() {
        const { assetTemplates } = this.props;

        if(!assetTemplates || assetTemplates.length === 0) {
            return null;
        }

        return (
            <div className={styles.container}>
                <Container className={styles.main}>
                    <div className={styles.headingcontainer}>
                        <h4 className={styles.heading}>Create Asset</h4>
                    </div>
                    <div className={styles.typecontainer}>
                        <div>
                            <Label className={styles.subheading}>Asset Type</Label>
                        </div>
                        <Input type="select" onChange={this.handleChange}>
                        {
                            assetTemplates.map(o => {
                                return <option key={o.type} id={o.type}>{o.type}</option>
                            })
                        }
                        </Input>
                    </div>
                    <AssetTemplateRequirements/>
                </Container>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { asset } = state;
    const { assetTemplates } = asset;
    return {
        assetTemplates
    };
}

const connectedCreateAssetPage = connect(mapStateToProps)(CreateAssetPage);
export { connectedCreateAssetPage as CreateAssetPage };