import React from 'react';
import PropTypes from 'prop-types';
import checked from '_images/thumbsup.png';
import styles from './DocumentTable.module.css';

import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { Badge, Button, Input, Label } from 'reactstrap';
import { customTableTheme, formatShortDateTime, status } from '_helpers';
import { documentActions, companyActions } from '_actions';
import { FaFileDownload, FaFilePdf } from 'react-icons/fa';

class DocumentTable extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            currentSearch: '',
            filters: [],
            companySelectedId: 'select'
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleCompanySelection = this.handleCompanySelection.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleFilterApply = this.handleFilterApply.bind(this);
        this.handleBadgeClick = this.handleBadgeClick.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }
    
    columns = [
        {
            name: 'Document',
            selector: row => row['document'],
            sortable: true,
            wrap: true
        },
        {
            name: 'Description',
            selector: row => row['description'],
            sortable: true,
            grow: 4,
            hide: 'sm'
        },
        {
            name: 'Create Date',
            selector: row => row['create_date'],
            sortable: true,
            format: row => formatShortDateTime(row.ref.create_date),
            hide: 'sm',
            wrap: true
        },
        {
            name: 'Last Updated',
            selector: row => row['last_updated'],
            sortable: true,
            format: row => formatShortDateTime(row.ref.last_update_date),
            wrap: true
        },
        {
            name: 'Status',
            selector: row => row['status'],
            sortable: true,
            hide: 'sm'
        },
        {
            name: '',
            right: true,
            cell: row => <FaFileDownload className={styles.download} did={row.id} render={0} onClick={this.handleClick}>View</FaFileDownload>
        }
    ];

    taskColumns = [
        {
            name: 'Employee',
            selector: row => row['employee'],
            sortable: true,
            wrap: true
        },
        {
            name: 'Document',
            selector: row => row['document'],
            sortable: true,
            wrap: true
        },
        {
            name: 'Description',
            selector: row => row['description'],
            sortable: true,
            grow: 4,
            hide: 'sm'
        },
        {
            name: 'Last Updated',
            selector: row => row['last_updated'],
            format: row => formatShortDateTime(row.ref.last_update_date),
            sortable: true,
            wrap: true,
            hide: 'sm'
        },
        {
            name: 'Status',
            selector: row => row['status'],
            sortable: true,
            hide: 'sm'
        },
        {
            name: '',
            right: true,
            cell: row => <FaFileDownload className={styles.download} did={row.id} type={row.ref.type} render={'html'} onClick={this.handleClick}>View</FaFileDownload>
        },
        {
            name: '',
            right: true,
            cell: row => <FaFilePdf className={styles.download} did={row.id} type={row.ref.type} render={'pdf'} visibility={row.ref.type && row.ref.type === 1 ? 'visible' : 'hidden'} onClick={this.handleClick}>View Pdf</FaFilePdf>
        }
    ];

    handleTextChange(event) {
        this.setState({
            currentSearch: event.target.value
        })
    }

    handleFilterApply() {
        const { currentSearch, filters } = this.state;
        if(currentSearch) {
            if(!filters.includes(currentSearch)) {
                filters.push(currentSearch);
            }

            this.setState({
                currentSearch: '',
                filters: filters
            })
        }
    }

    handleBadgeClick(e) {
        var id = e.target.getAttribute("fid");

        const { filters } = this.state;

        for(var i = 0; i < filters.length; i++) {
            if(filters[i] === id) {
                filters.splice(i, 1);
            }
        }

        this.setState({
            filters: filters
        });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.handleFilterApply();
        }
    }

    handleClick(event) {
        const { dispatch, taskDocuments } = this.props;

        var docId = event.currentTarget.getAttribute('did');
        var viewableType = event.currentTarget.getAttribute('type');
        var renderType = event.currentTarget.getAttribute('render');

        dispatch(taskDocuments ? documentActions.downloadAdminFile(docId, viewableType, renderType) : documentActions.downloadFile(docId));
    }

    handleCompanySelection(event) {
        const { dispatch } = this.props;
        var index = event.target.selectedIndex;
        var selectedOption = event.target.childNodes[index];
        var cid = selectedOption.getAttribute('cid');
        
        dispatch(companyActions.getCompany(cid));
        dispatch(documentActions.updateSelectedCompany(cid));
    }

    componentDidMount() {
        const { allCompanies, dispatch, filter, taskDocuments } = this.props;

        if(allCompanies) {
            dispatch(documentActions.getAll(filter));
        } else if(!taskDocuments) {
            dispatch(documentActions.getAllForCompany(filter));
        }
    }

    render() {
        const { currentSearch, filters } = this.state;
        const { allCompanies, documents, relationships, selectedCompanyId, taskDocuments, user } = this.props;

        if(taskDocuments && documents && documents.length <= 0) {
            return null;
        }

        var data = documents.filter(d => d.status !== 4).reduce((result, d) => {
            if(taskDocuments) {
                result.push({
                    id: d.content_id,
                    employee: d.employee ? d.employee.user.first_name + ' ' + d.employee.user.last_name : '',
                    document: d.name,
                    description: d.description,
                    last_updated: d.last_update_date,
                    status: status(d.status),
                    ref: d
                });
            } 
            else {
                if(filters.length === 0) {
                    if(d.document_template) {
                        result.push({
                            id: d.document_id,
                            document: d.document_template.name,
                            description: d.document_template.description,
                            create_date: d.create_date,
                            last_updated: d.last_update_date,
                            status: status(d.status),
                            ref: d
                        });
                    }
                } else {
                    for(var i = 0; i < filters.length; i++) {
                        if( d.document_template.name.toLowerCase().includes(filters[i].toLowerCase()) || 
                            d.document_template.description.toLowerCase().includes(filters[i].toLowerCase()) ||
                            formatShortDateTime(d.create_date).toLowerCase().includes(filters[i].toLowerCase()) ||
                            formatShortDateTime(d.last_update_date).toLowerCase().includes(filters[i].toLowerCase())) {
                            
                            result.push({
                                id: d.document_id,
                                document: d.document_template.name,
                                description: d.document_template.description,
                                create_date: d.create_date,
                                last_updated: d.last_update_date,
                                status: status(d.status),
                                ref: d
                            });
                            break;
                        }
                    }
                }
            }

            return result;
        }, []);

        var selectedCompany;
        for(var i = 0; i < relationships.length; i++) {
            if(relationships[i].company_id === selectedCompanyId) {
                selectedCompany = relationships[i];
                break;
            }
        }

        var docTitle = allCompanies && selectedCompany ? `${user.first_name} ${user.last_name}'s Documents For ${selectedCompany.company_name}` 
        : `${user.first_name} ${user.last_name}'s Documents`;

        return (
            <div>
                <div className={styles.headingcontainer}>
                    {!taskDocuments &&
                    <div className={styles.labelcontainer}>
                        <Label className={styles.heading}>{docTitle}</Label>
                    </div>
                    }
                    {allCompanies &&
                    <div className={styles.companyselectcontainer}>
                        <Input className={styles.selectinput} type='select' defaultValue={'select'} onChange={this.handleCompanySelection}>
                            <option disabled value='select'>Select a company...</option>
                            {
                                relationships.map(r => {
                                    return <option key={r.relationship_id} cid={r.company_id}>{r.company_name}</option>
                                })
                            }
                        </Input>
                    </div>
                    }
                </div>
                
                {!taskDocuments && 
                    <div className={styles.filtercontainer}>
                        {filters.map(f => {
                            return  <Badge pill className={styles.badge} key={f} fid={f} onClick={this.handleBadgeClick}>
                                        <span className={styles.btext} fid={f} onClick={this.handleBadgeClick}>{f}</span>
                                        <span fid={f} className={styles.ex} onClick={this.handleBadgeClick}>X</span>
                                    </Badge>
                        })
                        }
                        <Input className={styles.filterinput} placeholder='Search...' onChange={this.handleTextChange} onKeyDown={this.handleKeyPress} value={currentSearch}/>
                        <Button className={styles.filterbutton} onClick={this.handleFilterApply}>Apply</Button>
                    </div>
                }
                
                <DataTable
                    columns={taskDocuments ? this.taskColumns : this.columns}
                    data={data}
                    striped
                    fixedHeader
                    fixedHeaderScrollHeight='500px'
                    customTheme={customTableTheme}
                    noHeader={true}
                    noDataComponent={
                        <div className={styles.nodatacontainer}>
                            <span className={styles.heading}>No Documents!</span>
                            <span className={styles.para}>You have nothing to view at this time.</span>
                            <img className={styles.checkedimg} src={checked} alt=":("/>
                        </div>
                    }
                />
            </div>        
        );
    }
}

DocumentTable.propTypes = {
    allCompanies: PropTypes.bool,
    filter: PropTypes.string,
    taskDocuments: PropTypes.bool
};
  
DocumentTable.defaultProps = {
    allCompanies: false,
    filter: 'all',
    taskDocuments: false
};

function mapStateToProps(state) {
    const { active, authentication, document, relationships } = state;
    const { currentTask } = active;
    const { companyDocuments, documents, fetchingDocument, selectedCompanyId } = document;
    const { user } = authentication;

    return {
        companyDocuments,
        currentTask,
        documents,
        fetchingDocument,
        relationships,
        selectedCompanyId,
        user
    };
}

const connectedDocumentTable = connect(mapStateToProps)(DocumentTable);
export { connectedDocumentTable as DocumentTable };