import { formConstants } from '_constants';

export function form(state = {}, reduxAction) {
    switch (reduxAction.type) {
        case formConstants.FORM_GET_REQUEST:
        case formConstants.FORM_GET_FAILURE:
        case formConstants.FORM_GET_SUCCESS:
        case formConstants.FORM_GET_All_TASK_FAILURE:
        case formConstants.FORM_GET_All_TASK_REQUEST:
        case formConstants.FORM_GET_All_TASK_SUCCESS:
            return { };
        default:
            return state
    }
}