import React from 'react';
import styles from './HealthCheckPage.module.css';

import { connect } from 'react-redux';

class HealthCheckPage extends React.Component {
    render() {
        return (
            <div className={styles.container}>
                <h2 className={styles.heading}>React App HealthCheck Overview</h2>
                <p><b>Environment:</b> {process.env.REACT_APP_STAGE}</p>
                <p><b>Version:</b> {process.env.REACT_APP_VERSION}</p>
                <h2 className={styles.heading}>Web Api HealthCheck Overview</h2>
                <iframe src={"https://www.tackledocs.com/api/healthcheck"} title={"API Health Check"}/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedHealthCheckPage = connect(mapStateToProps)(HealthCheckPage);
export { connectedHealthCheckPage as HealthCheckPage };