import React from 'react';
import classNames from 'classnames';
import styles from './CompleteDocumentAction.module.css';
import loading from '_images/loading.gif';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import MultiSelect from "@kenshooui/react-multi-select";

import { connect } from 'react-redux';
import { ProgressBar, Step } from 'react-step-progress-bar';
import { Button, Container, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { ConfirmationModal } from '_components/ConfirmationModal';
import { SignatureModal } from '_components/SignatureModal';
import { PdfViewer } from '_components/PdfViewer';
import { activeActions, alertActions } from '_actions';
import { formatPhoneNumber, formatDate, formatSocial, isQuestion, isUserInfo, isUserSignature, isValidDate, validatePhoneNumber, validPhoneNumberMessage, validateSocial, validSocialMessage } from '_helpers';
import { Prompt } from 'react-router-dom';

class CompleteDocumentAction extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            confirmed: false,
            percent: 0,
            signatures: {},
            showSignatures: true,
            validPhoneNumbers: true,
            validSocial: true,
            verified: false,
            verifiedSignatures: false,
            windowWidth: window.innerWidth
        };
        
        this.getDefaultValueForSelection = this.getDefaultValueForSelection.bind(this);
        this.getInputForKey = this.getInputForKey.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleBirthDateChange = this.handleBirthDateChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSelectableCheck = this.handleSelectableCheck.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onVerifyClick = this.onVerifyClick.bind(this);
        this.renderForLandscape = this.renderForLandscape.bind(this);
        this.renderForPortrait = this.renderForPortrait.bind(this);
        this.saveSignatureCallback = this.saveSignatureCallback.bind(this);
        this.toggleShow = this.toggleShow.bind(this);
        this.verifyData = this.verifyData.bind(this);
    }

    handleResize() {
        const { percent, signatures, verified, verifiedSignatures, windowWidth } = this.state;

        var threshHoldCrossed = (windowWidth < 600 && window.innerWidth >= 600) || (windowWidth >= 600 && window.innerWidth < 600);

        this.setState({
            percent: threshHoldCrossed ? 0 : percent,
            signatures: threshHoldCrossed ? {} : signatures,
            verified: threshHoldCrossed ? false : verified,
            verifiedSignatures: threshHoldCrossed ? false : verifiedSignatures,
            windowWidth: window.innerWidth
        })
    }

    handleTextChange(e) {
        const { dispatch } = this.props;

        var key = e.target.getAttribute("id");
        var type = e.target.getAttribute("rtype");
        var subtype = e.target.getAttribute("rsubtype");
        var selection = e.target.value;

        if(key === 'PhoneNumber' || key === 'EmergencyContactNumber'|| (type === '7' && subtype === '7')) {
            this.setState({
                validPhoneNumbers: validatePhoneNumber(selection)
            })
        }

        if(type === '7' && subtype === '6') {
            this.setState({
                validSocial: validateSocial(selection)
            })
        }

        dispatch(activeActions.updateActionRequirementSelection({ key: key, selection: selection }));
    }
    
    handleSelectChange(value, event) { 
        const { dispatch } = this.props;

        var key = event.target.name;

        dispatch(activeActions.updateActionRequirementSelection({ key: key, selection: value }));
        this.forceUpdate();
    }

    handleBirthDateChange(updatedDate) {
        const { dispatch } = this.props;

        dispatch(activeActions.updateActionRequirementSelection({ key: 'BirthDate', selection: updatedDate.toISOString() }));
        this.forceUpdate();
    }

    handleDateChange(key, updatedDate) {
        const { dispatch } = this.props;

        dispatch(activeActions.updateActionRequirementSelection({ key, selection: formatDate(updatedDate.toISOString()) }));
        this.forceUpdate();
    }

    handleSelectableCheck(selectedItems, qid) {
        const { dispatch } = this.props;
        
        var selectables = selectedItems.map(si => si.id);

        dispatch(activeActions.updateActionRequirementSelectables({key: qid, selectables}));
    }

    onSubmit() {
        const { dispatch, currentActionRequirements, currentActionRequirementSelections } = this.props;

        //actually verify data here
        var isVerified = true;
        for(var i = 0; i < currentActionRequirementSelections.length; i++) {
            var rs = currentActionRequirementSelections[i];

            if(rs.type !== 6 && !rs.selection) {
                isVerified = false;
                break;
            }

            if(rs.type === 6 && rs.sub_type === 1 && !rs.selection) {
                isVerified = false;
                break;
            }

            if(rs.type === 6 && rs.sub_type === 2 && (rs.selectables || []).length < rs.min_selections) {
                isVerified = false;
                break;
            }
        }

        if(isVerified) {
            var action_id = currentActionRequirements.action.action_id;
            var content_id = currentActionRequirements.action.content_id;

            this.setState({
                confirmed: true,
                percent: 100
            });

            dispatch(activeActions.completeAction({ action_id, content_id, selections: currentActionRequirementSelections }));
        }
    }

    onVerifyClick() {
        this.setState({
            percent: 0,
            signatures: {},
            verified: false,
            verifiedSignatures: false
        });
    }

    saveSignatureCallback(callback) {
        const { dispatch, currentActionRequirements, currentActionRequirementSelections } = this.props;
        const { percent, signatures } = this.state;

        var action_id = currentActionRequirements.action.action_id;
        var content_id = currentActionRequirements.action.content_id;

        dispatch(activeActions.updateActionRequirementSelection({ key: callback.key, selection: callback.image }));
        dispatch(activeActions.previewAction({ action_id, content_id, selections: currentActionRequirementSelections }));

        this.setState({
            signatures: {
                ...signatures,
                [callback.key]: true
            }
        })

        //check if everything is good to submit
        var isVerified = true;
        for(var i = 0; i < currentActionRequirementSelections.length; i++) {
            var rs = currentActionRequirementSelections[i];

            if(rs.type !== 6 && !rs.selection) {
                isVerified = false;
                break;
            }

            if(rs.type === 6 && rs.sub_type === 1 && !rs.selection) {
                isVerified = false;
                break;
            }

            if(rs.type === 6 && rs.sub_type === 2 && (rs.selectables || []).length < rs.min_selections) {
                isVerified = false;
                break;
            }
        }

        if(isVerified) {
            this.setState({
                verifiedSignatures: true,
                percent: percent + 33
            });
        }
    }

    verifyData() {
        const { dispatch, currentActionRequirements, currentActionRequirementSelections } = this.props;
        const { validPhoneNumbers, validSocial } = this.state;
        
        //actually verify data here
        var isVerified = true;
        for(var i = 0; i < currentActionRequirementSelections.length; i++) {
            var rs = currentActionRequirementSelections[i];

            if(isUserInfo(rs.type) && !rs.selection) {
                isVerified = false;
                break;
            }

            if(rs.type === 6 && rs.sub_type === 1 && !rs.selection) {
                isVerified = false;
                break;
            }

            if(rs.type === 6 && rs.sub_type === 2 && (rs.selectables || []).length < rs.min_selections) {
                isVerified = false;
                break;
            }
        }
        
        if(isVerified && validPhoneNumbers && validSocial) {
            this.setState({
                verified: true,
                percent: 33
            });

            var action_id = currentActionRequirements.action.action_id;
            var content_id = currentActionRequirements.action.content_id;
            dispatch(activeActions.previewAction({ action_id, content_id, selections: currentActionRequirementSelections }));
        } else {
            this.setState({
                verified: false
            });
            
            dispatch(alertActions.error("Please enter all information, make any selections required and answer all questions."));
        }

        window.scrollTo(0, 0);
    }
    
    toggleShow() {
        const { showSignatures } = this.state;

        this.setState({
            showSignatures: !showSignatures
        });
    }

    getDefaultValueForSelection(key) {
        const { currentActionRequirementSelections } = this.props;

        for(var i = 0; i < currentActionRequirementSelections.length; i++) {
            if(key === currentActionRequirementSelections[i].key) {
                return currentActionRequirementSelections[i].selection;
            }
        }
    }

    getDefaultSelectables(key) {
        const { currentActionRequirementSelections } = this.props;

        for(var i = 0; i < currentActionRequirementSelections.length; i++) {
            if(key === currentActionRequirementSelections[i].key && currentActionRequirementSelections[i].selectables && currentActionRequirementSelections[i].selectables.length > 0) {
                return currentActionRequirementSelections[i].selectables.reduce(function(result, selectable) {
                    result.push({id: selectable});
                    return result;
                }, []);
            }
        }

        return null;
    }

    getInputForKey(r) {
        const { user } = this.props;

        if(r.key === 'State') {
            return <RegionDropdown
                        className={styles.crselect}
                        blankOptionLabel='Select a country'
                        country={user.country || 'United States'}
                        defaultOptionLabel='Select a state'
                        name='State'
                        onChange={this.handleSelectChange}
                        value={this.getDefaultValueForSelection(r.key) || r.secondary_display || ''} />;
        } else if (r.key === 'Country') {
            return <CountryDropdown
                        className={styles.crselect}
                        defaultOptionLabel='Select a country'
                        name='Country'
                        onChange={this.handleSelectChange}
                        value={this.getDefaultValueForSelection(r.key) || r.secondary_display || ''} />
        } else if (r.key === 'BirthDate') {
            return <DateTimePicker
                        time={false}
                        name='BirthDate'
                        max={new Date()} 
                        defaultValue={isValidDate(this.getDefaultValueForSelection(r.key)) || isValidDate(r.secondary_display) ? new Date(this.getDefaultValueForSelection(r.key) || r.secondary_display) : undefined} 
                        onChange={this.handleBirthDateChange}
                        placeholder={'Enter Your DOB (mm/dd/yyyy)'}/>
        } else if(r.key === 'PhoneNumber' || r.key === 'EmergencyContactNumber') {
            var p = this.getDefaultValueForSelection(r.key) || r.secondary_display;
            
            return  <FormGroup>
                        <Input 
                            className={styles.verifyinput}
                            id={r.key} readOnly={r.read_only}
                            rtype={r.type} rsubtype={r.sub_type}
                            defaultValue={p}
                            onChange={(e) => {e.target.value = formatPhoneNumber(this.getDefaultValueForSelection(e.target.getAttribute('id')), e.target.value); this.handleTextChange(e);}}
                            invalid={!validatePhoneNumber(p)}/>
                            <FormFeedback invalid="true">
                                {validPhoneNumberMessage()}
                            </FormFeedback>
                    </FormGroup>;
        } else if (r.type === 6 && r.sub_type === 1) {
            return <Input
                        type='textarea'
                        className={styles.verifyinput}
                        id={r.key}
                        readOnly={r.read_only}
                        rtype={r.type}
                        defaultValue={this.getDefaultValueForSelection(r.key) || r.secondary_display}
                        onChange={this.handleTextChange}/>;
        } else if (r.type === 6 && r.sub_type === 2) {
            var selectables = r.selectables.reduce((result, s) => {
                result.push({id: s.selectable_id, label: s.selectable_text});
                return result;
            }, []);

            var defaultSelectables = this.getDefaultSelectables(r.key) || (r.selectables_answers || []).reduce(function(result, sa) {
                result.push({id: sa.selectable_id});
                return result;
            }, []);

            return  <div>
                        <div className={styles.mscomp} >
                        <MultiSelect
                            items={selectables} 
                            selectedItems={defaultSelectables} 
                            showSearch={false} 
                            showSelectAll={false} 
                            showSelectedItems={false}
                            minSelectedItems={r.min_selections}
                            maxSelectedItems={r.max_selections}
                            itemHeight={75}
                            onChange={(s) => this.handleSelectableCheck(s, r.key)}/>
                        </div>
                        {r.requires_additional &&
                            [   <Label key={`${r.key}/ral`}>{r.additional_display}</Label>,
                                <Input key={`${r.key}/rai`}
                                    type='textarea'
                                    className={styles.verifyinput}
                                    id={r.key}
                                    readOnly={r.read_only}
                                    rtype={r.type}
                                    defaultValue={this.getDefaultValueForSelection(r.key) || r.secondary_display}
                                    onChange={this.handleTextChange}/>]
                        }
                    </div>;
        } else if((r.type === 7 || r.type === 21) && r.sub_type === 1) {
            return <DateTimePicker
                        time={false}
                        id={r.key}
                        defaultValue={isValidDate(this.getDefaultValueForSelection(r.key)) || isValidDate(r.secondary_display) ? new Date(this.getDefaultValueForSelection(r.key) || r.secondary_display) : undefined} 
                        onChange={(date) => this.handleDateChange(r.key, date)}
                        placeholder={'Enter Date (mm/dd/yyyy)'}/>;
        } else if((r.type === 7 || r.type === 21) && r.sub_type === 3) {
            return <Input
                        autoCapitalize={'words'}
                        className={styles.verifyinput}
                        id={r.key} readOnly={r.read_only}
                        rtype={r.type} rsubtype={r.sub_type}
                        defaultValue={this.getDefaultValueForSelection(r.key) || r.secondary_display}
                        onChange={this.handleTextChange}/>;
        } else if((r.type === 7 || r.type === 21) && r.sub_type === 6) {
            var s = this.getDefaultValueForSelection(r.key) || r.secondary_display;

            return  <FormGroup>
                        <Input 
                            className={styles.verifyinput}
                            id={r.key} readOnly={r.read_only}
                            rtype={r.type} rsubtype={r.sub_type}
                            defaultValue={s}
                            onChange={(e) => {e.target.value = formatSocial(this.getDefaultValueForSelection(r.key), e.target.value); this.handleTextChange(e);}}
                            invalid={!validateSocial(s)}/>
                            <FormFeedback invalid="true">
                                {validSocialMessage()}
                            </FormFeedback>
                    </FormGroup>;
        } else if((r.type === 7 || r.type === 21) && r.sub_type === 7) {
            var pn = this.getDefaultValueForSelection(r.key) || r.secondary_display;
            
            return  <FormGroup>
                        <Input 
                            className={styles.verifyinput}
                            id={r.key} readOnly={r.read_only}
                            rtype={r.type} rsubtype={r.sub_type}
                            defaultValue={pn}
                            onChange={(e) => {e.target.value = formatPhoneNumber(this.getDefaultValueForSelection(r.key), e.target.value); this.handleTextChange(e);}}
                            invalid={!validatePhoneNumber(pn)}/>
                            <FormFeedback invalid="true">
                                {validPhoneNumberMessage()}
                            </FormFeedback>
                    </FormGroup>;
        } else {
            return <Input
                        autoCapitalize={'words'}
                        className={styles.verifyinput}
                        id={r.key} readOnly={r.read_only}
                        rtype={r.type}
                        defaultValue={this.getDefaultValueForSelection(r.key) || r.secondary_display}
                        onChange={this.handleTextChange}/>;
        }   
    }

    /* REACT HOOKS BELOW */

    componentDidMount() {
        window.scrollTo(0, 0);
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    render() {
        const { windowWidth } = this.state;

        if(windowWidth < 600) {
            return this.renderForPortrait();
        } else {
            return this.renderForLandscape();
        }
    }

    renderForPortrait() {
        const { currentActionRequirements, currentActionPreviewResult } = this.props;
        const { confirmed, percent, signatures, showSignatures, verified, verifiedSignatures } = this.state;

        if(!currentActionRequirements) {
            return null;
        }

        return (
            <div className={styles.container}>
                <Prompt when={!confirmed} message={verified ? 'Are you sure you want to leave? All answers and information will be lost. To alter your answers click the highlighted step under "Verify". Pressing "Ok" will lose all your updated answers.' : 'Are you sure you want to leave? All answers and information entered will be lost. Pressing "Ok" will lose all your updated answers.'}/>        
                <div className={styles.headingcontainer}>
                    <h4 className={styles.heading}>Complete Your To Do</h4>
                </div>
                <div className={styles.infocontainer}>
                    <Container className={styles.progresscontainer}>
                        <div className={styles.barheadercontainer}>
                            <div className={styles.headerlabelcontainer}>
                                <Label className={styles.headerlabel}>
                                    Verify
                                </Label>
                            </div>
                            <div className={styles.headerlabelcontainer}>
                                <Label className={styles.headerlabel}>
                                    Sign
                                </Label>
                            </div>
                            <div className={styles.headerlabelcontainer}>
                                <Label className={styles.headerlabel}>
                                    Confirm 
                                </Label>
                            </div>
                        </div>
                        <ProgressBar percent={percent} hasStepZero={false}>
                            <Step key='1'>
                                {({ index }) => (
                                    <div className={classNames({[styles.indexedStep]: !verified, [styles.accomplishedStep]: verified})} onClick={this.onVerifyClick}>
                                        {index + 1}
                                    </div>
                                )}
                            </Step>
                            <Step key='2'>
                                {({ index }) => (
                                    <div className={classNames({[styles.indexedStep]: !verifiedSignatures, [styles.accomplishedStep]: verifiedSignatures})}>
                                        {index + 1}
                                    </div>
                                )}
                            </Step>
                            <Step key='3'>
                                {({ index }) => (
                                    <div className={classNames({[styles.indexedStep]: !confirmed, [styles.accomplishedStep]: confirmed})}>
                                        {index + 1}
                                    </div>
                                )}
                            </Step>
                        </ProgressBar>
                    </Container>
                </div>
                {!verified &&
                <div className={styles.maincontainer}>
                    <Container className={styles.verifyform}>
                        {currentActionRequirements.requirements.filter(r => {return isUserInfo(r.type)}).length > 0 &&
                        <div>
                            <h4 className={styles.subheading}>Your Information</h4>
                        </div>
                        }
                        {currentActionRequirements.requirements.reduce((result, r) => {
                                if(isUserInfo(r.type)) {
                                    result.push(
                                        <div key={r.key + '/fg'} className={styles.verifysubform}>
                                            <FormGroup className={styles.verifygroup}>
                                                <Label for={r.display}>{r.display}</Label>
                                                {this.getInputForKey(r)}
                                            </FormGroup>
                                        </div>
                                    );
                                }

                                return result;
                            }, [])
                        }
                        {currentActionRequirements.requirements.filter(r => {return isQuestion(r.type)}).length > 0 &&
                        <div>
                            <h4 className={styles.subheading}>Questions</h4>
                        </div>
                        }
                        {currentActionRequirements.requirements.reduce((result, r) => {
                            if (isQuestion(r.type)) {
                                    result.push(
                                        <div key={r.key + '/fg'} className={styles.verifysubform}>
                                            <FormGroup className={styles.verifygroup}>
                                                <Label for={r.display}>{r.display}</Label>
                                                {this.getInputForKey(r)}
                                            </FormGroup>
                                        </div>
                                    );
                                }

                                return result;
                            }, [])
                        }
                        <Button color="success" className={styles.button} onClick={this.verifyData}>Verify Data</Button>
                    </Container>
                </div>
                }
                {verified &&
                <div className={styles.maincontainer}>
                    <div className={styles.signviewcontainer}>
                        {showSignatures &&
                            <Form className={styles.sigform}>
                                {currentActionRequirements.requirements.filter(r => {return isUserSignature(r.type)}).length > 0 &&
                                    <div className={styles.subheadingcontainer}>
                                        <h4 className={styles.subheader}>Signatures</h4>
                                        <Button className={styles.subheaderbutton} onClick={this.toggleShow}>View</Button>
                                    </div>
                                }
                                {currentActionRequirements.requirements.reduce((result, r) => {
                                        if (isUserSignature(r.type)) {
                                            result.push(
                                                <FormGroup key={r.key} className={styles.sigfg}>
                                                    <SignatureModal buttonText={`Sign ${r.display}`} id={r.key} disabled={signatures[r.key]} saveCallback={this.saveSignatureCallback} stripMetaData={false}/>
                                                </FormGroup>
                                            );
                                        }

                                        return result;
                                    }, [])
                                }
                            </Form>
                        }
                        {!showSignatures &&
                            <div className={styles.portraitpdfcontainer}>
                                <div className={styles.subheadingcontainer}>
                                    <h4 className={styles.subheader}>Current Document</h4>
                                    <Button className={styles.subheaderbutton} onClick={this.toggleShow}>Sign</Button>
                                </div>
                                {currentActionPreviewResult ? 
                                    <PdfViewer filePath={currentActionPreviewResult.result}></PdfViewer> :
                                    <div className={styles.loading}>
                                        <img className={styles.loadingimg} src={loading} alt=":("/>
                                    </div>
                                }
                                <ConfirmationModal 
                                    bodyText="By confirming you are completing this step of the document and that all data and signatures are valid." 
                                    buttonText="Complete Document" 
                                    disabled={!verifiedSignatures || confirmed} 
                                    confirmCallback={this.onSubmit}/>
                            </div>
                        }
                    </div>
                </div>
                }
            </div>
        );
    }

    renderForLandscape() {
        const { currentActionRequirements, currentActionPreviewResult } = this.props;
        const { confirmed, percent, signatures, verified, verifiedSignatures } = this.state;

        if(!currentActionRequirements) {
            return null;
        }
        
        return (
            <div className={styles.container}>
                <Prompt when={!confirmed} message={verified ? 'Are you sure you want to leave? All answers and information will be lost. To alter your answers click the highlighted step under "Verify". Pressing "Ok" will lose all your updated answers.' : 'Are you sure you want to leave? All answers and information entered will be lost. Pressing "Ok" will lose all your updated answers.'}/>
                <div className={styles.headingcontainer}>
                    <h4 className={styles.heading}>Complete Your To Do</h4>
                </div>
                <div className={styles.infocontainer}>
                    <Container className={styles.progresscontainer}>
                        <div className={styles.barheadercontainer}>
                            <div className={styles.headerlabelcontainer}>
                                <Label className={styles.headerlabel}>
                                    Verify
                                </Label>
                            </div>
                            <div className={styles.headerlabelcontainer}>
                                <Label className={styles.headerlabel}>
                                    Sign
                                </Label>
                            </div>
                            <div className={styles.headerlabelcontainer}>
                                <Label className={styles.headerlabel}>
                                    Confirm 
                                </Label>
                            </div>
                        </div>
                        <ProgressBar percent={percent} hasStepZero={false}>
                            <Step key='1'>
                                {({ index }) => (
                                    <div className={classNames({[styles.indexedStep]: !verified, [styles.accomplishedStep]: verified})} onClick={this.onVerifyClick}>
                                        {index + 1}
                                    </div>
                                )}
                            </Step>
                            <Step key='2'>
                                {({ index }) => (
                                    <div className={classNames({[styles.indexedStep]: !verifiedSignatures, [styles.accomplishedStep]: verifiedSignatures})}>
                                        {index + 1}
                                    </div>
                                )}
                            </Step>
                            <Step key='3'>
                                {({ index }) => (
                                    <div className={classNames({[styles.indexedStep]: !confirmed, [styles.accomplishedStep]: confirmed})}>
                                        {index + 1}
                                    </div>
                                )}
                            </Step>
                        </ProgressBar>
                    </Container>
                </div>
                {!verified &&
                <div className={styles.maincontainer}>
                    <Container className={styles.verifyform}>
                        {currentActionRequirements.requirements.filter(r => {return isUserInfo(r.type)}).length > 0 &&
                        <div>
                            <h4 className={styles.subheading}>Your Information</h4>
                        </div>
                        }
                        {currentActionRequirements.requirements.reduce((result, r) => {
                                if(isUserInfo(r.type)) {
                                    result.push(
                                        <div key={r.key + '/fg'} className={styles.verifysubform}>
                                            <FormGroup className={styles.verifygroup}>
                                                <Label for={r.display}>{r.display}</Label>
                                                {this.getInputForKey(r)}
                                            </FormGroup>
                                        </div>
                                    );
                                }

                                return result;
                            }, [])
                        }
                        {currentActionRequirements.requirements.filter(r => {return isQuestion(r.type)}).length > 0 &&
                        <div>
                            <h4 className={styles.subheading}>Questions</h4>
                        </div>
                        }
                        {currentActionRequirements.requirements.reduce((result, r) => {
                            if (isQuestion(r.type)) {
                                    result.push(
                                        <div key={r.key + '/fg'} className={styles.verifysubform}>
                                            <FormGroup className={styles.verifygroup}>
                                                <Label for={r.display}>{r.display}</Label>
                                                {this.getInputForKey(r)}
                                            </FormGroup>
                                        </div>
                                    );
                                }

                                return result;
                            }, [])
                        }
                        <Button color="success" className={styles.button} onClick={this.verifyData}>Verify Data</Button>
                    </Container>
                </div>
                }
                {verified &&
                <div className={styles.maincontainer}>
                    <div className={styles.signviewcontainer}>
                        <Form className={styles.sigform}>
                            {currentActionRequirements.requirements.filter(r => {return isUserSignature(r.type)}).length > 0 &&
                                <h4 className={styles.subheading}>Signatures</h4>
                            }
                            {currentActionRequirements.requirements.reduce((result, r) => {
                                    if (isUserSignature(r.type)) {
                                        result.push(
                                            <FormGroup key={r.key} className={styles.sigfg}>
                                                <SignatureModal buttonText={`Sign ${r.display}`} id={r.key} disabled={signatures[r.key]} saveCallback={this.saveSignatureCallback} stripMetaData={false}/>
                                            </FormGroup>
                                        );
                                    }

                                    return result;
                                }, [])
                            }
                        </Form>
                        <div className={styles.pdfcontainer}>
                            <h4 className={styles.subheading}>Current Document</h4>                  
                            {currentActionPreviewResult ? 
                                <PdfViewer filePath={currentActionPreviewResult.result}></PdfViewer> :
                                <div className={styles.loading}>
                                    <img className={styles.loadingimg} src={loading} alt=":("/>
                                </div>
                            }
                            <ConfirmationModal 
                                bodyText="By confirming you are completing this step of the document and that all data and signatures are valid." 
                                buttonText="Complete Document" 
                                disabled={!verifiedSignatures || confirmed} 
                                confirmCallback={this.onSubmit}/>
                        </div>
                    </div>
                </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { active, authentication } = state;
    const { currentActionRequirements, currentActionRequirementSelections, currentActionPreviewResult, currentActionCompletionResult } = active;
    const { autoLoggedOut, user } = authentication;

    return {
        autoLoggedOut,
        currentActionCompletionResult,
        currentActionPreviewResult,
        currentActionRequirements,
        currentActionRequirementSelections,
        user
    };
}

const connectedCompleteDocumentAction = connect(mapStateToProps)(CompleteDocumentAction);
export { connectedCompleteDocumentAction as CompleteDocumentAction };