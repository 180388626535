import { relationshipConstants } from '_constants';
import { relationshipService } from '_services';
import { alertActions } from './';

export const relationshipActions = {
    getAllRelationships,
};

function getAllRelationships(filter){
    return dispatch => {
        dispatch(request({ filter }));

        relationshipService.getAllByFilter(filter)
            .then(
                relationships => {
                    dispatch(success(relationships));
                    dispatch(alertActions.clear());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(filter) { return { type: relationshipConstants.RELATIONSHIPS_GET_REQUEST, filter } }
    function success(relationships) { return { type: relationshipConstants.RELATIONSHIPS_GET_SUCCESS, relationships } }
    function failure(error) { return { type: relationshipConstants.RELATIONSHIPS_GET_FAILURE, error } }
}