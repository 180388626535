import { authHeader, config, currentCompany, handleResponse, handleError } from '_helpers';

export const templateService = {
    getActionTemplates,
    getActionTypes,
    getContentIds,
    getRoles,
    getTaskTemplates,
    getTaskTemplateRequirements
};

function getActionTemplates(filter) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/actions/templates?filter=${filter}`  , requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });
}

function getActionTypes() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    
    return fetch(config.capiUrl + `/${currentCompany().company_id}/actions/types` , requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });
}

function getContentIds(actionType) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    
    return fetch(config.capiUrl + `/${currentCompany().company_id}/actions/contents/${actionType}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });
}

function getRoles() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    
    return fetch(config.capiUrl + `/${currentCompany().company_id}/roles/` , requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });
}

function getTaskTemplates(filter) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/tasks/templates?filter=${encodeURIComponent(filter)}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });
}

function getTaskTemplateRequirements(taskTemplateId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/tasks/templates/${taskTemplateId}/requirements/v3`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });
}