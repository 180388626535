import React from 'react';
import styles from './TaskTemplateRequirements.module.css';
import loading from '_images/loading2.gif';

import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

import { connect } from 'react-redux';
import { Form, FormGroup, FormFeedback, Label, Input, InputGroup, InputGroupAddon, Button } from 'reactstrap';
import { EmployeeSelector } from '_components/EmployeeSelector';
import { templateActions, assetActions } from '_actions';
import { activeActions, alertActions } from '_actions';
import { SignatureModal } from '_components/SignatureModal';
import { FileUploadModal } from '_components/FileUploadModal';
import { getConditionalRequirements, getSelectionFromType, getSelectionFromKey, formatPhoneNumber, isValidDate, validatePhoneNumber, validPhoneNumberMessage, validateSocial, isSelect, getContentRequirement  } from '_helpers';

class TaskTemplateRequirements extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            taskCustomName: null,
            selectedEmployees: null,
            assetName: null,
            assetId: null,
            managerId: null,
            submitted: false,
            validPhoneNumbers: true,
            validSocial: true,
        }

        this.getDefaultValueForSelection = this.getDefaultValueForSelection.bind(this);
        this.getInputForKey = this.getInputForKey.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleAssetSelectChange = this.handleAssetSelectChange.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.selectEmployeesCallback = this.selectEmployeesCallback.bind(this);
        this.saveSignatureCallback = this.saveSignatureCallback.bind(this);
        this.isRequirementDone = this.isRequirementDone.bind(this);
    }

    handleSelectChange(value, event) { 
        const { dispatch } = this.props;

        var key = event.target.name;
        var cid = event.target.getAttribute("cid");

        dispatch(templateActions.updateTaskTemplateRequirementSelection({ cid, key: key, selection: value }));
        this.forceUpdate();
    }

    handleChange(e) {
        const { dispatch, taskTemplateRequirements } = this.props;
        var index = e.target.selectedIndex;

        var type = e.target.getAttribute("rtype");
        var cId = e.target.getAttribute("cid");
        var oId = e.target.childNodes[index].getAttribute("id");
        var oName = e.target.childNodes[index].getAttribute("name");
        
        //update selection for main select
        var selection = getSelectionFromType(parseInt(type), {option_id: oId, name: oName});
        dispatch(templateActions.updateTaskTemplateRequirementSelection({ cid: cId, key: e.target.id, selection: selection,  type}));

        //handle any child requirements, by setting default selections
        var content = getContentRequirement(taskTemplateRequirements.requirements, cId);
        if(content) {
            for(var i = 0; i < content.content_requirements.length; i++) {
                var cr = content.content_requirements[i];
                if(cr.key === e.target.id) {
                    var cReqs = cr.conditional_requirements[selection];
                    if(cReqs) {
                        for(var j = 0; j < cReqs.length; j++) {
                            var cReq = cReqs[j];

                            if(isSelect(cReq.type)) {
                                dispatch(templateActions.updateTaskTemplateRequirementSelection({ cid: cId, key: cReq.key, selection: cReq.options[0].name, type: cReq.type}));
                            }
                        }
                    }
                }
            }
        }
    }

    handleNameChange(e) {
        this.setState({
            taskCustomName: e.target.value
        });
    }

    handleDateChange(cid, key, date) {
        const { dispatch } = this.props;
        dispatch(templateActions.updateTaskTemplateRequirementSelection({ cid, key, selection: date.toISOString()}));
    }

    handleTextChange(e) {
        const { dispatch } = this.props;

        var key = e.target.getAttribute("id");
        var type = e.target.getAttribute("rtype");
        var subtype = e.target.getAttribute("rsubtype");
        var selection = e.target.value;

        if(key === 'PhoneNumber' || key === 'EmergencyContactNumber'|| (type === '7' && subtype === '7')) {
            this.setState({
                validPhoneNumbers: validatePhoneNumber(selection)
            })
        }

        if(type === '7' && subtype === '6') {
            this.setState({
                validSocial: validateSocial(selection)
            })
        }

        dispatch(templateActions.updateTaskTemplateRequirementSelection({ cid: e.target.getAttribute("cid"), key: e.target.id, selection: e.target.value, type}));
    }

    handleAssetSelectChange(e) {
        var index = e.target.selectedIndex;
        var selectedAssetId = e.target.childNodes[index].getAttribute('aid');

        this.setState({
            assetName: e.target.value,
            assetId: selectedAssetId
        })
    }

    handleUpload(result) {
        const { dispatch } = this.props;
        
        dispatch(templateActions.updateTaskTemplateRequirementSelection({ cid: result.cid, key: result.id, selection: result.fileUpload.file_upload_id, type: result.rtype}));
    }

    handleSubmit() {
        const { dispatch, taskTemplateRequirementSelections, taskTemplateRequirements, currentTaskTemplate, user} = this.props;
        const { assetId, assetName, managerId, selectedEmployees, taskCustomName, validPhoneNumbers, validSocial } = this.state;

        var requirementsLookup = (taskTemplateRequirements.requirements.flatMap(r => r.content_requirements) || []).reduce((d, v) => {d[v.key] = v; return d}, {});

        var valid = true;
        Object.values(taskTemplateRequirementSelections).forEach(vals => {
            var selectionsLookup = (vals || []).reduce((d, v) => {d[v.key] = v; return d}, {});

            vals.forEach(s => {
                if(!s.selection) {
                    valid = false;
                }

                if(isSelect(s.type) && requirementsLookup[s.key] && requirementsLookup[s.key].conditional_requirements) {
                    var requiredConditionals = requirementsLookup[s.key].conditional_requirements[s.selection];
    
                    if(requiredConditionals) {
                        for(var j = 0; j < requiredConditionals.length; j++) {
                            var cReq = requiredConditionals[j];
                            var reqSelection = selectionsLookup[cReq.key];
    
                            if(!reqSelection) {
                                valid = false;
                                break;
                            }
                        }
                    }
                }
            });
        });

        if(currentTaskTemplate.custom_name && !currentTaskTemplate.requires_asset && !taskCustomName) {
            valid = false;
        }

        if(currentTaskTemplate.custom_name && currentTaskTemplate.requires_asset && (!assetName || !assetId)) {
            valid = false;
        }
        
        if(taskTemplateRequirements.requires_manager && !managerId) {
            valid = false;
        }
        
        if(!taskTemplateRequirements.requires_manager && (taskTemplateRequirements.needs_employees || taskTemplateRequirements.task_template.requires_employees) && (!selectedEmployees || selectedEmployees.length <= 0)) {
            valid = false;
        }

        if(valid && validPhoneNumbers && validSocial) {
            //submitted
            this.setState({
                submitted: true
            });

            //dispatch create
            dispatch(activeActions.createTask({
                task_template_id: currentTaskTemplate.task_template_id,
                task_custom_name: currentTaskTemplate.custom_name ? `${currentTaskTemplate.custom_name_prefix} ${currentTaskTemplate.requires_asset ? assetName : taskCustomName}` : null,
                task_asset_id: assetId,
                admin_id: taskTemplateRequirements.requires_manager ? user.user_id : null,
                manager_id: taskTemplateRequirements.requires_manager ? managerId : user.user_id, 
                content_data: taskTemplateRequirementSelections, 
                employees: (taskTemplateRequirements.needs_employees || taskTemplateRequirements.task_template.requires_employees) ? selectedEmployees : null
            }));
        } else {
            //dispatch failure
            dispatch(alertActions.error("Please enter all information, complete all signatures, and select atleast one employee if required"));
        }
    }

    selectEmployeesCallback(employees, roles) {
        const { taskTemplateRequirements } = this.props;

        var selectedEmployees = [];
        if(!taskTemplateRequirements.requires_manager && taskTemplateRequirements.requires_custom_roles) {
            selectedEmployees = Object.keys(employees).map(e => {
                return {
                    employee_id: employees[e].user_id,
                    custom_role: roles[e]
                }
            });
        } else if (taskTemplateRequirements.requires_manager) {
            if(Object.keys(employees).length > 0) {
                this.setState({
                    managerId: Object.keys(employees)[0]
                });
            }
        } else {
            selectedEmployees = Object.keys(employees).map(e => {
                return {
                    employee_id: employees[e].user_id
                }
            });
        }
        
        this.setState({
            selectedEmployees: selectedEmployees
        });
    };

    saveSignatureCallback(callback) {
        const { dispatch } = this.props;
        dispatch(templateActions.updateTaskTemplateRequirementSelection({ cid: callback.cid, key: callback.key, selection: callback.image}));
    }

    isRequirementDone(contentId, contentRequirement, selections) {
        var req = selections[contentId];
        if(req) {
            for(var i = 0; i < req.length; i++) {
                if (contentRequirement.key === req[i].key) {
                    return req[i].selection ? true : false;
                }
            }
        }

        return false;
    }

    getDefaultValueForSelection(cid, key) {
        const { taskTemplateRequirementSelections } = this.props;

        for(var i = 0; i < taskTemplateRequirementSelections[cid].length; i++) {
            if(key === taskTemplateRequirementSelections[cid][i].key) {
                return taskTemplateRequirementSelections[cid][i].selection;
            }
        }
    }

    getInputForKey(r, cr) {
        const { user } = this.props;

        if(cr.key === 'State') {
            return <RegionDropdown
                        className={styles.crselect}
                        blankOptionLabel='Select a country'
                        country={user.country || 'United States'}
                        defaultOptionLabel='Select a state'
                        name='State'
                        cid={r.content_id}
                        rtype={cr.type}
                        onChange={this.handleSelectChange}
                        value={this.getDefaultValueForSelection(r.content_id, cr.key) || cr.secondary_display || ''} />;
        } else if (cr.key === 'Country') {
            return <CountryDropdown
                        className={styles.crselect}
                        defaultOptionLabel='Select a country'
                        name='Country'
                        cid={r.content_id}
                        rtype={cr.type}
                        onChange={this.handleSelectChange}
                        value={this.getDefaultValueForSelection(r.content_id, cr.key) || cr.secondary_display || ''} />
        } else if (cr.key === 'BirthDate') {
            return <DateTimePicker
                        time={false}
                        name='BirthDate'
                        max={new Date()} 
                        defaultValue={isValidDate(this.getDefaultValueForSelection(r.content_id, cr.key)) || isValidDate(cr.secondary_display) ? new Date(this.getDefaultValueForSelection(r.content_id, cr.key) || cr.secondary_display) : undefined} 
                        cid={r.content_id}
                        rtype={cr.type}
                        onChange={this.handleDateChange}
                        placeholder={'Enter Your DOB (mm/dd/yyyy)'}/>
        } else if(cr.key === 'PhoneNumber' || cr.key === 'EmergencyContactNumber') {
            var p = this.getDefaultValueForSelection(r.content_id, cr.key) || r.secondary_display;
            
            return  <FormGroup>
                        <Input 
                            className={styles.verifyinput}
                            id={cr.key} readOnly={cr.read_only}
                            rtype={cr.type} rsubtype={cr.sub_type}
                            defaultValue={p}
                            cid={r.content_id}
                            onChange={(e) => {e.target.value = formatPhoneNumber(this.getDefaultValueForSelection(r.content_id, cr.key), e.target.value); this.handleTextChange(e);}}
                            invalid={!validatePhoneNumber(p)}/>
                            <FormFeedback invalid="true">
                                {validPhoneNumberMessage()}
                            </FormFeedback>
                    </FormGroup>;
        } else {
            return <Input
                        autoCapitalize={'words'}
                        className={styles.verifyinput}
                        id={cr.key} readOnly={cr.read_only}
                        cid={r.content_id}
                        rtype={cr.type}
                        defaultValue={this.getDefaultValueForSelection(r.content_id, cr.key) || r.secondary_display}
                        onChange={this.handleTextChange}/>;
        }  
    }

    /* REACT HOOKS BELOW */

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(assetActions.getAssets('active'));
    }

    render() {
        const { assets, taskTemplateRequirements, taskTemplateRequirementSelections } = this.props;
        const { submitted } = this.state;

        if(!taskTemplateRequirements) {
            return null;
        }

        return (
        <div className={styles.container}>
            {taskTemplateRequirements.task_template.custom_name && !taskTemplateRequirements.task_template.requires_asset &&
                <div className={styles.subcontainer}>
                    <Form className={styles.form}>
                        <Label className={styles.subheading}>Complete Your Task Name</Label>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">{taskTemplateRequirements.task_template.custom_name_prefix}</InputGroupAddon>
                            <Input onChange={this.handleNameChange}/>
                        </InputGroup>
                    </Form>
                </div>
            }
            {taskTemplateRequirements.task_template.custom_name && taskTemplateRequirements.task_template.requires_asset &&
                <div className={styles.subcontainer}>
                    <Form className={styles.form}>
                        <Label className={styles.subheading}>Complete Your Task Name</Label>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">{taskTemplateRequirements.task_template.custom_name_prefix}</InputGroupAddon>
                            <Input type='select' defaultValue={"Select a asset..."} onChange={this.handleAssetSelectChange}>
                                <option key={'select'} disabled readOnly value={"Select a asset..."}>Select a asset...</option>
                                {assets.filter(a => a.type === taskTemplateRequirements.task_template.required_asset_type).map(a => {
                                    return <option key={a.asset_id} aid={a.asset_id} value={a.name}>{a.name}</option>
                                })
                                }
                            </Input>
                        </InputGroup>
                    </Form>
                </div>
            }
            <div className={styles.subcontainer}>
                {
                taskTemplateRequirements.requirements.map(r => {
                    if(r.content_requirements.length === 0) {
                        return null;
                    }

                    return  <Form className={styles.form} key={r.content_id}>
                                <Label className={styles.subheading}>{r.content_name}</Label>
                                {
                                    r.content_requirements.map(cr => {
                                        return  <div key={r.content_id + '/' + cr.key}>
                                                    {(cr.type === 2 || cr.type === 24 || cr.type === 29) &&
                                                        <div>
                                                            <FormGroup>
                                                                <Label for={cr.display}>Select {cr.display}</Label>
                                                                <Input type="select" id={cr.key} cid={r.content_id} rtype={cr.type} onChange={this.handleChange}>
                                                                {
                                                                    cr.options.map(o => {
                                                                        return <option key={o.option_id} id={o.option_id} name={o.name}>{o.name}</option>
                                                                    })
                                                                }
                                                                </Input>
                                                            </FormGroup>
                                                            {getConditionalRequirements(cr.conditional_requirements, getSelectionFromKey(taskTemplateRequirementSelections[r.content_id], cr.key)) &&
                                                                <div className={styles.conditionalrequirementscontainer}>
                                                                    {
                                                                        getConditionalRequirements(cr.conditional_requirements, getSelectionFromKey(taskTemplateRequirementSelections[r.content_id], cr.key)).map(cReq => {
                                                                            return  <FormGroup key={cReq.key} className={styles.conditionalrequirement}>
                                                                                        <Label for={cReq.display}>{cReq.display}</Label>
                                                                                        {(cReq.type === 24 || cReq.type === 29) &&
                                                                                            <Input type="select" id={cReq.key} cid={r.content_id} rtype={cReq.type} subtype={cReq.sub_type} onChange={this.handleChange}>
                                                                                            {
                                                                                                cReq.options.map(o => {
                                                                                                    return <option key={o.option_id} id={o.selectable_id} name={o.name}>{o.name}</option>
                                                                                                })
                                                                                            }</Input>
                                                                                        }
                                                                                        {(cReq.type === 32 || cReq.type === 33) &&
                                                                                            <FileUploadModal buttonColor={'secondary'} id={cReq.key} cid={r.content_id} rtype={cReq.type} subtype={cReq.sub_type} upid={cReq.value} saveCallback={this.handleUpload}/>
                                                                                        }
                                                                                        {(cReq.type !== 32 && cReq.type !== 33 && cReq.type !== 24 && cReq.type !== 29) &&
                                                                                            <Input type="text" id={cReq.key} cid={r.content_id} rtype={cReq.type} subtype={cReq.sub_type} onChange={this.handleTextChange}/>
                                                                                        }
                                                                                    </FormGroup>
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                    {(cr.type === 5 || cr.type === 25) &&
                                                        <FormGroup>
                                                            <Label for={cr.display}>{cr.display}</Label>
                                                            {this.getInputForKey(r, cr)}
                                                        </FormGroup>
                                                    }
                                                    {(cr.type === 8 || cr.type === 26) && cr.manual_type === 1 &&
                                                        <FormGroup>
                                                            <Label for={cr.display}>{cr.display}</Label>
                                                            <DateTimePicker
                                                                time={false}
                                                                id={cr.key} cid={r.content_id}
                                                                defaultValue={null}
                                                                onChange={(date) => this.handleDateChange(r.content_id, cr.key, date)}
                                                                rtype={cr.type}
                                                                placeholder={'Enter Date (mm/dd/yyyy)'}/>
                                                        </FormGroup>
                                                    }
                                                    {(cr.type === 8 || cr.type === 26) && cr.manual_type === 3 &&
                                                        <FormGroup>
                                                            <Label for={cr.display}>{cr.display}</Label>
                                                            <Input type="text" id={cr.key} cid={r.content_id} rtype={cr.type} onChange={this.handleTextChange}></Input>
                                                        </FormGroup>
                                                    }
                                                    {(cr.type === 8 || cr.type === 26) && (cr.manual_type !== 1 && cr.manual_type !== 3) &&
                                                        <FormGroup>
                                                            <Label for={cr.display}>{cr.display}</Label>
                                                            <Input type="text" id={cr.key} cid={r.content_id} rtype={cr.type} onChange={this.handleTextChange}></Input>
                                                        </FormGroup>
                                                    }
                                                    {(cr.type === 10 || cr.type === 13 || cr.type === 27 || cr.type === 28) &&
                                                        <FormGroup>
                                                            <SignatureModal buttonText={`Sign ${cr.display}`} disabled={this.isRequirementDone(r.content_id, cr, taskTemplateRequirementSelections)} id={cr.key} cid={r.content_id} rtype={cr.type} saveCallback={this.saveSignatureCallback} stripMetaData={false}/>
                                                        </FormGroup>
                                                    }
                                                    {(cr.type === 32 || cr.type === 33) &&
                                                        <FileUploadModal buttonColor={'secondary'} id={cr.key} cid={r.content_id} rtype={cr.type} subtype={cr.sub_type} upid={cr.value} saveCallback={this.handleUpload}/>
                                                    }
                                                </div>
                                    })
                                }
                            </Form>           
                })
                }
            </div>
            {(taskTemplateRequirements.needs_employees || taskTemplateRequirements.task_template.requires_employees) &&
                <div className={styles.subcontainer}>
                    <EmployeeSelector forTask={true} adminMode={taskTemplateRequirements.requires_manager} customRoles={taskTemplateRequirements.requires_custom_roles} maxSelections={(taskTemplateRequirements.requires_manager ? 1 : 0)} 
                                      selectedCallback={this.selectEmployeesCallback}/>
                </div>
            }
            <div className={styles.subcontainer}>
                <Button className={styles.button} color="success" disabled={submitted} onClick={this.handleSubmit}>Start Task</Button>
                {submitted &&
                <img className={styles.loading} src={loading} alt=':('/>
                }
            </div>
        </div>
        );
    }
}

function mapStateToProps(state) {
    const { template, authentication, asset } = state;
    const { currentTaskTemplate, taskTemplateRequirements, taskTemplateRequirementSelections } = template;
    const { user } = authentication;
    const { assets } = asset;

    return {
        assets,
        user,
        currentTaskTemplate,
        taskTemplateRequirements,
        taskTemplateRequirementSelections
    };
}

const connectedTaskTemplateRequirements = connect(mapStateToProps)(TaskTemplateRequirements);
export { connectedTaskTemplateRequirements as TaskTemplateRequirements };