import React from 'react';
import styles from './TaskSummaryTable.module.css';

import { connect } from 'react-redux';
import { Label } from 'reactstrap';
import { ProgressBar, Step } from "react-step-progress-bar";
import { DocumentTable } from '_components/DocumentTable';
import { formatDateTime, currentUser } from '_helpers';
import { MdCancel, MdAddBox } from 'react-icons/md';
import { ConfirmationModal } from '_components/ConfirmationModal';
import { activeActions, documentActions } from '_actions';
import { SelectEmployeeModal } from '_components/SelectEmployeeModal';

class TaskSummaryTable extends React.Component {
    constructor(props) {
        super(props);

        this.handleMarkIncomplete = this.handleMarkIncomplete.bind(this);
        this.handleAddEmployee = this.handleAddEmployee.bind(this);
    }

    handleMarkIncomplete(result) {
        const { dispatch, currentTask } = this.props;

        dispatch(activeActions.markEmployeeIncomplete({ taskId: currentTask.task_id, employeeId: result.data.id }));
    }

    handleAddEmployee(result) {
        const { dispatch, currentTask, taskRequiresCustomRole } = this.props;

        if(taskRequiresCustomRole) {
            const [employeeId, role] = Object.entries(result.employees)[0];
            dispatch(activeActions.addEmployeeToTask({ taskId: currentTask.task_id, employeeId: employeeId, employeeRole: role}));
        } else {
            var employeeId = Object.keys(result.employees)[0];
            dispatch(activeActions.addEmployeeToTask({ taskId: currentTask.task_id, employeeId: employeeId, employeeRole: '' }));
        }
    }

    componentDidMount() {
        const { dispatch, currentTask } = this.props;

        dispatch(activeActions.getTaskRequireCustomRole(currentTask.task_id));
        dispatch(documentActions.getAllForTask(currentTask.task_id));
    }

    render() {
        const { currentTask, documents, employeeActionSummaries, managerActionSummaries, user, taskRequiresCustomRole } = this.props;

        if(!currentTask) {
            return null;
        }

        var managerStatuses = {};
        var managerCompleteCount = {};
        managerActionSummaries.forEach(sum => {
            sum.actions.forEach(act => {
                if(act.user_id === currentTask.manager.user.user_id) {
                    managerStatuses[act.user_id] ? managerStatuses[act.user_id].push(act)
                        : managerStatuses[act.user_id] = [act.user_name, act];

                    managerCompleteCount[act.user_id] = managerCompleteCount[act.user_id] ? managerCompleteCount[act.user_id] : 0;
                    if(act.status === 3) {
                        managerCompleteCount[act.user_id] = managerCompleteCount[act.user_id] + 1;
                    }
                }
            });
        });
        
        var employeeStatuses = {};
        var employeeCompleteCount = {};
        var employeeIncomplete = {};
        employeeActionSummaries.forEach(sum => {
            sum.actions.forEach(act => {
                if(act.user_id !== currentTask.manager.user.user_id) {
                    employeeStatuses[act.user_id] ? employeeStatuses[act.user_id].push(act)
                    : employeeStatuses[act.user_id] = [act.user_name, act];
                    
                    employeeCompleteCount[act.user_id] = employeeCompleteCount[act.user_id] ? employeeCompleteCount[act.user_id] : 0;
                    if(act.status === 3) {
                        employeeCompleteCount[act.user_id] = employeeCompleteCount[act.user_id] + 1;
                    } else if(act.status === 5) {
                        employeeIncomplete[act.user_id] = true;
                    }
                }
            });
        });

        return (
            <div className={styles.container}>
                <div className={styles.datecontainer}>
                    <h5 className={styles.date}>Started: { formatDateTime(currentTask.date_started) }</h5>
                    {currentTask.status === 3 &&
                        <h5 className={styles.date}>Completed: { formatDateTime(currentTask.date_completed) }</h5>
                    }
                    {currentTask.status === 4 &&
                        <h5 className={styles.date}>Nullified: { formatDateTime(currentTask.date_completed) }</h5>
                    }
                </div>
                {Object.entries(managerStatuses).length > 0 &&
                <div className={styles.managertablecontainer}>
                    <div className={styles.headingcontainer}>
                        <div>
                            <Label className={styles.heading}>Manager Summary</Label>
                        </div>
                    </div>
                    <div className={styles.horcontainerheader}>
                        <div className={styles.vertcontainer}>
                            <Label className={styles.headerlabel}>
                                Manager
                            </Label>
                        </div>
                        <div className={styles.vertflexedcontainer}>
                            <div className={styles.tableheader}>
                                {
                                    managerActionSummaries.map(actionSummary => {
                                        return  <div className={styles.labelcontainer} key={actionSummary.template.action_template_id}>
                                                    <Label className={styles.headerlabel}>
                                                        { actionSummary.template.name }
                                                    </Label>
                                                </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>       
                    {
                        Object.entries(managerStatuses).map(manStat => {
                            return  <div className={styles.horcontainer} key={manStat[0]} eid={manStat[0]}>
                                        <div className={styles.vertcontainer}>
                                            {manStat[1][0]}
                                        </div>
                                        <div className={styles.vertflexedcontainer}>
                                            <ProgressBar percent={(managerCompleteCount[currentTask.manager.user.user_id] / managerActionSummaries.length) * 100} hasStepZero={false}>
                                            {
                                                manStat[1].reduce((result, data) => {
                                                    if(typeof data === 'string') {
                                                        //do nothing
                                                    } else if (data.status === 3) {
                                                        result.push(
                                                                <Step key={data.action_id}>
                                                                    {({ index }) => (
                                                                        <div aid={data.action_id} id={data.action_id} className={styles.accomplishedStep} onClick={this.handleClick}>
                                                                            {index + 1}
                                                                        </div>
                                                                    )}
                                                                </Step>);
                                                    } else if (data.status === 5) {
                                                        result.push(
                                                                <Step key={data.action_id}>
                                                                    {({ index }) => (
                                                                        <div aid={data.action_id} id={data.action_id} className={styles.incompletedStep} onClick={this.handleClick}>
                                                                            {index + 1}
                                                                        </div>
                                                                    )}
                                                                </Step>);
                                                    } else {
                                                        result.push(
                                                                <Step key={data.action_id}>
                                                                    {({ index }) => (
                                                                        <div aid={data.action_id} id={data.action_id} className={styles.indexedStep}>
                                                                            {index + 1}
                                                                        </div>
                                                                    )}
                                                                </Step>);
                                                    }
                                                    return result;
                                                }, [])
                                            }
                                            </ProgressBar>
                                        </div>
                                    </div>
                        })
                    }
                </div>
                }
                {Object.entries(employeeStatuses).length > 0 &&
                <div className={styles.employeetablecontainer}>
                    <div className={styles.headingcontainer}>
                        <div className={styles.horcontainer}>
                            <Label className={styles.heading}>Employee Summaries</Label>
                            {currentTask.status === 2 && currentTask.manager.user.user_id === user.user_id &&
                                <SelectEmployeeModal clickable={<MdAddBox className={styles.viewa}/>} confirmCallback={this.handleAddEmployee} customRoles={taskRequiresCustomRole}/>
                            }
                        </div>
                    </div>
                    <div className={styles.horcontainerheader}>
                        <div className={styles.vertcontainer}>
                            <Label className={styles.headerlabel}>
                                Employee
                            </Label>
                        </div>
                        <div className={styles.vertflexedcontainer}>
                            <div className={styles.tableheader}>
                                {
                                    employeeActionSummaries.map(actionSummary => {
                                        return  <div className={styles.labelcontainer} key={actionSummary.template.action_template_id}>
                                                    <Label className={styles.headerlabel}>
                                                        { actionSummary.template.name }
                                                    </Label>
                                                </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>       
                    {
                        Object.entries(employeeStatuses).map(empStat => {
                                return  <div className={styles.horcontainer} key={empStat[0]} eid={empStat[0]}>
                                            <div className={styles.vertcontainer}>
                                                <div className={styles.horcontainer}>
                                                    {!employeeIncomplete[empStat[0]] && currentTask.status === 2 && currentTask.manager.user.user_id === currentUser().user_id &&
                                                        <ConfirmationModal bodyText={`By confirming, you will be marking ${empStat[1][0]} as a non-participant for this task.`} clickable={<MdCancel className={styles.viewc}/>} confirmCallback={this.handleMarkIncomplete} data={{id:empStat[0]}}/>
                                                    }
                                                    <Label className={styles.lname}>{empStat[1][0]}</Label>
                                                </div>
                                            </div>
                                            <div className={styles.vertflexedcontainer}>
                                                <ProgressBar percent={(employeeCompleteCount[empStat[0]] / employeeActionSummaries.length) * 100} hasStepZero={false} unfilledBackground={employeeIncomplete[empStat[0]] ? "#dc3545": ""}>
                                                {
                                                    empStat[1].reduce((result, data) => {
                                                        if(typeof data === 'string') {
                                                            //do nothing
                                                        } else if (data.status === 3) {
                                                            result.push(
                                                                    <Step key={data.action_id}>
                                                                        {({ index }) => (
                                                                            <div aid={data.action_id} id={data.action_id} className={styles.accomplishedStep} onClick={this.handleClick}>
                                                                                {index + 1}
                                                                            </div>
                                                                        )}
                                                                    </Step>)
                                                        } else if (data.status === 5) {
                                                            result.push(
                                                                    <Step key={data.action_id}>
                                                                        {({ index }) => (
                                                                            <div aid={data.action_id} id={data.action_id} className={styles.incompletedStep} onClick={this.handleClick}>
                                                                                {index + 1}
                                                                            </div>
                                                                        )}
                                                                    </Step>);
                                                        } else {
                                                            result.push(
                                                                    <Step key={data.action_id}>
                                                                        {({ index }) => (
                                                                            <div aid={data.action_id} id={data.action_id} className={styles.indexedStep}>
                                                                                {index + 1}
                                                                            </div>
                                                                        )}
                                                                    </Step>)
                                                        }
                                                        return result;
                                                    }, [])
                                                }
                                                </ProgressBar>
                                            </div>
                                        </div>
                        })
                    }
                </div>
                }
                {documents && documents.length > 0 &&
                <div className={styles.documentcontainer}>
                    <div className={styles.headingcontainer}>
                        <div>
                            <Label className={styles.heading}>Task Documents</Label>
                        </div>
                    </div>
                    <DocumentTable taskDocuments={true}/>
                </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { active, authentication, document } = state;
    const { currentTask, employeeActionSummaries, managerActionSummaries, taskRequiresCustomRole } = active;
    const { user } = authentication;
    const { documents } = document;

    return {
        currentTask,
        documents,
        employeeActionSummaries,
        managerActionSummaries,
        taskRequiresCustomRole,
        user
    };
}

const connectedTaskSummaryTable = connect(mapStateToProps)(TaskSummaryTable);
export { connectedTaskSummaryTable as TaskSummaryTable };