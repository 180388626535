import React from 'react';
import styles from './CompleteAssetDetailsAction.module.css';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import MultiSelect from "@kenshooui/react-multi-select";

import { connect } from 'react-redux';
import { activeActions, alertActions } from '_actions';
import { ConfirmationModal } from '_components/ConfirmationModal';
import { formatPhoneNumber, formatDate, formatSocial, isValidDate, isPartOfTable, validatePhoneNumber, validPhoneNumberMessage, validateSocial, validSocialMessage } from '_helpers';
import { FormFeedback, FormGroup, Input, Label, Table } from 'reactstrap';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

class CompleteAssetDetailsAction extends React.Component {
    constructor(props) {
        super(props);

        this.complete = this.complete.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleBirthDateChange = this.handleBirthDateChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSelectableCheck = this.handleSelectableCheck.bind(this);
        this.getInputForKey = this.getInputForKey.bind(this);
        this.getDefaultValueForSelection = this.getDefaultValueForSelection.bind(this);
    }

    complete() {
        const { dispatch, currentActionRequirements, currentActionRequirementSelections } = this.props;

        //actually verify data here
        var isVerified = true;
        for(var i = 0; i < currentActionRequirementSelections.length; i++) {
            var rs = currentActionRequirementSelections[i];
            
            if(rs.type !== 6 && (rs.selection === undefined || rs.selection === null)) {
                isVerified = false;
                break;
            }

            if(rs.type === 6 && rs.sub_type === 1 && !rs.selection) {
                isVerified = false;
                break;
            }

            if(rs.type === 6 && rs.sub_type === 2 && (rs.selectables || []).length < rs.min_selections) {
                isVerified = false;
                break;
            }
        }

        if(isVerified) {
            var action_id = currentActionRequirements.action.action_id;
            var content_id = currentActionRequirements.action.content_id;

            dispatch(activeActions.completeAction({ action_id, content_id, selections: currentActionRequirementSelections }));
        } else {
            dispatch(alertActions.error("Please fill in all required information"));
        }
    }

    handleTextChange(e) {
        const { dispatch } = this.props;

        var key = e.target.getAttribute("id");
        var selection = e.target.value;

        dispatch(activeActions.updateActionRequirementSelection({ key: key, selection: selection }));
    }
    
    handleSelectChange(value, event) { 
        const { dispatch } = this.props;

        var key = event.target.name;

        dispatch(activeActions.updateActionRequirementSelection({ key: key, selection: value }));
        this.forceUpdate();
    }

    handleBirthDateChange(updatedDate) {
        const { dispatch } = this.props;

        dispatch(activeActions.updateActionRequirementSelection({ key: 'BirthDate', selection: updatedDate.toISOString() }));
        this.forceUpdate();
    }

    handleDateChange(key, updatedDate) {
        const { dispatch } = this.props;

        dispatch(activeActions.updateActionRequirementSelection({ key, selection: formatDate(updatedDate.toISOString()) }));
        this.forceUpdate();
    }

    handleSelectableCheck(selectedItems, qid) {
        const { dispatch } = this.props;
        
        var selectables = selectedItems.map(si => si.id);

        dispatch(activeActions.updateActionRequirementSelectables({key: qid, selectables}));
    }

    getInputForKey(r) {
        const { user } = this.props;

        if(r.key === 'State') {
            return <RegionDropdown
                        className={styles.crselect}
                        blankOptionLabel='Select a country'
                        country={user.country || 'United States'}
                        defaultOptionLabel='Select a state'
                        name='State'
                        onChange={this.handleSelectChange}
                        value={this.getDefaultValueForSelection(r.key) || r.secondary_display || ''} />;
        } else if (r.key === 'Country') {
            return <CountryDropdown
                        className={styles.crselect}
                        defaultOptionLabel='Select a country'
                        name='Country'
                        onChange={this.handleSelectChange}
                        value={this.getDefaultValueForSelection(r.key) || r.secondary_display || ''} />
        } else if (r.key === 'BirthDate') {
            return <DateTimePicker
                        time={false}
                        name='BirthDate'
                        max={new Date()} 
                        defaultValue={isValidDate(this.getDefaultValueForSelection(r.key)) || isValidDate(r.secondary_display) ? new Date(this.getDefaultValueForSelection(r.key) || r.secondary_display) : undefined} 
                        onChange={this.handleBirthDateChange}
                        placeholder={'Enter Your DOB (mm/dd/yyyy)'}/>
        } else if(r.key === 'PhoneNumber' || r.key === 'EmergencyContactNumber') {
            var p = this.getDefaultValueForSelection(r.key) || r.secondary_display;
            
            return  <FormGroup>
                        <Input 
                            className={styles.verifyinput}
                            id={r.key} readOnly={r.read_only}
                            rtype={r.type} rsubtype={r.sub_type}
                            defaultValue={p}
                            onChange={(e) => {e.target.value = formatPhoneNumber(this.getDefaultValueForSelection(e.target.getAttribute('id')), e.target.value); this.handleTextChange(e);}}
                            invalid={!validatePhoneNumber(p)}/>
                            <FormFeedback invalid="true">
                                {validPhoneNumberMessage()}
                            </FormFeedback>
                    </FormGroup>;
        } else if (r.type === 6 && r.sub_type === 1) {
            return <Input
                        type='textarea'
                        className={styles.verifyinput}
                        id={r.key}
                        readOnly={r.read_only}
                        rtype={r.type}
                        defaultValue={this.getDefaultValueForSelection(r.key) || r.secondary_display}
                        onChange={this.handleTextChange}/>;
        } else if (r.type === 6 && r.sub_type === 2) {
            var selectables = r.selectables.reduce((result, s) => {
                result.push({id: s.selectable_id, label: s.selectable_text});
                return result;
            }, []);

            var defaultSelectables = this.getDefaultSelectables(r.key) || (r.selectables_answers || []).reduce(function(result, sa) {
                result.push({id: sa.selectable_id});
                return result;
            }, []);

            return  <div>
                        <div className={styles.mscomp} >
                        <MultiSelect
                            items={selectables} 
                            selectedItems={defaultSelectables} 
                            showSearch={false} 
                            showSelectAll={false} 
                            showSelectedItems={false}
                            minSelectedItems={r.min_selections}
                            maxSelectedItems={r.max_selections}
                            itemHeight={75}
                            onChange={(s) => this.handleSelectableCheck(s, r.key)}/>
                        </div>
                        {r.requires_additional &&
                            [   <Label key={`${r.key}/ral`}>{r.additional_display}</Label>,
                                <Input key={`${r.key}/rai`}
                                    type='textarea'
                                    className={styles.verifyinput}
                                    id={r.key}
                                    readOnly={r.read_only}
                                    rtype={r.type}
                                    defaultValue={this.getDefaultValueForSelection(r.key) || r.secondary_display}
                                    onChange={this.handleTextChange}/>]
                        }
                    </div>;
        } else if((r.type === 7 || r.type === 21) && r.sub_type === 1) {
            return <DateTimePicker
                        time={false}
                        id={r.key}
                        defaultValue={isValidDate(this.getDefaultValueForSelection(r.key)) || isValidDate(r.secondary_display) ? new Date(this.getDefaultValueForSelection(r.key) || r.secondary_display) : undefined} 
                        onChange={(date) => this.handleDateChange(r.key, date)}
                        placeholder={'Enter Date (mm/dd/yyyy)'}/>;
        } else if((r.type === 7 || r.type === 21) && r.sub_type === 3) {
            return <Input
                        autoCapitalize={'words'}
                        className={styles.verifyinput}
                        id={r.key} readOnly={r.read_only}
                        rtype={r.type} rsubtype={r.sub_type}
                        defaultValue={this.getDefaultValueForSelection(r.key) || r.secondary_display}
                        onChange={this.handleTextChange}/>;
        } else if((r.type === 7 || r.type === 21) && r.sub_type === 6) {
            var s = this.getDefaultValueForSelection(r.key) || r.secondary_display;

            return  <FormGroup>
                        <Input 
                            className={styles.verifyinput}
                            id={r.key} readOnly={r.read_only}
                            rtype={r.type} rsubtype={r.sub_type}
                            defaultValue={s}
                            onChange={(e) => {e.target.value = formatSocial(this.getDefaultValueForSelection(r.key), e.target.value); this.handleTextChange(e);}}
                            invalid={!validateSocial(s)}/>
                            <FormFeedback invalid="true">
                                {validSocialMessage()}
                            </FormFeedback>
                    </FormGroup>;
        } else if((r.type === 7 || r.type === 21) && r.sub_type === 7) {
            var pn = this.getDefaultValueForSelection(r.key) || r.secondary_display;
            
            return  <FormGroup>
                        <Input 
                            className={styles.verifyinput}
                            id={r.key} readOnly={r.read_only}
                            rtype={r.type} rsubtype={r.sub_type}
                            defaultValue={pn}
                            onChange={(e) => {e.target.value = formatPhoneNumber(this.getDefaultValueForSelection(r.key), e.target.value); this.handleTextChange(e);}}
                            invalid={!validatePhoneNumber(pn)}/>
                            <FormFeedback invalid="true">
                                {validPhoneNumberMessage()}
                            </FormFeedback>
                    </FormGroup>;
        } else if(r.type === 24) {
            return  <FormGroup>
                        <Input 
                            className={styles.verifyinput}
                            id={r.key} 
                            readOnly={r.read_only}
                            name={r.key}
                            type={'select'}
                            rtype={r.type} 
                            rsubtype={r.sub_type}
                            onChange={(e) => this.handleSelectChange(e.target.value, e)}>
                                {
                                    r.selectables.map(s => {
                                        return <option key={s.selectable_id}>{s.selectable_text}</option>
                                    })
                                }
                            </Input>
                    </FormGroup>;
        } else {
            return <Input
                        autoCapitalize={'words'}
                        className={styles.verifyinput}
                        id={r.key} readOnly={r.read_only}
                        rtype={r.type}
                        defaultValue={this.getDefaultValueForSelection(r.key) || r.secondary_display}
                        onChange={this.handleTextChange}/>;
        }
    }

    getDefaultValueForSelection(key) {
        const { currentActionRequirementSelections } = this.props;

        for(var i = 0; i < currentActionRequirementSelections.length; i++) {
            if(key === currentActionRequirementSelections[i].key) {
                return currentActionRequirementSelections[i].selection;
            }
        }
    }

    getDefaultSelectables(key) {
        const { currentActionRequirementSelections } = this.props;

        for(var i = 0; i < currentActionRequirementSelections.length; i++) {
            if(key === currentActionRequirementSelections[i].key && currentActionRequirementSelections[i].selectables && currentActionRequirementSelections[i].selectables.length > 0) {
                return currentActionRequirementSelections[i].selectables.reduce(function(result, selectable) {
                    result.push({id: selectable});
                    return result;
                }, []);
            }
        }

        return null;
    }
    
    componentDidMount() {
        const { dispatch, currentActionRequirements } = this.props;

        dispatch(activeActions.getTaskAsset(currentActionRequirements.action.task_id));
    }

    render() {
        const { currentActionRequirements, taskAsset } = this.props;

        if(!currentActionRequirements || !taskAsset) {
            return null;
        }

        return (
            <div className={styles.container}>
                <div className={styles.headingcontainer}>
                    <h4 className={styles.heading}>Complete Your To Do</h4>
                </div>
                <div className={styles.maincontainer}>
                    <div className={styles.subcontainer}>
                        <div className={styles.subheading}>Add Data For {taskAsset.name}</div>
                    </div>
                    {
                        currentActionRequirements.requirements.filter(r => !isPartOfTable(r.type, r.sub_type)).reduce((result, r) => {
                            result.push(
                                <div key={r.key + '/fg'} className={styles.verifysubform}>
                                    <FormGroup className={styles.verifygroup}>
                                        <Label for={r.display}>{r.display}</Label>
                                        {this.getInputForKey(r)}
                                    </FormGroup>
                                </div>
                            );
                            return result;
                        }, [])
                    }
                    
                    {currentActionRequirements.requirements.filter(r => isPartOfTable(r.type, r.sub_type) && !r.key.includes("ResearchTrip")).length > 0 &&
                    <div>
                        <Label>Trip Pounds</Label>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Grade</th>
                                    <th>Pounds Caught</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    currentActionRequirements.requirements.filter(r => isPartOfTable(r.type, r.sub_type) && !r.key.includes("ResearchTrip")).reduce((result, r) => {
                                        result.push(
                                            <tr key={r.key + '/fg'}>
                                                <td className={styles.centertext}>{r.display}</td>
                                                <td><Input id={r.key} defaultValue={0} onChange={this.handleTextChange}/></td>
                                            </tr>
                                        );
                                        return result;
                                    }, [])
                                }
                            </tbody>
                        </Table>
                    </div>
                    }

                    {currentActionRequirements.requirements.filter(r => isPartOfTable(r.type, r.sub_type) && r.key.includes("ResearchTrip")).length > 0 &&
                    <div>
                        <Label>Research Trip Pounds</Label>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Grade</th>
                                    <th>Pounds Caught</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                currentActionRequirements.requirements.filter(r => isPartOfTable(r.type, r.sub_type) && r.key.includes("ResearchTrip")).reduce((result, r) => {
                                    result.push(
                                        <tr key={r.key + '/fg'}>
                                            <td className={styles.centertext}>{r.display}</td>
                                            <td><Input id={r.key} defaultValue={0} onChange={this.handleTextChange}/></td>
                                        </tr>
                                    );
                                    return result;
                                }, [])
                            }
                            </tbody>
                        </Table>
                    </div>
                    }

                    <div className={styles.buttoncontainer}>
                        <div>To complete this action, click below.</div>
                        <ConfirmationModal 
                            bodyText="By confirming the completion of this To Do. You are acknowledging that this To Do has been completed to its full extent." 
                            buttonText="Confirm" 
                            confirmCallback={this.complete}/>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {   
    const { active } = state;
    const { currentActionRequirements, currentActionRequirementSelections, taskAsset } = active;
    return {
        currentActionRequirements,
        currentActionRequirementSelections,
        taskAsset
    };
}

const connectedCompleteAssetDetailsAction = connect(mapStateToProps)(CompleteAssetDetailsAction);
export { connectedCompleteAssetDetailsAction as CompleteAssetDetailsAction };