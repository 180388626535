export function status(status) {
    switch(status) {
        case 0:
          return "Inactive";
        case 1:
          return "Pending";
        case 2:
          return "Active";
        case 3:
          return "Completed";
        case 4:
          return "Nullified";
        case 5:
          return "Incomplete";
        default:
          return "Unknown";
    }
}