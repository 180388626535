import React from 'react';
import styles from './ChangePasswordPage.module.css'

import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { PasswordForm } from '_components/PasswordForm';

class ChangePasswordPage extends React.Component { 
    render() {      
        return (
            <Container className={styles.container}>
                <h2 className={styles.heading}>Change Password</h2>
                <p className={styles.para}>Update your password, make sure that it meets all requirements</p>
                <PasswordForm></PasswordForm>
            </Container>
        );
    }
}
    
function mapStateToProps(state) {      
    return {

    };
}

const connectedChangePasswordPage = connect(mapStateToProps)(ChangePasswordPage);
export { connectedChangePasswordPage as ChangePasswordPage };