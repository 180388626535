import React from 'react';
import PropTypes from 'prop-types';
import checked from '_images/thumbsup.png';
import styles from './ReminderLogTable.module.css';
import DataTable from 'react-data-table-component';

import { ReminderDetailsModal } from '_components/ReminderDetailsModal';
import { MdPageview } from 'react-icons/md';
import { connect } from 'react-redux';
import { status, formatShortDateTime, customTableTheme } from '_helpers';
import { reminderActions, assetActions } from '_actions';
import { Badge, Button, Input } from 'reactstrap';

class ReminderLogTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentSearch: '',
            filters: [],
            showModal: false
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleFilterApply = this.handleFilterApply.bind(this);
        this.handleBadgeClick = this.handleBadgeClick.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    columns = [
        {
            name: 'Reminder',
            selector: 'heading',
            sortable: true,
            grow: 2,
        },
        {
            name: 'Asset',
            selector: 'asset_id',
            sortable: true,
            grow: 2,
        },
        {
            name: 'Start Date',
            selector: 'create_date',
            format: row => formatShortDateTime(row.ref.create_date),
            sortable: true,
            hide: 'sm',
            wrap: true
        },
        {
            name: 'Last Updated',
            selector: 'last_updated',
            format: row => formatShortDateTime(row.ref.last_update_date),
            sortable: true,
            wrap: true
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: true,
            hide: 'sm'
        },
        {
            name: '',
            right: true,
            cell: row => <MdPageview className={styles.view} rid={row.ref.reminder_id} onClick={this.handleClick}>View</MdPageview>
        }
    ];
    
    closeModal() {
        this.setState({
            showModal: false
        })
    }

    handleClick(e) {
        const { dispatch } = this.props;

        var rid = e.currentTarget.getAttribute('rid');
        dispatch(reminderActions.getReminderDetails(rid));
        
        this.setState({
            showModal: true
        })
    }

    handleTextChange(event) {
        this.setState({
            currentSearch: event.target.value
        })
    }

    handleFilterApply() {
        const { currentSearch, filters } = this.state;
        if(currentSearch) {
            if(!filters.includes(currentSearch)) {
                filters.push(currentSearch);
            }

            this.setState({
                currentSearch: '',
                filters: filters
            })
        }
    }

    handleBadgeClick(e) {
        var id = e.target.getAttribute("fid");

        const { filters } = this.state;

        for(var i = 0; i < filters.length; i++) {
            if(filters[i] === id) {
                filters.splice(i, 1);
            }
        }

        this.setState({
            filters: filters
        });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.handleFilterApply();
        }
    }
    
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(assetActions.getAssets('all'));
    }

    render() {
        const { currentSearch, filters, showModal } = this.state;
        const { reminders, assets } = this.props;

        var asset_data = {};

        assets.forEach(a => {
            asset_data[a.asset_id] = a.name;
        });

        var data = reminders.filter(r => r.status !== 4).reduce((result, r) => {
            var assetName = asset_data[r.asset_id];

            if(filters.length === 0) {
                result.push({
                    heading: r.heading,
                    asset_id: assetName,
                    create_date: r.create_date,
                    last_updated: r.last_update_date,
                    status: status(r.status),
                    ref: r
                });
            } else {
                for(var i = 0; i < filters.length; i++) {
                    if( r.heading.toLowerCase().includes(filters[i].toLowerCase()) || 
                        assetName.toLowerCase().includes(filters[i].toLowerCase())) {

                        result.push({
                            heading: r.heading,
                            asset_id: assetName,
                            create_date: r.create_date,
                            last_updated: r.last_update_date,
                            status: status(r.status),
                            ref: r
                        });
                        break;
                    }
                }
            }
            return result;
        }, []);

        return (
            <div className={styles.container}>
                <div className={styles.filtercontainer}>
                    {filters.map(f => {

                        return  <Badge pill className={styles.badge} key={f} fid={f} onClick={this.handleBadgeClick}>
                                    <span className={styles.btext} fid={f} onClick={this.handleBadgeClick}>{f}</span>
                                    <span fid={f} className={styles.ex} onClick={this.handleBadgeClick}>X</span>
                                </Badge>
                        
                    })
                    }
                    <Input className={styles.filterinput} placeholder='Search...' onChange={this.handleTextChange} onKeyDown={this.handleKeyPress} value={currentSearch}/>
                    <Button className={styles.filterbutton} onClick={this.handleFilterApply}>Apply</Button>
                </div>
                <DataTable
                    columns={this.columns}
                    data={data}
                    striped
                    fixedHeader
                    fixedHeaderScrollHeight='500px'
                    customTheme={customTableTheme}
                    noHeader
                    noDataComponent={
                        <div className={styles.nodatacontainer}>
                            <span className={styles.heading}>No Tasks!</span>
                            <span className={styles.para}>You have nothing to view at this time.</span>
                            <img className={styles.checkedimg} src={checked} alt=":("/>
                        </div>
                    }
                />
                <ReminderDetailsModal forceModal={showModal} closeModal={this.closeModal}/>
            </div>        
        );
    }
}

ReminderLogTable.propTypes = {
    filter: PropTypes.string
};
  
ReminderLogTable.defaultProps = {
    filter: 'all'
};

function mapStateToProps(state) {
    const { company, reminder, asset } = state;
    const { reminders } = reminder;
    const { assets } = asset;


    return {
        company, 
        reminders,
        assets
    };
}
const connectedReminderLogTable = connect(mapStateToProps)(ReminderLogTable);
export { connectedReminderLogTable as ReminderLogTable };