import { companyConstants } from '_constants';
import { companyService } from '_services';
import { alertActions, relationshipActions } from './';
import { history } from '_helpers';
import { userActions } from './user.actions';

export const companyActions = {
    getCompany,
    getEmployeesByFilter,
    getEmployeesBySearch,
    getEmployeeDetails,
    getEmployeeCertifications,
    update,
    updateEmployeeRelationship,
    updateRelationship,
    addEmployee,
    completeCompany, 
    addBillingInfo,
    updateBillingInfo,
    cancelPendingInvite,
    getCustomRoles,
    getCustomData,
    getLatestCatchPrices
};

function getCompany(companyId, gotoCompanyPage, gotoAcceptPage){
    return dispatch => {
        dispatch(request({ companyId }));

        companyService.get(companyId)
            .then(
                company => {
                    dispatch(success(company));
                    dispatch(alertActions.clear());

                    if(gotoCompanyPage) {
                        history.push('/company');
                    } else if(gotoAcceptPage) {
                        history.push('/company/accept');
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(companyId) { return { type: companyConstants.COMPANY_GET_REQUEST, companyId } }
    function success(currentCompany) { return { type: companyConstants.COMPANY_GET_SUCCESS, currentCompany } }
    function failure(error) { return { type: companyConstants.COMPANY_GET_FAILURE, error } }
}

function getEmployeesByFilter(filter){
    return dispatch => {
        dispatch(request({ filter }));

        companyService.getEmployeesByFilter(filter)
            .then(
                employees => {
                    dispatch(success(employees));
                    dispatch(alertActions.clear());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(filter) { return { type: companyConstants.COMPANY_GET_EMPLOYEES_REQUEST, filter } }
    function success(employees) { return { type: companyConstants.COMPANY_GET_EMPLOYEES_SUCCESS, employees } }
    function failure(error) { return { type: companyConstants.COMPANY_GET_EMPLOYEES_FAILURE, error } }
}

function getEmployeesBySearch(search, managersOnly){
    return dispatch => {
        dispatch(request({ search, managersOnly }));

        if(managersOnly) {
            companyService.getManagersBySearch(search)
            .then(
                employees => {
                    dispatch(success(employees));
                    dispatch(alertActions.clear());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
        } else {
            companyService.getEmployeesBySearch(search)
            .then(
                employees => {
                    dispatch(success(employees));
                    dispatch(alertActions.clear());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
        }
    };

    function request(search) { return { type: companyConstants.COMPANY_GET_EMPLOYEES_REQUEST, search } }
    function success(employees) { return { type: companyConstants.COMPANY_GET_EMPLOYEES_SUCCESS, employees } }
    function failure(error) { return { type: companyConstants.COMPANY_GET_EMPLOYEES_FAILURE, error } }
}

function getEmployeeDetails(userId) {
    return dispatch => {
        dispatch(request(userId));
        dispatch(alertActions.clear());
        history.push('/company/employees/info');
    };

    function request(userId) { return { type: companyConstants.COMPANY_GET_EMPLOYEE_DETAILS, userId } }
}

function getEmployeeCertifications(userId) {
    return dispatch => {
        dispatch(request(userId));

        companyService.getEmployeeCertifications(userId)
        .then(
            certifications => {
                dispatch(success(certifications));
                dispatch(alertActions.clear());
            },
            error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    };

    function request(userId) { return { type: companyConstants.COMPANY_GET_EMPLOYEE_CERTIFICATIONS_REQUEST, userId } }
    function success(certifications) { return { type: companyConstants.COMPANY_GET_EMPLOYEE_CERTIFICATIONS_SUCCESS, certifications } }
    function failure(error) { return { type: companyConstants.COMPANY_GET_EMPLOYEE_CERTIFICATIONS_FAILURE, error } }
}

function update(company){
    return dispatch => {
        dispatch(request(company));

        companyService.update(company)
            .then(
                company => {
                    dispatch(success(company));
                    dispatch(alertActions.clear());
                    history.push('/company');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(company) { return { type: companyConstants.COMPANY_UPDATE_REQUEST, company } }
    function success(currentCompany) { return { type: companyConstants.COMPANY_UPDATE_SUCCESS, currentCompany } }
    function failure(error) { return { type: companyConstants.COMPANY_UPDATE_FAILURE, error } }
}

function updateEmployeeRelationship(employee) {
    return dispatch => {
        dispatch(request(employee));

        companyService.updateEmployeeRelationship(employee)
            .then(
                employee => {
                    dispatch(success(employee));
                    dispatch(alertActions.clear());
                    dispatch(companyActions.getEmployeesByFilter('All'));
                    history.push('/company/employees');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(employee) { return { type: companyConstants.COMPANY_UPDATE_EMPLOYEE_RELATIONSHIP_REQUEST, employee } }
    function success(employee) { return { type: companyConstants.COMPANY_UPDATE_EMPLOYEE_RELATIONSHIP_SUCCESS, employee } }
    function failure(error) { return { type: companyConstants.COMPANY_UPDATE_EMPLOYEE_RELATIONSHIP_FAILURE, error } }
}

function updateRelationship(employee) {
    return dispatch => {
        dispatch(request(employee));

        companyService.updateRelationship(employee)
            .then(
                employee => {
                    dispatch(success(employee));
                    dispatch(alertActions.clear());
                    dispatch(relationshipActions.getAllRelationships());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(employee) { return { type: companyConstants.COMPANY_UPDATE_EMPLOYEE_RELATIONSHIP_REQUEST, employee } }
    function success(employee) { return { type: companyConstants.COMPANY_UPDATE_EMPLOYEE_RELATIONSHIP_SUCCESS, employee } }
    function failure(error) { return { type: companyConstants.COMPANY_UPDATE_EMPLOYEE_RELATIONSHIP_FAILURE, error } }
}

function addEmployee(employee) {
    return dispatch => {
        dispatch(request(employee));

        companyService.addEmployee(employee)
            .then(
                employee => {
                    dispatch(success(employee));
                    dispatch(alertActions.clear());
                    dispatch(companyActions.getEmployeesByFilter('All'));
                    history.push('/company/employees');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(employee) { return { type: companyConstants.COMPANY_ADD_EMPLOYEE_REQUEST, employee } }
    function success(employee) { return { type: companyConstants.COMPANY_ADD_EMPLOYEE_SUCCESS, employee } }
    function failure(error) { return { type: companyConstants.COMPANY_ADD_EMPLOYEE_FAILURE, error } }
}

function addBillingInfo(stripeCustomer) {
    return dispatch => {
        dispatch(request(stripeCustomer));

        companyService.addBillingInfo(stripeCustomer)
            .then(
                stripeCustomer => {
                    dispatch(success(stripeCustomer));
                    history.push('/company/');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(stripeCustomer) { return { type: companyConstants.COMPANY_ADD_BILLING_INFO_REQUEST, stripeCustomer } }
    function success(stripeCustomer) { return { type: companyConstants.COMPANY_ADD_BILLING_INFO_SUCCESS, stripeCustomer } }
    function failure(error) { return { type: companyConstants.COMPANY_ADD_BILLING_INFO_FAILURE, error } }
}

function updateBillingInfo(stripeCustomer) {
    return dispatch => {
        dispatch(request(stripeCustomer));

        companyService.updateBillingInfo(stripeCustomer)
            .then(
                stripeCustomer => {
                    dispatch(success(stripeCustomer));
                    history.push('/company/');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(stripeCustomer) { return { type: companyConstants.COMPANY_ADD_BILLING_INFO_REQUEST, stripeCustomer } }
    function success(stripeCustomer) { return { type: companyConstants.COMPANY_ADD_BILLING_INFO_SUCCESS, stripeCustomer } }
    function failure(error) { return { type: companyConstants.COMPANY_ADD_BILLING_INFO_FAILURE, error } }
}

function completeCompany(company) {
    return dispatch => {
        dispatch(request(company));

        companyService.completeCompany(company)
            .then(
                company => {
                    dispatch(success(company));
                    dispatch(userActions.refreshToken(localStorage.getItem("user")));
                    dispatch(alertActions.clear());
                    history.push('/company/billing');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(company) { return { type: companyConstants.COMPANY_COMPLETE_REQUEST, company } }
    function success(company) { return { type: companyConstants.COMPANY_COMPLETE_SUCCESS, company } }
    function failure(error) { return { type: companyConstants.COMPANY_COMPLETE_FAILURE, error } }
}

function cancelPendingInvite(employeeId) {
    return dispatch => {
        dispatch(request(employeeId));

        companyService.cancelPendingInvite(employeeId)
            .then(
                relationship => {
                    dispatch(success(relationship));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(employeeId) { return { type: companyConstants.COMPANY_CANCEL_INVITE_REQUEST, employeeId } }
    function success(relationship) { return { type: companyConstants.COMPANY_CANCEL_INVITE_SUCCESS, relationship } }
    function failure(error) { return { type: companyConstants.COMPANY_CANCEL_INVITE_FAILURE, error } }
}

function getCustomRoles() {
    return dispatch => {
        dispatch(request());
        
        companyService.getCustomRoles()
            .then(
                roles => {
                    dispatch(success(roles));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: companyConstants.COMPANY_CUSTOM_ROLES_REQUEST } }
    function success(roles) { return { type: companyConstants.COMPANY_CUSTOM_ROLES_SUCCESS, roles } }
    function failure(error) { return { type: companyConstants.COMPANY_CUSTOM_ROLES_FAILURE, error } }
}

function getCustomData(key) {
    return dispatch => {
        dispatch(request());
      
        companyService.getCustomData(key)
            .then(
                customData => {
                    dispatch(success(customData));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: companyConstants.COMPANY_CUSTOM_DATA_REQUEST } }
    function success(customData) { return { type: companyConstants.COMPANY_CUSTOM_DATA_SUCCESS, customData } }
    function failure(error) { return { type: companyConstants.COMPANY_CUSTOM_DATA_FAILURE, error } }
}

function getLatestCatchPrices() {
    return dispatch => {
        dispatch(request());
      
        companyService.getLatestCatchPrices()
            .then(
                latestCatchPrices => {
                    dispatch(success(latestCatchPrices));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: companyConstants.COMPANY_CATCH_PRICES_REQUEST } }
    function success(latestCatchPrices) { return { type: companyConstants.COMPANY_CATCH_PRICES_SUCCESS, latestCatchPrices } }
    function failure(error) { return { type: companyConstants.COMPANY_CATCH_PRICES_FAILURE, error } }
}