import { adminConstants } from '_constants';

var initialState = {
    company: {},
    companies: [],
    questions: [],
    documentTemplates: [],
    actionTemplates: [],
    taskTemplates: []
};

export function admin(state = initialState, reduxAction) {
    switch (reduxAction.type) {
        case adminConstants.CREATE_COMPANY_REQUEST:
        case adminConstants.CREATE_COMPANY_FAILURE:
            return {
                ...state,
                company: {}
            };
        case adminConstants.CREATE_COMPANY_SUCCESS:
            return {
                ...state,
                company: reduxAction.company
            };
        case adminConstants.UPDATE_COMPANY_SELECTION:
            return {
                ...state,
                company: getCompany(state.companies, reduxAction.companyId)
            }
        case adminConstants.GET_COMPANIES_REQUEST:
        case adminConstants.GET_COMPANIES_FAILURE:
            return {
                ...state,
                companies: [],
                company: {},
                questions: [],
                documentTemplates: [],
                actionTemplates: [],
                taskTemplates: []
            };
        case adminConstants.GET_COMPANIES_SUCCESS:
            return {
                ...state,
                companies: reduxAction.companies,
                company: {},
                questions: [],
                documentTemplates: [],
                actionTemplates: [],
                taskTemplates: []
            };
        case adminConstants.GET_QUESTIONS_REQUEST:
        case adminConstants.GET_QUESTIONS_FAILURE:
            return {
                ...state,
                questions: []
            };
        case adminConstants.GET_QUESTIONS_SUCCESS:
            return {
                ...state,
                questions: reduxAction.questions
            };
        case adminConstants.GET_DOCUMENT_TEMPLATE_REQUEST:
        case adminConstants.GET_DOCUMENT_TEMPLATE_FAILURE:
            return {
                ...state,
                documentTemplates: []
            };
        case adminConstants.GET_DOCUMENT_TEMPLATE_SUCCESS:
            return {
                ...state,
                documentTemplates: reduxAction.documentTemplates
            };
        case adminConstants.GET_ACTION_TEMPLATE_REQUEST:
        case adminConstants.GET_ACTION_TEMPLATE_FAILURE:
            return {
                ...state,
                actionTemplates: []
            };
        case adminConstants.GET_ACTION_TEMPLATE_SUCCESS:
            return {
                ...state,
                actionTemplates: reduxAction.actionTemplates
            };
        case adminConstants.GET_TASK_TEMPLATE_REQUEST:
        case adminConstants.GET_TASK_TEMPLATE_FAILURE:
            return {
                ...state,
                taskTemplates: []
            };
        case adminConstants.GET_TASK_TEMPLATE_SUCCESS:
            return {
                ...state,
                taskTemplates: reduxAction.taskTemplates
            };
        default:
            return state
    }
}

function getCompany(companies, companyId) {
    for(var i = 0; i < companies.length; i++) {
        if(companies[i].company_id === companyId) {
            localStorage.setItem("company", JSON.stringify(companies[i]));
            return companies[i];
        }
    }
}