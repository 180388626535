import { userConstants } from '_constants';
import { defaultPreferences } from '_helpers';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { 
  autoLoggedOut: false, 
  loggedIn: true, 
  refreshing: false, 
  user, 
  preferences: {}
} : {};

export function authentication(state = initialState, reduxAction) {
  switch (reduxAction.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: reduxAction.user
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: reduxAction.response.user
      };
    case userConstants.LOGIN_FAILURE:
    case userConstants.LOGOUT:
      return {
        autoLoggedOut: reduxAction.autoLogout
      };
    case userConstants.UPDATE_SUCCESS:
      return {
        ...state,
        user: reduxAction.user 
      };
    case userConstants.REFRESH_REQUEST:
      return {
        ...state,
        refreshing: true
      };
    case userConstants.REFRESH_SUCCESS:
      return {
        ...state,
        refreshing: false,
        user: reduxAction.response.user
      };
    case userConstants.REFRESH_FAILURE:
      return {
        ...state,
        refreshing: false
      };
    case userConstants.GET_PREFERENCES_REQUEST:
      return {
        ...state,
        preferences: defaultPreferences()
      };
    case userConstants.GET_PREFERENCES_FAILURE:
      return {
        ...state,
        preferences: defaultPreferences()
      };
    case userConstants.GET_PREFERENCES_SUCCESS:
      return {
        ...state,
        preferences: reduxAction.preferences || defaultPreferences()
      }
    case userConstants.UPDATE_PREFERENCES_REQUEST:
    case userConstants.UPDATE_PREFERENCES_FAILURE:
      return {
        ...state,
        preferences: reduxAction.preferences
      };
    case userConstants.UPDATE_PREFERENCES_SUCCESS:
      return {
        ...state,
        preferences: reduxAction.preferences
      }
    case userConstants.TOGGLE_PREFERENCE:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          [reduxAction.name]: !state.preferences[reduxAction.name]
        }
      }
    case userConstants.UPDATE_LANGUAGE_PREFERENCE:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          preferred_language: reduxAction.code 
        }
      }
    default:
      return state
  }
}