import React from 'react';
import PropTypes from 'prop-types';
import styles from './SignatureModal.module.css';

import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SignatureCanvas from 'react-signature-canvas';

class SignatureModal extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            modal: false
        };

        this.canvasRef = React.createRef();
        this.toggle = this.toggle.bind(this);
        this.clear = this.clear.bind(this);
        this.save = this.save.bind(this);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    clear() {
        this.canvasRef.clear();
    }

    save() {
        const { saveCallback, stripMetaData } = this.props;

        var key = this.props.id;
        var id = this.props.id;
        var cid = this.props.cid;
        var ctype = this.props.ctype;
        var image = stripMetaData ? this.canvasRef.toDataURL('image/png').replace(/^data:image\/(png|jpg);base64,/, "") : this.canvasRef.toDataURL('image/png');
        
        //callback of setting state with latest values
        saveCallback({ id, cid, ctype, key, image });
        this.toggle();
    }

    render() {
        const { buttonColor, buttonText, disabled } = this.props;

        return (
        <div>
            <Button color={buttonColor} disabled={disabled} onClick={this.toggle}>{buttonText}</Button>
            <Modal isOpen={this.state.modal} toggle={this.toggle} className={styles.modal}>
                <ModalHeader toggle={this.toggle}>Sign Below</ModalHeader>
                <ModalBody className={styles.modalbody}>
                    <SignatureCanvas ref={(ref) => { this.canvasRef = ref }} canvasProps={{className: styles.sigcanvas}}></SignatureCanvas>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.save}>Save</Button>
                    <Button color="secondary" onClick={this.clear}>Clear</Button>
                </ModalFooter>
            </Modal>
        </div>
        );
    }
}

SignatureModal.propTypes = {
    buttonColor: PropTypes.string,
    buttonText: PropTypes.string,
    disabled: PropTypes.bool,
    saveCallback: PropTypes.func,
    stripMetaData: PropTypes.bool
};
  
SignatureModal.defaultProps = {
    buttonColor: "success",
    buttonText: '',
    disabled: false,
    saveCallback: null,
    stripMetaData: true
};

function mapStateToProps(state) {
    return {
    };
}

const connectedSignatureModal = connect(mapStateToProps)(SignatureModal);
export { connectedSignatureModal as SignatureModal };