import { assetConstants } from '_constants';

var initialState = {
    assets: [],
    assetTemplates: [],
    assetTemplateRequirementSelections: null,
    currentAsset: null,
    currentAssetTemplate: null,
    fetchingAssets: false,
    assetDetails: [],
    assetDetailLimits: [],
    assetDetailLimitSelections: []
};

export function asset(state = initialState, reduxAction) {
    switch (reduxAction.type) {
        case assetConstants.ASSETS_GET_REQUEST:
            return {
                ...state,
                assets: [],
                fetchingAssets: true
            }
        case assetConstants.ASSETS_GET_FAILURE:
            return {
                ...state,
                assets: [],
                fetchingAssets: false
            };
        case assetConstants.ASSETS_GET_SUCCESS:
            return {
                ...state,
                assets: reduxAction.assets,
                fetchingAssets: false
            };
        case assetConstants.ASSET_GET_REQUEST:
            return { 
                ...state,
                currentAsset: null,
                fetchingAssets: true
            };
        case assetConstants.ASSET_GET_FAILURE:
            return {
                ...state,
                currentAsset: null,
                fetchingAssets: false
            };
        case assetConstants.ASSET_GET_SUCCESS:
            return {
                ...state,
                currentAsset: reduxAction.asset,
                fetchingAssets: false
            };
        case assetConstants.ASSET_TEMPLATES_GET_REQUEST:
            return {
                ...state,
                assetTemplates: [],
                currentAssetTemplate: null,
                currentAsset: null,
                fetchingAssets: true
            };
        case assetConstants.ASSET_TEMPLATES_GET_FAILURE:
            return {
                ...state,
                assetTemplates: [],
                currentAssetTemplate: null,
                fetchingAssets: false
            };
        case assetConstants.ASSET_TEMPLATES_GET_SUCCESS:
            return {
                ...state,
                assetTemplates: reduxAction.assetTemplates,
                assetTemplateRequirementSelections: reduxAction.assetTemplates[0] ? buildRequirementSelections(reduxAction.assetTemplates[0].data_keys) : {},
                currentAssetTemplate: reduxAction.assetTemplates[0] || {},
                fetchingAssets: false
            };
        case assetConstants.ASSET_TEMPLATE_GET_REQUEST:
            return {
                ...state,
                currentAssetTemplate: null,
                fetchingAssets: true
            }
        case assetConstants.ASSET_TEMPLATE_GET_FAILURE:
            return {
                ...state,
                currentAssetTemplate: null,
                fetchingAssets: false
            };
        case assetConstants.ASSET_TEMPLATE_GET_SUCCESS:
            return {
                ...state,
                assetTemplateRequirementSelections: buildRequirementSelections(reduxAction.assetTemplate.data_keys, state.currentAsset),
                currentAssetTemplate: reduxAction.assetTemplate,
                fetchingAssets: false
            };
        case assetConstants.UPDATE_ASSET_REQUIREMENTS_SELECTION:
            return {
                ...state,
                assetTemplateRequirementSelections: updateSelections(state.assetTemplateRequirementSelections, reduxAction.updatedSelection)
            };
        case assetConstants.UPDATE_ASSET_SUCCESS:
            return {
                ...state,
                currentAsset: reduxAction.asset
            };
        case assetConstants.UPDATE_ASSET_REQUEST:
        case assetConstants.UPDATE_ASSET_FAILURE:
            return {
                ...state,
                currentAsset: reduxAction.asset
            };
        case assetConstants.ASSET_GET_ANNUAL_SUMMARY_REQUEST:
        case assetConstants.ASSET_GET_ANNUAL_SUMMARY_FAILURE:
            return {
                ...state,
                assetSummary: {},
            };
        case assetConstants.ASSET_GET_ANNUAL_SUMMARY_SUCCESS:
            return {
                ...state,
                assetSummary: reduxAction.assetDetails
            };
        case assetConstants.ASSET_GET_ANNUAL_ORG_SUMMARY_REQUEST:
        case assetConstants.ASSET_GET_ANNUAL_ORG_SUMMARY_FAILURE:
            return {
                ...state,
                assetOrgSummary: [],
            };
        case assetConstants.ASSET_GET_ANNUAL_ORG_SUMMARY_SUCCESS:
            return {
                ...state,
                assetOrgSummary: reduxAction.assetDetails
            };
        case assetConstants.ASSET_GET_ANNUAL_DETAIED_REPORT_SUCCESS:
            return {
                ...state,
                assetDetailedReport: reduxAction.assetDetailedReport,
            };    
        case assetConstants.ASSET_GET_ANNUAL_DETAIED_REPORT_REQUEST:
            return {
                ...state,
                assetDetailedReport: {},
                detailsAssetId: reduxAction.request.asset_id,
                detailsYear: reduxAction.request.year
            };
        case assetConstants.ASSET_GET_ANNUAL_DETAIED_REPORT_FAILURE:
            return {
                ...state,
                assetDetailedReport: {},
            };        
        case assetConstants.ASSET_GET_ANNUAL_DETAILS_REQUEST:
        case assetConstants.ASSET_GET_ANNUAL_DETAILS_FAILURE:
            return {
                ...state,
                assetDetails: {},
            };
        case assetConstants.ASSET_GET_ANNUAL_DETAILS_SUCCESS:
            return {
                ...state,
                assetDetails: reduxAction.assetDetails
            };
        case assetConstants.ASSET_VIEW_ASSET_ANNUAL_DETAILS: {
            return {
                ...state,
                detailsAssetId: reduxAction.viewParameters.assetId,
                detailsYear: reduxAction.viewParameters.year
            };
        }
        case assetConstants.ASSET_GET_DETAILS_LIMITS_SUCCESS:
            return {
                ...state,
                assetDetailLimits: reduxAction.limits,
                assetDetailLimitSelections: buildAssetLimits(reduxAction.limits)
            };
        case assetConstants.ASSET_GET_DETAILS_LIMITS_REQUEST:
        case assetConstants.ASSET_GET_DETAILS_LIMITS_FAILURE:
            return {
                ...state,
                assetDetailLimits: [],
                assetDetailLimitSelections: []
            };
        case assetConstants.ASSET_DETAILS_LIMITS_UPDATE:
            return {
                ...state,
                assetDetailLimitSelections: updateAssetLimit(state.assetDetailLimitSelections, reduxAction.limit)
            };
        default:
            return state;
    }
}

function buildRequirementSelections(requirements, currentAsset) {
    var selections  = [];

    requirements.forEach(r => {
        selections.push({
            data_key: r,
            selection: currentAsset ? currentAsset.asset_data[r] : ''
        })
    })

    return selections;
}

function updateSelections(selections, updatedSelection) {
    
    selections.forEach(s => {
        if(s.data_key === updatedSelection.key) {
            s.selection = updatedSelection.selection;
        }
    });

    return selections;
}

function buildAssetLimits(limits) {
    var result  = [];

    limits.forEach(l => {
        result.push({
            limit_id: l.limit_id,
            asset_id: l.asset_id,
            limit: l.limit,
            area: l.area,
            year: l.year
        });
    })

    return result;
}

function updateAssetLimit(limits, updatedLimit) {

    limits.forEach(l => {
        if(l.limit_id && l.limit_id === updatedLimit.limit_id) {
            l.limit = updatedLimit.value;
        } else if(l.area === updatedLimit.area && l.asset_id === updatedLimit.asset_id && l.year === parseInt(updatedLimit.year)) {
            l.limit = updatedLimit.value;
        }
    });

    return limits;
}