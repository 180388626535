import React from 'react';
import styles from './UserPreferencesPage.module.css';

import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { PreferencesForm } from '_components/PreferencesForm';

class UserPreferencesPage extends React.Component {
    render() {
        const { match: { params } } = this.props;

        return (
            <Container className={styles.container}>
                <h2 className={styles.heading}>Your Preferences</h2>
                <p className={styles.para}>Your prefence changes could take up to 24 hours to take effect.</p>
                <PreferencesForm uid={params.uid || ''} huid={params.huid || ''}/>
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

const connectedUserPreferencesPage = connect(mapStateToProps)(UserPreferencesPage);
export { connectedUserPreferencesPage as UserPreferencesPage };