import React from 'react';
import PropTypes from 'prop-types';
import styles from './ConfirmationModal.module.css';

import { connect } from 'react-redux';
import { Button, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ConfirmationModal extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            modal: false
        };

        this.toggle = this.toggle.bind(this);
        this.confirm = this.confirm.bind(this);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    confirm() {
        const { confirmCallback, data } = this.props;
        
        //callback of setting state with latest values
        confirmCallback({ succeed: true, data });
        this.toggle();
    }

    render() {
        const { buttonClassOverride, containerClassOverride, bodyText, buttonColor, buttonText, clickable, disabled } = this.props;

        return (
        <div className={containerClassOverride}>
            {clickable &&
                <div onClick={this.toggle}>
                    {clickable}
                </div>
            }
            {!clickable &&
                <Button className={buttonClassOverride || styles.button} color={buttonColor} disabled={disabled} onClick={this.toggle}>{buttonText}</Button>
            }
            <Modal isOpen={this.state.modal} toggle={this.toggle} className={styles.modal}>
                <ModalHeader toggle={this.toggle}>Confirmation</ModalHeader>
                <ModalBody className={styles.modalbody}>
                    <Label>{bodyText}</Label>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.confirm}>Confirm</Button>
                    <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
        );
    }
}

ConfirmationModal.propTypes = {
    bodyText: PropTypes.string,
    buttonColor: PropTypes.string,
    buttonText: PropTypes.string,
    containerClassOverride: PropTypes.string,
    buttonClassOverride: PropTypes.string,
    confirmCallback: PropTypes.func,
    data: PropTypes.object,
    disabled: PropTypes.bool,
    clickable: PropTypes.object
};
  
ConfirmationModal.defaultProps = {
    bodyText: '',
    buttonColor: 'success',
    buttonText: '',
    containerClassOverride: null,
    buttonClassOverride: null,
    confirmCallback: null,
    data: null,
    disabled: false,
    clickable: null
};

function mapStateToProps(state) {
    return {
    };
}

const connectedConfirmationModal = connect(mapStateToProps)(ConfirmationModal);
export { connectedConfirmationModal as ConfirmationModal };