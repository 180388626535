import XlsExport from 'xlsexport/xls-export.js';
import { status, formatShortDateTime, getCurrentDate } from '_helpers';

export function exportTasks(taskList, header, fileName) {
    if(!header) {
        header = 'Company Tasks';
    }

    if(!fileName) {
        fileName = `${getCurrentDate()} Company Tasks`;
    }

    var dataToExport = (taskList || []).map(t => {
        return {
            "Task Name": t.name || t.task_template.name,
            "Manager": t.manager_name,
            "Create Date": formatShortDateTime(t.create_date),
            "Last Updated": formatShortDateTime(t.last_update_date),
            "Status": status(t.status)
        };
    });

    exportData(dataToExport, header, fileName);
}

export function exportEmployees(employeeList, header, fileName) {
    if(!header) {
        header = 'Company Employees';
    }

    if(!fileName) {
        fileName = `${getCurrentDate()} Company Employees`;
    }

    var dataToExport = (employeeList || []).map(e => {
        return {
            "First Name": e.user.first_name,
            "Last Name": e.user.last_name,
            "Email": e.user.email,
            "Phone": e.user.phone_number,
            "Address": e.user.address,
            "State": e.user.state,
            "City": e.user.city,
            "Zip Code": e.user.zip_code,
            "Emergency Contact Name": e.user.emergency_contact_name,
            "Emergency Contact Number": e.user.emergency_contact_number
        };
    });

    exportData(dataToExport, header, fileName);
}

export function exportVesselData(vesselData, assets, header, fileName) {
    if(!header) {
        header = 'Company Vessels';
    }

    if(!fileName) {
        fileName = `${getCurrentDate()} Company Vessels`;
    }

    var assetLookup = assets.reduce((result, a) => {
        result[a.asset_id] = a.name;
        return result;
    }, {});

    var dataToExport = [];
    for(var i = 0; i < Object.entries(vesselData || {}).length; i++) {
        var entry = Object.entries(vesselData || {})[i];
        var vesselName = assetLookup[entry[0]];

        for(var j = 0; j < (entry[1] || []).length; j++) {
            var data = (entry[1] || [])[j];

            var dataToPush = {
                "Name": vesselName,
                "Area": data.area,
                "Limit": data.limit,
                "Caught": data.caught || 0,
                "Remaining": data.limit - (data.caught || 0)
            };

            dataToExport.push(dataToPush);
        }
    }

    exportData(dataToExport, header, fileName);
}

export function exportIndividualVesselPoundData(assetDetailedReportData, assetName) {
    var fileName;
    var header;

    if(!header) {
        header = assetName + ' Detailed Data';
    }

    if(!fileName) {
        fileName = `${getCurrentDate() + assetName} Data`;
    }

    var dataToExport = [];
    assetDetailedReportData.rows.forEach(rowObj => {
        var dataToPush = {};
        assetDetailedReportData.columns.forEach(col => {
            dataToPush[col.export_title] = rowObj[col.accessor] ||  "0" ;
        });
        
        dataToExport.push(dataToPush);
    });
        
    if(assetDetailedReportData.research_rows.length > 0) {
        var headerRow = {};
        headerRow[assetDetailedReportData.columns[0].export_title] = "Research Report Data";
        dataToExport.push(headerRow);
    }

    assetDetailedReportData.research_rows.forEach(rowObj => {
        var dataToPush = {};
        assetDetailedReportData.columns.forEach(col => {
            dataToPush[col.export_title] = rowObj[col.accessor] || "0" ;
        });

        dataToExport.push(dataToPush);
    });
                
    exportData(dataToExport, header, fileName);
}

export function exportData(data, header, fileName) {
    var xls = new XlsExport(data, header);
    xls.exportToXLS(`${fileName}.xls`);
}