import { companyConstants } from '_constants';

export function company(state = { roles: []}, reduxAction) {
    switch (reduxAction.type) {
        case companyConstants.COMPANY_GET_SUCCESS:
            return reduxAction.currentCompany;
        case companyConstants.COMPANY_GET_REQUEST:
        case companyConstants.COMPANY_GET_FAILURE:
            return {

            };
        case companyConstants.COMPANY_GET_EMPLOYEES_SUCCESS:
            return {
                ...state,
                employees: reduxAction.employees,
                fetchingEmployees: false
            };
        case companyConstants.COMPANY_GET_EMPLOYEES_REQUEST:
            return {
                ...state,
                employees: [],
                fetchingEmployees: true
            }
        case companyConstants.COMPANY_GET_EMPLOYEES_CLEAR:
        case companyConstants.COMPANY_GET_EMPLOYEES_FAILURE:
            return {
                ...state,
                employees: [],
                fetchingEmployees: false
            };

        case companyConstants.COMPANY_GET_EMPLOYEE_DETAILS:
            return {
                ...state,
                currentEmployee: getUserFromEmployees(reduxAction.userId, state.employees)
            };
        case companyConstants.COMPANY_GET_EMPLOYEE_CERTIFICATIONS_REQUEST:
        case companyConstants.COMPANY_GET_EMPLOYEE_CERTIFICATIONS_FAILURE:
            return {
                ...state,
                currentEmployeeCertifications: []
            };
        case companyConstants.COMPANY_GET_EMPLOYEE_CERTIFICATIONS_SUCCESS:
            return {
                ...state,
                currentEmployeeCertifications: reduxAction.certifications
            };
        case companyConstants.COMPANY_UPDATE_SUCCESS:
            return reduxAction.currentCompany;
        case companyConstants.COMPANY_UPDATE_REQUEST:
        case companyConstants.COMPANY_UPDATE_FAILURE:
            return {
                
            };
        case companyConstants.COMPANY_UPDATE_EMPLOYEE_RELATIONSHIP_SUCCESS:
        case companyConstants.COMPANY_UPDATE_EMPLOYEE_RELATIONSHIP_REQUEST:
        case companyConstants.COMPANY_UPDATE_EMPLOYEE_RELATIONSHIP_FAILURE:
            return {
                ...state,
            };
        
        case companyConstants.COMPANY_ADD_BILLING_INFO_FAILURE:
        case companyConstants.COMPANY_ADD_BILLING_INFO_REQUEST:
        case companyConstants.COMPANY_ADD_BILLING_INFO_SUCCESS:
            return{
                stripeCustomer: reduxAction.stripeCustomer,
                ...state,
            };
        case companyConstants.COMPANY_CANCEL_INVITE_FAILURE:
        case companyConstants.COMPANY_CANCEL_INVITE_REQUEST:
            return {
                ...state
            };
        case companyConstants.COMPANY_CANCEL_INVITE_SUCCESS:
            return {
                ...state,
                employees: state.employees.filter(e => e.user.user_id !== reduxAction.relationship.user_id)
            };
        case companyConstants.COMPANY_CUSTOM_ROLES_REQUEST:
        case companyConstants.COMPANY_CUSTOM_ROLES_FAILURE:
            return {
                ...state,
                roles: []
            };
        case companyConstants.COMPANY_CUSTOM_ROLES_SUCCESS:
            return {
                ...state,
                roles: reduxAction.roles
            };
        case companyConstants.COMPANY_CUSTOM_DATA_REQUEST:
        case companyConstants.COMPANY_CUSTOM_DATA_FAILURE:
            return {
                ...state,
                customData: []
            };
        case companyConstants.COMPANY_CUSTOM_DATA_SUCCESS:
            return {
                ...state,
                customData: reduxAction.customData
            };
        case companyConstants.COMPANY_CATCH_PRICES_REQUEST:
        case companyConstants.COMPANY_CATCH_PRICES_FAILURE:
            return {
                ...state,
                latestCatchPrices: []
            };
        case companyConstants.COMPANY_CATCH_PRICES_SUCCESS:
            return {
                ...state,
                latestCatchPrices: reduxAction.latestCatchPrices
            };
        default:
            return state
    }
}

function getUserFromEmployees(userId, employees) {
    var employee = {};

    employees.forEach(e => {
        if(e.user.user_id === userId) {
            employee = e;
        }
    });

    return employee;
}