import { authHeader, config, currentCompany, currentUser, handleResponse, handleError } from '_helpers';

export const documentService = {
    getAll,
    getAllForCompany,
    getAllForTask,
    getDownloadLink,
    getAdminDownloadLink
};

function getAll(filter) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.apiUrl + `/users/${currentUser().user_id}/documents?filter=${filter}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });
}

function getAllForCompany(filter) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/documents/users/${currentUser().user_id}?filter=${filter}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });
}

function getAllForTask(taskId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/tasks/${taskId}/viewables`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });
}

function getDownloadLink(documentId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/documents/${documentId}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function getAdminDownloadLink(viewableId, viewableType, renderType) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    
    return fetch(config.capiUrl + `/${currentCompany().company_id}/${viewableType === '0' ? 'documents' : 'forms'}/${viewableId}/${renderType === 'pdf' ? 'pdf/admin' : 'admin'}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}