import { reminderConstants } from "_constants";
import { reminderService } from "_services";
import { alertActions } from '.';
import { history } from "_helpers";

export const reminderActions = {
    getCompanyReminders,
    getUserReminders,
    navigateCreateReminder,
    getReminderDetails,
    createReminder,
    createLogEntry,
    deactivateReminder
}

function getCompanyReminders(filter) {
    return dispatch => {
        dispatch(request(filter));
        
        reminderService.getCompanyReminders(filter)
            .then(
                reminders => { 
                    dispatch(success(reminders));
                    dispatch(alertActions.clear());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(filter) { return { type: reminderConstants.GET_COMPANY_REMINDERS_REQUEST, filter } }
    function success(reminders) { return { type: reminderConstants.GET_COMPANY_REMINDERS_SUCCESS, reminders } }
    function failure(error) { return { type: reminderConstants.GET_COMPANY_REMINDERS_FAILURE, error } }
}

function getUserReminders(filter) {
    return dispatch => {
        dispatch(request(filter));
        
        reminderService.getUserReminders(filter)
            .then(
                reminders => { 
                    dispatch(success(reminders));
                    dispatch(alertActions.clear());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(filter) { return { type: reminderConstants.GET_USER_REMINDERS_REQUEST, filter } }
    function success(reminders) { return { type: reminderConstants.GET_USER_REMINDERS_SUCCESS, reminders } }
    function failure(error) { return { type: reminderConstants.GET_USER_REMINDERS_FAILURE, error } }
}

function navigateCreateReminder(enddate){
    return dispatch => {
        dispatch(request(enddate));
        dispatch(alertActions.clear());
        history.push('/company/reminder/create');
    };

    function request(enddate) { return { type: reminderConstants.NAVIGATE_TO_CREATE_REMINDER_REQUEST, enddate } }
}

function getReminderDetails(reminderId) {
    return dispatch => {
        dispatch(request(reminderId));
        
        reminderService.getReminderDetails(reminderId)
            .then(
                currentReminder => { 
                    dispatch(success(currentReminder));
                    dispatch(alertActions.clear());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(reminderId) { return { type: reminderConstants.GET_REMINDER_DETAILS_REQUEST, reminderId } }
    function success(currentReminder) { return { type: reminderConstants.GET_REMINDER_DETAILS_SUCCESS, currentReminder } }
    function failure(error) { return { type: reminderConstants.GET_REMINDER_DETAILS_FAILURE, error } }
}

function createReminder(reminder) {
    return dispatch => {
        dispatch(request());
        
        reminderService.createReminder(reminder)
            .then(
                reminderobj => {
                    dispatch(success(reminderobj));
                    dispatch(alertActions.clear());
                    history.push('/company/calendar');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(reminderobj) { return { type: reminderConstants.CREATE_COMPANY_REMINDER_REQUEST, reminderobj } }
    function success(reminderobj) { return { type: reminderConstants.CREATE_COMPANY_REMINDER_SUCCESS, reminderobj } }
    function failure(error) { return { type: reminderConstants.CREATE_COMPANY_REMINDER_FAILURE, error } }
}

function createLogEntry(reminder) {
    return dispatch => {
        dispatch(request());
        
        reminderService.createLogEntry(reminder)
            .then(
                reminderobj => {
                    dispatch(success(reminderobj));
                    dispatch(alertActions.clear());
                    history.push('/company/calendar');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(reminderobj) { return { type: reminderConstants.CREATE_COMPANY_LOG_ENTRY_REQUEST, reminderobj } }
    function success(reminderobj) { return { type: reminderConstants.CREATE_COMPANY_LOG_ENTRY_SUCCESS, reminderobj } }
    function failure(error) { return { type: reminderConstants.CREATE_COMPANY_LOG_ENTRY_FAILURE, error } }
}

function deactivateReminder(reminderId) {
    return dispatch => {
        dispatch(request(reminderId));
        
        reminderService.deactivateReminder(reminderId)
            .then(
                reminderobj => {
                    dispatch(success(reminderobj));
                    dispatch(alertActions.clear());
                    dispatch(getCompanyReminders('all'));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(reminderId) { return { type: reminderConstants.DEACTIVATE_COMPANY_REMINDER_REQUEST, reminderId } }
    function success(reminder) { return { type: reminderConstants.DEACTIVATE_COMPANY_REMINDER_SUCCESS, reminder } }
    function failure(error) { return { type: reminderConstants.DEACTIVATE_COMPANY_REMINDER_FAILURE, error } }
}