import React from 'react';
import checked from '_images/thumbsup.png';
import styles from './AdminPage.module.css';

import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { Button } from 'reactstrap';
import { formatShortDateTime, status, customTableTheme } from '_helpers';
import { adminActions } from '_actions';
import { ConfirmationModal } from '_components/ConfirmationModal';
import { FaTrash } from 'react-icons/fa';
import { CompanySelector } from '_components/CompanySelector';

class AdminTaskTemplatePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            companyId: ''
        }

        this.handleDelete = this.handleDelete.bind(this);
    }

    columns = [
        {
            name: 'Id',
            selector: 'id',
            sortable: true,
            grow: 2,
            wrap: true
        },
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
            wrap: true
        },
        {
            name: 'Custom Name Prefix',
            selector: 'custom_name_prefix',
            sortable: true,    
            wrap: true
        },
        {
            name: 'Required Asset Type',
            selector: 'required_asset_type',
            sortable: true,    
            wrap: true
        },
        {
            name: 'Description',
            selector: 'description',
            sortable: true,    
            wrap: true
        },
        {
            name: 'Custom Name',
            selector: 'custom_name',
            sortable: true,    
            wrap: true
        },
        {
            name: 'Requires Asset',
            selector: 'requires_asset',
            sortable: true,    
            wrap: true
        },
        {
            name: 'Requires Employees',
            selector: 'requires_employees',
            sortable: true,    
            wrap: true
        },
        {
            name: 'Manager Is Employee',
            selector: 'manager_is_employee',
            sortable: true,    
            wrap: true
        },
        {
            name: 'Start Notification',
            selector: 'start_notification',
            sortable: true,    
            wrap: true
        },
        {
            name: 'EndNotification',
            selector: 'end_notification',
            sortable: true,    
            wrap: true
        },
        {
            name: 'Create Date',
            selector: 'create_date',
            sortable: true,
            wrap: true,
            hide: 'sm'
        },
        {
            name: 'Last Updated',
            selector: 'last_updated',
            sortable: true,
            wrap: true,
            hide: 'sm'
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: true,
            wrap: true,
            hide: 'sm'
        },
        {
            name: '',
            grow: 0,
            right: true,
            hide: 'sm',
            cell: row => <ConfirmationModal bodyText={`By confirming you will be deleting ${row.name} from our site for this company.`} 
                                            clickable={<FaTrash className={styles.edit}/>} 
                                            confirmCallback={this.handleDelete} 
                                            data={row.ref}
                                            />
        }
    ];

    handleDelete({data}) {
        const { dispatch, company } = this.props;
        
        var taskTemplate = data;
        taskTemplate.status = 0;

        dispatch(adminActions.deactivateTaskTemplate(company.company_id, taskTemplate));
    }

    componentDidUpdate() {
        const { dispatch, company } = this.props;
        const { companyId } = this.state;

        if(company && company.company_id && company.company_id !== companyId) {
            this.setState({
                companyId: company.company_id
            }, dispatch(adminActions.getTaskTemplates(company.company_id, 'all')));
        }
    }

    render() {
        const { company, taskTemplates } = this.props;

        var data = taskTemplates.map(tt => {
            return {
                id: tt.task_template_id,
                name: tt.name,
                description: tt.description,
                custom_name_prefix: tt.custom_name_prefix,
                required_asset_type: tt.required_asset_type,
                custom_name: tt.custom_name.toString(),
                requires_asset: tt.requires_asset.toString(),
                requires_employees: tt.requires_employees.toString(),
                manager_is_employee: tt.manager_is_employee.toString(),
                start_notification: tt.start_notification.toString(),
                end_notification: tt.end_notification.toString(),
                create_date: formatShortDateTime(tt.create_date),
                last_updated: formatShortDateTime(tt.last_update_date),
                status: status(tt.status),
                ref: tt
            };
        });

        return (
            <div className={styles.container}>
                <div className={styles.headingcontainer}>
                    <CompanySelector/>
                    <h4 className={styles.header}>{company.company_name} Task Templates</h4>
                    <div>
                        <LinkContainer to='/admin/companies/tasks/templates/create' className={styles.headingbutton}>
                            <Button color="success" disabled={company && company.company_id ? false : true}>Create Task Template</Button>
                        </LinkContainer>
                    </div>
                </div>
                <DataTable
                    columns={this.columns}
                    data={data}
                    striped
                    fixedHeader
                    fixedHeaderScrollHeight='500px'
                    customTheme={customTableTheme}
                    noHeader
                    noDataComponent={
                        <div className={styles.nodatacontainer}>
                            <span className={styles.heading}>No Task templates!</span>
                            <span className={styles.para}>You have nothing to view at this time.</span>
                            <img className={styles.checkedimg} src={checked} alt=":("/>
                        </div>
                    }/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { admin } = state;
    const { company, taskTemplates } = admin;

    return { 
        company,
        taskTemplates
    };
}

const connectedAdminTaskTemplatePage = connect(mapStateToProps)(AdminTaskTemplatePage);
export { connectedAdminTaskTemplatePage as AdminTaskTemplatePage };