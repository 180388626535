import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-widgets/dist/css/react-widgets.css';
import 'react-mde/lib/styles/css/react-mde-all.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-step-progress-bar/styles.css';
import "react-toggle/style.css";
import "@kenshooui/react-multi-select/dist/style.css"
import { PersistGate } from 'redux-persist/integration/react'
import { App } from './App';
import { Footer } from '_components/Footer'
import { Provider } from 'react-redux';
import { store, persistor } from './_helpers';
import * as serviceWorker from './serviceWorker';


//pdf stuff
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const withFooter = WrappedComponent => () => [
    <WrappedComponent key="1" />,
    <Footer store={store} key="2" />
];

const Wrapper = () => (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App/>
      </PersistGate>
    </Provider>
);
const WrapperWithFooter = withFooter(Wrapper);

ReactDOM.render(<WrapperWithFooter/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
