import React from 'react';
import PropTypes from 'prop-types';
import styles from './EmployeeSelector.module.css';

import { Button, Input, Label, ListGroup, ListGroupItem, Popover, PopoverHeader, PopoverBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import { companyActions, templateActions } from '_actions';
import { companyConstants } from '_constants';
import { MdReplay } from 'react-icons/md';

class EmployeeSelector extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            currentSearch: '',
            selectedEmployees: props.prePopulatedEmployees.reduce((d, v) => {d[v.user.user_id] = v.user; return d}, {}),
            selectedRoles: {},
            recentEmployees: [],
            popoverOpen: false,
            modalOpen: false,
            modalEmployee: {},
            modalRole: ''
        }

        this.handleClick = this.handleClick.bind(this);
        this.handleRecentClick = this.handleRecentClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.toggle = this.toggle.bind(this);
        this.modalToggle = this.modalToggle.bind(this);
        this.modalSave = this.modalSave.bind(this);
        this.modalChange = this.modalChange.bind(this);
    }

    handleClick(event) {
        const { selectedEmployees } = this.state;
        const { adminMode, roles, customRoles } = this.props;

        if(!adminMode && customRoles) {
            this.setState( {
                modalOpen: true,
                popoverOpen: false,
                currentSearch: '',
                modalEmployee: JSON.parse(event.target.getAttribute('em')),
                modalRole: roles[0].role
            })
        } else {
            this.setState({
                currentSearch: '',
                selectedEmployees: {
                    ...selectedEmployees,
                    [event.target.getAttribute('eid')]: JSON.parse(event.target.getAttribute('em'))
                },
                popoverOpen: false
            }, () => {
                //callback of setting state with latest values
                this.props.selectedCallback(this.state.selectedEmployees);
            });
        }
    }

    handleRecentClick() {
        const{ dispatch, currentTaskTemplate } = this.props;

        dispatch(templateActions.getRecentEmployeesForTask(currentTaskTemplate.task_template_id));
    }

    handleChange(e) {
        const { adminMode, dispatch } = this.props;
        const { popoverOpen } = this.state;

        var value = e.target.value;
        this.setState({
            currentSearch: value
        });

        if(value && value.length > 0) {
            dispatch(companyActions.getEmployeesBySearch(e.target.value, adminMode));

            if(!popoverOpen) {
                this.setState({
                    popoverOpen: true
                })
            }

        } else {
            dispatch({type: companyConstants.COMPANY_GET_EMPLOYEES_CLEAR});

            this.setState({
                popoverOpen: false
            })
        }
    }

    handleDelete(event) {
        const { selectedEmployees, selectedRoles } = this.state;
        var eid = event.target.getAttribute('eid');
        delete selectedEmployees[eid];
        delete selectedRoles[eid];

        this.setState({
            selectedEmployees,
            selectedRoles
        }, () => {
            //callback of setting state with latest values
            this.props.selectedCallback(this.state.selectedEmployees, this.state.selectedRoles);
        });
    }

    toggle() {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    }

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch({type: companyConstants.COMPANY_GET_EMPLOYEES_CLEAR});
        dispatch(companyActions.getCustomRoles());
    }

    static getDerivedStateFromProps(props, state) {
        if(!checkEmployeeLists(props.recentEmployees, state.recentEmployees)) {
            let selectedEmployees = state.selectedEmployees;
            let recentEmployees = props.recentEmployees;

            for(var i = 0; i < recentEmployees.length; i++) {
                selectedEmployees[recentEmployees[i].user.user_id] = recentEmployees[i].user;
            }

            //callback of setting state with latest values
            props.selectedCallback(selectedEmployees);
            
            return {
                recentEmployees: recentEmployees,
                selectedEmployees: selectedEmployees
            }
        }

        return null;
    }

    modalToggle() {
        this.setState({
            modalOpen: !this.state.modalOpen
        });
    }

    modalChange(e) {
        var index = e.target.selectedIndex;
        var selectedRole = e.target.childNodes[index].getAttribute('role');

        this.setState({
            modalRole: selectedRole
        })
    }

    modalSave() {
        const { modalEmployee, modalRole, selectedEmployees, selectedRoles } = this.state;
        
        this.setState({
            selectedEmployees: {
                ...selectedEmployees,
                [modalEmployee.user_id]: modalEmployee
            },
            modalOpen: false
        }, () => {
            this.setState({
                selectedRoles: {
                    ...selectedRoles,
                    [modalEmployee.user_id]: modalRole
                }
            }, () => {
                //callback of setting state with latest values
                this.props.selectedCallback(this.state.selectedEmployees, this.state.selectedRoles);
            });
        });
    }

    render() {
        const { adminMode, employees, forTask, maxSelections, roles, customRoles } = this.props;
        const { currentSearch, popoverOpen, selectedEmployees, selectedRoles, modalOpen, modalEmployee } = this.state;

        return (
            <div className={styles.container}>
                <Label className={styles.subheading}>Select {adminMode ? "Managers" : "Employees"}</Label>
                <div>
                    <div className={styles.labelcontainer}>
                        <Label>Enter {adminMode ? "Manager's" : "Employee's"} Name</Label>
                        {forTask && !customRoles && !adminMode &&
                        <MdReplay className={styles.icon} size={35} onClick={this.handleRecentClick}/>
                        }
                    </div>
                    <Input id="employeeinput" autoComplete="off" autoCorrect="off" type="search" value={currentSearch} onChange={this.handleChange} disabled={maxSelections !== 0 && Object.keys(selectedEmployees).length >= maxSelections}/>
                    <Popover placement="bottom" isOpen={popoverOpen} target="employeeinput" toggle={this.toggle}>
                        <PopoverHeader>Select {adminMode ? "Managers" : "Employees"}</PopoverHeader>
                        <PopoverBody className={styles.popoverbody}>
                        {employees && employees.length > 0 &&
                            <ListGroup>
                                {employees.map(e => {
                                        if(selectedEmployees[e.user.user_id]) {
                                            return null;
                                        }

                                        return  <ListGroupItem key={e.user.user_id} eid={e.user.user_id} em={JSON.stringify(e.user)} onClick={this.handleClick}>
                                                    {e.user.first_name} {e.user.last_name}
                                                </ListGroupItem>
                                    })
                                }
                            </ListGroup>
                        }
                        </PopoverBody>
                    </Popover>
                </div>
                <div>
                    <h5>Selected {adminMode ? "Managers" : "Employees"}</h5>
                    <ListGroup className={styles.listgroup}>
                        {Object.keys(selectedEmployees).length > 0 ?
                            Object.values(selectedEmployees).sort((a, b) => a.first_name.localeCompare(b.first_name) || a.first_name.localeCompare(b.first_name)).map(e => {
                                return  <ListGroupItem className={styles.listgroupitem} key={e.user_id} eid={e.user_id}>
                                            <div className={styles.lgicontainer}>
                                                <span className={styles.emSpan}>{e.first_name} {e.last_name} {selectedRoles[e.user_id] ? '(' + selectedRoles[e.user_id] + ')' : ''} </span>
                                                <Button color="danger" eid={e.user_id} onClick={this.handleDelete} className={styles.delBtn}>X</Button>
                                            </div>
                                        </ListGroupItem>
                            }) :
                            <div className={styles.nodatacontainer}>
                                <span className={styles.title}>Heads up!</span>
                                <span className={styles.para}>No {adminMode ? "managers" : "employees"} selected</span>
                            </div>
                        }
                    </ListGroup>
                </div>

                <Modal isOpen={modalOpen} toggle={this.modalToggle} className={styles.modal}>
                    <ModalHeader toggle={this.modalToggle}>Select Employee Role</ModalHeader>
                    <ModalBody className={styles.modalbody}>
                        <Label className={styles.label}>Selected Employee</Label>
                        <Input readOnly={true} value={modalEmployee.first_name + ' ' + modalEmployee.last_name}/>
                
                        <Label className={styles.label}>Select Role</Label>
                        <Input type='select' onChange={this.modalChange}>
                            {(roles || []).map(r => {
                                return  <option key={r.role_id} rid={r.role_id} role={r.role}>
                                            {r.role}
                                        </option>
                                })
                            }
                        </Input>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={this.modalSave}>Add Employee</Button>
                        <Button color="secondary" onClick={this.modalToggle}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

EmployeeSelector.propTypes = {
    adminMode: PropTypes.bool,
    customRoles: PropTypes.bool,
    forTask: PropTypes.bool,
    maxSelections: PropTypes.number,
    selectedCallback: PropTypes.func,
    prePopulatedEmployees: PropTypes.array
};
  
EmployeeSelector.defaultProps = {
    adminMode: false,
    customRoles: false,
    forTask: false,
    maxSelections: 0,
    selectedCallback: () => {},
    prePopulatedEmployees: []
};

function mapStateToProps(state) {
    const { company, template } = state;
    const { employees, roles } = company;
    const { currentTaskTemplate, recentEmployees } = template;

    return {
        currentTaskTemplate,
        employees,
        recentEmployees,
        roles
    };
}

const connectedEmployeeSelector = connect(mapStateToProps)(EmployeeSelector);
export { connectedEmployeeSelector as EmployeeSelector };

function checkEmployeeLists(emp1, emp2) {
    if(emp1 === undefined && emp2 === undefined) {
        return true;
    }

    if (!Array.isArray(emp1) || ! Array.isArray(emp2) || emp1.length !== emp2.length) {
        return false;
    }

    for(var i = 0; i < emp1.length; i++) {
        if(emp1[i].user.user_id !== emp2[i].user.user_id) {
            return false;
        }
    }

    return true;
}