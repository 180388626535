import { userConstants } from '_constants';

export function registration(state = {}, action) {
  switch (action.type) {
    case userConstants.REGISTER_REQUEST:
      return { 
        registering: true
      };
    case userConstants.REGISTER_SUCCESS:
    case userConstants.REGISTER_FAILURE:
    case userConstants.REGISTER_CLEAR:
      return {
        registering: false
      };
    default:
      return state
  }
}