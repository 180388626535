import React from 'react';
import styles from './CompanyEmployeeInfoPage.module.css';
import fallback from '_images/user_fallback_dark.svg';

import { Button, Form, FormGroup, Input, Label, Table } from 'reactstrap';
import { DateTimePicker } from 'react-widgets';
import { ConfirmationModal } from '_components/ConfirmationModal';
import { CompanyRoleModal } from '_components/CompanyRoleModal';
import { EmployeeCertificationModal } from '_components/EmployeeCertificationModal';
import { connect } from 'react-redux';
import { companyActions } from '_actions';
import { convertDate } from '_helpers';

class CompanyEmployeeInfoPage extends React.Component {
    constructor(props) {
        super(props);

        this.handleActivate = this.handleActivate.bind(this);
        this.handleDeactivate = this.handleDeactivate.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
    }

    handleActivate() {
        const { company, currentEmployee, dispatch } = this.props;

        dispatch(companyActions.updateEmployeeRelationship({ 
            user_id: currentEmployee.user.user_id, 
            company_id: company.company_id,
            role: currentEmployee.company_role,
            sub_role: currentEmployee.sub_role,
            status: 2
        }));
    }

    handleDeactivate() {
        const { company, currentEmployee, dispatch } = this.props;

        dispatch(companyActions.updateEmployeeRelationship({ 
            user_id: currentEmployee.user.user_id, 
            company_id: company.company_id, 
            role: currentEmployee.company_role,
            sub_role: currentEmployee.sub_role,
            status: 0
        }));
    }

    handleUpdate(result) {
        const { company, currentEmployee, dispatch } = this.props;

        dispatch(companyActions.updateEmployeeRelationship({
            user_id: currentEmployee.user.user_id,
            company_id: company.company_id,
            role: result.selectedRole,
            sub_role: result.selectedSubRole,
            status: currentEmployee.status
        }));
    }

    componentDidMount() {
        const { dispatch, currentEmployee, company } = this.props;

        if(company.feature_flag_certifications) {
            dispatch(companyActions.getEmployeeCertifications(currentEmployee.user.user_id));
        }
    }

    render() {
        const { company, currentEmployee, currentEmployeeCertifications, relationships } = this.props;

        if(!currentEmployee) {
            return null;
        }

        var relationship = {};  
        relationships.forEach(r => {
            if(r.company_id === company.company_id) {
                relationship = r;
            }
        });

        return (
            <div className={styles.container}>
                <div className={styles.topcontainer}>
                    <img className={styles.imglogo} src={currentEmployee.user.user_logo_path || fallback} alt=":("/>
                    <div className={styles.headingcontainer}>
                        <h2>{currentEmployee.user.first_name} {currentEmployee.user.last_name}</h2>
                        <h5>{currentEmployee.user.email}</h5>
                    </div>
                </div>
                <div className={styles.botcontainer}>
                    <Form>
                        <h5 className={styles.formheading}>General Information</h5>
                        <div className={styles.formarea}>
                            <FormGroup className={styles.formgroup}>
                                <Label>First Name</Label>
                                <Input readOnly value={currentEmployee.user.first_name}/>
                            </FormGroup>
                            <FormGroup className={styles.formgroup}>
                                <Label>Last Name</Label>
                                <Input readOnly value={currentEmployee.user.last_name}/>
                            </FormGroup>
                            <FormGroup className={styles.formgroup}>
                                <Label>Date of Birth</Label>
                                <DateTimePicker readOnly time={false} defaultValue={convertDate(currentEmployee.user.birth_date)}/>
                            </FormGroup>
                            <FormGroup className={styles.formgroup}>
                                <Label>Phone Number</Label>
                                <Input readOnly type="tel" value={currentEmployee.user.phone_number}/>
                            </FormGroup>
                        </div>
                        <h5 className={styles.formheading}>Address Information</h5>
                        <div className={styles.formarea}>
                            <FormGroup className={styles.formgroup}>
                                <Label>Address</Label>
                                <Input readOnly value={currentEmployee.user.address}/>
                            </FormGroup>
                            <FormGroup className={styles.formgroup}>
                                <Label>City</Label>
                                <Input readOnly value={currentEmployee.user.city}/>
                            </FormGroup>
                            <FormGroup className={styles.formgroup}>
                                <Label>State</Label>
                                <Input readOnly value={currentEmployee.user.state}/>
                            </FormGroup>
                            <FormGroup className={styles.formgroup}>
                                <Label>Zipcode</Label>
                                <Input readOnly value={currentEmployee.user.zip_code}/>
                            </FormGroup>
                        </div>
                        <h5 className={styles.formheading}>Emergency Contact Information</h5>
                        <div className={styles.formarea}>
                            <FormGroup className={styles.formgroup}>
                                <Label>Contact Name</Label>
                                <Input readOnly value={currentEmployee.user.emergency_contact_name}/>
                            </FormGroup>
                            <FormGroup className={styles.formgroup}>
                                <Label>Contact Number</Label>
                                <Input readOnly type="tel" value={currentEmployee.user.emergency_contact_number}/>
                            </FormGroup>
                        </div>
                    </Form>
                    {relationship.role >= 3 &&
                        <div>
                            {company.feature_flag_certifications &&
                                <div className={styles.certificationscontainer}>
                                    <div className={styles.certificationsheadingcontainer}>
                                        <h5 className={styles.formheading}>Certifications/Licenses</h5>
                                        <EmployeeCertificationModal buttonColor='primary'/>
                                    </div>
                                    {(!currentEmployeeCertifications || currentEmployeeCertifications.length === 0) &&
                                        <div>No Certifications</div>
                                    }
                                    {(currentEmployeeCertifications && currentEmployeeCertifications.length > 0) &&
                                        <Table striped>
                                            <thead>
                                                <tr>
                                                    <th width="25%">Type</th>
                                                    <th width="15%">Expiration Date</th>
                                                    <th width="45%">Description</th>
                                                    <th width="15%"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentEmployeeCertifications.map(c => {
                                                    return  <tr key={c.certification_id}>
                                                                <td>{c.type}</td>
                                                                <td>{new Date(c.expiration_date).toLocaleDateString()}</td>
                                                                <td>{c.description}</td>
                                                                <td>
                                                                    <Button>D</Button>
                                                                    <Button>E</Button>
                                                                    <Button>X</Button>
                                                                </td>
                                                            </tr>
                                                })}
                                            </tbody>
                                        </Table>
                                    }
                                </div>
                            }
                            <div className={styles.actioncontainer}>
                                <CompanyRoleModal confirmCallback={this.handleUpdate}/>
                                {currentEmployee.status === 2 &&
                                    <ConfirmationModal buttonColor='danger' buttonText='Deactivate Employee' bodyText='By deactivating your employee they will no longer be associated with your company.' confirmCallback={this.handleDeactivate}/>
                                }
                                {currentEmployee.status === 0 && 
                                    <ConfirmationModal buttonText='Activate Employee' bodyText='By activating your employee they will now be associated with your company again.' confirmCallback={this.handleActivate}/>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { company, relationships } = state;
    const { currentEmployee, currentEmployeeCertifications } = company;

    return {
        company,
        currentEmployee,
        currentEmployeeCertifications,
        relationships
    };
}

const connectedCompanyEmployeeInfoPage = connect(mapStateToProps)(CompanyEmployeeInfoPage);
export { connectedCompanyEmployeeInfoPage as CompanyEmployeeInfoPage };