import React, { Component } from 'react';

import styles from './CheckoutForm.module.css';
import { connect } from 'react-redux';
import { Button, FormGroup, Input, Label, Container } from 'reactstrap';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { companyActions } from '_actions';
import { CardElement, injectStripe } from 'react-stripe-elements';
import PropTypes from 'prop-types';

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
        stripeCustomer:{
          address: {
              line1: "",
              city: "",
              country: "",
              postal_code: "", 
              state: ""
          },
          user: props.user, //this takes the user out of redux store and copies it to local component state so that changes dont take effect until confirmed 
          token: ""},
          payMethod: "1",
      };

  this.handleChange = this.handleChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.handleCountryChange = this.handleCountryChange.bind(this);
  this.handleStateChange = this.handleStateChange.bind(this);
  this.setPayMethod = this.setPayMethod.bind(this);
  this.createToken = this.createToken.bind(this);
  }

   async createToken(ev) {
    //var payload;
    var payload = await this.props.stripe.createToken();
    return payload.token;
  }

async handleSubmit(event) {
  //submit
   const { dispatch, update } = this.props;
   const { stripeCustomer, payMethod } = this.state;
   
   if(payMethod === "1") {
      var tokid = await this.createToken();
      stripeCustomer.token = tokid;
   }
  
   if(!update) {
      dispatch(companyActions.addBillingInfo(stripeCustomer));
   } else {
      dispatch(companyActions.updateBillingInfo(stripeCustomer));
   }
}

handleChange(event) {
  const { name, value } = event.target;
  const { stripeCustomer } = this.state;
  const { address } = stripeCustomer;

  this.setState({
      stripeCustomer: {
          ...stripeCustomer,
          address: {
              ...address,
              [name]: value
          }
      }
  });
}

handleCountryChange(value) {
  const { stripeCustomer } = this.state;
  const { address } = stripeCustomer;
  this.setState({
      stripeCustomer: {
          ...stripeCustomer,
          address: {
              ...address,
              country: value
          }
      }
  });
}

handleStateChange(value) {
  const { stripeCustomer } = this.state;
  const { address } = stripeCustomer;
  this.setState({
      stripeCustomer: {
          ...stripeCustomer,
          address: {
              ...address,
              state: value
          }
      }
  });
}

setPayMethod(event) {
  this.setState({
      payMethod: event.target.value
  });
}

render() {   
  const { stripeCustomer } = this.state;
  return (
        <Container className={styles.container}>
            <h5 className={styles.formheading}>Billing Address Information</h5>
            <div className={styles.formarea}>
                <FormGroup className={styles.formgroup}>
                    <Label>Address</Label>
                    <Input name="line1" autoCapitalize={'words'} defaultValue={stripeCustomer.address.line1 || ''} onChange={this.handleChange}/>
                </FormGroup>
                <FormGroup className={styles.formgroup}>
                    <Label>City</Label>
                    <Input name="city" autoCapitalize={'words'} defaultValue={stripeCustomer.address.city || ''} onChange={this.handleChange}/>
                </FormGroup>
                <FormGroup className={styles.formgroup}>
                    <Label>State</Label>
                    <RegionDropdown
                        className={styles.crselect}
                        blankOptionLabel="Select a country"
                        defaultOptionLabel="Select a state"
                        country={stripeCustomer.address.country || 'United States'}
                        value={stripeCustomer.address.state || ''}
                        onChange={this.handleStateChange} />
                </FormGroup>
                <FormGroup className={styles.formgroup}>
                    <Label>Country</Label>
                      <CountryDropdown
                        className={styles.crselect}
                        defaultOptionLabel="Select a country"
                        value={stripeCustomer.address.country || ''}
                        onChange={this.handleCountryChange}
                        priorityOptions={["US", "GB", "CA"]} />
                </FormGroup>
                <FormGroup className={styles.formgroup}>
                    <Label>Zip Code</Label>
                    <Input name="postal_code" defaultValue={stripeCustomer.address.postal_code || ''} onChange={this.handleChange}/>
                </FormGroup>
                <FormGroup className={styles.formgroup}>       
                    <Label>How would you like to pay?</Label>
                        <div onChange={this.setPayMethod.bind(this)}>
                            <Label check className={styles.label}>
                                <Input className={styles.radioButton}type="radio" value="1" name="payment" defaultChecked/> Credit Card
                            </Label>
                            <Label check className={styles.label}>
                                <Input  type="radio" value="2" name="payment" /> Send Me A Bill
                            </Label>
                        </div>
                    </FormGroup>
                </div>
                <FormGroup className={styles.formgroup}>
                    { this.renderFormGroupForCC() }
                </FormGroup>
              <Button color="success" className={styles.btn} onClick={this.handleSubmit}>Submit</Button>
          </Container>
  );
}

    renderFormGroupForCC() {
        const { payMethod } = this.state;

        if(payMethod === "1") {
            return (
                <FormGroup className={styles.cardformgroup}>
                    <div className="checkout">
                    <p>Provide your card number for automatic payments: </p>
                    <CardElement />
                    </div>  
                </FormGroup>
            );
        } else {
            return [];
        }
    }
}


CheckoutForm.propTypes = {
  update: PropTypes.bool
};

CheckoutForm.defaultProps = {
 update: true
};


function mapStateToProps(state) {      
    const { authentication } = state;
    const { user } = authentication;

    return {
        user
    };
}

const connectedCheckoutForm = injectStripe(connect(mapStateToProps)(CheckoutForm));
export { connectedCheckoutForm as CheckoutForm };