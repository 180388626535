import jwt_decode from 'jwt-decode';
import { userActions } from '_actions';
import { userConstants } from '_constants';

export const refreshMiddleware = store => next => action => {
    //Prevent a god damn fire storm of token refreshes
    if(action.type !== userConstants.REFRESH_REQUEST && 
        action.type !== userConstants.REFRESH_SUCCESS && 
        action.type !== userConstants.REFRESH_FAILURE && 
        action.type !== "ALERT_ERROR" &&
        action.type !== "persist/PERSIST" &&
        action.type !== "persist/REHYDRATE") {

        //get token from local storage
        var token = localStorage.getItem('token');
        var refreshing = store.getState().authentication.refreshing;

        if(token && !refreshing) {
            var dateNow = new Date().getTime() / 1000; //current date to compare to
            var decoded = jwt_decode(token); //date and time token expires
            var buffer = 1200; //buffer 1200 seconds = 20 minutes

            //if we're inside the buffer
            if(decoded.exp - dateNow < buffer) {
                //refresh the token
                store.dispatch(userActions.refreshToken(localStorage.getItem('user')));  
            }
        }
    }
    return next(action);
}