import { createStore, applyMiddleware } from 'redux';
import { refreshMiddleware } from '_middleware/refreshMiddleware';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '_reducers';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

const loggerMiddleware = createLogger();

const persistConfig = {
    key: 'root',
    storage,
  }
   
const persistedReducer = persistReducer(persistConfig, rootReducer)

const devStore = createStore(
    persistedReducer,
    applyMiddleware(
        thunkMiddleware,
        loggerMiddleware,
        refreshMiddleware
    )
);

const prodStore = createStore(
    persistedReducer,
    applyMiddleware(
        thunkMiddleware,
        refreshMiddleware
    )
);

// chain of environment fallbacks
export const store = process.env.REACT_APP_STAGE === 'production'
  ? prodStore
  : devStore;

export const persistor = persistStore(store);