const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneRegex = new RegExp('[0-9]{3}-[0-9]{3}-[0-9]{4}$');
const socialRegex = new RegExp('[0-9]{3}-[0-9]{2}-[0-9]{4}$');
const maxLength = 50;


export function strongPassword(password) {
    return strongRegex.test(password) && password.length <= maxLength;
}

export function strongPasswordRequirementMessage() {
    return `Please enter a password that is atleast 8 characters and contains each of the following: Uppercase Letter, Lowercase Letter, Number, Symbol (!@##$%^&*)`;
}

export function validateEmail(email) {
    return emailRegex.test(email);
}

export function validatePhoneNumber(phoneNumber) {
    if(!phoneNumber) {
        return true;
    }
    
    return phoneRegex.test(phoneNumber);
}

export function validPhoneNumberMessage() {
    return 'Enter Valid Phone Number e.g. XXX-XXX-XXXX';
}

export function formatPhoneNumber(oldNumber, number) {
    if(!oldNumber) {
        return number;
    }

    if(oldNumber.length >= number.length) {
        return number;
    }

    if(number.length === 3) {
        return number += '-';
    }

    if(number.length === 7) {
        return number += '-';
    }

    if(number.length > 12 && oldNumber) {
        return oldNumber;
    }

    return number;
}

export function validateSocial(social) {
    if(!social) {
        return true;
    }
    
    return socialRegex.test(social);
}

export function validSocialMessage() {
    return 'Enter Valid Social Security Number e.g. XXX-XX-XXXX';
}

export function formatSocial(oldSocial, social) {
    if(!oldSocial) {
        return social;
    }

    if(oldSocial.length >= social.length) {
        return social;
    }

    if(social.length === 3) {
        return social += '-';
    }

    if(social.length === 6) {
        return social += '-';
    }

    return social;
}