import React from 'react';

import { connect } from 'react-redux';
import { UserNavBar } from '_components/NavigationBar/UserNavBar';
import { GuestNavBar } from '_components/NavigationBar/GuestNavBar';

class NavigationBar extends React.Component {
    render() {
        const { user } = this.props;
        if(user && user.first_name) {
            return <UserNavBar/>;
        } else{
            return <GuestNavBar/>;
        }
    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    const { user } = authentication;
    
    return {
        user
    };
}

const connectedNavigationBar = connect(mapStateToProps)(NavigationBar);
export { connectedNavigationBar as NavigationBar };