import React from 'react';
import styles from './AdminPage.module.css';

import { connect } from 'react-redux';
import { Button, Container, FormGroup, Input, Label } from 'reactstrap';
import { adminActions, templateActions, alertActions } from '_actions';

class AdminCreateActionTemplatePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            actionTemplate: {
                action_type: '',
                content_id: '',
                content_key: '',
                description: '',
                name: '',
                role: ''
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.createActionTypeItems = this.createActionTypeItems.bind(this);
        this.createContentItems = this.createContentItems.bind(this);
        this.createRoleItems = this.createRoleItems.bind(this);
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { actionTemplate } = this.state;
        const { dispatch } = this.props;

        if(event.target.name === "action_type") {
            //populate content ids here.
            dispatch(templateActions.getContentIds(event.target.value));
        }

        this.setState({
            actionTemplate: {
                ...actionTemplate,
                [name]: value
            }
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        
        const { dispatch, company } = this.props;
        const { actionTemplate } = this.state;
        
        // perform all neccassary validations
        if (company && company.company_id && 
            actionTemplate.name && actionTemplate.description && 
            actionTemplate.role &&  actionTemplate.action_type && 
            actionTemplate.content_id && actionTemplate.content_key) {
            dispatch(adminActions.createActionTemplate(company.company_id, actionTemplate));
        }
        else {
            dispatch(alertActions.error("Make sure everything is filled in"));
        }
    }

    createActionTypeItems() {
        const actionTypes = [];

        for (const obj of this.props.actionTypes) {
            actionTypes.push(<option key={obj.id} value={obj.id}>{obj.name}</option>);
        }

        return actionTypes;
    }

    createContentItems() {
        const contentItems = [];

        for (const obj of this.props.contentIds) {
            contentItems.push(<option key={obj.content_id} value={obj.content_id}>{obj.name}</option>);
        }

        return contentItems;
    }
    
    createRoleItems() {
        const roles = [];

        for (const obj of this.props.roles) {
            roles.push(<option key={obj.id} value={obj.id}>{obj.name}</option>);
        }

        return roles;
    }

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(templateActions.getRoles());
        dispatch(templateActions.getActionTypes());
    }

    render() {
        const { actionTypes, company, roles} = this.props;

        if(!roles || !actionTypes) {
            return null;
        }

        return (
            <div className={styles.container}>
                <div className={styles.headingcontainer}>
                    <h4 className={styles.header}>Create Action Template For {company.company_name}</h4>
                </div>
                <div className={styles.main}>
                    <Container>
                        <FormGroup>
                            <Label>Name</Label>
                            <Input name="name" onChange={this.handleChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Description</Label>
                            <Input name="description" onChange={this.handleChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Role</Label>
                            <Input type="select" name="role" defaultValue={"Select a role..."} onChange={this.handleChange}>
                                <option readOnly disabled value={"Select a role..."}>Select a role...</option>
                                {this.createRoleItems()}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Action Type</Label>
                            <Input type="select" name="action_type" defaultValue={"Select a action type..."} onChange={this.handleChange}>
                                <option readOnly disabled value={"Select a action type..."}>Select a action type...</option>
                                {this.createActionTypeItems()}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Content Id</Label>                                                             
                            <Input type="select" name="content_id" defaultValue={"Select a content id..."} onChange={this.handleChange}>
                                <option readOnly disabled value={"Select a content id..."}>Select a content id...</option>
                                {this.createContentItems()}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Content Key</Label>
                            <Input name="content_key" onChange={this.handleChange}/>
                        </FormGroup>
                        <Button color="primary" onClick={this.handleSubmit}>Submit</Button>
                    </Container>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { admin, template } = state;
    const { contentIds, roles, actionTypes } = template;
    const { company } = admin;

    return {
        company, contentIds, roles, actionTypes
    };
}

const connectedAdminCreateActionTemplatePage = connect(mapStateToProps)(AdminCreateActionTemplatePage);
export { connectedAdminCreateActionTemplatePage as AdminCreateActionTemplatePage };