import React from 'react';
import styles from './CompanyAssetTypesPage.module.css';

import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { AssetTypeTable } from '_components/AssetTypeTable';

class CompanyAssetTypesPage extends React.Component 
{
    render() {
        const { company } = this.props;
        return (
            <div className={styles.container}>
                <div className={styles.headingcontainer}>
                    <h4 className={styles.header}>{company.company_name} Assets</h4>
                    <div>            
                        <LinkContainer to='/company/assets/templates/create' className={styles.headingbutton}>
                            <Button color="success">Add Type</Button>
                        </LinkContainer>
                    </div>
                </div>
                <AssetTypeTable></AssetTypeTable>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { company } = state;
    return {
        company
    };
}

const connectedCompanyAssetTypesPage = connect(mapStateToProps)(CompanyAssetTypesPage);
export { connectedCompanyAssetTypesPage as CompanyAssetTypesPage };