import React from 'react';
import PropTypes from 'prop-types';
import styles from './EmployeeCertificationModal.module.css';

import { connect } from 'react-redux';
import { Button, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { DateTimePicker } from 'react-widgets';
import { companyService } from '_services';
import { FileUploadModal } from '_components/FileUploadModal';

class EmployeeCertificationModal extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            modal: false,
            certificationTypes: []
        };

        this.toggle = this.toggle.bind(this);
        this.confirm = this.confirm.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        console.log(event);
    }

    handleDateChange(event) {
        console.log(event);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal,
        });
    }

    confirm() {
        this.toggle();
    }

    componentDidMount() {
        companyService.getCustomData('Certification')
        .then(
            customData => {
               this.setState({certificationTypes: customData});
            }
        );
    }

    render() {
        const { certificationTypes } = this.state;
        const { buttonColor, disabled } = this.props;

        return (
        <div>
            <Button className={styles.button} color={buttonColor} disabled={disabled} onClick={this.toggle}>Add Certification</Button>
            <Modal isOpen={this.state.modal} toggle={this.toggle} className={styles.modal}>
                <ModalHeader toggle={this.toggle}>Add New Certification</ModalHeader>
                <ModalBody className={styles.modalbody}>
                    <FormGroup>
                        <Label>Certification/License Type</Label>
                        <Input type="select" id="type" onChange={this.handleChange}>
                            {certificationTypes && certificationTypes.map(c => {
                                return <option key={c.data_id}>{c.data}</option>
                            })}
                        </Input>
                    </FormGroup>
                    <FormGroup className={styles.formgroup}>
                        <Label>Expiration Date</Label>
                        <DateTimePicker time={false} onChange={this.handleDateChange}/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Description</Label>
                        <Input autoComplete="off" autoCorrect="off" type="textarea" id="description" onChange={this.handleChange}></Input>
                    </FormGroup>
                    <FormGroup>
                        <Label>File</Label>
                        <FileUploadModal autoComplete="off" autoCorrect="off" type="email" name="email" onChange={this.handleChange}></FileUploadModal>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.confirm}>Confirm</Button>
                    <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
        );
    }
}

EmployeeCertificationModal.propTypes = {
    buttonColor: PropTypes.string,
    confirmCallback: PropTypes.func,
    disabled: PropTypes.bool,

};
  
EmployeeCertificationModal.defaultProps = {
    buttonColor: 'success',
    confirmCallback: null,
    disabled: false
};

function mapStateToProps(state) {
    const { company } = state;

    return {
        company
    };
}

const connectedEmployeeCertificationModal = connect(mapStateToProps)(EmployeeCertificationModal);
export { connectedEmployeeCertificationModal as EmployeeCertificationModal };