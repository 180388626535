import { authHeader, config, currentUser, handleResponse, handleError } from '_helpers';

export const userService = {
    login,
    logout,
    completeRegister,
    register,
    update,
    refreshToken,
    resetPassword,
    completeResetPassword,
    getPreferences,
    getPreferencesAnon,
    updatePreferences,
    updatePreferencesAnon
};

function completeRegister(userId, registerId) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch(`${config.apiUrl}/users/${userId}/registration/${registerId}`, requestOptions)
        .then(handleResponse, handleError)       
        .then(response => {
            return response;
        });
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${config.apiUrl}/users/register`, requestOptions)
        .then(handleResponse, handleError)       
        .then(response => {
            return response;
        });
}

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch(config.apiUrl + '/users/login', requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            // login successful if there's a jwt token in the response with a user object
            if (response.user && response.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(response.user));
                localStorage.setItem('relationships', JSON.stringify(response.relationships));
                localStorage.setItem('token', JSON.stringify(response.token));
            }
            
            return { user: response.user, relationships: response.relationships };
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('relationships');
    localStorage.removeItem('token');
    localStorage.removeItem('company');
    localStorage.removeItem('persist:root');
}

function refreshToken(user) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: user
    };

    return fetch(config.apiUrl + '/users/refresh', requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            // refresh successful if there's a jwt token in the response
            if (response.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(response.user));
                localStorage.setItem('relationships', JSON.stringify(response.relationships));
                localStorage.setItem('token', JSON.stringify(response.token));
            }
            
            return { user: response.user, relationships: response.relationships };
        });
}

function update(user) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(config.apiUrl + '/users', requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function resetPassword(email) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.apiUrl + `/users/reset/${encodeURIComponent(email)}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });
}

function completeResetPassword(request) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
    };

    return fetch(config.apiUrl + `/users/${request.user_id}/reset`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function getPreferences(userId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.apiUrl + `/users/${userId}/preferences`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function getPreferencesAnon(userId, hashedUserId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch(config.apiUrl + `/users/${userId}/preferences/${hashedUserId}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function updatePreferences(preferences) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(preferences)
    };

    return fetch(config.apiUrl + `/users/${currentUser().user_id}/preferences`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}

function updatePreferencesAnon(uid, huid, preferences) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(preferences)
    };

    return fetch(config.apiUrl + `/users/${uid}/preferences/${huid}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}