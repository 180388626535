import React from 'react';
import styles from './CompanyPage.module.css';

import fallback from '_images/company_fallback.png';
import Markdown from 'markdown-to-jsx';
import Ticker from 'react-ticker'
import { FaAnchor, FaCopy, FaFolderPlus, FaFolderOpen, FaLandmark, FaShip, FaUsers } from 'react-icons/fa';
import { FaCalendarAlt } from 'react-icons/fa';
import { connect } from 'react-redux';
import { Label } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { ActionListGroup } from '_components/ActionListGroup';
import { TaskListGroup } from '_components/TaskListGroup';
import { companyActions } from '_actions';
import { formatUtcDate } from '_helpers';

class CompanyPage extends React.Component {
    constructor(props) {
        super(props);

        this.renderTilesForEmployee = this.renderTilesForEmployee.bind(this);
        this.renderTilesForManager = this.renderTilesForManager.bind(this);
        this.renderTilesForAdmin = this.renderTilesForAdmin.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        const{ dispatch, company } = this.props;
        
        if(company.feature_flag_pounds_details) {
            dispatch(companyActions.getLatestCatchPrices());
        }
    }

    render() {
        const { company, relationships} = this.props;
        const { latestCatchPrices } = company;

        var lookup = relationships.reduce(function(map, obj) {
            map[obj.company_id] = obj.role;
            return map;
        }, {});

        return (
            <div className={styles.container}>
                <div className={styles.logocontainer}>
                    {company.company_logo_path ?     
                        <img className={styles.logo} src={company.company_logo_path} alt=":("/>
                        :
                        <img className={styles.logo} src={fallback} alt=":("/>
                    }
                    <div className={styles.logoheadingcontainer}>
                        <h1>{company.company_name}</h1>
                        <h4>{company.company_description}</h4>
                    </div>
                </div>
                {company.feature_flag_pounds_details && latestCatchPrices && latestCatchPrices.length > 0 &&
                    <div>
                        <Ticker>
                            {({ index }) => (
                                <>
                                    {latestCatchPrices[index % latestCatchPrices.length] &&
                                        <div>
                                            <p key={index % latestCatchPrices.length + 'date'} className={styles.tickerdate}>{formatUtcDate(latestCatchPrices[index % latestCatchPrices.length].price_date)}</p>
                                            <p key={index % latestCatchPrices.length} className={styles.tickertext}><b>{latestCatchPrices[index % latestCatchPrices.length].group_name}:</b> ${latestCatchPrices[index % latestCatchPrices.length].price.toFixed(2)}</p>
                                        </div>
                                    }
                                </>
                            )}
                        </Ticker>
                    </div>
                }
                <div className={styles.boardcontainer}>
                    <div className={styles.boardheadingcontainer}>
                        <h2 className={styles.heading}>Bulletin Board</h2>
                    </div>
                    <div className={styles.board}>
                        <Markdown options={markdownOptions} >
                            {company.company_information || ''}
                        </Markdown>
                    </div>
                </div>
                <div className={styles.uppercontainer}>
                    <div className={styles.headingcontainer}>
                        <h2 className={styles.heading}>Quick Start</h2>
                    </div>
                    <div className={styles.activitycontainer}>
                        {lookup[company.company_id] >= 1 &&
                            this.renderTilesForEmployee()
                        }
                        {lookup[company.company_id] >= 2 &&
                            this.renderTilesForManager()
                        }
                        {lookup[company.company_id] >= 3 &&
                            this.renderTilesForAdmin()
                        }
                        {company.feature_flag_pounds_details &&
                            this.renderTilesForPoundsTracking(lookup[company.company_id])
                        }
                    </div>
                </div>
                <div className={styles.lowercontainer}>
                    {lookup[company.company_id] >= 2 &&
                        <TaskListGroup filter={'active'}/>
                    }
                    <ActionListGroup filter={'active'} allCompanies={false}/>
                </div>
            </div>
        );
    }

    renderTilesForAdmin() {
        return [
            <LinkContainer key='companyinfo' to='/company/info'>
                <div className={styles.activity}>
                    <Label className={styles.activitylabel}>Company Info</Label>
                    <FaLandmark className={styles.activityicon} size={50}/>
                </div>
            </LinkContainer>,
            <LinkContainer key='companytasks' to='/company/tasks'>
                <div className={styles.activity}>
                    <Label className={styles.activitylabel}>Company Tasks</Label>
                    <FaFolderOpen className={styles.activityicon} size={50}/>
                </div>
            </LinkContainer>,
            <LinkContainer key='companyassets' to='/company/assets'>
                <div className={styles.activity}>
                    <Label className={styles.activitylabel}>Assets</Label>
                    <FaShip className={styles.activityicon} size={50}/>
                </div>
            </LinkContainer>
        ];
    }

    renderTilesForManager() {
       return [
            <LinkContainer key='companyemployees' to='/company/employees'>
                <div className={styles.activity}>
                    <Label className={styles.activitylabel}>Employees</Label>
                    <FaUsers className={styles.activityicon} size={50}/>
                </div>
            </LinkContainer>,
            <LinkContainer key='createtask' to='/company/tasks/create'>
                <div className={styles.activity}>
                    <Label className={styles.activitylabel}>Create Task</Label>
                    <FaFolderPlus className={styles.activityicon} size={50}/>
                </div>
            </LinkContainer>
        ];
    }

    renderTilesForEmployee() {
        return [
            <LinkContainer key='documents' to='/company/employee/documents'>
                <div className={styles.activity}>
                    <Label className={styles.activitylabel}>My Documents</Label>
                    <FaCopy className={styles.activityicon} size={50}/>
                </div>
            </LinkContainer>,
            <LinkContainer key='calendar' to='/company/calendar'>
                <div className={styles.activity}>
                    <Label className={styles.activitylabel}>Calendar</Label>
                    <FaCalendarAlt className={styles.activityicon} size={50}/>
                </div>
            </LinkContainer>
        ];
    }

    renderTilesForPoundsTracking(companyRole) {
        var tiles = []

        if(companyRole >= 2) {
            tiles.push(<LinkContainer key='pounds' to='/company/pounds'>
                            <div className={styles.activity}>
                                <Label className={styles.activitylabel}>Track Pounds</Label>
                                <FaAnchor className={styles.activityicon} size={50}/>
                            </div>
                        </LinkContainer>);
        }

        return tiles;
    }
}

function mapStateToProps(state) {
    const { company, relationships } = state;
    return {
        company, relationships
    };
}

const connectedCompanyPage = connect(mapStateToProps)(CompanyPage);
export { connectedCompanyPage as CompanyPage };


const markdownOptions = {
    overrides: {
        td: {
            props: {
                className: styles.boardtable,
            },
        },
        th: {
            props: {
                className: styles.boardtable,
            },
        },
        tr: {
            props: {
                className: styles.boardtable,
            },
        },
    }
};