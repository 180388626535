import React from 'react';
import classNames from 'classnames';
import styles from './TaskTemplateSelector.module.css';

import { connect } from 'react-redux';
import { templateActions } from '_actions';
import { MdAssignment, MdAssignmentInd } from 'react-icons/md';

class TaskTemplateSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedId: null
        }

        this.handleClick = this.handleClick.bind(this);
    }
    
    handleClick(e) {
        const { dispatch } = this.props;

        var ttid = e.target.getAttribute('ttid');
        if(!ttid) {
            ttid = e.target.parentNode.getAttribute('ttid');
        }

        this.setState({
            selectedId: ttid
        });

        dispatch(templateActions.getTaskTemplateRequirements(ttid));
    }

    render() {
        const { taskTemplates } = this.props;
        const { selectedId } = this.state;
        
        return (
            <div className={classNames({ [styles.container]: true, [styles.smallcontainer]: taskTemplates.length <= 5,})}>
                {taskTemplates.sort((a, b) => (+a.requires_employees) - (+b.requires_employees) || a.name.localeCompare(b.name)).map(t => {
                    return  <div className={classNames({ [styles.selectablecontainer]: true, [styles.selectedcontainer]: selectedId === t.task_template_id })} key={t.task_template_id} ttid={t.task_template_id} onClick={this.handleClick}>
                                <div className={classNames({ [styles.selectable]: true, [styles.selected]: selectedId === t.task_template_id })} ttid={t.task_template_id}>
                                    {t.requires_employees ? 
                                        <MdAssignmentInd className={styles.selectableicon} ttid={t.task_template_id} onClick={this.handleClick}/> :
                                        <MdAssignment className={styles.selectableicon} ttid={t.task_template_id} onClick={this.handleClick}/>
                                    }
                                </div>
                                <div className={styles.label} ttid={t.task_template_id}>{t.name}</div>
                            </div>
                })
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { template } = state;
    const { taskTemplates } = template;

    return {
        taskTemplates
    };
}

const connectedTaskTemplateSelector = connect(mapStateToProps)(TaskTemplateSelector);
export { connectedTaskTemplateSelector as TaskTemplateSelector };