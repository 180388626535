import { templateConstants } from '_constants';
import { templateService, taskService } from '_services';
import { alertActions } from '.';

export const templateActions = {
    getActionTemplates,
    getActionTypes,
    getContentIds,
    getRoles,
    getTaskTemplates,
    getTaskTemplateRequirements,
    updateTaskTemplateRequirementSelection,
    getRecentEmployeesForTask
};

function getActionTemplates(filter) {
    return dispatch => {
        dispatch(request(filter));
        
        templateService.getActionTemplates(filter)
            .then(
                actionTemplates => { 
                    dispatch(success(actionTemplates));
                    dispatch(alertActions.clear());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(filter) { return { type: templateConstants.GET_ACTION_TEMPLATES_REQUEST, filter } }
    function success(actionTemplates) { return { type: templateConstants.GET_ACTION_TEMPLATES_SUCCESS, actionTemplates } }
    function failure(error) { return { type: templateConstants.GET_ACTION_TEMPLATES_FAILURE, error } }
}

function getActionTypes() {
    return  dispatch => {
        dispatch(request());
        
        templateService.getActionTypes()
            .then(
                actionTypes => { 
                    dispatch(success(actionTypes));
                    dispatch(alertActions.clear());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(actionTypes) { return { type: templateConstants.GET_ACTION_TYPES_REQUEST , actionTypes } }
    function success(actionTypes) { return { type: templateConstants.GET_ACTION_TYPES_SUCCESS , actionTypes } }
    function failure(error) { return { type: templateConstants.GET_ACTION_TYPES_FAILURE , error } }
}

function getContentIds(actiontype) {
    return dispatch => {   
        dispatch(request({ actiontype }));
        
        templateService.getContentIds(actiontype)
            .then(
                contentIds => {
                    dispatch(success(contentIds));
                    dispatch(alertActions.clear());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(contentIds) { return { type: templateConstants.GET_CONTENT_IDS_REQUEST, contentIds } }
    function success(contentIds) { return { type: templateConstants.GET_CONTENT_IDS_SUCCESS, contentIds } }
    function failure(error) { return { type: templateConstants.GET_CONTENT_IDS_FAILURE, error } }
}

function getRoles() {
    return dispatch => {     
        dispatch(request());
        
        templateService.getRoles()
            .then(
                roles => {
                    dispatch(success(roles));
                    dispatch(alertActions.clear());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(roles) { return { type: templateConstants.GET_TASK_ROLES_REQUEST, roles } }
    function success(roles) { return { type: templateConstants.GET_TASK_ROLES_SUCCESS, roles } }
    function failure(error) { return { type: templateConstants.GET_TASK_ROLES_FAILURE, error } }
}

function getTaskTemplates(filter) {
    return dispatch => {
        dispatch(request());
        
        templateService.getTaskTemplates(filter)
            .then(
                taskTemplates => {
                    dispatch(success(taskTemplates));
                    dispatch(alertActions.clear());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(taskTemplates) { return { type: templateConstants.GET_TASK_TEMPLATES_REQUEST, taskTemplates } }
    function success(taskTemplates) { return { type: templateConstants.GET_TASK_TEMPLATES_SUCCESS, taskTemplates } }
    function failure(error) { return { type: templateConstants.GET_TASK_TEMPLATES_FAILURE, error } }
}

function getTaskTemplateRequirements(taskTemplateId) {
    return dispatch => {
        dispatch(request(taskTemplateId));
        
        templateService.getTaskTemplateRequirements(taskTemplateId)
            .then(
                taskTemplates => {
                    dispatch(success(taskTemplates));
                    dispatch(alertActions.clear());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(taskTemplateId) { return { type: templateConstants.GET_TASK_TEMPLATE_REQUIREMENTS_REQUEST, taskTemplateId } }
    function success(taskTemplateRequirements) { return { type: templateConstants.GET_TASK_TEMPLATE_REQUIREMENTS_SUCCESS, taskTemplateRequirements } }
    function failure(error) { return { type: templateConstants.GET_TASK_TEMPLATE_REQUIREMENTS_FAILURE, error } }
}

function updateTaskTemplateRequirementSelection(updatedSelection) {
    return dispatch => {  
        dispatch(update(updatedSelection));
    };

    function update(updatedSelection) { return { type: templateConstants.UPDATE_TEMPLATE_REQUIREMENTS_SELECTION, updatedSelection } }
}

function getRecentEmployeesForTask(taskTemplateId) {
    return dispatch => {
        dispatch(request(taskTemplateId));

        taskService.getRecentEmployees(taskTemplateId)
            .then(
                tasks => {
                    dispatch(success(tasks));
                    dispatch(alertActions.clear());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(taskTemplateId) { return { type: templateConstants.TASK_RECENT_EMPLOYEES_REQUEST, taskTemplateId } }
    function success(employees) { return { type: templateConstants.TASK_RECENT_EMPLOYEES_SUCCESS, employees } }
    function failure(error) { return { type: templateConstants.TASK_RECENT_EMPLOYEES_FAILURE, error } }
}