export const companyConstants = {
    COMPANY_GET_REQUEST: 'COMPANY_GET_REQUEST',
    COMPANY_GET_SUCCESS: 'COMPANY_GET_SUCCESS',
    COMPANY_GET_FAILURE: 'COMPANY_GET_FAILURE',

    COMPANY_GET_EMPLOYEES_REQUEST: 'COMPANY_GET_EMPLOYEES_REQUEST',
    COMPANY_GET_EMPLOYEES_SUCCESS: 'COMPANY_GET_EMPLOYEES_SUCCESS',
    COMPANY_GET_EMPLOYEES_FAILURE: 'COMPANY_GET_EMPLOYEES_FAILURE',
    COMPANY_GET_EMPLOYEES_CLEAR: 'COMPANY_GET_EMPLOYEES_CLEAR',
    
    COMPANY_GET_EMPLOYEE_DETAILS: 'COMPANY_GET_EMPLOYEE_DETAILS',

    COMPANY_UPDATE_REQUEST: 'COMPANY_UPDATE_REQUEST',
    COMPANY_UPDATE_SUCCESS: 'COMPANY_UPDATE_SUCCESS',
    COMPANY_UPDATE_FAILURE: 'COMPANY_UPDATE_FAILURE',

    COMPANY_UPDATE_EMPLOYEE_RELATIONSHIP_REQUEST: 'COMPANY_UPDATE_EMPLOYEE_RELATIONSHIP_REQUEST',
    COMPANY_UPDATE_EMPLOYEE_RELATIONSHIP_SUCCESS: 'COMPANY_UPDATE_EMPLOYEE_RELATIONSHIP_SUCCESS',
    COMPANY_UPDATE_EMPLOYEE_RELATIONSHIP_FAILURE: 'COMPANY_UPDATE_EMPLOYEE_RELATIONSHIP_FAILURE',

    COMPANY_ADD_EMPLOYEE_REQUEST: 'COMPANY_ADD_EMPLOYEE_REQUEST',
    COMPANY_ADD_EMPLOYEE_SUCCESS: 'COMPANY_ADD_EMPLOYEE_SUCCESS',
    COMPANY_ADD_EMPLOYEE_FAILURE: 'COMPANY_ADD_EMPLOYEE_FAILURE',

    COMPANY_COMPLETE_REQUEST: 'COMPANY_COMPLETE_REQUEST',
    COMPANY_COMPLETE_SUCCESS: 'COMPANY_COMPLETE_SUCCESS',
    COMPANY_COMPLETE_FAILURE: 'COMPANY_COMPLETE_FAILURE',

    COMPANY_ADD_BILLING_INFO_REQUEST: 'COMPANY_ADD_BILLING_INFO_REQUEST',
    COMPANY_ADD_BILLING_INFO_SUCCESS: 'COMPANY_ADD_BILLING_INFO_SUCCES',
    COMPANY_ADD_BILLING_INFO_FAILURE: 'COMPANY_ADD_BILLING_INFO_FAILURE',

    COMPANY_CANCEL_INVITE_REQUEST: 'COMPANY_CANCEL_INVITE_REQUEST',
    COMPANY_CANCEL_INVITE_SUCCESS: 'COMPANY_CANCEL_INVITE_SUCCESS',
    COMPANY_CANCEL_INVITE_FAILURE: 'COMPANY_CANCEL_INVITE_FAILURE',

    COMPANY_CUSTOM_ROLES_REQUEST: 'COMPANY_CUSTOM_ROLES_REQUEST',
    COMPANY_CUSTOM_ROLES_SUCCESS: 'COMPANY_CUSTOM_ROLES_SUCCESS',
    COMPANY_CUSTOM_ROLES_FAILURE: 'COMPANY_CUSTOM_ROLES_FAILURE',

    COMPANY_CUSTOM_DATA_REQUEST: 'COMPANY_CUSTOM_DATA_REQUEST',
    COMPANY_CUSTOM_DATA_SUCCESS: 'COMPANY_CUSTOM_DATA_SUCCESS',
    COMPANY_CUSTOM_DATA_FAILURE: 'COMPANY_CUSTOM_DATA_FAILURE',

    COMPANY_CATCH_PRICES_REQUEST: 'COMPANY_CATCH_PRICES_REQUEST',
    COMPANY_CATCH_PRICES_SUCCESS: 'COMPANY_CATCH_PRICES_SUCCESS',
    COMPANY_CATCH_PRICES_FAILURE: 'COMPANY_CATCH_PRICES_FAILURE',

    COMPANY_GET_EMPLOYEE_CERTIFICATIONS_REQUEST: 'COMPANY_GET_EMPLOYEE_CERTIFICATIONS_REQUEST',
    COMPANY_GET_EMPLOYEE_CERTIFICATIONS_SUCCESS: 'COMPANY_GET_EMPLOYEE_CERTIFICATIONS_SUCCESS',
    COMPANY_GET_EMPLOYEE_CERTIFICATIONS_FAILURE: 'COMPANY_GET_EMPLOYEE_CERTIFICATIONS_FAILURE',

    COMPANY_ADD_EMPLOYEE_CERTIFICATIONS_REQUEST: 'COMPANY_ADD_EMPLOYEE_CERTIFICATIONS_REQUEST',
    COMPANY_ADD_EMPLOYEE_CERTIFICATIONS_SUCCESS: 'COMPANY_ADD_EMPLOYEE_CERTIFICATIONS_SUCCESS',
    COMPANY_ADD_EMPLOYEE_CERTIFICATIONS_FAILURE: 'COMPANY_ADD_EMPLOYEE_CERTIFICATIONS_FAILURE',
};