import React from 'react';
import styles from './CompleteManualAction.module.css';

import { connect } from 'react-redux';
import { activeActions } from '_actions';
import { ConfirmationModal } from '_components/ConfirmationModal';

class CompleteManualAction extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            verified: false
        };

        this.complete = this.complete.bind(this);
    }

    complete() {
        const { dispatch, currentActionRequirements } = this.props;

        var action_id = currentActionRequirements.action.action_id;
        var content_id = currentActionRequirements.action.content_id;

        dispatch(activeActions.completeAction({ action_id, content_id, selections: [] }));
    }

    render() {
        const { currentActionRequirements } = this.props;
        const { action_template } = currentActionRequirements;

        if(!currentActionRequirements) {
            return null;
        }

        return (
            <div className={styles.container}>
                <div className={styles.headingcontainer}>
                    <h4 className={styles.heading}>Complete Your To Do</h4>
                </div>
                <div className={styles.maincontainer}>
                    <div className={styles.subcontainer}>
                        <div className={styles.subheading}>{action_template.name}</div>
                    </div>
                    <div className={styles.paracontainer}>
                        <div>You have the following to complete:</div>
                        <div className={styles.boldpara}>{action_template.description}</div>
                    </div>
                    <div className={styles.buttoncontainer}>
                        <div>To complete this action, click below.</div>
                        <ConfirmationModal 
                            bodyText="By confirming the completion of this To Do. You are acknowleding that this To Do has been completed to its full extent." 
                            buttonText="Confirm" 
                            confirmCallback={this.complete}/>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {   
    const { active } = state;
    const { currentActionRequirements } = active;
    return {
        currentActionRequirements
    };
}

const connectedCompleteManualAction = connect(mapStateToProps)(CompleteManualAction);
export { connectedCompleteManualAction as CompleteManualAction };