export const userConstants = {
    REGISTER_REQUEST: 'USER_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USER_REGISTER_FAILURE',
    REGISTER_CLEAR: 'USER_REGISTER_CLEAR',

    LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USER_LOGIN_FAILURE',

    UPDATE_REQUEST: 'UPDATE_REQUEST',
    UPDATE_SUCCESS: 'UPDATE_SUCCESS',
    UPDATE_FAILURE: 'UPDATE_FAILURE',
    
    LOGOUT: 'USER_LOGOUT',

    REFRESH_REQUEST: 'USER_REFRESH_REQUEST',
    REFRESH_SUCCESS: 'USER_REFRESH_SUCCESS',
    REFRESH_FAILURE: 'USER_REFRESH_FAILURE',

    GET_PREFERENCES_REQUEST: 'GET_PREFERENCES_REQUEST',
    GET_PREFERENCES_SUCCESS: 'GET_PREFERENCES_SUCCESS',
    GET_PREFERENCES_FAILURE: 'GET_PREFERENCES_FAILURE',

    UPDATE_PREFERENCES_REQUEST: 'UPDATE_PREFERENCES_REQUEST',
    UPDATE_PREFERENCES_SUCCESS: 'UPDATE_PREFERENCES_SUCCESS',
    UPDATE_PREFERENCES_FAILURE: 'UPDATE_PREFERENCES_FAILURE',

    TOGGLE_PREFERENCE: 'TOGGLE_PREFERENCE',
    UPDATE_LANGUAGE_PREFERENCE: 'UPDATE_LANGUAGE_PREFERENCE'
};