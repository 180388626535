export function isPoundsLimit(type) {
    return type === 0;
}

export function getLimitType(type) {
    if(type === 1) {
        return 'Days';
    }

    return 'Pounds';
}