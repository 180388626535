import { authHeader, config, currentCompany, handleResponse, handleError } from '_helpers';

export const companyService = {
    get,
    getEmployeesByFilter,
    getEmployeesBySearch,
    getManagersBySearch,
    update,
    updateEmployeeRelationship,
    updateRelationship,
    addEmployee,
    completeCompany,
    addBillingInfo,
    updateBillingInfo,
    cancelPendingInvite,
    getCustomRoles,
    getCustomData,
    getFileUpload,
    getFileUploadUrl,
    uploadFile,
    getLatestCatchPrices,
    getEmployeeCertifications
};

function get(companyId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${companyId}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            if (response) {
                // store company
                localStorage.setItem('company', JSON.stringify(response));
            }

            return response;
        });
}

function getEmployeesByFilter(filter) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/employees?filter=${filter}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });
}

function getEmployeesBySearch(search) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/employees?search=${search}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });
}

function getManagersBySearch(search) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/managers?search=${search}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });
}

function update(company) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(company)
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            if (response) {
                // store company
                localStorage.setItem('company', JSON.stringify(response));
            }

            return response;
        });
}

function updateEmployeeRelationship(employee) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(employee)
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/employees/${employee.user_id}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });
}

function updateRelationship(employee) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(employee)
    };

    return fetch(config.capiUrl + `/${employee.company_id}/employees`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });
}

function addEmployee(employee) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(employee)
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/employees`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });

}

function addBillingInfo(stripeCustomer) {

    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(stripeCustomer)
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/createstripecustomers`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });

}

function updateBillingInfo(stripeCustomer) {

    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(stripeCustomer)
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/updatestripecustomer`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });

}

function completeCompany(company) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(company)
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });
}

function cancelPendingInvite(employeeId) {
    const requestOptions = {
        method: 'DELETE',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/employees/${employeeId}/invites`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });
}

function getCustomRoles() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/customroles`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });
}

function getCustomData(key) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/customdata/${key}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });
}

function getFileUpload(uploadId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/uploads/${uploadId}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });
}

function getFileUploadUrl(uploadId, fileExtension) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/uploads/${uploadId}/url?fileExtension=${encodeURIComponent(fileExtension)}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });
}

function uploadFile(fileUpload) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(fileUpload)
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/uploads/${fileUpload.file_upload_id}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });
}

function getLatestCatchPrices() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/metrics/todayscatch`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });
}

function getEmployeeCertifications(userId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.capiUrl + `/${currentCompany().company_id}/employees/${userId}/certifications`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {
            return response;
        });
}