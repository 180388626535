import React from 'react';
import styles from './WelcomePage.module.css';

import welcomepic2 from '_images/welcome_pic2.png';
import welcomepic3 from '_images/welcome_pic3.png';
import desktopshot from '_images/macshotnew.png';
import appleStore from '_images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import googleStore from '_images/google-play-badge.png';

import { connect } from 'react-redux';
import { StickyContainer, Sticky } from 'react-sticky';
import { GuestNavBar } from '_components/NavigationBar/GuestNavBar';
import { Helmet } from 'react-helmet';
import { alertActions, userActions } from '_actions';

class WelcomePage extends React.Component {
    constructor(props) {
        super(props);

        // reset login status
        this.props.dispatch(userActions.logout(true));
    }

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(alertActions.clear());
    }

    render() {
        return (
            <StickyContainer>
                <Helmet> 
                    <title>TackleDocs: Stay Organized, Speed up company operations.</title>
                    <meta name="description" content="Tackle your documents Manage your business from your smartphone. Generate Invoices, Documents and Customer agreements on the fly! Tackle your documents Keep organized with our Company Calendar. Our platform was designed to be used on a mobile device. It works
                in Chrome, Safari, and Firefox. All features are easily accessible on a mobile site so you can keep working on the go." />
                </Helmet>
                <Sticky topOffset={0}>{({ style }) => <GuestNavBar style={style} sticky={true}/>}</Sticky>
                <div className={styles.main}>
                    <div className={styles.container}>
                    <div className={styles.sidebar}>
                    <h3 className={styles.paraDesktop}>Tackle Your Documents. </h3>
                    <h5 className={styles.picSubHeader}>Struggle keeping track of paperwork and company tasks? We can help. </h5>
                            <img className={styles.pic} src={desktopshot} width="1090" height="702" alt="TackleDocs, Tackledocs, tackledocs, tackle docs"/>
                            <p className={styles.paraDesktopSubText}>Tackle your documents! Manage your business from your smartphone. Generate Invoices, Documents and Customer agreements on the fly! 
                            Our platform was designed to be used on a mobile device. It works in Chrome, Safari, and Firefox. Access tackledocs from a tablet, smartphone or desktop, as long as you have a internet connection. 
                            </p>
                    </div>
                    <div className={styles.sidebar}> 
                            <div>
                            <h4 className={styles.paraDesktop}>Stay Organized</h4>
                            <h5 className={styles.picSubHeader}>With Our Company Calendar </h5>
                            </div>
                            <img className={styles.pic} src={welcomepic2} width="550" height="550" alt="Small Business Calendar"/>
                            <p className={styles.para}> Stay organized using our company calendar. Generate reminders, create tasks for employees. 
                            Never let another certification, safety check, or training expire again. Stay ontop of your business by keeping up to date on what needs to be done today, this week, this year.</p>
                        </div>
                        <div className={styles.sidebar}>
                            <div>
                            <h4 className={styles.paraDesktop}>Easily Accessible </h4>
                            <h5 className={styles.picSubHeader}>Company Operations</h5>
                            </div>
                            <img className={styles.pic} src={welcomepic3} width="400" height="400" alt="TackleDocs, Tackledocs, tackledocs, tackle docs"/>
                            <p className={styles.para}>Easy, scalable small business management right from your smart phone. All employees can log in from their smartphone. The mobile site is available
                            anywhere you have an internet connection. Emails and reminders get sent to your clients and employees ahead of time so you can avoid roadblocks and keep your company runnning 
                            smoothly.</p>
                        </div>
                        <div className={styles.sidebar}>
                            <div>
                                <h4 className={styles.paraDesktop}>Download Now </h4>
                                <h5 className={styles.picSubHeader}>On All Mobile Platforms</h5>
                            </div>
                            <div className={styles.storesContainer}>
                                <a className={styles.badgea} href="https://apps.apple.com/us/app/tackledocs/id1494979921?ls=1">
                                    <img className={styles.badgea} src={appleStore} alt="TackleDocs"/>
                                </a>
                                <a className={styles.badgeg} href="https://play.google.com/store/apps/details?id=com.absqrd.TackleDocs2">
                                    <img className={styles.badgeg} src={googleStore} alt="TackleDocs"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </StickyContainer> 
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

const connectedWelcomePage = connect(mapStateToProps)(WelcomePage);
export { connectedWelcomePage as WelcomePage };