import React from 'react';
import styles from './AlertDisplay.module.css';

import { connect } from 'react-redux';
import { Alert } from 'reactstrap';

class AlertDisplay extends React.Component {
    render() {
        const { message, type } = this.props;

        if(!message) {
            return null;
        }

        return (
            <Alert className={styles.display} color={type}>{message}</Alert>
        );
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    const { message, type } = alert;

    return {
        message, type
    };
}

const connectedAlertDisplay = connect(mapStateToProps)(AlertDisplay);
export { connectedAlertDisplay as AlertDisplay };