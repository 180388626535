import React from 'react';
import styles from './CompanyTasksByEmployeePage.module.css';
import { TaskEmployeeTable } from '_components/TaskEmployeeTable';

import { connect } from 'react-redux';

class CompanyTasksByEmployeePage extends React.Component {  
    render() {
        const { company } = this.props;

        return (
            <div className={styles.container}>
                <div className={styles.headingcontainer}>
                    <h4 className={styles.header}>{company.company_name} Tasks</h4>
                </div>
                <TaskEmployeeTable/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { company } = state;

    return {
        company
    };
}

const connectedCompanyTasksByEmployeePage = connect(mapStateToProps)(CompanyTasksByEmployeePage);
export { connectedCompanyTasksByEmployeePage as CompanyTasksByEmployeePage };