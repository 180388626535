import React from 'react';
import styles from './EditAssetTemplatePage.module.css';

import { connect } from 'react-redux';
import { alertActions, assetActions } from '_actions';
import { Container, Input, Form, FormGroup, Button, ListGroup, ListGroupItem } from 'reactstrap';
import { ConfirmationModal } from '_components/ConfirmationModal';

class EditAssetTemplatePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            type: props.currentAssetTemplate.type,
            dataKeys: props.currentAssetTemplate.data_keys,
            currentDataKey: null
        };

        this.handleAdd = this.handleAdd.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleDelete(event) {
        const { dataKeys } = this.state;
        var list = [];
        for(var i = 0; i< dataKeys.length; i++) {
            if(dataKeys[i] !== event.target.id) {
                list.push(dataKeys[i]);
            }
        }

        this.setState({
            dataKeys: list
        });
    }

    handleAdd() {
        const { dataKeys, currentDataKey } = this.state;

        var valid = true;
        dataKeys.forEach(dk => {
            if(dk === currentDataKey) {
                valid = false;
            }
        });

        if(currentDataKey && valid) {
            dataKeys.push(currentDataKey);
        }

        this.setState({
            currentDataKey: null
        });
    }

    onSubmit() {
        const { dispatch, currentAssetTemplate } = this.props;
        const { type, dataKeys } = this.state;
        
        if (type) {
            currentAssetTemplate.type = type;
            currentAssetTemplate.data_keys = dataKeys;

            dispatch(assetActions.updateAssetTemplate(currentAssetTemplate));
        } else {
            //handle fail
            dispatch(alertActions.error("A type is required to be entered"));
        }       
    }

    onDelete() {
        const { dispatch, currentAssetTemplate } = this.props;
        
        currentAssetTemplate.status = 0;

        dispatch(assetActions.updateAssetTemplate(currentAssetTemplate));
    }

    render() {
        const { currentDataKey, dataKeys, type } = this.state;

        return (
            <div className={styles.container}>
                <Container className={styles.main}>
                    <div className={styles.headingcontainer}>
                        <h3 className={styles.heading}>Editing {type}</h3>
                    </div>
                    <div className={styles.typecontainer}>
                        <h4 className={styles.subheading}>Asset Type</h4>
                        <Input id="type" readOnly value={type || ''} onChange={this.handleChange}/>
                    </div>
                    <Form className={styles.form}>
                        <FormGroup>
                            <h4 className={styles.subheading}>Add Attribute</h4>
                            <div className={styles.formsubgroup}>
                                <Input className={styles.input} id="currentDataKey" value={currentDataKey || ''} onChange={this.handleChange}/>
                                <Button className={styles.add} id="addAttribute" onClick={this.handleAdd}>Add</Button>
                            </div>
                        </FormGroup>
                        <h4 className={styles.subheading}>Asset Attributes</h4>
                        <ListGroup className={styles.listgroup}>
                        {dataKeys && dataKeys.length > 0 ?
                            dataKeys.map(dk => {
                                return <ListGroupItem className={styles.listgroupitem} key={dk}>{dk} <Button color="danger" id={dk} onClick={this.handleDelete}>X</Button></ListGroupItem>
                            })
                            :
                            <div className={styles.nodatacontainer}>
                                <span className={styles.title}>Heads up!</span>
                                <span className={styles.para}>No attributes added</span>
                            </div>
                        }
                        </ListGroup>   
                        <FormGroup className={styles.formsubgroup}>
                            <Button className={styles.button}  color="success" onClick={this.onSubmit}>Submit</Button>
                            <ConfirmationModal  bodyText={`By confirming you will be deleting ${type} from your company and all assets that are of that type.`}
                                                buttonColor="danger"
                                                buttonText="Delete"
                                                confirmCallback={this.onDelete}
                                            />
                        </FormGroup>
                    </Form>
                </Container>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { asset } = state;
    const { currentAssetTemplate } = asset;
    return {
        currentAssetTemplate
    };
}

const connectedEditAssetTemplatePage = connect(mapStateToProps)(EditAssetTemplatePage);
export { connectedEditAssetTemplatePage as EditAssetTemplatePage };