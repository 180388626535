import React from 'react';
import styles from './CompanyCalendarPage.module.css';

import Toggle from 'react-toggle';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { Button } from 'reactstrap';
import { ReminderDetailsModal } from '_components/ReminderDetailsModal';
import { reminderActions } from '_actions'; 
import { convertDate, currentCompanyRole } from '_helpers';

const localizer = momentLocalizer(moment);
class CompanyCalendarPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false
        }

        this.eventStyleGetter = this.eventStyleGetter.bind(this);
        this.handleDateClick = this.handleDateClick.bind(this);
        this.handleEventClick = this.handleEventClick.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    closeModal() {
        this.setState({
            showModal: false
        })
    }

    eventStyleGetter(event) {
        var backgroundColor = event.status === 3 ? '#26a749' : 'primary';
        var style = {
            backgroundColor: backgroundColor
        };
        return {
            style: style
        };
    }
    
    handleEventClick(e) {
        const { dispatch } = this.props;
        dispatch(reminderActions.getReminderDetails(e.rid));
        
        this.setState({
            showModal: true
        })
    }

    handleDateClick(e) {
        const { dispatch } = this.props;
        var today = new Date();
        
        if( e.end.getFullYear() >= today.getFullYear() &&
            e.end.getMonth() >= today.getMonth() &&
            e.end.getDate() >= today.getDate() &&
            currentCompanyRole() >= 2) 
        {
            dispatch(reminderActions.navigateCreateReminder(e.end));
        }
    }

    handleToggle(e) {
        const { dispatch } = this.props;

        if(e.target.checked) {
            dispatch(reminderActions.getUserReminders('all'));
        } else {
            dispatch(reminderActions.getCompanyReminders('all'));
        }
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(reminderActions.getCompanyReminders('all'));
    }

    render() {
        const { company, reminders } = this.props;
        const { showModal } = this.state;

        var events = reminders ? 
        reminders.filter(r => r.status === 2 || r.status === 3).map(r => {
            return {
                start: convertDate(r.end_date),      
                end: convertDate(r.end_date),
                title: r.heading,
                rid: r.reminder_id,
                status: r.status
            }
        }) : [];

        return (
            <div className={styles.container}>
                <div className={styles.headingcontainer}>
                    <h4 className={styles.header}>{company.company_name}'s Calendar</h4>
                    {currentCompanyRole() >= 2 && 
                    <div>
                        <LinkContainer to='/company/reminder/create' className={styles.headingbutton}>
                            <Button color="success">Add Reminder</Button>
                        </LinkContainer>
                        <LinkContainer to='/company/reminders/log/create' className={styles.headingbutton}>
                            <Button color="success">Add Log Entry</Button>
                        </LinkContainer>
                        <LinkContainer to='/company/reminders/log' className={styles.headingbutton}>
                            <Button color="success">Logs</Button>
                        </LinkContainer>
                    </div>
                    }
                </div>
                <div className={styles.calendarcontainer}>
                    <div className={styles.togglecontainer}>
                            <Toggle aria-labelledby='toggler-label' onChange={this.handleToggle}/>
                            <span id='toggler-label' className={styles.label}>Just My Reminders</span>
                    </div>
                    <ReminderDetailsModal forceModal={showModal} closeModal={this.closeModal}/>
                    <Calendar   className={styles.calendar}
                                defaultDate={new Date()}
                                defaultView="month"
                                events={events}
                                localizer={localizer}
                                onSelectEvent={this.handleEventClick}
                                views={["month"]}
                                selectable={true}
                                onSelectSlot={this.handleDateClick}
                                eventPropGetter={this.eventStyleGetter}/>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { company, reminder } = state;
    const { currentReminder, reminders } = reminder;

    return {
        company,
        currentReminder,
        reminders
    };
}

const connectedCompanyCalendarPage = connect(mapStateToProps)(CompanyCalendarPage);
export { connectedCompanyCalendarPage as CompanyCalendarPage };