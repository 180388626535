export const adminConstants = {
    UPDATE_COMPANY_SELECTION: 'UPDATE_COMPANY_SELECTION',

    GET_COMPANIES_REQUEST: 'GET_COMPANIES_REQUEST',
    GET_COMPANIES_SUCCESS: 'GET_COMPANIES_SUCCESS',
    GET_COMPANIES_FAILURE: 'GET_COMPANIES_FAILURE',

    CREATE_COMPANY_REQUEST: 'CREATE_COMPANY_REQUEST',
    CREATE_COMPANY_SUCCESS: 'CREATE_COMPANY_SUCCESS',
    CREATE_COMPANY_FAILURE: 'CREATE_COMPANY_FAILURE',

    DEACTIVATE_COMPANY_REQUEST: 'DEACTIVATE_COMPANY_REQUEST',
    DEACTIVATE_COMPANY_SUCCESS: 'DEACTIVATE_COMPANY_SUCCESS',
    DEACTIVATE_COMPANY_FAILURE: 'DEACTIVATE_COMPANY_FAILURE',

    GET_QUESTIONS_REQUEST: 'GET_QUESTIONS_REQUEST',
    GET_QUESTIONS_SUCCESS: 'GET_QUESTIONS_SUCCESS',
    GET_QUESTIONS_FAILURE: 'GET_QUESTIONS_FAILURE',

    CREATE_QUESTION_REQUEST: 'CREATE_QUESTION_REQUEST',
    CREATE_QUESTION_SUCCESS: 'CREATE_QUESTION_SUCCESS',
    CREATE_QUESTION_FAILURE: 'CREATE_QUESTION_FAILURE',

    DEACTIVATE_QUESTION_REQUEST: 'DEACTIVATE_QUESTION_REQUEST',
    DEACTIVATE_QUESTION_SUCCESS: 'DEACTIVATE_QUESTION_SUCCESS',
    DEACTIVATE_QUESTION_FAILURE: 'DEACTIVATE_QUESTION_FAILURE',

    GET_DOCUMENT_TEMPLATE_REQUEST: 'GET_DOCUMENT_TEMPLATE_REQUEST',
    GET_DOCUMENT_TEMPLATE_SUCCESS: 'GET_DOCUMENT_TEMPLATE_SUCCESS',
    GET_DOCUMENT_TEMPLATE_FAILURE: 'GET_DOCUMENT_TEMPLATE_FAILURE',

    CREATE_DOCUMENT_TEMPLATE_REQUEST: 'CREATE_DOCUMENT_TEMPLATE_REQUEST',
    CREATE_DOCUMENT_TEMPLATE_SUCCESS: 'CREATE_DOCUMENT_TEMPLATE_SUCCESS',
    CREATE_DOCUMENT_TEMPLATE_FAILURE: 'CREATE_DOCUMENT_TEMPLATE_FAILURE',

    DEACTIVATE_DOCUMENT_TEMPLATE_REQUEST: 'DEACTIVATE_DOCUMENT_TEMPLATE_REQUEST',
    DEACTIVATE_DOCUMENT_TEMPLATE_SUCCESS: 'DEACTIVATE_DOCUMENT_TEMPLATE_SUCCESS',
    DEACTIVATE_DOCUMENT_TEMPLATE_FAILURE: 'DEACTIVATE_DOCUMENT_TEMPLATE_FAILURE',

    GET_ACTION_TEMPLATE_REQUEST: 'GET_ACTION_TEMPLATE_REQUEST',
    GET_ACTION_TEMPLATE_SUCCESS: 'GET_ACTION_TEMPLATE_SUCCESS',
    GET_ACTION_TEMPLATE_FAILURE: 'GET_ACTION_TEMPLATE_FAILURE',

    CREATE_ACTION_TEMPLATE_REQUEST: 'CREATE_ACTION_TEMPLATE_REQUEST',
    CREATE_ACTION_TEMPLATE_SUCCESS: 'CREATE_ACTION_TEMPLATE_SUCCESS',
    CREATE_ACTION_TEMPLATE_FAILURE: 'CREATE_ACTION_TEMPLATE_FAILURE',

    DEACTIVATE_ACTION_TEMPLATE_REQUEST: 'DEACTIVATE_ACTION_TEMPLATE_REQUEST',
    DEACTIVATE_ACTION_TEMPLATE_SUCCESS: 'DEACTIVATE_ACTION_TEMPLATE_SUCCESS',
    DEACTIVATE_ACTION_TEMPLATE_FAILURE: 'DEACTIVATE_ACTION_TEMPLATE_FAILURE',

    GET_TASK_TEMPLATE_REQUEST: 'GET_TASK_TEMPLATE_REQUEST',
    GET_TASK_TEMPLATE_SUCCESS: 'GET_TASK_TEMPLATE_SUCCESS',
    GET_TASK_TEMPLATE_FAILURE: 'GET_TASK_TEMPLATE_FAILURE',

    CREATE_TASK_TEMPLATE_REQUEST: 'CREATE_TASK_TEMPLATE_REQUEST',
    CREATE_TASK_TEMPLATE_SUCCESS: 'CREATE_TASK_TEMPLATE_SUCCESS',
    CREATE_TASK_TEMPLATE_FAILURE: 'CREATE_TASK_TEMPLATE_FAILURE',

    DEACTIVATE_TASK_TEMPLATE_REQUEST: 'DEACTIVATE_TASK_TEMPLATE_REQUEST',
    DEACTIVATE_TASK_TEMPLATE_SUCCESS: 'DEACTIVATE_TASK_TEMPLATE_SUCCESS',
    DEACTIVATE_TASK_TEMPLATE_FAILURE: 'DEACTIVATE_TASK_TEMPLATE_FAILURE',
}