import React from 'react';
import styles from './EditAssetPage.module.css';

import { connect } from 'react-redux';
import { assetActions } from '_actions';
import { Container, Input, Label } from 'reactstrap';
import { AssetTemplateRequirements } from '_components/AssetTemplateRequirements';

class EditAssetPage extends React.Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        const { dispatch, currentAsset } = this.props;
        dispatch(assetActions.getAssetTemplate(currentAsset.type));
    }

    handleChange(e) {
        const { dispatch } = this.props;
   
        var index = e.target.selectedIndex;
        var selectedOption = e.target.childNodes[index];

        dispatch(assetActions.getAssetTemplate(selectedOption.id));
    }

    render() {
        const { currentAsset } = this.props;

        if(!currentAsset) {
            return null;
        }

        return (
            <div className={styles.container}>
                <Container className={styles.main}>
                    <div className={styles.headingcontainer}>
                        <h4 className={styles.heading}>{currentAsset.name}</h4>
                    </div>
                    <div className={styles.typecontainer}>
                        <div>
                            <Label className={styles.subheading}>Asset Type</Label>
                        </div>
                        <Input readOnly type="text" value={currentAsset.type}></Input>
                    </div>
                    <AssetTemplateRequirements></AssetTemplateRequirements>
                </Container>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { asset } = state;
    const { currentAsset } = asset;
    return {
        currentAsset
    };
}

const connectedEditAssetPage = connect(mapStateToProps)(EditAssetPage);
export { connectedEditAssetPage as EditAssetPage };