import React from 'react';
import checked from '_images/thumbsup.png';
import styles from './AssetTypeTable.module.css';

import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { formatShortDateTime, status, customTableTheme } from '_helpers';
import { assetActions } from '_actions';
import { ConfirmationModal } from '_components/ConfirmationModal';
import { FaEdit, FaTrash } from 'react-icons/fa';

class AssetTypeTable extends React.Component {
    constructor(props) {
        super(props);

        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    columns = [
        {
            name: 'Type',
            selector: 'type',
            sortable: true,    
            wrap: true
        },
        {
            name: 'Last Updated',
            selector: 'last_updated',
            sortable: true,
            wrap: true
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: true,
            wrap: true,
            hide: 'sm'
        },
        {
            name: '',
            grow: 0,
            right: true,
            cell: row => <FaEdit className={styles.edit} attype={row.id} onClick={this.handleEdit}/>
        },
        {
            name: '',
            grow: 0,
            right: true,
            hide: 'sm',
            cell: row => <ConfirmationModal bodyText={`By confirming you will be deleting ${row.type} from your company and all assets that are of that type.`} 
                                            clickable={<FaTrash className={styles.edit}/>} 
                                            confirmCallback={this.handleDelete} 
                                            data={row.ref}
                                            />
        }
    ];

    handleEdit(event) {
        const { dispatch } = this.props;
        
        dispatch(assetActions.getAssetTemplate(event.currentTarget.getAttribute('attype'), true));
    }

    handleDelete({data}) {
        const { dispatch } = this.props;

        data.status = 0;
        
        dispatch(assetActions.updateAssetTemplate(data));
        dispatch(assetActions.getAssetTemplates('active'));
    }

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(assetActions.getAssetTemplates('active'));
    }

    render() {
        const { assetTemplates } = this.props;
        var data = assetTemplates.map(a => {
            return {
                id: a.type,
                type: a.type,
                last_updated: formatShortDateTime(a.last_update_date),
                status: status(a.status),
                ref: a
            };
        });

        return (
            <div className={styles.container}>
                <DataTable
                    columns={this.columns}
                    data={data}
                    striped
                    fixedHeader
                    fixedHeaderScrollHeight='500px'
                    customTheme={customTableTheme}
                    noHeader
                    noDataComponent={
                        <div className={styles.nodatacontainer}>
                            <span className={styles.heading}>No Assets!</span>
                            <span className={styles.para}>You have nothing to view at this time.</span>
                            <img className={styles.checkedimg} src={checked} alt=":("/>
                        </div>
                    }
                />
            </div>        
        );
    }
}

function mapStateToProps(state) {
    const { asset } = state;
    const { assetTemplates, fetchingAssets } = asset;
    return {
        assetTemplates,
        fetchingAssets
    };
}

const connectedAssetTypeTable = connect(mapStateToProps)(AssetTypeTable);
export { connectedAssetTypeTable as AssetTypeTable };