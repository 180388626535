import { currentUser } from "./user";

export function defaultPreferences() {
    return {
        user_id: currentUser().user_id,
        receive_notifications: true,
        receive_reminder_notifications: true,
        preferred_language: 'en-US'
    };
};

export function supportedLanguages() {
    return [
        { lang_code: "zh-CN", lang_name:"Chinese (PRC)" },
        { lang_code: "zh-TW", lang_name:"Chinese (Taiwan)" },
        { lang_code: "nl", lang_name:"Dutch" },
        { lang_code: "en-US", lang_name:"English" },
        { lang_code: "en-GB", lang_name:"English (Great Britain)" },
        { lang_code: "fi", lang_name:"Finnish" },
        { lang_code: "fr", lang_name:"French" },
        { lang_code: "de", lang_name:"German" },
        { lang_code: "it", lang_name:"Italian" },
        { lang_code: "ja", lang_name:"Japanese" },
        { lang_code: "ko", lang_name:"Korean" },
        { lang_code: "pl", lang_name:"Polish" },
        { lang_code: "pt-BR", lang_name:"Portuguese (Brazil)" },
        { lang_code: "pt", lang_name:"Portuguese (Portugal)" },
        { lang_code: "ru", lang_name:"Russian" },
        { lang_code: "es-MX", lang_name:"Spanish (Mexico)" },
        { lang_code: "es", lang_name:"Spanish (Spain)" },
        { lang_code: "sv", lang_name:"Swedish" }
    ];
};

export function getLanguageForCode(code) {
    var langs = supportedLanguages();

    for(var i = 0; i < langs.length; i++) {
        if(langs[i].lang_code === code) {
            return langs[i].lang_name;
        }
    }
    
    return 'English';
}

//local preferences
const localPrefKey = 'localPrefs';

export function getLocalPref(key) {
    var storedPrefs = localStorage.getItem(localPrefKey);
    var parsedPreferences = JSON.parse(storedPrefs);

    if(parsedPreferences) {
        return parsedPreferences[key] || null;
    }

    return null;
}

export function updateLocalPref(key, value) {
    var storedPrefs = localStorage.getItem(localPrefKey);
    var parsedPreferences = JSON.parse(storedPrefs);

    if(!parsedPreferences) {
        parsedPreferences = {};
    }

    parsedPreferences[key] = value;

    localStorage.setItem(localPrefKey, JSON.stringify(parsedPreferences));
}