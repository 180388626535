import { relationshipConstants, userConstants } from '_constants';

let storedRelationships = JSON.parse(localStorage.getItem('relationships'));

export function relationships(state = storedRelationships || [], reduxAction) {
    switch (reduxAction.type) {
        case userConstants.REGISTER_SUCCESS:
            return [];
        case userConstants.LOGIN_SUCCESS:
        case userConstants.REFRESH_SUCCESS:
            return reduxAction.response.relationships || [];
        case relationshipConstants.RELATIONSHIPS_GET_SUCCESS:
            return reduxAction.relationships || [];      
        case userConstants.LOGIN_REQUEST:
        case userConstants.LOGIN_FAILURE:
        case userConstants.REGISTER_REQUEST:
        case userConstants.REGISTER_FAILURE:
        case relationshipConstants.RELATIONSHIPS_GET_REQUEST:
        case relationshipConstants.RELATIONSHIPS_GET_FAILURE:
            return [];
        default:
            return state
    }
}