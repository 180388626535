import React from 'react';
import styles from './AdminPage.module.css';

import { connect } from 'react-redux';
import { Button, Container, FormGroup, Input, Label } from 'reactstrap';
import { adminActions, templateActions, alertActions } from '_actions';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

class AdminCreateTaskTemplatePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedActionId: '',
            taskTemplate: {
                description: '',
                name: '',
                custom_name_prefix: '',
                custom_name: false,
                required_asset_type: '',
                requires_asset: false,
                requires_employees: false,
                manager_is_employee: false,
                start_notification: false,
                end_notification: false,
                task_action_templates: []
            }
        };

        this.addActionTemplateToItemList = this.addActionTemplateToItemList.bind(this);
        this.createTaskActionTemplateComboItems = this.createTaskActionTemplateComboItems.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    addActionTemplateToItemList(actionTemplate) {
        const { taskTemplate } = this.state;

        const taskActionTemplate = {
            id: actionTemplate.action_template_id, 
            content: actionTemplate.name
        };

        var taskActionTemplates = taskTemplate.task_action_templates;
        taskActionTemplates.push(taskActionTemplate);

        this.setState({
            taskTemplate: {
                ...taskTemplate,
                task_action_templates: taskActionTemplates
            }
        });
    }
    
    createTaskActionTemplateComboItems() {
        const { actionTemplates } = this.props;

        const comboItems = [];
        for (const obj of actionTemplates) {
            comboItems.push(<option key={obj.action_template_id} value={obj.action_template_id}>{obj.name} -- {obj.description}</option>);
        }
        
        return comboItems;
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { selectedActionId, taskTemplate } = this.state;
        const { actionTemplates } = this.props;

        if(name === "selectaction") {
            this.setState({
                selectedActionId: value
            });
        } else if(name === "addaction") {
            var actionTemplateLookup = actionTemplates.reduce(function(map, obj) {
                map[obj.action_template_id] = obj;
                return map;
            }, {});

            var at = actionTemplateLookup[selectedActionId];
            this.addActionTemplateToItemList(at);
        } else {
            this.setState({
                taskTemplate: {
                    ...taskTemplate,
                    [name]: value
                }
            });
        }
    }
    
    handleSubmit(event) {
        event.preventDefault();

        const { taskTemplate } = this.state;
        const { dispatch, company } = this.props;

        if(company && company.company_id && taskTemplate.name && taskTemplate.description 
            && taskTemplate.manager_is_employee && taskTemplate.start_notification && taskTemplate.end_notification) {
            dispatch(adminActions.createTaskTemplate(company.company_id, taskTemplate));
        } else {
            dispatch(alertActions.error('Make sure everything is filled in'));
        }
    }

    onDragEnd(result) {
        const { taskTemplate } = this.state;

        // dropped outside the list
        if (!result.destination) {
            return;
        }
        
        taskTemplate.task_action_templates = reorder(
            taskTemplate.task_action_templates,
            result.source.index,
            result.destination.index
        );
        
        this.setState({
            taskTemplate
        });
    }

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(templateActions.getActionTemplates('active'));
    }

    render() {
        const { company } = this.props;
        const { taskTemplate } = this.state;
        
        return (
            <div className={styles.container}>
                <div className={styles.headingcontainer}>
                    <h4 className={styles.header}>Create Task Template For {company.company_name}</h4>
                </div>
                <Container>
                    <FormGroup>
                        <Label>Name</Label>
                        <Input name="name" onChange={this.handleChange}/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Description</Label>
                        <Input name="description" onChange={this.handleChange}/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Custom Name Prefix</Label>
                        <Input name="custom_name_prefix" onChange={this.handleChange}/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Required Asset Type</Label>
                        <Input name="required_asset_type" onChange={this.handleChange}/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Custom Name (boolean)</Label>
                        <Input name="custom_name" onChange={this.handleChange}/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Requires Asset (boolean)</Label>
                        <Input name="requires_asset" onChange={this.handleChange}/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Requires Employees (boolean)</Label>
                        <Input name="requires_employees" onChange={this.handleChange}/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Manager Is Employee (boolean)</Label>
                        <Input name="manager_is_employee" onChange={this.handleChange}/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Start Notification (boolean)</Label>
                        <Input name="start_notification" onChange={this.handleChange}/>
                    </FormGroup>
                    <FormGroup>
                        <Label>End Notification (boolean)</Label>
                        <Input name="end_notification" onChange={this.handleChange}/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Select An Action</Label>                                                             
                        <Input type="select" defaultValue={'Select an action...'} name="selectaction" onChange={this.handleChange}>
                            <option readOnly disabled value={'Select an action...'}>Select an action...</option>
                            {this.createTaskActionTemplateComboItems()}
                        </Input>
                        <Button name="addaction" onClick={this.handleChange}>Add</Button>
                    </FormGroup>
                </Container>
                <div className={styles.headingcontainer}>
                    <h4 className={styles.header}>Ordered Actions</h4>
                </div>
                <Container>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                                {taskTemplate.task_action_templates.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                        {(provided, snapshot) => (
                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                                                {item.content}
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                        </Droppable>
                    </DragDropContext>
                    <Button color="primary" onClick={this.handleSubmit}>Submit</Button>
                </Container>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { admin, template } = state;
    const { actionTemplates} = template;
    const { company } = admin;
        
    return {
        actionTemplates,
        company
    };
}

const connectedAdminCreateTaskTemplatePage = connect(mapStateToProps)(AdminCreateTaskTemplatePage);
export { connectedAdminCreateTaskTemplatePage as AdminCreateTaskTemplatePage };

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'white' : 'white',
    padding: grid,
    width: 1000
});

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  
  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'lightgreen',

  // styles we need to apply on draggables
  ...draggableStyle,
});