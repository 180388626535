import React from 'react';
import styles from './CompanySelector.module.css';

import { connect } from 'react-redux';
import { Input, Label } from 'reactstrap';
import { adminActions } from '_actions';

class CompanySelector extends React.Component {
    constructor(props) {
        super(props);
        
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const { dispatch } = this.props;
        
        var index = event.target.selectedIndex;
        var companyId = event.target.childNodes[index].getAttribute('cid');

        dispatch(adminActions.updateCompanySelection(companyId));
    }

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(adminActions.getCompanies('all'));
    }

    render() {
        const { companies } = this.props;

        return (
            <div className={styles.container}>
                <Label>Companies</Label>
                <Input type='select' defaultValue={"Select a company..."} onChange={this.handleChange}>
                    <option key={'select'} disabled readOnly value={"Select a company..."}>Select a company...</option>
                    {companies.map(c => {
                        return <option key={c.company_id} cid={c.company_id}>{c.company_name}</option>
                    })
                    }
                </Input>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { admin } = state;
    const { companies, company } = admin;

    return {
        companies, company
    };
}

const connectedCompanySelector = connect(mapStateToProps)(CompanySelector);
export { connectedCompanySelector as CompanySelector };