import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { document } from './document.reducer';
import { alert } from './alert.reducer';
import { template } from './template.reducer';
import { active } from './active.reducer';
import { company } from './company.reducer';
import { relationships } from './relationships.reducer';
import { asset } from './asset.reducer';
import { reminder } from './reminder.reducer';
import { admin } from './admin.reducer';
import {form } from './form.reducer';

const rootReducer = combineReducers({
  authentication,
  registration,
  document,
  alert,
  template,
  active,
  company,
  relationships,
  asset,
  reminder,
  admin,
  form
});

export default rootReducer;