import React from 'react';
import styles from './PreferencesForm.module.css';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Button, Label, Form, FormGroup, Input } from 'reactstrap';
import { userActions } from '_actions';
import { getLanguageForCode, supportedLanguages } from '_helpers';

class PreferencesForm extends React.Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleClick(e) {
        const { dispatch } = this.props;
        var name = e.target.name || e.target.getAttribute('name');
        dispatch(userActions.togglePreference(name));
    }

    handleLanguageChange(e) {
        const { dispatch } = this.props;

        var index = e.target.selectedIndex;
        var selectedOption = e.target.childNodes[index];
        var code =  selectedOption.id;

        dispatch(userActions.updateLanguagePref(code));
    }

    handleSubmit(e) {
        e.preventDefault();
        const { dispatch, preferences, uid, huid } = this.props;

        if(uid && huid) {
            dispatch(userActions.updatePreferencesAnon(uid, huid, preferences));
        } else {
            dispatch(userActions.updatePreferences(preferences));
        }
    }

    componentDidMount() {
        const { dispatch, user, uid, huid } = this.props;

        if(uid && huid) {
            dispatch(userActions.getPreferencesAnon(uid, huid));
        } else {
            dispatch(userActions.getPreferences(user.user_id));
        }
    }

    render() {
        const { preferences } = this.props;

        if(!preferences) {
            return null;
        }

        return (
            <Form onSubmit={this.handleSubmit} className={styles.form}>
                <div className={styles.subcontainer}>
                    <h4>Notification Preferences</h4>
                    <FormGroup check>
                        <Input type="checkbox" name="receive_notifications" checked={preferences.receive_notifications} onChange={this.handleClick}></Input>
                        <Label className={styles.noselect} name="receive_notifications" onClick={this.handleClick}>Receive all types of notifications (To Do's, Task, etc...)</Label>
                    </FormGroup>
                    <FormGroup check>
                        <Input type="checkbox" name="receive_reminder_notifications" checked={preferences.receive_reminder_notifications} onChange={this.handleClick}></Input>
                        <Label className={styles.noselect} name="receive_reminder_notifications" onClick={this.handleClick}>Receive Reminder Notifications</Label>
                    </FormGroup>
                </div>
                <div className={styles.subcontainer}>
                    <h4>Locale Preferences</h4>
                    <FormGroup>
                        <Label>Preferred Language</Label>
                        <Input type="select" value={getLanguageForCode(preferences.preferred_language)} onChange={this.handleLanguageChange}>
                        {
                            supportedLanguages().map(o => {
                                return <option key={o.lang_code} id={o.lang_code}>{o.lang_name}</option>
                            })
                        }
                        </Input>
                    </FormGroup>
                </div>
                <FormGroup className={styles.container}>
                    <Button color="primary" className={styles.submit}>Update Preferences</Button>
                </FormGroup>
            </Form>
        );
    }
}

PreferencesForm.propTypes = {
    uid: PropTypes.string,
    huid: PropTypes.string
};

function mapStateToProps(state) {
    const { preferences, user } = state.authentication;

    return {
        user,
        preferences
    };
}

const connectedPreferencesForm = connect(mapStateToProps)(PreferencesForm);
export { connectedPreferencesForm as PreferencesForm };