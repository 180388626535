import React from 'react';
import styles from './AssetTemplateRequirements.module.css';

import { connect } from 'react-redux';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { ConfirmationModal } from '_components/ConfirmationModal';
import { EmployeeSelector } from '_components/EmployeeSelector';
import { assetActions, alertActions, } from '_actions';

class AssetTemplateRequirements extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: props.currentAsset ? props.currentAsset.name : null,
            managers: []
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleManagerChange = this.handleManagerChange.bind(this);
    }

    handleTextChange(e) {
        const { dispatch } = this.props;
        dispatch(assetActions.updateAssetTemplateRequirementSelection({ key: e.currentTarget.id, selection: e.currentTarget.value }));
    }

    handleNameChange(e) {
        this.setState({
            name: e.target.value
        })
    }

    handleManagerChange(managers) {
        var managerIds = [];

        Object.keys(managers).forEach(m => managerIds.push(m));

        this.setState({
            managers: managerIds
        });
    }

    handleSubmit() {
        const { dispatch, currentAssetTemplate, assetTemplateRequirementSelections, currentAsset } = this.props;
        const { name, managers } = this.state;
        
        var valid = true;
        var asset_data = {};

        assetTemplateRequirementSelections.forEach(s => {
            if(!s.selection) {
                valid = false;
            } else {
                asset_data[s.data_key] = s.selection;
            }
        });

        if(name && valid) {
            //dispatch update or create depending on the mode
            if(currentAsset) {
                currentAsset.asset_data = asset_data;
                currentAsset.name = name;
                currentAsset.manager_ids = managers;
                dispatch(assetActions.updateAsset(currentAsset));
            } else {
                dispatch(assetActions.createAsset({ name, type: currentAssetTemplate.type, asset_data, manager_ids: managers }));
            }
        } else {
            //dispatch failure
            dispatch(alertActions.error("Please enter all required information"));
        }
    }

    handleDelete() {
        const { dispatch, currentAsset } = this.props;
        
        currentAsset.status = 0;

        dispatch(assetActions.updateAsset(currentAsset));
    }

    render() {
        const { assetTemplateRequirementSelections, currentAsset } = this.props;
        const { name } = this.state;

        if(!assetTemplateRequirementSelections) {
            return null;
        }

        return (
            <div>
                <Form className={styles.form}>
                    {!currentAsset &&
                        <div className={styles.namecontainer}>
                            <div>
                                <Label className={styles.subheading}>Asset Name</Label>
                            </div>
                            <Input className={styles.forminput} id="name" value={name || ''} onChange={this.handleNameChange}></Input>
                        </div>
                    }
                    {assetTemplateRequirementSelections.length > 0 &&
                        <div className={styles.infocontainer}>
                            <div>
                                <Label className={styles.sectionheading}>Asset Information</Label>
                            </div>
                            {assetTemplateRequirementSelections.map(r => {
                                    return  <FormGroup key={r.data_key}>
                                                <Label>Enter {r.data_key}</Label>
                                                <Input className={styles.forminput} id={r.data_key} autoComplete="off" autoCorrect="off" defaultValue={currentAsset ? currentAsset.asset_data[r.data_key] : r.selection} onChange={this.handleTextChange}/>
                                            </FormGroup>          
                                })
                            }
                        </div>
                    }
                </Form>
                <EmployeeSelector adminMode={true} selectedCallback={this.handleManagerChange} prePopulatedEmployees={currentAsset ? currentAsset.managers : []}/>
                <FormGroup className={styles.formsubgroup}>      
                    <Button className={styles.button} color="success" onClick={this.handleSubmit}>Submit</Button>
                    {currentAsset &&
                        <ConfirmationModal  bodyText={`By confirming you will be deleting ${name} from your company.`} 
                                            buttonColor="danger"
                                            buttonText="Delete"
                                            confirmCallback={this.handleDelete}
                                            />
                    }
                </FormGroup>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { asset } = state;
    const { assetTemplateRequirementSelections, currentAssetTemplate, currentAsset } = asset;
 
    return {
        assetTemplateRequirementSelections,
        currentAssetTemplate,
        currentAsset
    };
}

const connectedAssetTemplateRequirements = connect(mapStateToProps)(AssetTemplateRequirements);
export { connectedAssetTemplateRequirements as AssetTemplateRequirements };