import React from 'react';
import styles from './AdminPage.module.css';

import { connect } from 'react-redux';
import { Button, Container, FormGroup, Input, Label } from 'reactstrap';
import { adminActions } from '_actions';

class AdminCreateCompanyPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            company: {
                company_name: '',
                company_description: '',
                schema_name: '',
                database_server: '',
                owner_email: '',
            },
            submitted: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { company } = this.state;

        this.setState({
            company: {
                ...company,
                [name]: value
            }
        });
    }

    onSubmit(event) {
        event.preventDefault();

        const { company } = this.state;
        const { dispatch } = this.props;

        this.setState({
            submitted: true
        });

        dispatch(adminActions.createCompany(company));
    }

    render() {
        const { company, submitted } = this.state;

        return (
            <div className={styles.container}>
                <h4>Create a new company</h4>
                <Container className={styles.main}>
                    <FormGroup>
                        <Label>Company Name</Label>
                        <Input name="company_name" defaultValue={company.company_name} onChange={this.handleChange}></Input>
                    </FormGroup>
                    <FormGroup>
                        <Label>Company Description</Label>
                        <Input name="company_description" defaultValue={company.company_description} onChange={this.handleChange}></Input>
                    </FormGroup>
                    <FormGroup>
                        <Label>Schema Name</Label>
                        <Input name="schema_name" defaultValue={company.schema_name} onChange={this.handleChange}></Input>
                    </FormGroup>
                    <FormGroup>
                        <Label>Database Server</Label>
                        <Input name="database_server" defaultValue={company.database_server} onChange={this.handleChange}></Input>
                    </FormGroup>
                    <FormGroup>
                        <Label>Owner Email</Label>
                        <Input name="owner_email" defaultValue={company.owner_email} onChange={this.handleChange}></Input>
                    </FormGroup>
                    <Button color='primary' disabled={submitted} onClick={this.onSubmit}>Submit</Button>
                </Container>              
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    const { user } = authentication;
    return {
        user
    };
}

const connectedAdminCreateCompanyPage = connect(mapStateToProps)(AdminCreateCompanyPage);
export { connectedAdminCreateCompanyPage as AdminCreateCompanyPage };