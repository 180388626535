import React from 'react';
import styles from './CompanyEmployeesPage.module.css';

import { connect } from 'react-redux';
import { EmployeeTable } from '_components/EmployeeTable';
import { AddEmployeeModal } from '_components/AddEmployeeModal';
import { companyActions } from '_actions';

class CompanyEmployeesPage extends React.Component {
    constructor(props) {
        super(props);

        this.addEmployee = this.addEmployee.bind(this);
    }

    addEmployee(result) {
        const { company, dispatch } = this.props;
        dispatch(companyActions.addEmployee({ company_id: company.company_id, email: result.email }));
    }    
    
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(companyActions.getEmployeesByFilter('All'));
    }

    render() {
        const { company } = this.props;
        return (
            <div className={styles.container}>
                <div className={styles.headingcontainer}>
                    <h4 className={styles.header}>{company.company_name} Employees</h4>
                    <div>
                        <AddEmployeeModal className={styles.headerbutton} confirmCallback={this.addEmployee}/>
                    </div>
                </div>
                <EmployeeTable/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { company } = state;
    return {
        company
    };
}

const connectedCompanyEmployeesPage= connect(mapStateToProps)(CompanyEmployeesPage);
export { connectedCompanyEmployeesPage as CompanyEmployeesPage };