import React from 'react';
import styles from './CompanyTasksPage.module.css';
import { connect } from 'react-redux';
import { TaskTable } from '_components/TaskTable';
import { LinkContainer } from 'react-router-bootstrap';
import { Button } from 'reactstrap';

class CompanyTasksPage extends React.Component {  
    render() {
        const { company } = this.props;

        return (
            <div className={styles.container}>
                <div className={styles.headingcontainer}>
                    <h4 className={styles.header}>{company.company_name} Tasks</h4>
                    <div>
                        <LinkContainer to='/company/tasks/employees' className={styles.headingbutton}>
                            <Button color="primary">View By Employee</Button>
                        </LinkContainer>
                        <LinkContainer to='/company/tasks/create' className={styles.headingbutton}>
                            <Button color="success">Create Task</Button>
                        </LinkContainer>
                    </div>
                </div>
                <TaskTable/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { company } = state;

    return {
        company
    };
}

const connectedCompanyTasksPage = connect(mapStateToProps)(CompanyTasksPage);
export { connectedCompanyTasksPage as CompanyTasksPage };