import React from 'react';
import styles from './AssetDetailsDisplayDetailedPoundsPage.module.css';

import { Input, Button, Table, Label } from 'reactstrap';
import { connect } from 'react-redux';
import { assetActions } from '_actions';
import { AssetDetailsUpdateLimitsModal } from '_components/AssetDetailsUpdateLimitsModal';
import { exportIndividualVesselPoundData, getListOfYears } from '_helpers';
import { FaFileExcel, FaSave, FaWindowClose, FaEdit } from 'react-icons/fa';
import { currentCompanyRole, formatUtcDate} from '_helpers'
 
class AssetDetailsDisplayDetailedPoundsPage extends React.Component {
    constructor(props) {
        super(props);

        var possibleYears = getListOfYears(2020, new Date().getFullYear());

        this.state = {
            possibleYears: possibleYears,
            selectedYear: this.props.detailsYear || possibleYears[possibleYears.length - 1],
            assetId: this.props.detailsAssetId || null,
            editing: false,
            updatedData: {},
            canEdit : currentCompanyRole() >= 3 ? true : false 
        };
        
        this.handleChange = this.handleChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleExportToExcel = this.handleExportToExcel.bind(this);
        this.handleAssetSelectChange = this.handleAssetSelectChange.bind(this);
        this.handleChangeInput = this.handleChangeInput.bind(this);
    }

    handleAssetSelectChange(e) {
        const { dispatch } = this.props;
        const { selectedYear } = this.state;

        var index = e.target.selectedIndex;
        var selectedAssetId = e.target.childNodes[index].getAttribute('aid');

        this.setState({
            assetId: selectedAssetId
        })

        if(selectedYear && selectedAssetId) {
            dispatch(assetActions.getAssetAnnualDetailedReport(selectedAssetId, selectedYear));
        }
    }

    handleChange(event) {
        const { dispatch } = this.props;
        const { assetId } = this.state;
        
        var index = event.target.selectedIndex;
        var selectedYear = event.target.childNodes[index].getAttribute('year');

        this.setState({
            selectedYear: selectedYear
        });


        if(selectedYear && assetId) {
            dispatch(assetActions.getAssetAnnualDetailedReport(assetId, selectedYear));
        }
    }

    handleChangeInput(accessor, event, rowObj, isResearch) {
        const {updatedData, assetId, selectedYear} = this.state;

        if (updatedData[accessor] === undefined) { updatedData[accessor] = {} }
        
        updatedData[accessor].id = rowObj.gradeMapping[accessor];
        updatedData[accessor].vessel_task_id = rowObj.vesselTaskId;
        updatedData[accessor].amount = event.target.value;
        updatedData[accessor].entry_date = rowObj.tripDate;
        updatedData[accessor].area_name = rowObj.area;
        updatedData[accessor].asset_id = assetId;
        updatedData[accessor].year = selectedYear;
        updatedData[accessor].is_research = isResearch;
    }

    handleEdit(event) {
        event.isedit = true;
        event.updateData = event[0];

        this.setState({
            editing: true
        });
    }

    handleCancel(event) {
        event.isedit = false;

        this.setState({
            editing: false,
            updatedData: {}
        });
    }

    handleSave() {
        const { updatedData, assetId, selectedYear } = this.state;
        const { dispatch } = this.props;
        
        dispatch(assetActions.createUpdateAssetDetails(updatedData, assetId, selectedYear));

        this.setState({
            editing : false,
            updatedData : {}
        })
    }

    handleExportToExcel() {
        const { assetId } = this.state;
        const {assetDetailedReport, assets} = this.props;

        var assetLookup = assets.reduce((d, v) => {d[v.asset_id] = v; return d}, {});
        var selectedAsset = assetLookup[assetId];

        exportIndividualVesselPoundData(assetDetailedReport, selectedAsset.name)
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(assetActions.getAssets('active'));
    }

    render() {
        const { possibleYears, assetId, selectedYear, canEdit } = this.state;
        const { company, assets, assetDetailedReport  } = this.props;

        var assetLookup = assets.reduce((d, v) => {d[v.asset_id] = v; return d}, {});
        var selectedAsset = assetLookup[assetId];

        return (
            <div className={styles.container}>
                <div className={styles.headingcontainer}>
                    <h4 className={styles.header}>{company.company_name} Pounds Information</h4>
                    <Button className={styles.button} color="success" onClick={this.handleExportToExcel}><FaFileExcel size={20} className={styles.icon}/></Button>
                </div>
                <div className={styles.subheadingcontainer}>
                    <Input className={styles.year} type='select' onChange={this.handleChange}>
                    {
                        possibleYears.map(year => {
                            return <option key={year} selected={selectedYear === year} year={year}>{year} - {year + 1}</option>
                        })
                    }
                    </Input>
                    <Input className={styles.vessel} type='select' defaultValue={"Select a asset..."} onChange={this.handleAssetSelectChange}>
                        <option key={'select'} disabled readOnly value={"Select a asset..."}>Select a asset...</option>
                        {
                            assets.filter(a => a.type === "Boat" || a.type === "Vessel").map(a => {
                                return <option key={a.asset_id} selected={assetId === a.asset_id} aid={a.asset_id} value={a.name}>{a.name}</option>
                            })
                        }
                    </Input>
                    <AssetDetailsUpdateLimitsModal buttonColor='primary' buttonText='Update Allocations'/>
                </div>

                {assetDetailedReport && assetDetailedReport.columns && assetDetailedReport.rows && assetDetailedReport.rows.length > 0 && selectedAsset ? 
                <div>
                    <h4>{selectedAsset.name + " Pounds Report"}</h4>
                    <Table>
                        <tbody>
                            <tr>
                                {canEdit === true &&  <th className={styles.buttoncolumn}/>}
                                {this.renderTableHeader(assetDetailedReport)}
                            </tr>
                            {this.renderTableData(assetDetailedReport, assetDetailedReport.rows, false)}
                        </tbody>
                    </Table>

                    {assetDetailedReport.research_rows && assetDetailedReport.research_rows.length > 0 && 
                    <div>
                        <h4>{selectedAsset.name + " RSA Pounds Report"}</h4>
                        <Table>
                            <tbody>      
                                <tr>
                                    {canEdit === true &&  <th className={styles.buttoncolumn}/>}
                                    {this.renderTableHeader(assetDetailedReport)}
                                </tr>
                                {this.renderTableData(assetDetailedReport, assetDetailedReport.research_rows, true)}
                            </tbody>
                        </Table>
                    </div> 
                    }
                </div>
                :
                <div className={styles.container}>
                    <Label className={styles.nodata}>No Data To Display!</Label>
                </div>
                }
            </div>
        );
    }

    renderTableHeader(assetDetailedReport) {
        return assetDetailedReport.columns.map((column, index) => {
           return <th key={index}>{column.title}</th>
        })
    }

    formatData(accessor, data) {
        if(accessor === 'tripDate') {
            return formatUtcDate(data);
        }

        return data || 0;
    }

    renderTableData(assetDetailedReport, rows, isResearch) {
        const { editing, canEdit } = this.state;
        
        return rows.map(rowObj => {
            return <tr key={(isResearch ? 'r_' : '') + rowObj.tripDate + rowObj.area}>
                     {canEdit === true &&
                    <td> 
                        <div>
                            {rowObj.isedit  ?
                            <div className={styles.editbuttoncontainer}>
                                <Button className={styles.button} color="success" onClick={this.handleSave}><FaSave size={20} className={styles.icon}/></Button>
                                <Button className={styles.button} color="danger" onClick={() => this.handleCancel(rowObj)}><FaWindowClose size={20} className={styles.icon}/></Button>
                            </div>
                            : editing === false &&
                            <div className={styles.editbuttoncontainer}>
                                <Button className={styles.button} onClick={() => this.handleEdit(rowObj)}><FaEdit size={20} className={styles.icon}/></Button>
                            </div>
                            }
                            
                        </div>
                    </td>
                    }
                    {
                        assetDetailedReport.columns.map(col => {
                            return <td key={col.accessor}>
                                {col.accessor === 'days' && !rowObj.canEditDays ?
                                    <div>N/A</div>
                                :
                                col.editable && rowObj.isedit ? 
                                    <input className={styles.input} defaultValue={rowObj[col.accessor] || 0} onChange={(e) => this.handleChangeInput(col.accessor, e, rowObj, isResearch)}></input>
                                : 
                                    <div>{this.formatData(col.accessor, rowObj[col.accessor])}</div>
                                }
                            </td>
                        })
                    }
            </tr>
        })
    }
}

function mapStateToProps(state) {
    const { asset, company } = state;
    const { assetSummary, assets, assetDetails, assetDetailedReport, detailsAssetId, detailsYear } = asset;
    
    return {
        assets,
        assetSummary,
        company,
        assetDetails,
        assetDetailedReport,
        detailsAssetId,
        detailsYear
    };
}

const connectedAssetDetaisDisplayDetailedPoundsPage = connect(mapStateToProps)(AssetDetailsDisplayDetailedPoundsPage);
export { connectedAssetDetaisDisplayDetailedPoundsPage as AssetDetailsDisplayDetailedPoundsPage };