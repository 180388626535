import React from 'react';
import styles from './RegisterForm.module.css';
import eulaPdf from '_pdfs/EULA.pdf';
import privPdf from '_pdfs/Privacy Policy.pdf';
import { MdLock, MdLockOpen } from 'react-icons/md';

import { connect } from 'react-redux';
import { Button, Label, Form, FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { userActions, alertActions } from '_actions';
import { strongPassword, strongPasswordRequirementMessage, validateEmail } from '_helpers';

class RegisterForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {
                email: '',
                password: '',
                password2: '',
                first_name: '',
                last_name: '',
            },
            submitted: false,
            validEmail: false,
            strongPass: false,     
            strongPass2: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { user, strongPass, strongPass2, validEmail } = this.state;

        this.setState({
            user: {
                ...user,
                [name]: value
            },
            strongPass: name === 'password' ? strongPassword(value) : strongPass,
            strongPass2: name === 'password2' ? strongPassword(value) : strongPass2,
            validEmail: name === 'email' ? validateEmail(value) : validEmail
        });
    }

    handleSubmit(e) {
        e.preventDefault(); //THIS IS SO FUCKING IMPORTANT, IT WILL BREAK REACT BECAUSE OF THINGS

        this.setState({ submitted: true });
        const { user, strongPass, strongPass2, validEmail } = this.state;
        const { dispatch } = this.props;

        if (!user.email || !validEmail || !user.first_name || !user.last_name || !user.password || !user.password2) {
            dispatch(alertActions.error("Please enter all required information"));
        }

        if (user.password !== user.password2 || !strongPass || !strongPass2) {
            dispatch(alertActions.error("Your passwords doesn't match. Please enter your password correctly and make sure it meets the strength requirements."));
        }

        if (user.email && validEmail && user.first_name && user.last_name && user.password && user.password2 && user.password === user.password2 && strongPass && strongPass2) {
            dispatch(userActions.register(user));
        }
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(userActions.resetRegistering());
    }

    render() {
        const { registering } = this.props;
        const { user, strongPass, strongPass2, validEmail } = this.state;

        return (
            <Form autoComplete="new-password" onSubmit={this.handleSubmit}>
                <FormGroup>
                    <Label>First Name</Label>
                    <Input required type="text" autoCapitalize="on" name="first_name" value={user.first_name} onChange={this.handleChange}></Input>
                </FormGroup>
                <FormGroup>
                    <Label>Last Name</Label>
                    <Input required type="text" autoCapitalize="on" name="last_name" value={user.last_name} onChange={this.handleChange}></Input>
                </FormGroup>
                <FormGroup>
                    <Label>Email</Label>
                    <Input valid={validEmail} invalid={user.email.length > 0 && !validEmail} autoComplete="off" type="email" name="email" value={user.email} onChange={this.handleChange}></Input>
                    <FormFeedback invalid="true">
                        Please enter a valid email
                    </FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label>Password</Label>
                    <InputGroup>
                        <Input required valid={strongPass} invalid={user.password.length > 0 && !strongPass} autoComplete="new-password" type="password" name="password" value={user.password} onChange={this.handleChange}></Input>
                        <InputGroupAddon addonType="append"><span className="input-group-text">{strongPass ? <MdLock color='green'/> : <MdLockOpen/>}</span></InputGroupAddon>            
                        <FormFeedback invalid="true">
                            {strongPasswordRequirementMessage()}
                        </FormFeedback>
                    </InputGroup>
                </FormGroup>
                <FormGroup>
                    <Label>Confirm Password</Label>
                    <InputGroup>
                        <Input required valid={strongPass2} invalid={user.password2.length > 0  && !strongPass2} autoComplete="new-password" type="password" name="password2" value={user.password2} onChange={this.handleChange}></Input>
                        <InputGroupAddon addonType="append"><span className="input-group-text">{strongPass2 ? <MdLock color='green'/> : <MdLockOpen/>}</span></InputGroupAddon>
                        <FormFeedback invalid="true">
                            {strongPasswordRequirementMessage()}
                        </FormFeedback>
                    </InputGroup>
                </FormGroup>
                <FormGroup className={styles.container}>
                    <Button color="primary" className={styles.submit} disabled={registering}><MdLock/> Create Account</Button>
                </FormGroup>
                <FormGroup>
                    <span className={styles.policy}>By clicking Create Account, you agree to our <a href={eulaPdf} target="_blank" rel="noopener noreferrer">Terms</a> and have read and acknowledge our <a href={privPdf} target="_blank" rel="noopener noreferrer">Privacy Statement</a>.</span>
                </FormGroup>
            </Form>
        );
    }
}

function mapStateToProps(state) {
    const { registering } = state.registration;
    return {
        registering
    };
}

const connectedRegisterForm = connect(mapStateToProps)(RegisterForm);
export { connectedRegisterForm as RegisterForm };