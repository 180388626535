import React from 'react';
import styles from './AdminPage.module.css';

import { connect } from 'react-redux';
import { Button, Container, FormGroup, Input, Label } from 'reactstrap';
import { adminActions } from '_actions';

class AdminCreateDocumentTemplateAdvancedPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            document_template: ''
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        });
    }

    handleSubmit() {
        const { dispatch, company } = this.props;
        const { document_template } = this.state;

        if(company && company.company_id) {
            dispatch(adminActions.createAdvancedDocumentTemplate(company.company_id, document_template));
        }
    }

    render() {
        const { company } = this.props;

        return (
            <div className={styles.container}>
                <div className={styles.headingcontainer}>
                    <h4 className={styles.header}>Create Advanced Document Template For {company.company_name}</h4>
                </div>
                <div className={styles.main}>
                    <Container>
                        <FormGroup>
                            <Label>Advanced Document Template JSON Body</Label>
                            <Input type="textarea" className={styles.largeTextInput} name="document_template" onChange={this.handleChange}/>
                        </FormGroup>
                        <Button color="primary" onClick={this.handleSubmit}>Create Template</Button>
                    </Container>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { admin } = state;
    const { company } = admin;

    return { 
        company
    };
}

const connectedAdminCreateDocumentTemplateAdvancedPage = connect(mapStateToProps)(AdminCreateDocumentTemplateAdvancedPage);
export { connectedAdminCreateDocumentTemplateAdvancedPage as AdminCreateDocumentTemplateAdvancedPage };