import React from 'react';
import checked from '_images/thumbsup.png';
import styles from './RelationshipTable.module.css';

import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { companyRole, status, formatShortDateTime, customTableTheme } from '_helpers';

class RelationshipTable extends React.Component {
    columns = [
        {
            name: 'Company Name',
            selector: row => row['company_name'],
            sortable: true,
            wrap: true
        },
        {
            name: 'Company Role',
            selector: row => row['company_role'],
            sortable: true,
            hide: 'sm'
        },
        {
            name: 'Status',
            selector: row => row['status'],
            sortable: true,
            hide: 'sm',
            wrap: true
        },
        {
            name: 'Date Started',
            selector: row => row['create_date'],
            sortable: true,
            wrap: true
        }
    ];

    render() {
        const { relationships } = this.props;

        var data = relationships.map(r => {
            return {
                id: r.company_id,
                company_name: r.company_name,
                company_role: companyRole(r.role, r.sub_role),
                status: status(r.status),
                create_date: formatShortDateTime(r.create_date)
            };
        });

        return (
            <div>
                <DataTable
                    columns={this.columns}
                    data={data}
                    striped
                    fixedHeader
                    fixedHeaderScrollHeight='500px'
                    noHeader
                    customTheme={customTableTheme}
                    noDataComponent={
                        <div className={styles.nodatacontainer}>
                            <span className={styles.heading}>No Relationships!</span>
                            <span className={styles.para}>You have nothing to view at this time.</span>
                            <img className={styles.checkedimg} src={checked} alt=":("/>
                        </div>
                    }
                />
            </div>        
        );
    }
}

function mapStateToProps(state) {
    const { relationships } = state;
    return {
        relationships
    };
}

const connectedRelationshipTable = connect(mapStateToProps)(RelationshipTable);
export { connectedRelationshipTable as RelationshipTable };