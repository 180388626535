export const templateConstants = {
    GET_ACTION_TEMPLATES_REQUEST: 'GET_ACTION_TEMPLATES_REQUEST',
    GET_ACTION_TEMPLATES_SUCCESS: 'GET_ACTION_TEMPLATES_SUCCESS',
    GET_ACTION_TEMPLATES_FAILURE: 'GET_ACTION_TEMPLATES_FAILURE',

    GET_ACTION_TYPES_REQUEST: 'GET_ACTION_TYPES_REQUEST',
    GET_ACTION_TYPES_SUCCESS: 'GET_ACTION_TYPES_SUCCESS',
    GET_ACTION_TYPES_FAILURE: 'GET_ACTION_TYPES_FAILURE',

    GET_CONTENT_IDS_REQUEST: 'GET_CONTENT_IDS_REQUEST',
    GET_CONTENT_IDS_SUCCESS: 'GET_CONTENT_IDS_SUCCESS',
    GET_CONTENT_IDS_FAILURE: 'GET_CONTENT_IDS_FAILURE',

    GET_TASK_ROLES_REQUEST: 'GET_TASK_ROLES_REQUEST',
    GET_TASK_ROLES_SUCCESS: 'GET_TASK_ROLES_SUCCESS',
    GET_TASK_ROLES_FAILURE: 'GET_TASK_ROLES_FAILURE',

    GET_TASK_TEMPLATES_REQUEST: 'GET_TASK_TEMPLATE_REQUEST',
    GET_TASK_TEMPLATES_SUCCESS: 'GET_TASK_TEMPLATE_SUCCESS',
    GET_TASK_TEMPLATES_FAILURE: 'GET_TASK_TEMPLATE_FAILURE',

    GET_TASK_TEMPLATE_REQUIREMENTS_REQUEST: 'GET_TASK_TEMPLATE_REQUIREMENTS_REQUEST',
    GET_TASK_TEMPLATE_REQUIREMENTS_SUCCESS: 'GET_TASK_TEMPLATE_REQUIREMENTS_SUCCESS',
    GET_TASK_TEMPLATE_REQUIREMENTS_FAILURE: 'GET_TASK_TEMPLATE_REQUIREMENTS_FAILURE',

    UPDATE_TEMPLATE_REQUIREMENTS_SELECTION: 'UPDATE_TEMPLATE_REQUIREMENTS_SELECTION',

    TASK_RECENT_EMPLOYEES_REQUEST: 'TASK_RECENT_EMPLOYEES_REQUEST',
    TASK_RECENT_EMPLOYEES_SUCCESS: 'TASK_RECENT_EMPLOYEES_SUCCESS',
    TASK_RECENT_EMPLOYEES_FAILURE: 'TASK_RECENT_EMPLOYEES_FAILURE',
};