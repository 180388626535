import React from 'react';
import PropTypes from 'prop-types';
import styles from './FileUploadModal.module.css';

import { connect } from 'react-redux';
import { Button, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FaCloudUploadAlt, FaFile, FaFileExcel, FaFileImage, FaFilePdf, FaFileWord } from 'react-icons/fa';

import loading from '_images/loadingrings.svg';
import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-step-progress-bar";

import { companyService } from '_services';

class FileUploadModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            fileUpload: {},
            selectedFile: null,
            errorMessage: '',
            isUploading: false,
            uploadComplete: false,
            progress: 0
        };
        
        this.toggle = this.toggle.bind(this);
        this.save = this.save.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    toggle() {
        const { modal } = this.state;
        
        //refresh state on open
        this.setState({
            modal: !modal
        }, () => {
            if(this.state.modal === true) {
                this.setState({
                    selectedFile: null,
                    errorMessage: '',
                    isUploading: false,
                    uploadComplete: false,
                    progress: 0
                });
            }
        });
    }

    save() {
        this.toggle();
    }

    handleChange(e) {
        const { upid } = this.props;

        //grab file if they picked one
        var file = e.currentTarget.files[0];
        if(!file) {
            return;
        }

        //parse out file extension
        var fileExt = '.' + file.name.split('.').pop();
        
        //update state
        this.setState({
            selectedFile: file,
            errorMessage: ''
        });

        //get url for uploading
        companyService.getFileUploadUrl(upid, fileExt)
            .then(fileUpload => {
                //update local state
                this.setState({
                    fileUpload: fileUpload,
                    isUploading: true
                });
                
                //create xhr
                var xhr = new XMLHttpRequest();
                
                //hook into onload xhr event
                xhr.onload = () => {
                    if (xhr.status === 200) {
                        const { saveCallback, id, cid, rtype, subtype } = this.props;
                        
                        //mark upload as active by the client
                        const { fileUpload } = this.state;
                        fileUpload.status = 2;

                        //call api
                        companyService.uploadFile(fileUpload)
                            .then(fileUpload => {
                                //finalize state
                                this.setState({
                                    uploadComplete: true,
                                    isUploading: false,
                                    fileUpload: fileUpload
                                });

                                //trigger callback
                                if(saveCallback) {
                                    saveCallback({id, cid, rtype, subtype, fileUpload});
                                }
                            },
                            error => {
                                this.setState({
                                    errorMessage: 'Failed to upload the file',
                                    isUploading: false
                                });
                            }
                        );
                    }
                };

                //hook into xhr onprogress event
                xhr.upload.onprogress = event => {
                    //update progress
                    var percent = (event.loaded / event.total) * 100;
                    this.setState({
                        progress: percent
                    })
                };
                
                //hook into xhr error event
                xhr.onerror = () => {
                    this.setState({
                        errorMessage: 'Failed to upload the file',
                        isUploading: false
                    });
                };

                //kick off the upload
                xhr.open('PUT', fileUpload.signed_url, true);
                xhr.send(file);
            },
            error => {
                //display error
                this.setState({
                    errorMessage: error,
                    selectedFile: null,
                    isUploading: false
                });
            }
        );
    }

    componentDidMount() {
        const { upid } = this.props;

        companyService.getFileUpload(upid).then(
            fileUpload => {
                this.setState({
                    fileUpload: fileUpload
                });
            },
            error => {
                //display error
                this.setState({
                    errorMessage: error
                });
            }
        );
    }

    render() {
        const { buttonColor, buttonText } = this.props;
        const { fileUpload, selectedFile, errorMessage, isUploading, modal, uploadComplete, progress } = this.state;

        if(!fileUpload) {
            return null;
        }

        return (
            <div>
                {!uploadComplete ?
                    <Button color={buttonColor} onClick={this.toggle}>{buttonText}</Button>
                    :
                    <div className={styles.bodycontainer}>
                        <Label className={styles.completed}>{getIconForType(fileUpload, styles.uploadicon, '2.75vh')} {selectedFile.name}</Label>
                    </div>
                }
                <Modal isOpen={modal} toggle={this.toggle} className={styles.modal}>
                    <ModalHeader toggle={this.toggle}>File Upload</ModalHeader>
                        {errorMessage &&
                            <Label className={styles.error}>{errorMessage}</Label>
                        }
                        {(isUploading === false && !uploadComplete) &&
                            <ModalBody className={styles.modalbody}>
                                <label className={styles.uploadlabel} htmlFor="upload-file">
                                    <FaCloudUploadAlt className={styles.uploadicon} size={'4vh'}/>
                                    Click Here To Upload
                                </label>
                                <input type="file" name="upload-file" id="upload-file" style={{display: 'none'}} accept={fileUpload.supported_file_extensions} onChange={this.handleChange}/>
                            </ModalBody>
                        }
                        {isUploading === true &&
                            <ModalBody className={styles.modalbody}>
                                <img className={styles.loading} src={loading} alt=":("></img>
                                <div className={styles.progress}>
                                    <ProgressBar percent={progress} filledBackground="linear-gradient(to right, rgb(172 239 196), rgb(14 163 25))" />
                                </div>
                            </ModalBody>
                        }
                        {uploadComplete === true && 
                            <ModalBody className={styles.modalbody}>
                                <div className={styles.bodycontainer}>
                                    <Label className={styles.completedlabel}>Uploaded: </Label>
                                    <Label className={styles.completed}>{getIconForType(fileUpload, styles.uploadicon, '2.75vh')} {selectedFile.name}</Label>
                                </div>
                                <div className={styles.progress}>
                                    <ProgressBar percent={progress} filledBackground="linear-gradient(to right, rgb(172 239 196), rgb(14 163 25))" />
                                </div>
                            </ModalBody>
                        }
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggle}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

FileUploadModal.propTypes = {
    buttonColor: PropTypes.string,
    buttonText: PropTypes.string,
    saveCallback: PropTypes.func,
    id: PropTypes.string,
    cid: PropTypes.string,
    rtype: PropTypes.number,
    subtype: PropTypes.string,
    uploadId: PropTypes.string
};
  
FileUploadModal.defaultProps = {
    buttonColor: "success",
    buttonText: 'Upload File',
    saveCallback: null
};

function getIconForType(fileUpload, style, size) {
    switch(fileUpload.file_type) {
        case 1:
            return <FaFileImage className={style} size={size}/>
        case 2:
            return <FaFilePdf className={style} size={size}/>;
        case 3:
            return <FaFileWord className={style} size={size}/>;
        case 4:
            return <FaFileExcel className={style} size={size}/>;
        default:
            return <FaFile className={style} size={size}/>;
    }
}

function mapStateToProps(state) {
    const { company } = state;
    const { fileUpload } = company;

    return {
        fileUpload
    };
}

const connectedFileUploadModal = connect(mapStateToProps)(FileUploadModal);
export { connectedFileUploadModal as FileUploadModal };