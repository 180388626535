import React from 'react';
import styles from './NotFoundPage.module.css';

import { connect } from 'react-redux';

class NotFoundPage extends React.Component {
    render() {
        return (
            <div className={styles.container}>
                <h2 className={styles.heading}>Uh Oh! Page not found.</h2>
                <p>Please click our logo in the top left to get back on track</p>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedNotFoundPage = connect(mapStateToProps)(NotFoundPage);
export { connectedNotFoundPage as NotFoundPage };