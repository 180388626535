import { authHeader, config, currentUser, handleResponse, handleError } from '_helpers';

export const relationshipService = {
    getAllByFilter
};

function getAllByFilter(filter) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(config.apiUrl + `/relationships/${currentUser().user_id}?filter=${encodeURIComponent(filter)}`, requestOptions)
        .then(handleResponse, handleError)
        .then(response => {   
            return response;
        });
}