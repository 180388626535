export function currentCompany() {
    let company = JSON.parse(localStorage.getItem('company'));

    if (company) {
        return company;
    } else {
        return {};
    }
}

export function currentCompanyRole() {
    let company = JSON.parse(localStorage.getItem('company'));

    if (company) {
        let relationships = JSON.parse(localStorage.getItem('relationships'));

        for(var i = 0; i < relationships.length; i++) {
            if(relationships[i].company_id === company.company_id) {
                return relationships[i].role;
            }
        }
    }
    
    return null;
}

export function currentCompanySubRole() {
    let company = JSON.parse(localStorage.getItem('company'));

    if (company) {
        let relationships = JSON.parse(localStorage.getItem('relationships'));

        for(var i = 0; i < relationships.length; i++) {
            if(relationships[i].company_id === company.company_id) {
                return relationships[i].sub_role;
            }
        }
    }
    
    return null;
}