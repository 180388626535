import React from 'react';
import checked from '_images/thumbsup.png';
import styles from './EmployeeTable.module.css';

import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import { Badge, Button, Input } from 'reactstrap';
import { companyRole, status, customTableTheme } from '_helpers';
import { companyActions } from '_actions';
import { MdPageview, MdRestore, MdCancel } from 'react-icons/md';
import { ConfirmationModal } from '_components/ConfirmationModal';
import { exportEmployees } from '_helpers';
import { FaFileExcel } from 'react-icons/fa';

class EmployeeTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentSearch: '',
            filters: []
        };

        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleFilterApply = this.handleFilterApply.bind(this);
        this.handleBadgeClick = this.handleBadgeClick.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleReactivate = this.handleReactivate.bind(this);
        this.handleExportToExcel = this.handleExportToExcel.bind(this);
    }

    columns = [
        {
            name: 'Employee Name',
            selector: row => row['name'],
            grow: 2,
            sortable: true,
            wrap: true
        },
        {
            name: 'Email',
            selector: row => row['email'],
            grow: 2,
            sortable: true,
            hide: 'sm'
        },
        {
            name: 'Phone Number',
            selector: row => row['phone_number'],
            sortable: true,
            hide: 'sm',
            wrap: true
        },
        {
            name: 'Address',
            selector: row => row['address'],
            grow: 2,
            sortable: true,
            wrap: true
        },
        {
            name: 'Role',
            selector: row => row['company_role'],
            sortable: true,
            hide: 'sm'
        },
        {
            name: 'Status',
            selector: row => row['status'],
            sortable: true,
            hide: 'sm'
        },
        {
            name: '',
            right: true,
            cell: row =>  row.rstatus === 0 ? <ConfirmationModal bodyText={`By confirming, you will be re-activating ${row.email} to your company.`} clickable={<MdRestore className={styles.viewr}/>} confirmCallback={this.handleReactivate} data={row.ref}/> 
                        : row.rstatus === 1 ? <ConfirmationModal bodyText={`By confirming, you will be canceling your invite to ${row.email} to your company.`} clickable={<MdCancel className={styles.viewc}/>} confirmCallback={this.handleCancel} data={row.ref}/> 
                        : row.rstatus === 2 ? <MdPageview className={styles.view} eid={row.id} estatus={row.rstatus} onClick={this.handleClick}/> 
                        : null,
        }
    ];

    handleTextChange(event) {
        this.setState({
            currentSearch: event.target.value
        })
    }

    handleFilterApply() {
        const { currentSearch, filters } = this.state;
        if(currentSearch) {
            if(!filters.includes(currentSearch)) {
                filters.push(currentSearch);
            }

            this.setState({
                currentSearch: '',
                filters: filters
            })
        }
    }

    handleBadgeClick(e) {
        var id = e.target.getAttribute("fid");

        const { filters } = this.state;

        for(var i = 0; i < filters.length; i++) {
            if(filters[i] === id) {
                filters.splice(i, 1);
            }
        }

        this.setState({
            filters: filters
        });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.handleFilterApply();
        }
    }

    handleClick(event) {
        const { dispatch } = this.props;
        
        var employeeId = event.currentTarget.getAttribute('eid');
        dispatch(companyActions.getEmployeeDetails(employeeId));
    }

    handleCancel({data}) {
        const { dispatch } = this.props;

        var employeeId = data.user.user_id;

        dispatch(companyActions.cancelPendingInvite(employeeId));
    }
    
    handleReactivate({data}) {
        const { dispatch, company } = this.props;

        var employeeId = data.user.user_id;
        var employeeRole = data.company_role;

        dispatch(companyActions.updateEmployeeRelationship({ 
            user_id: employeeId, 
            company_id: company.company_id, 
            role: employeeRole, 
            status: 2
        }));
    }

    handleExportToExcel(e){
        const { employees } = this.props;
        exportEmployees(employees);
    }

    render() {
        const { currentSearch, filters } = this.state;
        const { employees } = this.props;

        if(!employees) {
            return null;
        }

        var data = employees.filter(e => e.status !== 4).reduce((result, e) => {
            var eName = e.user.first_name ? e.user.first_name + " " + e.user.last_name : 'N/A';

            if(filters.length === 0) {
                result.push({
                    id: e.user.user_id,
                    name: eName,
                    email: e.user.email,
                    phone_number: e.user.phone_number || 'N/A',
                    address: e.user.address ? `${e.user.address ? e.user.address : ''} ${e.user.city ? e.user.city : ''} ${e.user.state ? e.user.state : ''} ${e.user.zip_code ? e.user.zip_code : ''}` : 'N/A',
                    company_role: companyRole(e.company_role, e.sub_role),
                    status: status(e.status),
                    rstatus: e.status,
                    ref: e
                });
            } else {
                for(var i = 0; i < filters.length; i++) {
                    if( eName.toLowerCase().includes(filters[i].toLowerCase()) ||
                        e.user.email.toLowerCase().includes(filters[i].toLowerCase())) {
                        
                        result.push({
                            id: e.user.user_id,
                            name: eName,
                            email: e.user.email,
                            phone_number: e.user.phone_number || 'N/A',
                            address: e.user.address ? `${e.user.address ? e.user.address : ''} ${e.user.city ? e.user.city : ''} ${e.user.state ? e.user.state : ''} ${e.user.zip_code ? e.user.zip_code : ''}` : 'N/A',
                            company_role: companyRole(e.company_role, e.sub_role),
                            status: status(e.status),
                            rstatus: e.status,
                            ref: e
                        });
                        break;
                    }
                }
            }

            return result;
        }, []);

        return (
            <div className={styles.container}>
                <div className={styles.filtercontainer}>
                    {filters.map(f => {
                        return  <Badge pill className={styles.badge} key={f} fid={f} onClick={this.handleBadgeClick}>
                                    <span className={styles.btext} fid={f} onClick={this.handleBadgeClick}>{f}</span>
                                    <span fid={f} className={styles.ex} onClick={this.handleBadgeClick}>X</span>
                                </Badge>
                    })
                    }
                    <Input className={styles.filterinput} placeholder='Search...' onChange={this.handleTextChange} onKeyDown={this.handleKeyPress} value={currentSearch}/>
                    <Button className={styles.filterbutton} onClick={this.handleFilterApply}>Apply</Button>
                    <Button className={styles.filterbutton} color="success" onClick={this.handleExportToExcel}><FaFileExcel/></Button>
                </div>
                <DataTable
                    noHeader
                    columns={this.columns}
                    data={data}
                    striped
                    fixedHeader
                    fixedHeaderScrollHeight='500px'
                    customTheme={customTableTheme}
                    noDataComponent={
                        <div className={styles.nodatacontainer}>
                            <span className={styles.heading}>No Employees!</span>
                            <span className={styles.para}>You have nothing to view at this time.</span>
                            <img className={styles.checkedimg} src={checked} alt=":("/>
                        </div>
                    }
                />
            </div>        
        );
    }
}

function mapStateToProps(state) {
    const { company } = state;
    const { employees } = company;

    return {
        company,
        employees
    };
}

const connectedEmployeeTable = connect(mapStateToProps)(EmployeeTable);
export { connectedEmployeeTable as EmployeeTable };