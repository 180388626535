const local = {
    apiUrl: 'http://localhost:5000/api',
    capiUrl: 'http://localhost:5000/capi',
    stripe_pk: 'pk_test_fuHv3K0Sz8EMmzxGVKZzTqie00TxMZQ0JY'
};

const dev = {
    apiUrl: 'https://dev.tackledocs.com/api',
    capiUrl: 'https://dev.tackledocs.com/capi',
    stripe_pk: 'pk_test_fuHv3K0Sz8EMmzxGVKZzTqie00TxMZQ0JY'

};

const prod = {
    apiUrl: 'https://tackledocs.com/api',
    capiUrl: 'https://tackledocs.com/capi',
    stripe_pk: 'pk_live_y0fGDPXBzJwIRN4gTixkHSlg00mjxuGpFu'
};


// chain of environment fallbacks
const conf = process.env.REACT_APP_STAGE === 'production'
  ? prod
  : process.env.REACT_APP_STAGE === 'development'
  ? dev
  : local;

export const config = {
    // Add common config values here
    REQUEST_TIMEOUT: 5000,
    ...conf
};