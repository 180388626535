import { templateConstants } from '_constants';
import { getSelectionFromType } from '_helpers';

var initialState = {
    contentIds: [], 
    actionTemplates: [], 
    currentTaskTemplate: null, 
    taskTemplates: [], 
    taskTemplateRequirements: null, 
    roles: [], 
    actionTypes: [],
    recentEmployees: []
};

export function template(state = initialState, reduxAction)
{
    switch (reduxAction.type) {
        case templateConstants.CREATE_ACTION_TEMPLATE_REQUEST:
        case templateConstants.CREATE_ACTION_TEMPLATE_FAILURE:
            return {

            };
        case templateConstants.CREATE_ACTION_TEMPLATE_SUCCESS:
            return {

            };
        case templateConstants.CREATE_TASK_TEMPLATE_REQUEST:
        case templateConstants.CREATE_TASK_TEMPLATE_FAILURE:
            return {
                ...state,
                taskTemplateId : null
            };
        case templateConstants.CREATE_TASK_TEMPLATE_SUCCESS:
            return {
                ...state,
                taskTemplateId : reduxAction.taskTemplateId
            };
        case templateConstants.GET_ACTION_TEMPLATES_REQUEST:
        case templateConstants.GET_ACTION_TEMPLATES_FAILURE:
            return { 
                ...state,
                actionTemplates: []
            };
        case templateConstants.GET_ACTION_TEMPLATES_SUCCESS:
            return { 
                ...state,
                actionTemplates: reduxAction.actionTemplates
            };
        case templateConstants.GET_ACTION_TYPES_REQUEST:
        case templateConstants.GET_ACTION_TYPES_FAILURE:
            return { 
                ...state,
                actionTypes: []
            };
        case templateConstants.GET_ACTION_TYPES_SUCCESS:
            return {
                ...state,
                actionTypes: reduxAction.actionTypes
            };
        case templateConstants.GET_CONTENT_IDS_REQUEST:
        case templateConstants.GET_CONTENT_IDS_FAILURE:
            return {
                ...state,
                contentIds: []
            };
        case templateConstants.GET_CONTENT_IDS_SUCCESS:
            return {
                ...state,
                contentIds: reduxAction.contentIds
            };
        case templateConstants.GET_TASK_ROLES_REQUEST:
        case templateConstants.GET_TASK_ROLES_FAILURE: 
            return { 
                ...state,
                roles: []
            };
        case templateConstants.GET_TASK_ROLES_SUCCESS: 
            return { 
                ...state,
                roles: reduxAction.roles
            };
        case templateConstants.GET_TASK_TEMPLATES_REQUEST:
        case templateConstants.GET_TASK_TEMPLATES_FAILURE:
            return {
                ...state,
                taskTemplates: [],
                taskTemplateRequirements: null,
                taskTemplateRequirementSelections: null
            };
        case templateConstants.GET_TASK_TEMPLATES_SUCCESS:
            return {
                ...state,
                taskTemplates: reduxAction.taskTemplates
            };    
        case templateConstants.GET_TASK_TEMPLATE_REQUIREMENTS_REQUEST:
            return {
                ...state,
                currentTaskTemplate: getCurrentTaskTemplate(reduxAction.taskTemplateId, state.taskTemplates),
                recentEmployees: []
            }
        case templateConstants.GET_TASK_TEMPLATE_REQUIREMENTS_FAILURE:
            return {
                ...state,
                taskTemplateRequirements: null,
                taskTemplateRequirementSelections: null,
                recentEmployees: []
            };
        case templateConstants.GET_TASK_TEMPLATE_REQUIREMENTS_SUCCESS:
            return {
                ...state,
                taskTemplateRequirements: reduxAction.taskTemplateRequirements,
                taskTemplateRequirementSelections: buildRequirementSelections(reduxAction.taskTemplateRequirements.requirements),
                recentEmployees: []
            };
        case templateConstants.UPDATE_TEMPLATE_REQUIREMENTS_SELECTION:
            return {
                ...state,
                taskTemplateRequirementSelections: {
                    ...state.taskTemplateRequirementSelections,
                    [reduxAction.updatedSelection.cid]: updateSelections(state.taskTemplateRequirementSelections[reduxAction.updatedSelection.cid], reduxAction.updatedSelection)
                }
            };
        case templateConstants.TASK_RECENT_EMPLOYEES_SUCCESS:
            return {
                ...state,
                recentEmployees: reduxAction.employees
            };
        case templateConstants.TASK_RECENT_EMPLOYEES_REQUEST:
        case templateConstants.TASK_RECENT_EMPLOYEES_FAILURE:
            return {
                ...state,
                recentEmployees: []
            };
        default:
            return state;
    }
}

function buildRequirementSelections(requirements) {
    var selections = {};

    requirements.forEach(r => {
        selections[r.content_id] = r.content_requirements.map(cr => {
            return {
                key: cr.key,
                selection: cr.options ? getSelectionFromType(cr.type, cr.options[0]) : cr.value,
                type: cr.type
            };
        });
    })

    return selections;
}

function updateSelections(selections, updatedSelection) {
    var found = false;

    for(var i = 0; i < selections.length; i++) {
        var s = selections[i];

        if(s.key === updatedSelection.key) {
            s.selection = updatedSelection.selection;
            found = true;
            break;
        }
    }

    if(!found) {
        selections.push({
            key: updatedSelection.key,
            selection: updatedSelection.selection,
            type: updatedSelection.type
        })
    }

    return selections;
}

function getCurrentTaskTemplate(taskTemplateId, taskTemplates) {
    for(var i = 0; i < taskTemplates.length; i++) {
        if(taskTemplates[i].task_template_id === taskTemplateId) {
            return taskTemplates[i];
        }
    }
}