import React from 'react';
import styles from './SupportPage.module.css';

import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { userActions } from '_actions';

class SupportPage extends React.Component {
    constructor(props) {
        super(props);

        // reset login status
        this.props.dispatch(userActions.logout(true));
    }

    render() {
        return (
            <div>
                <Helmet> 
                    <title>TackleDocs: Stay Organized, Speed up company operations.</title>
                    <meta name="description" content="Tackle your documents Manage your business from your smartphone. Generate Invoices, Documents and Customer agreements on the fly! Tackle your documents Keep organized with our Company Calendar. Our platform was designed to be used on a mobile device. It works
                in Chrome, Safari, and Firefox. All features are easily accessible on a mobile site so you can keep working on the go." />
                </Helmet>
                <Container className={styles.container}>
                    <h2 className={styles.mainheading}>TackleDocs Support</h2>

                    <div className={styles.subcontainer}>
                        <h4 className={styles.heading}>Create A Reminder</h4>
                        <p className={styles.para}>Watch this video to see how to create a reminder on TackleDocs.</p>
                        <div className={styles.ytcontainer}>
                            <iframe src="https://www.youtube.com/embed/3HsAARID6Cc" title="Create A Reminder" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>

                    <div className={styles.subcontainer}>
                        <h4 className={styles.heading}>Sign A Document</h4>
                        <p className={styles.para}>Watch this video to see how to sign a document on TackleDocs.</p>
                        <div className={styles.ytcontainer}>
                            <iframe src="https://www.youtube.com/embed/SiAugrzjPsg" title="Signing A Document" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>

                    <div className={styles.subcontainer}>
                        <h4 className={styles.heading}>View A Document</h4>
                        <p className={styles.para}>Watch this video to see how to view an existing document on TackleDocs.</p>
                        <div className={styles.ytcontainer}>
                            <iframe src="https://www.youtube.com/embed/HkFhF13GB7E" title="Viewing A Document" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>

                    <div className={styles.subcontainer}>
                        <h4 className={styles.heading}>Add A Employee</h4>
                        <p className={styles.para}>Watch this video to see how to add an employee to your company on TackleDocs.</p>
                        <div className={styles.ytcontainer}>
                            <iframe src="https://www.youtube.com/embed/vSZBu3-Snug" title="Add A Employee" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>

                    <div className={styles.subcontainer}>
                        <h4 className={styles.heading}>Cancel A Task</h4>
                        <p className={styles.para}>Watch this video to see how to cancel an existing task on TackleDocs.</p>
                        <div className={styles.ytcontainer}>
                            <iframe src="https://www.youtube.com/embed/Du54rRbhD1g" title="Cancel A Task" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>

                    <div className={styles.subcontainer}>
                        <h4 className={styles.heading}>Additional Support</h4>
                        <span className={styles.para}>For all additional support needs please email your request to</span><a href="mailto:support@tackledocs.com">support@tackledocs.com</a>
                    </div>
                </Container>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

const connectedSupportPage = connect(mapStateToProps)(SupportPage);
export { connectedSupportPage as SupportPage };