import React from 'react';
import styles from './CreateReminderPage.module.css';

import { connect } from 'react-redux';
import { assetActions, alertActions } from '_actions';
import { EmployeeSelector } from '_components/EmployeeSelector/EmployeeSelector';
import { DateTimePicker } from 'react-widgets';
import { Button, Container, FormGroup, Input, Label } from 'reactstrap';
import { reminderActions } from '_actions'; 

class CreateReminderPage extends React.Component {
    constructor(props) {
        super(props);

        var currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 1);

        this.state = {
            reminder: {
                title: '',
                recurring: 0,
                recurring_type: 1,
                end_date: this.props.date || currentDate.toISOString(),
                asset_id: null,
                user_ids: [],
            }
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleAssetSelection = this.handleAssetSelection.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.selectEmployeesCallback = this.selectEmployeesCallback.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRecurringSelection = this.handleRecurringSelection.bind(this);
    }

    componentDidMount(e){
        const { dispatch } = this.props;
       
        dispatch(assetActions.getAssets("active"));
    }

    selectEmployeesCallback(data) {
        const { reminder } = this.state;

        this.setState({
            reminder: {
                ...reminder,
                user_ids:  Object.keys(data)
            }
        });
    };

    handleChange(e) {
        const { reminder } = this.state;
        var value = e.target.value
        var name = e.target.name;

        this.setState({
            reminder: {
                ...reminder,
                [name]: value
            }
        });
    }
    
    handleDateChange(updatedDate) {
        const { reminder } = this.state;

        this.setState({
            reminder: {
                ...reminder,
                end_date: updatedDate.toISOString()
            }
        });
    }

    handleAssetSelection(e) {
        const { reminder } = this.state;
        var index = e.target.selectedIndex;
        var selectedOption = e.target.childNodes[index];
       
        this.setState({
            reminder: {
                ...reminder,
                asset_id : selectedOption.id
            }
        });
    }

    handleSubmit() {
        const { dispatch } = this.props;
        const { reminder } = this.state;


        if(reminder.title && reminder.user_ids.length > 0 && reminder.asset_id && reminder.end_date) {
            dispatch(reminderActions.createReminder(reminder));
        } else {
            dispatch(alertActions.error("Please enter all required information"));
        }
    }

    handleRecurringSelection(e) {
        const { reminder } = this.state;
        var index = e.target.selectedIndex;   
        var selectedOption = e.target.childNodes[index];
        
        this.setState({
            reminder: {
                ...reminder,
                recurring_type: selectedOption.id
            }
        });
    }

    render() {
        const { reminder } = this.state;
        const { assets } = this.props;
        return (
            <div className={styles.container}>
                <Container className={styles.main}>
                    <div className={styles.headingcontainer}>
                        <h4 className={styles.heading}>Create Reminder</h4>
                    </div>
                    <div className={styles.subcontainer}>
                        <FormGroup className={styles.formgroup}>
                            <Label>Title</Label>
                            <Input required name="title" defaultValue={reminder.title} onChange={this.handleChange}/>
                        </FormGroup>
                        <FormGroup className={styles.formgroup}>
                            <Label>Date</Label>
                            <DateTimePicker time={false} name="end_date" min={new Date()} defaultValue={reminder.end_date ? new Date(reminder.end_date) : new Date()} onChange={this.handleDateChange}/>
                        </FormGroup>
                        <FormGroup className={styles.formgroup}>
                            <Label>Select Asset</Label>
                            <Input type="select" onChange={this.handleAssetSelection}>
                            <option disabled selected>Select a Asset...</option>
                            {
                                assets.map(o => {
                                return <option key={o.asset_id} id={o.asset_id}>{o.name}</option>
                                })
                            }
                            </Input>
                        </FormGroup>
                        <FormGroup className={styles.formgroup}>
                            <Label>Notes</Label>
                            <Input name="notes" type="textarea" className={styles.notesarea} defaultValue={reminder.notes} onChange={this.handleChange}/>
                        </FormGroup>
                        <FormGroup className={styles.formgroup}>
                            <Label>Recurring (Optional)</Label>
                            <div className={styles.formsubgroup}>
                                <Input className={styles.biginput} type="number" name="recurring" onChange={this.handleChange}/>
                                <Input className={styles.smallinput} type="select" onChange={this.handleRecurringSelection}>
                                    <option key='1' id = '1'>Days</option>
                                    <option key='2' id = '2'>Weeks</option>
                                    <option key='3' id = '3'>Months</option>
                                    <option key='4' id = '4'>Years</option>
                                </Input>
                            </div>
                        </FormGroup>
                        <div className={styles.subcontainer}>
                            <EmployeeSelector selectedCallback={this.selectEmployeesCallback}/>
                        </div>
                        <div className={styles.subcontainer}>
                            <Button className={styles.button} color="success" onClick={this.handleSubmit}>Add Reminder</Button>
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { reminder, asset } = state;
    const { date } = reminder;
    const { assets } = asset;
    return {
        date, assets
    };
}

const connectedCreateReminderPage = connect(mapStateToProps)(CreateReminderPage);
export { connectedCreateReminderPage as CreateReminderPage };