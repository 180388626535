import React from 'react';
import styles from './AssetAnnualOrganizationPage.module.css';

import { AssetDetailsUpdateLimitsModal } from '_components/AssetDetailsUpdateLimitsModal';
import { Input, Label, Table } from 'reactstrap';
import { connect } from 'react-redux';
import { assetActions } from '_actions';
import { getListOfYears, getDateRangeString } from '_helpers';
import { ProgressBar } from 'react-step-progress-bar';
import { FaBuilding } from 'react-icons/fa';
 
class AssetAnnualOrganizationPage extends React.Component {
    constructor(props) {
        super(props);

        var possibleYears = getListOfYears(2020, new Date().getFullYear());

        this.state = {
            possibleYears: possibleYears,
            selectedYear: possibleYears[possibleYears.length - 1]
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const { dispatch } = this.props;

        var index = event.target.selectedIndex;
        var selectedYear = event.target.childNodes[index].getAttribute('year');

        this.setState({
            selectedYear: selectedYear
        });
    
        dispatch(assetActions.getAssetAnnualOrganizationSummary(selectedYear));
    }

    componentDidMount() {
        const { possibleYears } = this.state;
        const { dispatch } = this.props;

        dispatch(assetActions.getAssetAnnualOrganizationSummary(possibleYears[possibleYears.length - 1]));
    }

    render() {
        const { possibleYears, selectedYear } = this.state;
        const { assetOrgSummary, company } = this.props;

        if(!assetOrgSummary) {
            return null;
        }

        return (
            <div className={styles.container}>
                <div className={styles.headingcontainer}>
                    <h4 className={styles.header}>{company.company_name} Research Pounds Information</h4>
                </div>
                <div className={styles.subheadingcontainer}>
                    <Input className={styles.year} type='select' defaultValue={selectedYear} onChange={this.handleChange}>
                    {
                        possibleYears.map(year => {
                            return <option key={year} value={year} year={year}>{year} - {year + 1}</option>
                        })
                    }
                    </Input>
                    <AssetDetailsUpdateLimitsModal buttonColor='primary' buttonText='Update Allocations' forOrganization={true} organizationYear={selectedYear}/>
                </div>
                <div className={styles.nowrapcontainer}>
                    {(!assetOrgSummary || assetOrgSummary.length === 0) &&
                        <div className={styles.container}>
                            <Label className={styles.nodata}>No Data To Display!</Label>
                        </div>
                    }
                    {assetOrgSummary.map(asum => {
                        return  <div key={asum.organization} className={styles.horcontainer}>
                                    <div className={styles.detailcontainer}>
                                        <div>
                                            <FaBuilding className={styles.icon} size={25}/>
                                            <Label className={styles.orglabel}>Organization: {asum.organization}</Label>
                                        </div>
                                        <div className={styles.wrapcontainer}>
                                            <div className={styles.detailcontainer}>
                                                <Label>Allocation:<br/>{asum.limit}</Label>
                                            </div>
                                            <div className={styles.detailcontainer}>
                                                <Label>Caught:<br/>{asum.totalCaught}</Label>
                                            </div>
                                            <div className={styles.detailcontainer}>
                                                <Label>Remaining:<br/>{(asum.limit - asum.totalCaught).toPrecision(7)}</Label>
                                            </div>
                                        </div>
                                        <ProgressBar percent={asum.totalCaught / asum.limit * 100}></ProgressBar>
                                        <div className={styles.tablecontainer}>  
                                            <Table className={styles.table}>
                                                <thead>
                                                    <tr>
                                                        <th>Vessel</th>
                                                        <th>Dates</th>
                                                        <th>Manager</th>
                                                        <th>Pounds</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {asum.tripDetails && asum.tripDetails.map(td => {
                                                    return  <tr key={td.tripId}>
                                                                <td>{td.vesselName}</td>
                                                                <td>{getDateRangeString(td.tripStartDate, td.tripEndDate)}</td>
                                                                <td>{td.managerName}</td>
                                                                <td>{td.poundsCaught}</td>
                                                            </tr>
                                                })
                                                }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                    })
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { asset, company } = state;
    const { assetOrgSummary } = asset;
    
    return {
        company,
        assetOrgSummary
    };
}

const connectedAssetAnnualOrganizationPage = connect(mapStateToProps)(AssetAnnualOrganizationPage);
export { connectedAssetAnnualOrganizationPage as AssetAnnualOrganizationPage };