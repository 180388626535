import React from 'react';
import PropTypes from 'prop-types';
import styles from './PdfViewer.module.css';

import { Document, Page } from 'react-pdf';

class PdfViewer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            docWidth: null,
            pages: [],
            pinchEnded: false,
            pinchDistance: 0,
            initialDocWidth: 0,
            touchIds: []
        }

        this.docRef = React.createRef();
        this.handleWheel = this.handleWheel.bind(this);
        this.handleTouchStart = this.handleTouchStart.bind(this);
        this.handleTouchMove = this.handleTouchMove.bind(this);
        this.handleTouchEnd = this.handleTouchEnd.bind(this);
        this.handleTouchCancel = this.handleTouchCancel.bind(this);
        this.onContainerLoad = this.onContainerLoad.bind(this);
        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
        this.setDocSize = this.setDocSize.bind(this);
    }

    handleWheel(event) {
        if(event.ctrlKey === true || event.altKey === true) {
            event.preventDefault();
            
            const { docWidth } = this.state;
            if (event.deltaY < 0) {
                //Zoom In
                this.setState({
                    docWidth: Math.min(docWidth + 50, 2000)
                });
            }
            else {
                //Zoom Out
                this.setState({
                    docWidth: Math.max(docWidth - 50, 300)
                });
            }
        }
    }

    handleTouchStart(event) {
        if(event.touches.length >= 2) {
            var touchIds = [];
            for(var i = 0; i < 2; i++) {
                touchIds.push(event.touches[i].identifier);
            }
            this.setState({
                pinchEnded: false,
                touchIds: touchIds
            });
        }
    }

    handleTouchMove(event) {
        const { initialDocWidth, touchIds, pinchDistance, pinchEnded } = this.state;
        if(touchIds.length >= 2 && !pinchEnded) {
            var touches = [];

            for(var i = 0; i < event.touches.length; i++) {
                for(var j = 0; j < touchIds.length; j++) {
                    if(event.touches[i].identifier === touchIds[j]) {
                        touches.push(event.touches[i]);
                    }
                }
            }

            if(touches.length === 2) {
                var xs = touches[1].screenX - touches[0].screenX, ys = touches[1].screenY - touches[0].screenY;
                xs *= xs;
                ys *= ys;
                var dist = Math.sqrt( xs + ys );

                if (dist > pinchDistance && pinchDistance !== 0) {
                    //Zoom In
                    this.setState({
                        docWidth: Math.min(initialDocWidth + (Math.abs(dist - pinchDistance)), 2000)
                    })
                }
                else if (dist < pinchDistance && pinchDistance !== 0) {
                    //Zoom Out
                    this.setState({
                        docWidth: Math.max(initialDocWidth - (Math.abs(dist - pinchDistance)), 300)
                    })
                } else if (pinchDistance === 0) {
                    //First Sample
                    this.setState({
                        pinchDistance: dist
                    })
                }
            }
        }
    }

    handleTouchEnd(event) {
        const { touchIds, docWidth } = this.state;
        var currentTouchIds = new Set();

        for(var i = 0; i < event.touches.length; i++) {
            currentTouchIds.add(event.touches[i].identifier);
        }

        for(var j = 0; j < touchIds.length; j++) {
            if(!currentTouchIds.has(touchIds[j])) {
                this.setState({
                    initialDocWidth: docWidth,
                    pinchEnded: true,
                    pinchDistance: 0
                })
            }
        }

        if(event.touches.length === 0) {
            this.setState({
                initialDocWidth: docWidth,
                pinchEnded: false,
                pinchDistance: 0,
                touchIds: []
            })
        }
    }

    handleTouchCancel(event) {
        const { touchIds, docWidth } = this.state;

        var currentTouchIds = new Set();
        for(var i = 0; i < event.touches.length; i++) {
            currentTouchIds.add(event.touches[i].identifier);
        }

        for(var j = 0; j < touchIds.length; j++) {
            if(!currentTouchIds.has(touchIds[j])) {
                this.setState({
                    initialDocWidth: docWidth,
                    pinchEnded: true,
                    pinchDistance: 0
                })
            }
        }

        if(event.touches.length === 0) {
            this.setState({
                initialDocWidth: docWidth,
                pinchEnded: false,
                pinchDistance: 0,
                touchIds: []
            })
        }
    }

    onContainerLoad(ref) {
        this.docRef = ref;    
        this.setDocSize();
    }

    onDocumentLoadSuccess({numPages}) {
        var pages = [];
        for(var i = 1; i <= numPages; i++) {
            pages.push(i);
        }

        this.setState({
            pages
        });
    }

    setDocSize() {
        this.setState({
            docWidth: this.docRef ? this.docRef.getBoundingClientRect().width : 1,
            initialDocWidth: this.docRef ? this.docRef.getBoundingClientRect().width : 1
        });
    }
    
    //REACT API HOOKS
    render() {
        const { docWidth, pages } = this.state;
        const { filePath, loadingElement } = this.props;

        if(!filePath) {
            return null;
        }

        return (
            <div className={styles.container} touch-action="pan-x pan-y" ref={this.onContainerLoad} onWheel={this.handleWheel} onTouchStart={this.handleTouchStart} onTouchMove={this.handleTouchMove} onTouchEnd={this.handleTouchEnd} onTouchCancel={this.handleTouchCancel}>
                <Document className={styles.document} touch-action="pan-x pan-y" file={filePath} onLoadSuccess={this.onDocumentLoadSuccess} loading={loadingElement} renderMode={"svg"}>
                {
                    pages.map(p => {
                        return  <Page className={styles.page} touch-action="pan-x pan-y" key={p} loading={loadingElement} pageNumber={p} renderAnnotationLayer={false} renderTextLayer={false} width={docWidth}/>
                    })
                }
                </Document>
            </div>
        );
    }
}

PdfViewer.propTypes = {
    filePath: PropTypes.string,
    loadingElement: PropTypes.element
};
  
PdfViewer.defaultProps = {
    filePath: '',
    loadingElement: <div></div>
};

export { PdfViewer };