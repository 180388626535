import React from 'react';
import styles from './PasswordForm.module.css';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Label, Form, FormFeedback, FormGroup, Input } from 'reactstrap';
import { userActions, alertActions } from '_actions';
import { strongPassword, strongPasswordRequirementMessage } from '_helpers';

class ResetPasswordForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            password: '',
            password2: '',
            strongPass: false,
            strongPass2: false,
            submitted: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const { strongPass, strongPass2 } = this.state;
        const { name, value } = event.target;

        this.setState({
            [name]: value,
            strongPass: name === 'password' ? strongPassword(value) : strongPass,
            strongPass2: name === 'password2' ? strongPassword(value) : strongPass2,
        });
    }

    handleSubmit(e) {
        e.preventDefault(); //THIS IS SO FUCKING IMPORTANT, IT WILL BREAK REACT BECAUSE OF THINGS

        const { password, password2, strongPass, strongPass2 } = this.state;
        const { dispatch, uid, rid } = this.props;

        if (password && password2 && password === password2 && strongPass && strongPass2) {
            
            this.setState({
                submitted: true
            });

            dispatch(userActions.completeResetPassword({user_id: uid, reset_password_id: rid, password}));

        } else if(password && password2 && password !== password2 && strongPass && strongPass2) {

            dispatch(alertActions.error("Password entries did not match. Please enter password correctly and try again."));
        }
    }

    render() {
        const { password, password2, strongPass, strongPass2, submitted } = this.state;

        return (
            <Form onSubmit={this.handleSubmit} className={styles.form}>
                <FormGroup>
                    <Label>Enter New Password</Label>
                    <Input required disabled={submitted} valid={strongPass} invalid={password.length > 0 && !strongPass} type="password" name="password" value={password} onChange={this.handleChange}></Input>
                    <FormFeedback invalid="true">
                        {strongPasswordRequirementMessage()}
                    </FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label>Confirm New Password</Label>
                    <Input required disabled={submitted} valid={strongPass2} invalid={password2.length > 0 && !strongPass2} type="password" name="password2" value={password2} onChange={this.handleChange}></Input>
                    <FormFeedback invalid="true">
                        {strongPasswordRequirementMessage()}
                    </FormFeedback>
                </FormGroup>
                <Button color="primary" disabled={submitted} className={styles.submit}>Continue</Button>
                {submitted &&
                    <FormGroup>
                        <Link to="/login" className="btn btn-link">Go to login</Link>
                    </FormGroup>
                }
            </Form>
        );
    }
}

ResetPasswordForm.propTypes = {
    uid: PropTypes.string,
    rid: PropTypes.string
};

ResetPasswordForm.defaultProps = {
    uid: '',
    rid: ''
};

function mapStateToProps(state) {
    return {
    };
}

const connectedResetPasswordForm = connect(mapStateToProps)(ResetPasswordForm);
export { connectedResetPasswordForm as ResetPasswordForm };