import React from 'react';
import styles from './CompanyInfoPage.module.css';

import { connect } from 'react-redux';
import { CompanyForm } from '_components/CompanyForm';

class CompanyInfoPage extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    
    render() {
        return (
            <div className={styles.container}>
                <div className={styles.headingcontainer}>
                    <h4 className={styles.header}>Company Information</h4>
                </div>
                <CompanyForm></CompanyForm>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { authentication, company, relationships } = state;
    const { user } = authentication;
    return {
        company, user, relationships
    };
}

const connectedCompanyInfoPage = connect(mapStateToProps)(CompanyInfoPage);
export { connectedCompanyInfoPage as CompanyInfoPage };