import { activeConstants } from '_constants';
import { actionService, taskService } from '_services';
import { alertActions, userActions } from './';
import { history } from '_helpers';

export const activeActions = {
    completeAction,
    previewAction,
    updateActionRequirementSelection,
    updateActionRequirementSelectables,
    getAction,
    getActionRequirements,
    getActionRequirementsForReminder,
    getAllActions,
    getAllActionsForCurrentCompany,
    createTask,
    updateTask,
    cancelTask,
    getTask,
    getTaskSummary,
    getManagerTasks,
    getAllTasksForCurrentCompany,
    markEmployeeIncomplete,
    addEmployeeToTask,
    getTaskRequireCustomRole,
    getTaskAsset,
    getTaskRequiresEmployees,
    getTaskEmployeeSummaries
};

function completeAction(action){
    return dispatch => {
        dispatch(request(action));

        actionService.completeAction(action)
            .then(
                actionResult => {
                    dispatch(success(actionResult));
                    dispatch(alertActions.clear());
                    dispatch(userActions.refreshToken(localStorage.getItem('user')));
                    history.push('/company');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(currentAction) { return { type: activeConstants.ACTION_COMPLETE_REQUEST, currentAction } }
    function success(currentActionResult) { return { type: activeConstants.ACTION_COMPLETE_SUCCESS, currentActionResult } }
    function failure(error) { return { type: activeConstants.ACTION_COMPLETE_FAILURE, error } }
}

function previewAction(action){
    return dispatch => {
        dispatch(request(action));

        actionService.previewAction(action)
            .then(
                actionResult => {
                    dispatch(success(actionResult));
                    dispatch(alertActions.clear());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(currentAction) { return { type: activeConstants.ACTION_PREVIEW_REQUEST, currentAction } }
    function success(currentActionResult) { return { type: activeConstants.ACTION_PREVIEW_SUCCESS, currentActionResult } }
    function failure(error) { return { type: activeConstants.ACTION_PREVIEW_FAILURE, error } }
}

function updateActionRequirementSelection(updatedSelection) {
    return dispatch => {
        dispatch(update(updatedSelection));
    };

    function update(updatedSelection) { return { type: activeConstants.ACTION_REQUIREMENTS_UPDATE, updatedSelection } }
}

function updateActionRequirementSelectables(updatedSelectables) {
    return dispatch => {
        dispatch(update(updatedSelectables));
    };

    function update(updatedSelectables) { return { type: activeConstants.ACTION_REQUIREMENTS_SELECTABLES_UPDATE, updatedSelectables } }
}

function getAction(actionId){
    return dispatch => {
        dispatch(request({ actionId }));
        
        actionService.get(actionId)
            .then(
                action => {
                    dispatch(success(action));
                    dispatch(alertActions.clear());
                    history.push('/action');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(actionId) { return { type: activeConstants.ACTION_GET_REQUEST, actionId } }
    function success(currentAction) { return { type: activeConstants.ACTION_GET_SUCCESS, currentAction } }
    function failure(error) { return { type: activeConstants.ACTION_GET_FAILURE, error } }
}

function getActionRequirements(actionId, companyId){
    return dispatch => {
        dispatch(request({ actionId, companyId }));
        
        actionService.getActionRequirements(actionId, companyId)
            .then(
                action => {
                    dispatch(success(action));
                    dispatch(alertActions.clear());
                    history.push('/action');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(request) { return { type: activeConstants.ACTION_REQUIREMENTS_GET_REQUEST, request } }
    function success(currentActionRequirements) { return { type: activeConstants.ACTION_REQUIREMENTS_GET_SUCCESS, currentActionRequirements } }
    function failure(error) { return { type: activeConstants.ACTION_REQUIREMENTS_GET_FAILURE, error } }
}

function getActionRequirementsForReminder(reminderId){
    return dispatch => {
        dispatch(request(reminderId));
        
        actionService.getActionRequirementsForReminder(reminderId)
            .then(
                response => {
                    dispatch(success(response));
                    dispatch(alertActions.clear());
                    history.push('/action');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(reminderId) { return { type: activeConstants.REMINDER_ACTION_REQUIREMENTS_GET_REQUEST, reminderId } }
    function success(currentActionRequirements) { return { type: activeConstants.REMINDER_ACTION_REQUIREMENTS_GET_SUCCESS, currentActionRequirements } }
    function failure(error) { return { type: activeConstants.REMINDER_ACTION_REQUIREMENTS_GET_FAILURE, error } }
}

function getAllActions(filter){
    return dispatch => {
        dispatch(request({ filter }));

        actionService.getAllByFilterForUser(filter)
            .then(
                actions => {
                    dispatch(success(actions));
                    dispatch(alertActions.clear());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(filter) { return { type: activeConstants.ACTIONS_GET_REQUEST, filter } }
    function success(actions) { return { type: activeConstants.ACTIONS_GET_SUCCESS, actions } }
    function failure(error) { return { type: activeConstants.ACTIONS_GET_FAILURE, error } }
}

function getAllActionsForCurrentCompany(filter){
    return dispatch => {
        dispatch(request({ filter }));

        actionService.getAllByFilterForCompanyAndUser(filter)
            .then(
                actions => {
                    dispatch(success(actions));
                    dispatch(alertActions.clear());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(filter) { return { type: activeConstants.ACTIONS_GET_REQUEST, filter } }
    function success(actions) { return { type: activeConstants.ACTIONS_GET_SUCCESS, actions } }
    function failure(error) { return { type: activeConstants.ACTIONS_GET_FAILURE, error } }
}

function createTask(task){
    return dispatch => {
        dispatch(request(task));

        taskService.create(task)
            .then(
                task => {
                    dispatch(success(task));
                    dispatch(alertActions.clear());
                    history.push('/company');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(currentTask) { return { type: activeConstants.TASK_CREATE_REQUEST, currentTask } }
    function success(currentTask) { return { type: activeConstants.TASK_CREATE_SUCCESS, currentTask } }
    function failure(error) { return { type: activeConstants.TASK_CREATE_FAILURE, error } }
}

function cancelTask(taskId){
    return dispatch => {
        dispatch(request(taskId));

        taskService.cancel(taskId)
            .then(
                task => {
                    dispatch(success(task));
                    dispatch(alertActions.clear());
                    history.push('/company');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(filter) { return { type: activeConstants.TASK_CANCEL_REQUEST, filter } }
    function success(currentTask) { return { type: activeConstants.TASK_CANCEL_SUCCESS, currentTask } }
    function failure(error) { return { type: activeConstants.TASK_CANCEL_FAILURE, error } }
}

function updateTask(task){
    return dispatch => {
        dispatch(request({ task }));

        taskService.update(task)
            .then(
                task => {
                    dispatch(success(task));
                    dispatch(alertActions.clear());
                    history.push('/task');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(currentTask) { return { type: activeConstants.TASK_UPDATE_REQUEST, currentTask } }
    function success(currentTask) { return { type: activeConstants.TASK_UPDATE_SUCCESS, currentTask } }
    function failure(error) { return { type: activeConstants.TASK_UPDATE_FAILURE, error } }
}

function getTask(taskId) {
    return dispatch => {
        dispatch(request(taskId));

        taskService.get(taskId)
            .then(
                task => {
                    dispatch(success(task));
                    dispatch(alertActions.clear());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(filter) { return { type: activeConstants.TASK_GET_REQUEST, filter } }
    function success(currentTask) { return { type: activeConstants.TASK_GET_SUCCESS, currentTask } }
    function failure(error) { return { type: activeConstants.TASK_GET_FAILURE, error } }
}

function getTaskSummary(taskId, dontPush) {
    return dispatch => {
        dispatch(request(taskId));

        taskService.getSummary(taskId)
            .then(
                task => {
                    dispatch(success(task));
                    dispatch(alertActions.clear());

                    if(!dontPush) {
                        history.push('/company/tasks/summary');
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(filter) { return { type: activeConstants.TASK_GET_REQUEST, filter } }
    function success(currentTask) { return { type: activeConstants.TASK_GET_SUCCESS, currentTask } }
    function failure(error) { return { type: activeConstants.TASK_GET_FAILURE, error } }
}

function getManagerTasks(filter){
    return dispatch => {
        dispatch(request(filter));

        taskService.getAllForManager(filter)
            .then(
                tasks => {
                    dispatch(success(tasks));
                    dispatch(alertActions.clear());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(filter) { return { type: activeConstants.TASKS_GET_REQUEST, filter } }
    function success(tasks) { return { type: activeConstants.TASKS_GET_SUCCESS, tasks } }
    function failure(error) { return { type: activeConstants.TASKS_GET_FAILURE, error } }
}

function getAllTasksForCurrentCompany(filter) {
    return dispatch => {
        dispatch(request(filter));

        taskService.getAllForCompany(filter)
            .then(
                tasks => {
                    dispatch(success(tasks));
                    dispatch(alertActions.clear());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(filter) { return { type: activeConstants.TASKS_GET_REQUEST, filter } }
    function success(tasks) { return { type: activeConstants.TASKS_GET_SUCCESS, tasks } }
    function failure(error) { return { type: activeConstants.TASKS_GET_FAILURE, error } }
}

function markEmployeeIncomplete(employeeRequest) {
    return dispatch => {
        dispatch(request(employeeRequest));

        taskService.markEmployeeIncomplete(employeeRequest)
            .then(
                task => {
                    dispatch(success(task));
                    dispatch(getTaskSummary(task.task_id, true));
                    dispatch(alertActions.clear());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(request) { return { type: activeConstants.TASK_MARK_EMPLOYEE_INCOMPLETE_REQUEST, request } }
    function success(task) { return { type: activeConstants.TASK_MARK_EMPLOYEE_INCOMPLETE_SUCCESS, task } }
    function failure(error) { return { type: activeConstants.TASK_MARK_EMPLOYEE_INCOMPLETE_FAILURE, error } }
}

function addEmployeeToTask(employeeRequest) {
    return dispatch => {
        dispatch(request(employeeRequest));

        taskService.addEmployeeToTask(employeeRequest)
            .then(
                task => {
                    dispatch(success(task));
                    dispatch(getTaskSummary(task.task_id, true));
                    dispatch(alertActions.clear());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(request) { return { type: activeConstants.TASK_ADD_EMPLOYEE_REQUEST, request } }
    function success(task) { return { type: activeConstants.TASK_ADD_EMPLOYEE_SUCCESS, task } }
    function failure(error) { return { type: activeConstants.TASK_ADD_EMPLOYEE_FAILURE, error } }
}

function getTaskRequireCustomRole(taskId) {
    return dispatch => {
        dispatch(request(taskId));

        taskService.getTaskRequireCustomRole(taskId)
            .then(requiresCustomRole => {
                    dispatch(success(requiresCustomRole));
                    dispatch(alertActions.clear());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(taskId) { return { type: activeConstants.TASK_GET_ROLE_REQUIREMENT_REQUEST, taskId } }
    function success(requiresCustomRole) { return { type: activeConstants.TASK_GET_ROLE_REQUIREMENT_SUCCESS, requiresCustomRole } }
    function failure(error) { return { type: activeConstants.TASK_GET_ROLE_REQUIREMENT_FAILURE, error } }
}

function getTaskAsset(taskId) {
    return dispatch => {
        dispatch(request(taskId));

        taskService.getTaskAsset(taskId)
            .then(asset => {
                    dispatch(success(asset));
                    dispatch(alertActions.clear());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(taskId) { return { type: activeConstants.TASK_GET_TASK_ASSET_REQUEST, taskId } }
    function success(asset) { return { type: activeConstants.TASK_GET_TASK_ASSET_SUCCESS, asset } }
    function failure(error) { return { type: activeConstants.TASK_GET_TASK_ASSET_FAILURE, error } }
}

function getTaskRequiresEmployees(taskId) {
    return dispatch => {
        dispatch(request(taskId));

        taskService.getTaskRequiresEmployees(taskId)
            .then(requiresEmployees => {
                    dispatch(success(requiresEmployees));
                    dispatch(alertActions.clear());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(taskId) { return { type: activeConstants.TASK_GET_TASK_REQUIRES_EMPLOYEES_REQUEST, taskId } }
    function success(requiresEmployees) { return { type: activeConstants.TASK_GET_TASK_REQUIRES_EMPLOYEES_SUCCESS, requiresEmployees } }
    function failure(error) { return { type: activeConstants.TASK_GET_TASK_REQUIRES_EMPLOYEES_FAILURE, error } }
}

function getTaskEmployeeSummaries(userId) {
    return dispatch => {
        dispatch(request(userId));

        taskService.getTaskEmployeeSummaries(userId)
            .then(summaries => {
                    dispatch(success(summaries));
                    dispatch(alertActions.clear());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(userId) { return { type: activeConstants.TASK_GET_TASK_EMPLOYEE_SUMMARIES_REQUEST, userId } }
    function success(summaries) { return { type: activeConstants.TASK_GET_TASK_EMPLOYEE_SUMMARIES_SUCCESS, summaries } }
    function failure(error) { return { type: activeConstants.TASK_GET_TASK_EMPLOYEE_SUMMARIES_FAILURE, error } }
}