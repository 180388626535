import { reminderConstants } from '_constants';

export function reminder(state = {}, reduxAction) {
    switch (reduxAction.type) {
        case reminderConstants.GET_USER_REMINDERS_REQUEST:
        case reminderConstants.GET_USER_REMINDERS_FAILURE:
        case reminderConstants.GET_COMPANY_REMINDERS_REQUEST:
        case reminderConstants.GET_COMPANY_REMINDERS_FAILURE:
            return {
                reminders: []
            };
        case reminderConstants.GET_USER_REMINDERS_SUCCESS:
        case reminderConstants.GET_COMPANY_REMINDERS_SUCCESS:
            return {
                reminders: reduxAction.reminders
            };
        case reminderConstants.NAVIGATE_TO_CREATE_REMINDER_REQUEST:
            return{
                date: reduxAction.enddate
            };
        case reminderConstants.CREATE_COMPANY_REMINDER_REQUEST:
        case reminderConstants.CREATE_COMPANY_REMINDER_FAILURE:
            return {

            };
        case reminderConstants.CREATE_COMPANY_REMINDER_SUCCESS:
            return {
                
            };
        case reminderConstants.GET_REMINDER_DETAILS_REQUEST:
        case reminderConstants.GET_REMINDER_DETAILS_FAILURE:
            return {
                ...state,
                currentReminder: null
            };
        case reminderConstants.GET_REMINDER_DETAILS_SUCCESS:
            return {
                ...state,
                currentReminder: reduxAction.currentReminder
            };
        default:
            return state;
    }
}