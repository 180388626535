import React from 'react';
import styles from './CreateTaskPage.module.css';

import { connect } from 'react-redux';
import { TaskTemplateSelector } from '_components/TaskTemplateSelector';
import { TaskTemplateRequirements } from '_components/TaskTemplateRequirements';
import { templateActions } from '_actions';

class CreateTaskPage extends React.Component {
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(templateActions.getTaskTemplates('active'));
    }

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.headingcontainer}>
                    <h4 className={styles.header}>Create A New Task</h4>
                </div>
                <TaskTemplateSelector/>
                <TaskTemplateRequirements/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

const connectedCreateTaskPage = connect(mapStateToProps)(CreateTaskPage);
export { connectedCreateTaskPage as CreateTaskPage };