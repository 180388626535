import React from 'react';
import styles from './CompanyRemindersLogPage.module.css';
import { connect } from 'react-redux';
import { ReminderLogTable } from '_components/ReminderLogTable';

class CompanyRemindersLogPage extends React.Component {  
    render() {
        const { company } = this.props;

        return (
            <div className={styles.container}>
                <div className={styles.headingcontainer}>
                    <h4 className={styles.header}>{company.company_name} Reminders</h4>
                </div>
                <ReminderLogTable></ReminderLogTable>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { company, reminder } = state;
    const { reminders } = reminder;

    return {
        company, 
        reminders
    };
}

const connectedCompanyRemidnersLogPage = connect(mapStateToProps)(CompanyRemindersLogPage);
export { connectedCompanyRemidnersLogPage as CompanyRemindersLogPage };