import React from 'react';
import styles from './CompanyForm.module.css';
import PropTypes from 'prop-types';
import termsPdf from '_pdfs/Terms and Conditions.pdf';

import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import ReactMde from "react-mde";
import Markdown from 'markdown-to-jsx';
import * as loadImage from 'blueimp-load-image';
import { currentCompanyRole } from '_helpers';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Label, Form, FormGroup, Input } from 'reactstrap';
import { companyActions } from '_actions';
import { convertDate } from '_helpers';
import { SignatureModal } from '_components/SignatureModal';

class CompanyForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            company: props.company, //this takes the company out of redux store and copies it to local component state so that changes dont take effect until confirmed
            selectedMdeTab: 'write'
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleInformationChange = this.handleInformationChange.bind(this);
        this.handleMdeTabChange = this.handleMdeTabChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.saveSignatureCallback = this.saveSignatureCallback.bind(this);
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { company } = this.state;

        this.setState({
            company: {
                ...company,
                [name]: value
            }
        });
    }

    handleFileChange(event) {
        var file = event.target.files[0];
        
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
            loadImage(reader.result, (image) => {
                const { company } = this.state;
                company.company_local_logo = image.src;

                this.setState({
                    company
                });

            }, {orientation: true, minWidth: 320, maxWidth: 320, minHeight: 240, maxHeight: 240});
        }.bind(this);
    }

    handleInformationChange(newInfo) {
        const { company } = this.state;
        
        this.setState({
            company: {
                ...company,
                company_information: newInfo
            }
        });
    }

    handleMdeTabChange(newTab) {
        this.setState({
            selectedMdeTab: newTab
        });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ 
            submitted: true 
        });
        
        const { company } = this.state;
        const { dispatch } = this.props;

        if(company.company_local_logo) {
            company.company_logo = company.company_local_logo;
        }

        dispatch(companyActions.update(company));
    }

    saveSignatureCallback(e) {
        const { dispatch } = this.props;
        const { company } = this.state;

        var updatedCompany = {
            ...company,
            owner_signature: e.image
        };

        if(company.company_local_logo) {
            updatedCompany.company_logo = company.company_local_logo;
        }

        dispatch(companyActions.completeCompany(updatedCompany));
    }

    render() {
        const { acceptCompany } = this.props;
        const { company, selectedMdeTab } = this.state;
        
        return (
            <div className={styles.container}>
                <Form onSubmit={this.handleSubmit}>
                    <FormGroup>
                        <Label>Company Name</Label>
                        <Input required readOnly name="company_name" value={company.company_name}></Input>
                    </FormGroup>
                    <FormGroup>
                        <Label>Create Date</Label>
                        <DateTimePicker disabled readOnly name="create_date" defaultValue={convertDate(company.create_date)}></DateTimePicker>
                    </FormGroup>
                    <FormGroup>
                        <Label>Company Description</Label>
                        <Input required name="company_description" value={company.company_description} onChange={this.handleChange}></Input>
                    </FormGroup>
                    <FormGroup className={styles.sidebyside}>
                        <div>
                            <Label>Company Logo</Label>
                            <Input type="file" name="company_local_logo" accept=".jpg, .jpeg, .png, .bmp, .gif" onChange={this.handleFileChange}></Input>
                        </div>
                        {company.company_local_logo &&
                            <img className={styles.imgpreview} src={company.company_local_logo} alt="Something went wrong :("></img>
                        }
                    </FormGroup>
                    {!acceptCompany &&
                    <FormGroup>
                        <Label>Bulletin Board</Label>
                        <ReactMde   minEditorHeight={400}
                                    defaultValue={company.company_information || ''}
                                    value={company.company_information || ''}
                                    onChange={this.handleInformationChange}
                                    selectedTab={selectedMdeTab}
                                    onTabChange={this.handleMdeTabChange}
                                    generateMarkdownPreview={(markdown) => Promise.resolve(
                                        <div className={styles.boardcontainer}>
                                            <div className={styles.boardheadingcontainer}>
                                                <div className={styles.heading}>Bulletin Board</div>
                                            </div>
                                            <div className={styles.board}>
                                                <Markdown>
                                                    {markdown}
                                                </Markdown>
                                            </div>
                                        </div>
                                    )}/>
                    </FormGroup>
                    }
                    {acceptCompany &&
                    <div>
                        <FormGroup className={styles.confirmcontainer}>
                            <Label>Create Company Below By Signing and Agreeing To Our Terms</Label>
                            <SignatureModal buttonColor={"primary"} buttonText={'Create Company Account'} id={'owner_signature'} disabled={company.owner_signature ? true : false} saveCallback={this.saveSignatureCallback} stripMetaData={false}/>
                        </FormGroup>
                        <FormGroup>
                            <span className={styles.policy}>By clicking Create Company Account, you agree to our <a href={termsPdf} target="_blank" rel="noopener noreferrer">Customer Terms</a> and understand your responsibility of maintaining a company account on TackleDocs.</span>
                        </FormGroup>
                    </div>
                    }
                    {!acceptCompany &&
                    <FormGroup>
                        <Button color="primary">Update</Button>
                        <Link to="/company" className="btn btn-link">Cancel</Link>
                    </FormGroup>
                    }
                </Form>
                {currentCompanyRole() >= 4 && !acceptCompany &&
                    <div className={styles.activitycontainer}>
                        <Link to="/company/updatebilling" className="btn btn-link">Update you Billing Info here.</Link>
                    </div>
                }
            </div>
        );
    }
}



CompanyForm.propTypes = {
    acceptCompany: PropTypes.bool
};
  
CompanyForm.defaultProps = {
    acceptCompany: false
};

function mapStateToProps(state) {
    const { company } = state;

    return {
        company
    };
}

const connectedCompanyForm = connect(mapStateToProps)(CompanyForm);
export { connectedCompanyForm as CompanyForm };