import React from 'react';
import styles from './MyDocumentsPage.module.css';

import { connect } from 'react-redux';
import { DocumentTable } from '_components/DocumentTable';

class MyDocumentsPage extends React.Component 
{
    render() {
        return (
            <div className={styles.container}>
                <div className={styles.headingcontainer}>
                    <h4 className={styles.heading}>My Documents</h4>
                </div>
                <DocumentTable allCompanies={true}/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedMyCMyDocumentsPage = connect(mapStateToProps)(MyDocumentsPage);
export { connectedMyCMyDocumentsPage as MyDocumentsPage };