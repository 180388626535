export function companyRole(role, subRole) {
    switch(role) {
        case 1:
          return "Employee";
        case 2:
          return "Manager";
        case 3:
          return adminSubRole(subRole);
        case 4:
          return "Owner";
        default:
          return "Unknown";
    }
}

export function siteRole(role) {
  switch(role) {
      case 1:
        return "User";
      case 2:
        return "Admin";
      case 3:
        return "Developer";
      case 4:
        return "Owner";
      default:
        return "Unknown";
  }
}

export function adminSubRole(subRole) {
  switch(subRole) {
    case 1:
      return "Admin (Full)";
    default:
      return "Admin (Limited)";
  }
}

export function possibleRoles() {
  return [
    {role: 1, subRole: 0},
    {role: 2, subRole: 0},
    {role: 3, subRole: 0},
    {role: 3, subRole: 1}
  ];
}