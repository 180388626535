import React from 'react';
import styles from './PasswordForm.module.css';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Label, Form, FormFeedback, FormGroup, Input } from 'reactstrap';
import { alertActions, userActions } from '_actions';
import { strongPassword, strongPasswordRequirementMessage } from '_helpers';

class PasswordForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {        
            user: props.user,
            current_password: '',
            password: '',
            password2: '',    
            strongPass: false,
            strongPass2: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const { strongPass, strongPass2 } = this.state;
        const { name, value } = event.target;

        this.setState({
            [name]: value,          
            strongPass: name === 'password' ? strongPassword(value) : strongPass,
            strongPass2: name === 'password2' ? strongPassword(value) : strongPass2,
        });
    }

    handleSubmit(e) {
        e.preventDefault(); //THIS IS SO FUCKING IMPORTANT, IT WILL BREAK REACT BECAUSE OF THINGS
        
        const { user, current_password, password, password2, strongPass, strongPass2 } = this.state;
        const { dispatch } = this.props;

        if (password && password2 && password === password2 && strongPass && strongPass2) {
            
            user['current_password'] = current_password;
            user['password'] = password;
            dispatch(userActions.update(user));

        } else if(password && password2 && password !== password2 && strongPass && strongPass2) {

            dispatch(alertActions.error("Your new password entries did not match. Please enter password correctly and try again."));
        }
    }

    render() {
        const { current_password, password, password2, strongPass, strongPass2 } = this.state;

        return (
            <Form onSubmit={this.handleSubmit} className={styles.form}>
                <FormGroup>
                    <Label>Current Password</Label>
                    <Input required autoComplete="new-password" type="password" name="current_password" value={current_password} onChange={this.handleChange}></Input>
                </FormGroup>
                <FormGroup>
                    <Label>New Password</Label>
                    <Input required autoComplete="new-password" valid={strongPass} invalid={password.length > 0 && !strongPass} type="password" name="password" value={password} onChange={this.handleChange}></Input>
                    <FormFeedback invalid="true">
                        {strongPasswordRequirementMessage()}
                    </FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label>Confirm New Password</Label>
                    <Input required autoComplete="new-password" valid={strongPass2} invalid={password2.length > 0 && !strongPass2} type="password" name="password2" value={password2} onChange={this.handleChange}></Input>
                    <FormFeedback invalid="true">
                        {strongPasswordRequirementMessage()}
                    </FormFeedback>
                </FormGroup>
                <Button color="primary" className={styles.submit}>Update</Button>
                <Link to="/home" className="btn btn-link">Cancel</Link>
            </Form>
        );
    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    const { user } = authentication;

    return {
        user
    };
}

const connectedPasswordForm = connect(mapStateToProps)(PasswordForm);
export { connectedPasswordForm as PasswordForm };