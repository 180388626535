export function isUserInfo(type) {
    return type === 4 || type === 15 || type === 7 || type === 21 || type === 22 || type === 23 || type === 30 || type === 31;
}

export function isUserSignature(type) {
    return type === 9 || type === 12 || type === 16;
}

export function isQuestion(type) {
    return type === 6;
}

export function isSelect(type) {
    return type === 22 || type === 23 || type === 24 || type === 29;
}

export function getSelectionFromType(type, option) {
    if(type === 24 || type === 29) {
        return option.name;
    }
    
    return option.option_id;
}

export function isPartOfTable(type, subType) {
    return (type === 7 || type === 8 || type === 26) && subType === 8;
}

export function getSelectionFromKey(selections, key) {
    if(selections && selections.length > 0) {
        for(var i = 0; i < selections.length; i++) {
            if(selections[i].key === key) {
                return selections[i].selection;
            }
        }
    } else if (selections && selections.length !== 0) {
        return selections[key];
    }
}

export function getConditionalRequirements(requirements, selection) {
    if(requirements && requirements[selection]) {
        return requirements[selection];
    }
}

export function getContentRequirement(requirements, contentId) {
    if(requirements) {
        for(var i = 0; i < requirements.length; i++) {
            var content = requirements[i];

            if(content.content_id === contentId){
                return content;
            }
        }
    }

    return null;
}

export function getActionRequirement(requirements, key) {
    if(requirements) {
        for(var i = 0; i < requirements.length; i++) {
            var req = requirements[i];

            if(req.key === key){
                return req;
            }
        }
    }

    return null;
}