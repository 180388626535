import { assetConstants } from '_constants';
import { assetService } from '_services';
import { alertActions } from './';
import { history } from '_helpers';

export const assetActions = {
    getAssets,
    getAsset,
    getAssetTemplates,
    getAssetTemplate,
    updateAssetTemplateRequirementSelection,
    createAsset,
    updateAsset,
    createAssetTemplate,
    updateAssetTemplate,
    getAssetAnnualSummary,
    getAssetAnnualOrganizationSummary,
    getAssetAnnualDetailedReport,
    viewAssetAnnualDetails,
    createUpdateAssetDetails,
    getAssetDetailLimits,
    getOrganizationAssetDetailLimits,
    updateAssetDetailLimits,
    updateOrganizationAssetDetailLimits,
    updateAssetDetailsLimitSelections
};

function getAssets(filter) {
    return dispatch => {
        dispatch(request(filter));

        assetService.getAllCompanyAssets(filter)
            .then(
                assets => {
                    dispatch(success(assets));
                    dispatch(alertActions.clear());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(filter) { return { type: assetConstants.ASSETS_GET_REQUEST, filter } }
    function success(assets) { return { type: assetConstants.ASSETS_GET_SUCCESS, assets } }
    function failure(error) { return { type: assetConstants.ASSETS_GET_FAILURE, error } }
}

function getAsset(assetId) {
    return dispatch => {
        dispatch(request(assetId));

        assetService.getAsset(assetId)
            .then(
                asset => {
                    dispatch(success(asset));
                    dispatch(alertActions.clear());
                    history.push('/company/assets/edit');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(assetId) { return { type: assetConstants.ASSET_GET_REQUEST, assetId } }
    function success(asset) { return { type: assetConstants.ASSET_GET_SUCCESS, asset } }
    function failure(error) { return { type: assetConstants.ASSET_GET_FAILURE, error } }
}

function getAssetTemplates(filter) {
    return  dispatch => {
        dispatch(request(filter));
        
        assetService.getAssetTemplates(filter)
            .then(
                assetTemplates => {
                    dispatch(success(assetTemplates));
                    dispatch(alertActions.clear());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
            
    function request(filter) { return { type: assetConstants.ASSET_TEMPLATES_GET_REQUEST, filter } }
    function success(assetTemplates) { return { type: assetConstants.ASSET_TEMPLATES_GET_SUCCESS, assetTemplates } }
    function failure(error) { return { type: assetConstants.ASSET_TEMPLATES_GET_FAILURE, error } }
}

function getAssetTemplate(assetTemplateType, editMode) {
    return dispatch => {     
        dispatch(request(assetTemplateType));

        assetService.getAssetTemplate(assetTemplateType)
            .then(
                assetTemplate => { 
                    dispatch(success(assetTemplate));
                    dispatch(alertActions.clear());

                    if(editMode) {
                        history.push('/company/assets/templates/edit');
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(assetTemplateType) { return { type: assetConstants.ASSET_TEMPLATE_GET_REQUEST, assetTemplateType } }
    function success(assetTemplate) { return { type: assetConstants.ASSET_TEMPLATE_GET_SUCCESS, assetTemplate } }
    function failure(error) { return { type: assetConstants.ASSET_TEMPLATE_GET_FAILURE, error } }
}

function updateAssetTemplateRequirementSelection(updatedSelection) {
    return dispatch => {  
        dispatch(update(updatedSelection));
    };

    function update(updatedSelection) { return { type: assetConstants.UPDATE_ASSET_REQUIREMENTS_SELECTION, updatedSelection } }
}

function createAsset(asset) {
    return dispatch => {
        dispatch(request(asset));

        assetService.create(asset)
            .then(
                asset => {
                    dispatch(success(asset));
                    dispatch(assetActions.getAssets('active'));
                    dispatch(alertActions.clear());
                    history.goBack();
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(asset) { return { type: assetConstants.ASSET_CREATE_REQUEST, asset } }
    function success(asset) { return { type: assetConstants.ASSET_CREATE_SUCCESS, asset } }
    function failure(error) { return { type: assetConstants.ASSET_CREATE_FAILURE, error } }
}

function updateAsset(asset) {
    return dispatch => {
        dispatch(request(asset));

        assetService.update(asset)
            .then(
                asset => {
                    dispatch(success(asset));
                    dispatch(alertActions.clear());
                    history.replace('/company/assets');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(asset) { return { type: assetConstants.ASSET_UPDATE_REQUEST, asset } }
    function success(asset) { return { type: assetConstants.ASSET_UPDATE_SUCCESS, asset } }
    function failure(asset) { return { type: assetConstants.ASSET_UPDATE_FAILURE, asset } }
}

function createAssetTemplate(assetTemplate) {
    return dispatch => {
        dispatch(request(assetTemplate));

        assetService.createAssetTemplate(assetTemplate)
            .then(
                asset => {
                    dispatch(success(asset));
                    dispatch(alertActions.clear());
                    history.goBack();
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(assetTemplate) { return { type: assetConstants.ASSET_TEMPLATE_CREATE_REQUEST, assetTemplate } }
    function success(assetTemplate) { return { type: assetConstants.ASSET_TEMPLATE_CREATE_SUCCESS, assetTemplate } }
    function failure(error) { return { type: assetConstants.ASSET_TEMPLATE_CREATE_FAILURE, error } }
}

function updateAssetTemplate(assetTemplate) {
    return dispatch => {
        dispatch(request(assetTemplate));

        assetService.updateAssetTemplate(assetTemplate)
            .then(
                asset => {
                    dispatch(success(asset));
                    dispatch(alertActions.clear());
                    history.goBack();
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(assetTemplate) { return { type: assetConstants.ASSET_TEMPLATE_UPDATE_REQUEST, assetTemplate } }
    function success(assetTemplate) { return { type: assetConstants.ASSET_TEMPLATE_UPDATE_SUCCESS, assetTemplate } }
    function failure(error) { return { type: assetConstants.ASSET_TEMPLATE_UPDATE_FAILURE, error } }
}

function getAssetAnnualSummary(year) {
    return  dispatch => {
        dispatch(request(year));
        
        assetService.getAssetAnnualSummary(year)
        .then(
            assetDetails => {
                dispatch(success(assetDetails));
                dispatch(alertActions.clear());
            },
            error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    };
    
    function request(year) { return { type: assetConstants.ASSET_GET_ANNUAL_SUMMARY_REQUEST, year } }
    function success(assetDetails) { return { type: assetConstants.ASSET_GET_ANNUAL_SUMMARY_SUCCESS, assetDetails } }
    function failure(error) { return { type: assetConstants.ASSET_GET_ANNUAL_SUMMARY_FAILURE, error } }
}

function getAssetAnnualOrganizationSummary(year) {
    return  dispatch => {
        dispatch(request(year));
        
        assetService.getAssetAnnualOrganizationSummary(year)
        .then(
            assetDetails => {
                dispatch(success(assetDetails));
                dispatch(alertActions.clear());
            },
            error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    };
    
    function request(year) { return { type: assetConstants.ASSET_GET_ANNUAL_ORG_SUMMARY_REQUEST, year } }
    function success(assetDetails) { return { type: assetConstants.ASSET_GET_ANNUAL_ORG_SUMMARY_SUCCESS, assetDetails } }
    function failure(error) { return { type: assetConstants.ASSET_GET_ANNUAL_ORG_SUMMARY_FAILURE, error } }
}

function getAssetAnnualDetailedReport(asset_id, year, userId) {
    return  dispatch => {
        dispatch(request({asset_id, year}));
        
        assetService.getAssetAnnualDetailedReport(asset_id, year, userId)
        .then(
            assetDetailedReport => {
                dispatch(success(assetDetailedReport));
                dispatch(alertActions.clear());
            },
            error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    };
    
    function request(request) { return { type: assetConstants.ASSET_GET_ANNUAL_DETAIED_REPORT_REQUEST, request } }
    function success(assetDetailedReport) { return { type: assetConstants.ASSET_GET_ANNUAL_DETAIED_REPORT_SUCCESS, assetDetailedReport } }
    function failure(error) { return { type: assetConstants.ASSET_GET_ANNUAL_DETAIED_REPORT_FAILURE, error } }
} 

function createUpdateAssetDetails(poundsObject, assetId, year) {
    return dispatch => {
        dispatch(request(poundsObject));
            assetService.createUpdateAssetDetails(assetId, year, poundsObject).then(
                poundsObject => {
                    dispatch(success(poundsObject));
                    dispatch(assetActions.getAssetAnnualDetailedReport(assetId, year));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(poundsObject) { return { type: assetConstants.ASSET_CUPDATE_ANNUAL_DETAILS_REQUEST, poundsObject } }
    function success(poundsObject) { return { type: assetConstants.ASSET_CUPDATE_ANNUAL_DETAILS_SUCCESS, poundsObject } }
    function failure(error) { return { type: assetConstants.ASSET_CUPDATE_ANNUAL_DETAILS_FAILURE, error } }
}

function viewAssetAnnualDetails(assetId, year) {
    return dispatch => {
        dispatch(view({ assetId, year }));
        dispatch(getAssetAnnualDetailedReport(assetId, year));
        dispatch(getAssetDetailLimits(assetId, year));
        history.push('/company/pounds/details');
    };

    function view(viewParameters) { return { type: assetConstants.ASSET_VIEW_ASSET_ANNUAL_DETAILS, viewParameters } }
}

function getAssetDetailLimits(assetId, year) {
    return  dispatch => {
        dispatch(request({assetId, year}));
        
        assetService.getAssetDetailLimits(assetId, year)
        .then(
            limits => {
                dispatch(success(limits));
                dispatch(alertActions.clear());
            },
            error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    };
    
    function request(request) { return { type: assetConstants.ASSET_GET_DETAILS_LIMITS_REQUEST, request } }
    function success(limits) { return { type: assetConstants.ASSET_GET_DETAILS_LIMITS_SUCCESS, limits } }
    function failure(error) { return { type: assetConstants.ASSET_GET_DETAILS_LIMITS_FAILURE, error } }
}

function getOrganizationAssetDetailLimits(year) {
    return  dispatch => {
        dispatch(request({year}));
        
        assetService.getOrganizationAssetDetailLimits(year)
        .then(
            limits => {
                dispatch(success(limits));
                dispatch(alertActions.clear());
            },
            error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    };
    
    function request(request) { return { type: assetConstants.ASSET_GET_DETAILS_LIMITS_REQUEST, request } }
    function success(limits) { return { type: assetConstants.ASSET_GET_DETAILS_LIMITS_SUCCESS, limits } }
    function failure(error) { return { type: assetConstants.ASSET_GET_DETAILS_LIMITS_FAILURE, error } }
}

function updateAssetDetailLimits(assetId, year, limits) {
    return  dispatch => {
        dispatch(request({assetId, year, limits}));
        
        assetService.updateAssetDetailLimits(assetId, year, limits)
        .then(
            limits => {
                dispatch(success(limits));
                dispatch(alertActions.clear());
            },
            error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    };
    
    function request(request) { return { type: assetConstants.ASSET_UPDATE_DETAILS_LIMITS_REQUEST, request } }
    function success(limits) { return { type: assetConstants.ASSET_UPDATE_DETAILS_LIMITS_SUCCESS, limits } }
    function failure(error) { return { type: assetConstants.ASSET_UPDATE_DETAILS_LIMITS_FAILURE, error } }
}

function updateOrganizationAssetDetailLimits(year, limits) {
    return  dispatch => {
        dispatch(request({year, limits}));
        
        assetService.updateOrganizationAssetDetailLimits(year, limits)
        .then(
            limits => {
                dispatch(success(limits));
                dispatch(alertActions.clear());
            },
            error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    };
    
    function request(request) { return { type: assetConstants.ASSET_UPDATE_DETAILS_LIMITS_REQUEST, request } }
    function success(limits) { return { type: assetConstants.ASSET_UPDATE_DETAILS_LIMITS_SUCCESS, limits } }
    function failure(error) { return { type: assetConstants.ASSET_UPDATE_DETAILS_LIMITS_FAILURE, error } }
}

function updateAssetDetailsLimitSelections(limit) {
    return dispatch => {  
        dispatch(update(limit));
    };

    function update(limit) { return { type: assetConstants.ASSET_DETAILS_LIMITS_UPDATE, limit } }
}