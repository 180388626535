import React from 'react';
import styles from './CompleteTaskRequirementsAction.module.css';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';

import { connect } from 'react-redux';
import { EmployeeSelector } from '_components/EmployeeSelector';
import { Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { ConfirmationModal } from '_components/ConfirmationModal';
import { SignatureModal } from '_components/SignatureModal';
import { FileUploadModal } from '_components/FileUploadModal';
import { activeActions, alertActions } from '_actions';
import { formatPhoneNumber, formatDate, getConditionalRequirements, getSelectionFromType, getSelectionFromKey, isSelect, isValidDate, validatePhoneNumber, validPhoneNumberMessage, validateSocial, getActionRequirement } from '_helpers';
import { Prompt } from 'react-router-dom';

class CompleteTaskRequirementsAction extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedEmployees: null
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleBirthDateChange = this.handleBirthDateChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.saveSignatureCallback = this.saveSignatureCallback.bind(this);
        this.selectEmployeesCallback = this.selectEmployeesCallback.bind(this);
        this.getDefaultValueForSelection = this.getDefaultValueForSelection.bind(this);
        this.getInputForKey = this.getInputForKey.bind(this);
        this.isRequirementDone = this.isRequirementDone.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    handleTextChange(e) {
        const { dispatch } = this.props;

        var key = e.target.getAttribute("id");
        var type = e.target.getAttribute("rtype");
        var subtype = e.target.getAttribute("rsubtype");
        var selection = e.target.value;

        if(key === 'PhoneNumber' || key === 'EmergencyContactNumber'|| (type === '7' && subtype === '7')) {
            this.setState({
                validPhoneNumbers: validatePhoneNumber(selection)
            });
        }

        if(type === '7' && subtype === '6') {
            this.setState({
                validSocial: validateSocial(selection)
            });
        }

        dispatch(activeActions.updateActionRequirementSelection({ key: key, selection: selection, type, subtype }));
    }

    handleChange(e) {
        const { dispatch, currentActionRequirements } = this.props;
        var index = e.target.selectedIndex;
        
        var type = e.target.getAttribute("rtype");
        var oId = e.target.childNodes[index].getAttribute("id");
        var oName = e.target.childNodes[index].getAttribute("name");

        //update selection for main select
        var selection = getSelectionFromType(parseInt(type), {option_id: oId, name: oName});
        dispatch(activeActions.updateActionRequirementSelection({ key: e.target.id, selection: selection, type }));

        //handle any child requirements
        var mainReq = getActionRequirement(currentActionRequirements.requirements, e.target.id);
        if(mainReq && mainReq.conditional_requirements && mainReq.conditional_requirements[selection]) {
            var conditionalReqs = mainReq.conditional_requirements[selection];

            for(var i = 0; i < conditionalReqs.length; i++) {
                var cReq = conditionalReqs[i];

                if(isSelect(cReq.type)) {
                    dispatch(activeActions.updateActionRequirementSelection({key: cReq.key, selection: cReq.options[0].name, type: cReq.type }));
                }
            }
        }

        this.forceUpdate();
    }

    handleSelectChange(value, event) { 
        const { dispatch } = this.props;

        var key = event.target.name;

        dispatch(activeActions.updateActionRequirementSelection({ key: key, selection: value }));
        this.forceUpdate();
    }

    handleBirthDateChange(updatedDate) {
        const { dispatch } = this.props;

        dispatch(activeActions.updateActionRequirementSelection({ key: 'BirthDate', selection: updatedDate.toISOString() }));
        this.forceUpdate();
    }

    handleDateChange(key, updatedDate) {
        const { dispatch } = this.props;

        dispatch(activeActions.updateActionRequirementSelection({ key, selection: formatDate(updatedDate.toISOString()) }));
        this.forceUpdate();
    }

    handleUpload(result) {
        const { dispatch } = this.props;
        
        dispatch(activeActions.updateActionRequirementSelection({ key: result.id, selection: result.fileUpload.file_upload_id, type: result.rtype}));
    }

    saveSignatureCallback(callback) {
        const { dispatch } = this.props;
        dispatch(activeActions.updateActionRequirementSelection({ key: callback.key, selection: callback.image}));
        this.forceUpdate();
    }

    selectEmployeesCallback(employees, roles) {
        const { taskRequiresCustomRole } = this.props;

        var selectedEmployees = [];
        if(taskRequiresCustomRole) {
            selectedEmployees = Object.keys(employees).map(e => {
                return {
                    employee_id: employees[e].user_id,
                    custom_role: roles[e]
                }
            });
        } else {
            selectedEmployees = Object.keys(employees).map(e => {
                return {
                    employee_id: employees[e].user_id
                }
            });
        }
        
        this.setState({
            selectedEmployees: selectedEmployees
        });
    }

    getDefaultValueForSelection(key) {
        const { currentActionRequirementSelections } = this.props;

        for(var i = 0; i < currentActionRequirementSelections.length; i++) {
            if(key === currentActionRequirementSelections[i].key) {
                return currentActionRequirementSelections[i].selection;
            }
        }
    }

    getInputForKey(cr) {
        const { user } = this.props;

        if(cr.key === 'State') {
            return <RegionDropdown
                        className={styles.crselect}
                        blankOptionLabel='Select a country'
                        country={user.country || 'United States'}
                        defaultOptionLabel='Select a state'
                        name='State'
                        onChange={this.handleSelectChange}
                        value={this.getDefaultValueForSelection(cr.key) || cr.secondary_display} />;
        } else if (cr.key === 'Country') {
            return <CountryDropdown
                        className={styles.crselect}
                        defaultOptionLabel='Select a country'
                        name='Country'
                        onChange={this.handleSelectChange}
                        value={this.getDefaultValueForSelection(cr.key) || cr.secondary_display} />
        } else if (cr.key === 'BirthDate') {
            return <DateTimePicker
                        time={false}
                        name='BirthDate'
                        max={new Date()} 
                        defaultValue={isValidDate(this.getDefaultValueForSelection(cr.key)) || isValidDate(cr.secondary_display) ? new Date(this.getDefaultValueForSelection(cr.key) || cr.secondary_display) : undefined} 
                        onChange={this.handleDateChange}
                        placeholder={'Enter Your DOB (mm/dd/yyyy)'}/>
        } else if(cr.key === 'PhoneNumber' || cr.key === 'EmergencyContactNumber') {
            var p = this.getDefaultValueForSelection(cr.key) || '';
            
            return  <FormGroup>
                        <Input 
                            className={styles.verifyinput}
                            id={cr.key} readOnly={cr.read_only}
                            rtype={cr.type} rsubtype={cr.sub_type}
                            defaultValue={p}
                            onChange={(e) => {e.target.value = formatPhoneNumber(this.getDefaultValueForSelection(cr.key), e.target.value); this.handleTextChange(e);}}
                            invalid={!validatePhoneNumber(p)}/>
                            <FormFeedback invalid="true">
                                {validPhoneNumberMessage()}
                            </FormFeedback>
                    </FormGroup>;
        } else {
            return <Input
                        autoCapitalize={'words'}
                        className={styles.verifyinput}
                        id={cr.key} readOnly={cr.read_only}
                        rtype={cr.type}
                        defaultValue={this.getDefaultValueForSelection(cr.key) || cr.secondary_display}
                        onChange={this.handleTextChange}/>;
        }  
    }

    isRequirementDone(contentRequirement, selections) {
        if(selections) {
            for(var i = 0; i < selections.length; i++) {
                if (contentRequirement.key === selections[i].key) {
                    return selections[i].selection ? true : false;
                }
            }
        }

        return false;
    }

    onSubmit() {
        const { dispatch, currentActionRequirements, currentActionRequirementSelections, taskRequiresEmployees } = this.props;
        const { selectedEmployees } = this.state;
        
        var requirementsLookup = (currentActionRequirements.requirements || []).reduce((d, v) => {d[v.key] = v; return d}, {});
        var selectionsLookup = (currentActionRequirementSelections || []).reduce((d, v) => {d[v.key] = v; return d}, {});

        //actually verify data here
        var isVerified = true;
        for(var i = 0; i < currentActionRequirementSelections.length; i++) {
            var rs = currentActionRequirementSelections[i];

            if(rs.type !== 6 && !rs.selection) {
                isVerified = false;
                break;
            }

            if(rs.type === 6 && rs.sub_type === 1 && !rs.selection) {
                isVerified = false;
                break;
            }

            if(rs.type === 6 && rs.sub_type === 2 && (rs.selectables || []).length < rs.min_selections) {
                isVerified = false;
                break;
            }

            if(isSelect(rs.type) && requirementsLookup[rs.key] && requirementsLookup[rs.key].conditional_requirements) {
                var requiredConditionals = requirementsLookup[rs.key].conditional_requirements[rs.selection];

                if(requiredConditionals) {
                    for(var j = 0; j < requiredConditionals.length; j++) {
                        var cReq = requiredConditionals[j];
                        var reqSelection = selectionsLookup[cReq.key];

                        if(!reqSelection) {
                            isVerified = false;
                            break;
                        }
                    }
                }
            }
        }

        if(taskRequiresEmployees && (!selectedEmployees || selectedEmployees.length <= 0)) {
            isVerified = false;
        } else {
            currentActionRequirementSelections.push({key: 'RequiresEmployees', employees: selectedEmployees, type: 0})
        }

        if(isVerified) {
            var action_id = currentActionRequirements.action.action_id;
            var content_id = currentActionRequirements.action.content_id;

            dispatch(activeActions.completeAction({ action_id, content_id, selections: currentActionRequirementSelections }));
        } else {
            dispatch(alertActions.error("Please enter in all required information and select employees if required"))
        }
    }

    /* REACT HOOKS BELOW */
    componentDidMount() {
        const { dispatch, currentActionRequirements } = this.props;

        dispatch(activeActions.getTaskRequiresEmployees(currentActionRequirements.action.content_id));
        dispatch(activeActions.getTaskRequireCustomRole(currentActionRequirements.action.content_id));
    }

    render() {
        const { completingCurrentAction, currentActionCompleted, currentActionRequirements, currentActionRequirementSelections, taskRequiresEmployees, taskRequiresCustomRole } = this.props;

        if(!currentActionRequirements) {
            return null;
        }

        return (
            <div className={styles.container}>
                <Prompt when={!currentActionCompleted} message={'Are you sure you want to leave? All answers and information will be lost. Pressing "Ok" will lose all your updated answers.'}/>
                <div className={styles.headingcontainer}>
                    <h4 className={styles.heading}>Complete Your To Do</h4>
                </div>
                <Form className={styles.form}>
                {
                    currentActionRequirements.requirements.map(cr => {
                        return  <div key={cr.key}>
                                    {(cr.type === 2 || cr.type === 24 || cr.type === 29) &&
                                        <div>
                                            <FormGroup>
                                                <Label for={cr.display}>Select {cr.display}</Label>
                                                <Input type="select" id={cr.key} rtype={cr.type} onChange={this.handleChange}>
                                                {
                                                    cr.selectables.map(o => {
                                                        return <option key={o.selectable_id} id={o.selectable_id} name={o.selectable_text}>{o.selectable_text}</option>
                                                    })
                                                }
                                                </Input>
                                            </FormGroup>
                                            {getConditionalRequirements(cr.conditional_requirements, getSelectionFromKey(currentActionRequirementSelections, cr.key)) &&
                                                <div className={styles.conditionalrequirementscontainer}>
                                                {
                                                    getConditionalRequirements(cr.conditional_requirements, getSelectionFromKey(currentActionRequirementSelections, cr.key)).map(cReq => {
                                                        return  <FormGroup key={cReq.key} className={styles.conditionalrequirement}>
                                                                    <Label for={cReq.display}>{cReq.display}</Label>
                                                                    {(cReq.type === 24 || cReq.type === 29) &&
                                                                        <Input type="select" id={cReq.key} rtype={cReq.type} subtype={cReq.sub_type} onChange={this.handleChange}>
                                                                        {
                                                                            cReq.options.map(o => {
                                                                                return <option key={o.option_id} id={o.selectable_id} name={o.name}>{o.name}</option>
                                                                            })
                                                                        }
                                                                        </Input>
                                                                    }
                                                                    {(cReq.type === 32 || cReq.type === 33) &&
                                                                        <FileUploadModal buttonColor={'secondary'} id={cReq.key} rtype={cReq.type} subtype={cReq.sub_type} upid={cReq.value} saveCallback={this.handleUpload}/>
                                                                    }
                                                                    {(cReq.type !== 32 && cReq.type !== 33 && cReq.type !== 24 && cReq.type !== 29) &&
                                                                        <Input type="text" id={cReq.key} rtype={cReq.type} subtype={cReq.sub_type} onChange={this.handleTextChange}></Input>
                                                                    }
                                                                </FormGroup>
                                                    })
                                                }
                                                </div>
                                            }
                                        </div>
                                    }
                                    {(cr.type === 5 || cr.type === 25) &&
                                        <FormGroup>
                                            <Label for={cr.display}>{cr.display}</Label>
                                            {this.getInputForKey(cr)}
                                        </FormGroup>
                                    }
                                    {(cr.type === 8 || cr.type === 26) && cr.manual_type === 1 &&
                                        <FormGroup>
                                            <Label for={cr.display}>{cr.display}</Label>
                                            <DateTimePicker
                                                time={false}
                                                id={cr.key}
                                                defaultValue={null} 
                                                onChange={(date) => this.handleDateChange(cr.key, date)}
                                                placeholder={'Enter Date (mm/dd/yyyy)'}/>
                                        </FormGroup>
                                    }
                                    {(cr.type === 8 || cr.type === 26) && cr.manual_type === 3 &&
                                        <FormGroup>
                                            <Label for={cr.display}>{cr.display}</Label>
                                            <Input type="text" id={cr.key} rtype={cr.type} onChange={this.handleTextChange}></Input>
                                        </FormGroup>
                                    }
                                    {(cr.type === 8 || cr.type === 26) && (cr.manual_type !== 1 && cr.manual_type !== 3) &&
                                        <FormGroup>
                                            <Label for={cr.display}>{cr.display}</Label>
                                            <Input type="text" id={cr.key} rtype={cr.type} onChange={this.handleTextChange}></Input>
                                        </FormGroup>
                                    }
                                    {(cr.type === 10 || cr.type === 13 || cr.type === 27 || cr.type === 28) &&
                                        <FormGroup>
                                            <SignatureModal buttonText={`Sign ${cr.display}`} disabled={this.isRequirementDone(cr, currentActionRequirementSelections)} id={cr.key} rtype={cr.type} saveCallback={this.saveSignatureCallback} stripMetaData={false}/>
                                        </FormGroup>
                                    }
                                    {(cr.type === 32 || cr.type === 33) &&
                                        <FileUploadModal buttonColor={'secondary'} id={cr.key} rtype={cr.type} subtype={cr.sub_type} upid={cr.secondary_display} saveCallback={this.handleUpload}/>
                                    }
                                </div>
                    })
                }
                </Form>
                {taskRequiresEmployees &&
                    <div className={styles.subcontainer}>
                        <EmployeeSelector customRoles={taskRequiresCustomRole} selectedCallback={this.selectEmployeesCallback}/>
                    </div>
                }
                <ConfirmationModal  bodyText={`By finalizing the task you will be creating to do's for your selected employees.`} 
                                    buttonColor="success"
                                    buttonText="Finalize Task"
                                    buttonClassOverride={styles.complete}
                                    containerClassOverride={styles.confirmation}
                                    disabled={completingCurrentAction}
                                    confirmCallback={this.onSubmit}/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { active, authentication } = state;
    const { completingCurrentAction, currentActionCompleted, currentActionRequirements, currentActionRequirementSelections, currentActionCompletionResult, taskRequiresEmployees, taskRequiresCustomRole } = active;
    const { user } = authentication;

    return {
        completingCurrentAction,
        currentActionCompleted,
        currentActionCompletionResult,
        currentActionRequirements,
        currentActionRequirementSelections,
        taskRequiresEmployees, 
        taskRequiresCustomRole,
        user
    };
}

const connectedCompleteTaskRequirementsAction = connect(mapStateToProps)(CompleteTaskRequirementsAction);
export { connectedCompleteTaskRequirementsAction as CompleteTaskRequirementsAction };