import React from 'react';
import styles from './UpdateBillingInfoPage.module.css';

import { connect } from 'react-redux';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { CheckoutForm } from '_components/CheckoutForm/CheckoutForm';
import { config } from '../_helpers'

class UpdateBillingInfoPage extends React.Component { 
    
    constructor(props) {
        super(props);       
        this.state = {
            user: props.user, //this takes the user out of redux store and copies it to local component state so that changes dont take effect until confirmed
        };
    }
  
    render() {   
        return (
            <StripeProvider apiKey={config.stripe_pk}>
                <div className={styles.container}>
                    <div className={styles.headingcontainer}>
                        <h4 className={styles.header}>Company Billing Information</h4>
                    </div>
                    <Elements>
                        <CheckoutForm />
                    </Elements>
                </div>
            </StripeProvider>
        );
    }  
}

function mapStateToProps(state) {      
    const { authentication } = state;
    const { user } = authentication;

    return {
        user
    };
}

const connectedUpdateBillingInfoPage = connect(mapStateToProps)(UpdateBillingInfoPage);
export { connectedUpdateBillingInfoPage as UpdateBillingInfoPage };