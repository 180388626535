import React from 'react';
import PropTypes from 'prop-types';
import styles from './LoginForm.module.css';
import { MdLock } from 'react-icons/md';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Label, Form, FormGroup, Input } from 'reactstrap';
import { userActions } from '_actions';

class LoginForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            submitted: false,
            remember: false,
            redirectPath: props.redirectPath
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleClick() {
        const { remember } = this.state;

        this.setState({
            remember: !remember
        });
    }

    handleSubmit(e) {
        e.preventDefault();

        const { username, password, redirectPath } = this.state;
        const { dispatch } = this.props;

        if (username && password) {
            this.setState({ submitted: true });
            dispatch(userActions.login(username, password, redirectPath));
        }
    }

    render() {
        const { username, password, remember} = this.state;

        return (
            <Form name="form" onSubmit={this.handleSubmit} className={styles.form}>
                <FormGroup>
                    <Label>Email</Label>
                    <Input type="text" name="username" value={username} onChange={this.handleChange}></Input>
                </FormGroup>
                <FormGroup>
                    <Label>Password</Label>
                    <Input type="password" name="password" value={password} onChange={this.handleChange}></Input>
                </FormGroup>
                <FormGroup check>
                    <Input type="checkbox" name="remember" checked={remember} onChange={this.handleClick}></Input>
                    <Label name="remember" className={styles.noselect} onClick={this.handleClick}>Remember me</Label>
                </FormGroup>
                <FormGroup className={styles.container}>
                    <Button color="primary" className={styles.submit}><MdLock/> Login</Button>
                </FormGroup>
                <FormGroup>
                    <Link to="/password/reset" className="btn btn-link">I forgot my password</Link>
                </FormGroup>
                <FormGroup>
                    <span>New to TackleDocs?</span>
                    <Link to="/register" className="btn btn-link">Create an account</Link>
                </FormGroup>
            </Form>
        );
    }
}

LoginForm.propTypes = {
    redirectPath: PropTypes.string
};
  
LoginForm.defaultProps = {
    redirectPath: null
};

function mapStateToProps(state) {
    const { loggingIn } = state.authentication;
    return {
        loggingIn
    };
}

const connectedLoginForm = connect(mapStateToProps)(LoginForm);
export { connectedLoginForm as LoginForm };