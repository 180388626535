import React from 'react';
import styles from './HomePage.module.css';

import { connect } from 'react-redux';
import { CompanyListGroup } from '_components/CompanyListGroup';
import { ActionListGroup } from '_components/ActionListGroup/';

class UserHomePage extends React.Component {
    render() {
        const { user } = this.props;
        return (
            <div className={styles.container}>
                <h2 className={styles.heading}>Welcome {user.first_name} {user.last_name},</h2>
                <p className={styles.para}>Overview of your relationships and to do's</p>
                <div className={styles.main}>
                    <CompanyListGroup/>
                    <ActionListGroup filter={'active'} allCompanies={true}/>
                </div>              
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    const { user } = authentication;
    return {
        user
    };
}

const connectedUserHomePage = connect(mapStateToProps)(UserHomePage);
export { connectedUserHomePage as UserHomePage };