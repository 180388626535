import React from 'react';
import PropTypes from 'prop-types';
import checked from '_images/thumbsup.png';
import styles from './TaskTable.module.css';

import DataTable from 'react-data-table-component';
import { MdPageview } from 'react-icons/md';
import { connect } from 'react-redux';
import { status, formatShortDateTime, customTableTheme, exportTasks, getLocalPref, updateLocalPref } from '_helpers';
import { activeActions } from '_actions';
import { Badge, Button, Input } from 'reactstrap';
import { FaFileExcel } from 'react-icons/fa';

class TaskTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentSearch: '',
            filters: [],
            selectedTemplateId: getLocalPref('companyTasksSelectedTemplate') || null,
            selectedYear: getLocalPref('companyTasksSelectedYear') || 'all'
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleFilterApply = this.handleFilterApply.bind(this);
        this.handleYearChange = this.handleYearChange.bind(this);
        this.handleBadgeClick = this.handleBadgeClick.bind(this);
        this.handleTemplateClick = this.handleTemplateClick.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleExport = this.handleExport.bind(this);
    }

    columns = [
        {
            name: 'Manager',
            selector: row => row['manager'],
            sortable: true,
            hide: 'sm',
            wrap: true
        },
        {
            name: 'Task Name',
            selector: row => row['template_name'],
            sortable: true,
            grow: 2,
        },
        {
            name: 'Start Date',
            selector: row => row['create_date'],
            format: row => formatShortDateTime(row.ref.create_date),
            sortable: true,
            hide: 'sm',
            wrap: true
        },
        {
            name: 'Last Updated',
            selector: row => row['last_updated'],
            format: row => formatShortDateTime(row.ref.last_update_date),
            sortable: true,
            wrap: true
        },
        {
            name: 'Status',
            selector: row => row['status'],
            sortable: true,
            hide: 'sm'
        },
        {
            name: '',
            right: true,
            cell: row => <MdPageview className={styles.view} tid={row.id} onClick={this.handleClick}>View</MdPageview>
        }
    ];

    handleClick(event) {
        const { dispatch } = this.props;    
        dispatch(activeActions.getTaskSummary(event.currentTarget.getAttribute('tid')));
    }

    handleTextChange(event) {
        this.setState({
            currentSearch: event.target.value
        })
    }

    handleFilterApply() {
        const { currentSearch, filters } = this.state;
        if(currentSearch) {
            if(!filters.includes(currentSearch)) {
                filters.push(currentSearch);
            }

            this.setState({
                currentSearch: '',
                filters: filters
            })
        }
    }

    handleYearChange(event) {
        var index = event.target.selectedIndex;
        var selectedOption = event.target.childNodes[index];
        var year = selectedOption.getAttribute('year');

        this.setState({
            selectedYear: year
        });

        updateLocalPref('companyTasksSelectedYear', year);
    }

    handleBadgeClick(e) {
        var id = e.target.getAttribute("fid");

        const { filters } = this.state;

        for(var i = 0; i < filters.length; i++) {
            if(filters[i] === id) {
                filters.splice(i, 1);
            }
        }

        this.setState({
            filters: filters
        });
    }

    handleTemplateClick(e) {
        const { selectedTemplateId } = this.state;

        var id = e.target.getAttribute("ttid");
        var updatedValue = selectedTemplateId === id ? null : id;

        this.setState({
            selectedTemplateId: updatedValue
        });

        updateLocalPref('companyTasksSelectedTemplate', updatedValue);
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.handleFilterApply();
        }
    }

    handleExport() {
        const { tasks } = this.props;

        exportTasks(tasks);
    }
    
    componentDidMount() {
        const { dispatch, filter } = this.props;       
        dispatch(activeActions.getAllTasksForCurrentCompany(filter));
    }

    render() {
        const { currentSearch, filters, selectedTemplateId, selectedYear } = this.state;
        const { possibleTaskYears, tasks, taskTemplates } = this.props;

        //filter the tasks for the selected template
        var templateFilteredTasks = tasks.filter(t => selectedTemplateId === null || t.task_template.task_template_id === selectedTemplateId);

        //filter the tasks for the selected year
        var yearFilteredTasks = templateFilteredTasks.filter(t => selectedYear === 'all' || t.last_update_date.startsWith(selectedYear));

        //apply filters for only non cancelled tasks
        var data = yearFilteredTasks.filter(t => t.status !== 4).reduce((result, t) => {
            if(filters.length === 0) {
                result.push({
                    id: t.task_id,
                    manager: t.manager_name,
                    template_name: t.name,
                    create_date: t.create_date,
                    last_updated: t.last_update_date,
                    status: status(t.status),
                    ref: t
                });
            } else {
                for(var i = 0; i < filters.length; i++) {
                    if( t.name.toLowerCase().includes(filters[i].toLowerCase()) || 
                        t.manager_name.toLowerCase().includes(filters[i].toLowerCase())) {

                        result.push({
                            id: t.task_id,
                            manager: t.manager_name,
                            template_name: t.name,
                            create_date: t.create_date,
                            last_updated: t.last_update_date,
                            status: status(t.status),
                            ref: t
                        });
                        break;
                    }
                }
            }
            return result;
        }, []);

        return (
            <div className={styles.container}>
                <div className={styles.taskbarcontainer}>
                    <Input type='select' className={styles.selectinput} value={selectedYear} onChange={this.handleYearChange}>
                        <option key={'all'} value={'all'} year={'all'}>All Years</option>
                        {possibleTaskYears.map(y => {
                            return <option key={y} value={y} year={y}>{y}</option>
                        })
                        }
                    </Input>
                    <div className={styles.filtercontainer}>
                        {filters.map(f => {

                            return  <Badge pill className={styles.badge} key={f} fid={f} onClick={this.handleBadgeClick}>
                                        <span className={styles.btext} fid={f} onClick={this.handleBadgeClick}>{f}</span>
                                        <span fid={f} className={styles.ex} onClick={this.handleBadgeClick}>X</span>
                                    </Badge>
                            
                        })
                        }
                        <Input className={styles.filterinput} placeholder='Search...' onChange={this.handleTextChange} onKeyDown={this.handleKeyPress} value={currentSearch}/>
                        <Button className={styles.filterbutton} onClick={this.handleFilterApply}>Apply</Button>
                        <Button className={styles.filterbutton} color="success" onClick={this.handleExport}><FaFileExcel/></Button>
                    </div>
                </div>
                <div className={styles.templatetypecontainer}>
                    <div className={styles.btntoolbar}>
                        {taskTemplates.map(tt => {
                            
                            return <Button active={tt.task_template_id === selectedTemplateId} className={styles.bgbutton} key={tt.task_template_id} ttid={tt.task_template_id} onClick={this.handleTemplateClick}>{tt.name}</Button>
                        })
                        }
                    </div>
                </div>
                <DataTable
                    columns={this.columns}
                    data={data}
                    striped
                    fixedHeader
                    fixedHeaderScrollHeight='500px'
                    customTheme={customTableTheme}
                    noHeader
                    noDataComponent={
                        <div className={styles.nodatacontainer}>
                            <span className={styles.heading}>No Tasks!</span>
                            <span className={styles.para}>You have nothing to view at this time.</span>
                            <img className={styles.checkedimg} src={checked} alt=":("/>
                        </div>
                    }
                />
            </div>        
        );
    }
}

TaskTable.propTypes = {
    filter: PropTypes.string
};
  
TaskTable.defaultProps = {
    filter: 'all'
};

function mapStateToProps(state) {
    const { active } = state;
    const { possibleTaskYears, tasks, taskTemplates } = active;
    return {
        possibleTaskYears,
        tasks,
        taskTemplates
    };
}

const connectedTaskTable = connect(mapStateToProps)(TaskTable);
export { connectedTaskTable as TaskTable };